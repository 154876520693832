import { TreeSelect } from 'antd';
import * as React from 'react';
import { useInput } from 'react-admin';
import { forwardRef, useImperativeHandle, useState } from 'react';

interface TreeInputProps {
    source: string;
    data: any;
    placeholder: string;
    treeCheckable: boolean;
    defaultValue?: any;
    disabled?: boolean;
}

 const TreeInput = forwardRef((props: TreeInputProps, ref) => {
    const {
        data,
        placeholder,
        treeCheckable,
        defaultValue,
        disabled,
        ...rest
    } = props;
    const {
        input: { onChange, value, onBlur },
        meta: { touched, error },
    } = useInput(rest);
    const [selectedItem, setSelectedItem] = useState(defaultValue ?? undefined);
    useImperativeHandle(ref, () => ({
        getSelectedItem: () => selectedItem,
    }));

    return (
        <TreeSelect
            treeLine={{
                showLeafIcon: false,
            }}
            value={value != '' ? value : undefined}
            showSearch
            treeNodeFilterProp="title"
            style={{
                width: '100%',
            }}
            dropdownStyle={{
                maxHeight: 400,
            }}
            allowClear
            treeDefaultExpandAll
            onBlur={onBlur}
            treeData={data}
            onChange={item => {
                // console.log(item);
                setSelectedItem(item);
                onChange(item);
            }}
            treeCheckable={treeCheckable}
            placeholder={placeholder}
            defaultValue={defaultValue ?? undefined}
            disabled={disabled}
        />
    );
})

export default TreeInput;
