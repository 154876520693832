export const isObject = (value: any) => {
    return typeof value === 'object';
};

export const isDateObject = (value: any) => {
    return isObject(value) && typeof value?.getMonth === 'function';
};

export const isArray = (value: any) => {
    return isObject(value) && Array.isArray(value);
};

export const serialize = (obj, prefix?: string): string => {
    let str: string[] = [];
    let propertyName: string;
    for (propertyName in obj) {
        if (obj.hasOwnProperty(propertyName)) {
            const key: string = prefix
                ? `${prefix}.${propertyName}`
                : propertyName;
            let propertyValue: any = obj[propertyName];
            if (isDateObject(propertyValue)) {
                str.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        (propertyValue as Date).toLocaleString()
                    )}`
                );
            } else if (isArray(propertyValue)) {
                (propertyValue as any[]).forEach(value => {
                    if (isObject(value)) {
                        str.push(serialize(value, key));
                    } else {
                        str.push(
                            `${encodeURIComponent(key)}=${encodeURIComponent(
                                value
                            )}`
                        );
                    }
                });
            } else if (isObject(propertyValue)) {
                str.push(serialize(propertyValue, key));
            } else if (propertyValue !== null && propertyValue !== undefined) {
                str.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        propertyValue
                    )}`
                );
            }
        }
    }
    return str.join('&');
};