import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    CreateProps,
    FormTab,
    required,
    TabbedForm,
    TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import GroupPermission from './GroupPermission';
import {
    CallSplitTwoTone,
    CallTwoTone,
    CategoryTwoTone,
    DomainTwoTone,
    DraftsTwoTone,
    EmojiEventsTwoTone,
    ErrorTwoTone,
    HowToRegTwoTone,
    LensTwoTone,
    LibraryBooksTwoTone,
    NotificationsTwoTone,
    PageviewTwoTone,
    SettingsTwoTone,
    SupervisorAccountTwoTone,
} from '@material-ui/icons';
import visitors from '../user';
import GroupTwoToneIcon from '@material-ui/icons/GroupTwoTone';
import DashboardTwoTone from '@material-ui/icons/DashboardTwoTone';
import CommonEditToolbar from '../component/CommonEditToolbar';

const ICON_MAP = {
    category: <CategoryTwoTone color={'primary'} />,
    call: <CallTwoTone color={'primary'} />,
    'gsv-rating': <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />,
    callReflect: <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />,
    groupCallService: <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />,
    'classify-by-theme': (
        <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />
    ),
    'classify-gsv': <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />,
    distribution: <CallSplitTwoTone color={'primary'} />,
    'evaluation-form': (
        <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />
    ),
    department: <DomainTwoTone color={'primary'} />,
    department_list: <DomainTwoTone color={'primary'} />,
    user: <visitors.icon color={'primary'} />,
    user_list: <visitors.icon color={'primary'} />,
    group: <GroupTwoToneIcon color={'primary'} />,
    complain: <ErrorTwoTone color={'primary'} />,
    supervised: <CallTwoTone color={'primary'} />,
    period: <LibraryBooksTwoTone color={'primary'} />,
    telephonistGroup: <SupervisorAccountTwoTone color={'primary'} />,
    keywords: <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />,
    report: <EmojiEventsTwoTone color={'primary'} />,
    dashboard: <DashboardTwoTone color={'primary'} />,
    warningCategory: <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />,
    systemSettings: <SettingsTwoTone color={'primary'} />,
    notifyTemplate: <DraftsTwoTone color={'primary'} />,
    notifyFlow: <NotificationsTwoTone color={'primary'} />,
    partner: <HowToRegTwoTone color={'primary'} />,
    filtersTemplate: <PageviewTwoTone color={'primary'} />,
};

export const styles = {
    input: { width: '24em' },
    tab: {
        width: '100%',
    },
};

const useStyles = makeStyles(styles);

const GroupCreate: FC<CreateProps> = props => {
    const classes = useStyles();

    return (
        <Create {...props} actions={<CommonEditToolbar />}>
            <TabbedForm redirect="list">
                <FormTab label="resources.group.tabs.information">
                    <TextInput
                        className={classes.input}
                        autoFocus
                        source="groupName"
                        validate={required()}
                    />
                    <TextInput
                        source="groupCode"
                        validate={required()}
                        className={classes.input}
                    />
                    <TextInput
                        source="description"
                        multiline
                        className={classes.input}
                        rows={4}
                    />
                </FormTab>
                <FormTab label="resources.group.tabs.permissions">
                    <GroupPermission />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default GroupCreate;
