import * as React from 'react';
import {FC, useState } from 'react';
import {
    Create,
    CreateProps,
    FormTab, Labeled,
    required,
    TabbedForm,
    TextInput,
    useQueryWithStore, useTranslate,
} from 'react-admin';

import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import CommonEditToolbar from '../../component/CommonEditToolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import TreeInput from "./TreeInput";

const useStyles = makeStyles({
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tab: {
        maxWidth: '40em',
        display: 'block',
    },
});
const PhanAnhCreate: FC<CreateProps> = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const { loaded, data } = useQueryWithStore({
        type: 'getList',
        resource: 'callReflect',
        payload: {
            filter: {
                parentId: null,
            },
            sort: { field: 'name', order: 'DESC' },
            pagination: { page: 1, perPage: 1000 },
        },
    });
    const entryList: Array<{
        parentId: string | null | undefined;
        title: string | null | undefined;
        value: string | null | undefined;
    }> = data
        ? data.map(d => {
            return {
                parentId: d.parentId,
                title: d.name,
                value: d.id,
            };
        })
        : [];

    const nestTree = function (
        items,
        value = null,
        link = 'parentId',
        level = 0
    ) {
        if (level === 2) {
            return;
        } else {
            return items
                .filter(item => item[link] === value)
                .map(item => ({
                    ...item,
                    children: nestTree(
                        items,
                        item.value,
                        'parentId',
                        level + 1
                    ),
                }));
        }
    };

    return (
        <Create
            {...props}
            title={'Tạo mới chủ đề cuộc gọi'}
            actions={<CommonEditToolbar />}
        >

            <TabbedForm redirect={"list"} >
                <FormTab label="Thêm chủ đề tích mã" contentClassName={classes.tab}>
                    <TextInput
                        validate={requiredValidate}
                        source="name"
                        fullWidth
                        label={translate('resources.callReflect.fields.name')}
                    />
                    <Box className="my_ant_tree_select single_choice">
                        <Labeled label={"Cấp cha"}>
                            <TreeInput source={'parentId'} data={nestTree(entryList)} treeCheckable={false} placeholder={"Tìm kiếm chủ đề cấp 1"}/>
                        </Labeled>
                    </Box>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

const requiredValidate = [required()];

export default PhanAnhCreate;
