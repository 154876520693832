import { AttachFile, CloudUpload, Publish } from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { Button, fetchUtils, useNotify, useRefresh } from 'react-admin';
import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { BASE_URL } from '../../common/constants';

const useStyles = makeStyles(theme => ({
    dropzone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        '&:focus': {
            borderColor: '#2196f3',
        },
    },
    errorDialog: {
        '& .MuiPaper-root': {
            minWidth: '740px !important',
        },
    },
}));

const ImportButton = props => {
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const [file, setFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openError, setOpenError] = useState(false);
    const [errorData, setErrorData] = useState<any[]>([]);
    const handleClose = () => {
        setFile(null);
        setOpen(false);
    };
    const handleCloseError = () => {
        setOpenError(false);
    };
    const openPopup = () => {
        setOpen(true);
    };
    const handleFile = acceptedFiles => {
        if (acceptedFiles) setFile(acceptedFiles[0]);
    };
    const importFile = () => {
        if (!file) {
            notify('Vui lòng tải file lên', 'error');
            return;
        }
        setLoading(true);
        const data = new FormData();
        // @ts-ignore
        data.append('file', file);
        fetchUtils
            .fetchJson(`${BASE_URL}/callReflect/import/`, {
                method: 'POST',
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    LdapType: `${localStorage.getItem('ldap_type')}`,
                }),
                body: data,
            })
            .then(r => {
                setLoading(false);
                notify('Nhập chủ đề tích mã thành công', 'success');
                handleClose();
                refresh();
            })
            .catch(({ body }) => {
                setLoading(false);
                if (
                    body.errorCode !== null &&
                    body.errorCode !== undefined &&
                    body.errorCode === 'error.importUser.hasErrorRows' &&
                    Array.isArray(body.params)
                ) {
                    setErrorData(body.params);
                    setOpenError(true);
                } else {
                    notify('Có lỗi khi import', 'error');
                }
            });
    };

    return (
        <>
            <Button
                variant="text"
                onClick={openPopup}
                startIcon={<Publish />}
                label={'Nhập'}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Nhập chủ đề tích mã
                </DialogTitle>
                <DialogContent>
                    <Dropzone onDrop={handleFile}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div
                                    {...getRootProps({
                                        className: classes.dropzone,
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    {!file && (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CloudUpload />
                                            <Box ml={1} />
                                            <p>
                                                Kéo thả hoặc nhấp để tải lên
                                                file excel nhập danh sách chủ đề
                                                tích mã.
                                            </p>
                                        </Box>
                                    )}
                                    {file && (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <AttachFile />
                                            {/*// @ts-ignore*/}
                                            <p>{file.name}</p>
                                        </Box>
                                    )}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </DialogContent>
                <DialogActions>
                    <Box mx={2} my={1}>
                        <Button
                            onClick={importFile}
                            variant="contained"
                            color="primary"
                            label={'Xác nhận'}
                            disabled={loading}
                            startIcon={
                                loading ? <CircularProgress size={16} /> : null
                            }
                        />
                        <Button
                            onClick={handleClose}
                            color="primary"
                            label={'Hủy'}
                        />
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ImportButton;
