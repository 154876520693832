import * as React from 'react';
import { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import { FieldProps } from 'react-admin';
import { Complain } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
    danger: { backgroundColor: red[500], color: '#fff' },
});

const StatusField: FC<FieldProps<Complain>> = ({ record }) => {
    const classes = useStyles();
    return record ? (
        <span className={classes.main}>
            {record.status === 'chuaXuLy' ? (
                <Chip
                    size="small"
                    key={record.status}
                    className={classes.chip}
                    label="Chưa xử lý"
                />
            ) : record.status === 'dongY' ? (
                <Chip
                    color={'primary'}
                    size="small"
                    key={record.status}
                    className={classes.chip}
                    label="Đồng ý"
                />
            ) : (
                <Chip
                    size="small"
                    key={record.status}
                    className={[classes.chip, classes.danger].join(' ')}
                    label="Từ chối"
                />
            )}
        </span>
    ) : null;
};

StatusField.defaultProps = {
    addLabel: true,
    source: 'status',
    label: 'Trạng thái',
};

export default StatusField;
