import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, ClassNameMap, MenuItem, Typography } from '@mui/material';
import { useTranslate } from 'ra-core';
import { AppState } from '../types';
import { updateHiddenFields } from '../resource-setting-reducer';
import { Button } from 'react-admin';

const useStyles = makeStyles({
    menuIcon: {
        margin: 'auto auto',
    },
    menu: {
        width: '30ch',
    },
    columns: {
        maxHeight: '21rem',
        overflow: 'auto',
    },
    title: {},
});

interface IToggleFieldsMenuProps {
    resource: string;
    allFields: string[];
    className?: ClassNameMap<'title' | 'menu' | 'menuIcon' | 'columns'>;
}

export const ToggleFieldsMenu = ({
    resource,
    allFields,
    className,
}: IToggleFieldsMenuProps) => {
    const translate = useTranslate();
    const [anchorElement, setAnchorElement] = useState(null);
    const dispatch = useDispatch();

    const { hiddenFields } = useSelector((state: AppState) => {
        // console.log(state)
        return state.resourceSetting[resource];
    });

    const open = Boolean(anchorElement);
    const classes = useStyles();

    const handleOpen = event => {
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const isHiddenField = (field: string, hiddenFields: string[]) => {
        return hiddenFields && hiddenFields.indexOf(field) !== -1;
    };

    const handleClick = field => {
        if (isHiddenField(field, hiddenFields)) {
            hiddenFields.splice(hiddenFields.indexOf(field), 1); //remove khỏi danh sách hidden fields;
        } else {
            hiddenFields.push(field); //them vao ds hidden fields;
        }
        dispatch(updateHiddenFields({ resource, hiddenFields: hiddenFields }));
    };

    return (
        <div className={`icon-dialog-show-columns`}>
            <Button
                onClick={handleOpen}
                variant="text"
                color="primary"
                label={'Cột hiển thị'}
                startIcon={<MoreVertIcon />}
            />
            <Menu
                id="long-menu"
                anchorEl={anchorElement}
                keepMounted
                open={open}
                onClose={handleClose}
                classes={{
                    paper: classes.menu,
                }}
            >
                {allFields && allFields.length ? (
                    <div>
                        <Typography
                            align={'center'}
                            variant={'h6'}
                            className={classes.title}
                        >
                            {'Cột hiển thị'}
                        </Typography>
                        <div
                            className={
                                className && className.columns
                                    ? className.columns
                                    : classes.columns
                            }
                        >
                            {allFields.map((field, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => handleClick(field)}
                                >
                                    <Checkbox
                                        checked={
                                            !isHiddenField(field, hiddenFields)
                                        }
                                    />
                                    {translate(
                                        `resources.${resource}.fields.${field}`
                                    )}
                                </MenuItem>
                            ))}
                        </div>
                    </div>
                ) : null}
            </Menu>
        </div>
    );
};
