import {
    Box,
    Checkbox,
    CheckboxProps,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import * as React from 'react';
import { grey } from '@material-ui/core/colors';
import { useN02SwitchStyles } from '@mui-treasury/styles/switch/n02';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    row: {
        backgroundColor: theme.palette.type === 'light' ? grey[100] : grey[900],
        borderColor: theme.palette.type === 'light' ? grey[200] : grey[800],
    },
}));

const PermissionRow = ({
    row,
    icon,
    checkPermissionState,
    permissionState,
}: any) => {
    const classes = useStyles();
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        permission
    ) => {
        const checked = event.target.checked;
        checkPermissionState(`${row.id}_${permission}`, checked);
    };

    const getChecked = permission => {
        return !!permissionState[`${row.id}_${permission}`];
    };

    const checkAllRow = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        row.permissions.forEach(item => {
            const key = `${row.id}_${item}`;
            checkPermissionState(key, checked);
        });
    };

    const getRowChecked = row => {
        let count = 0;
        row.permissions.forEach(item => {
            const key = `${row.id}_${item}`;
            if (permissionState[key]) {
                count++;
            }
        });
        return count === row.permissions.length;
    };

    const hasOne = row => {
        let count = 0;
        row.permissions.forEach(item => {
            const key = `${row.id}_${item}`;
            if (permissionState[key]) {
                count++;
            }
        });
        return count > 0 && count < row.permissions.length;
    };

    function StyledCheckbox(props: CheckboxProps) {
        const getCheckAllRow = getRowChecked(row);
        const indeterminate = hasOne(row);
        return (
            <Checkbox
                indeterminate={indeterminate}
                color="primary"
                {...props}
                onChange={checkAllRow}
                checked={getCheckAllRow}
            />
        );
    }

    const renderIcon = () => {
        return (
            <Box display="flex" alignItems="center">
                {icon}
                <Box ml={1}>
                    <span>{row.name}</span>
                </Box>
            </Box>
        );
    };

    const switchStyles = useN02SwitchStyles();
    const translate = useTranslate();

    const permissions = React.useMemo(() => {
        if (row.id !== 'distribution') return row.permissions;

        const ppc1 = 'ppc1';
        const ppc2 = 'ppc2';
        const rowPermission = row.permissions || [];

        if (getChecked(ppc1)) {
            return rowPermission.map(item =>
                item === ppc2 ? `_${item}` : item
            );
        }
        if (getChecked(ppc2)) {
            return rowPermission.map(item =>
                item === ppc1 ? `_${item}` : item
            );
        }

        return row.permissions;
    }, [row, getChecked]);

    return (
        <Box
            borderRadius={8}
            display={'flex'}
            alignItems={'center'}
            px={4}
            py={1}
            m={1}
            className={classes.row}
            border={1}
        >
            <Box flex={1}>
                <FormControlLabel
                    control={<StyledCheckbox />}
                    label={renderIcon()}
                />
            </Box>
            <Box flex={2} display={'flex'} justifyContent={'flex-end'}>
                {permissions.map(item => (
                    <Box
                        width={150}
                        textAlign={'center'}
                        key={item}
                        visibility={item.startsWith('_') && 'hidden'}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    classes={switchStyles}
                                    checked={getChecked(item)}
                                    onChange={event =>
                                        handleChange(event, item)
                                    }
                                />
                            }
                            label={translate(`pos.permission.${item}`)}
                            labelPlacement="top"
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default PermissionRow;
