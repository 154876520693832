import {Identifier, RecordMap} from "react-admin";
import {Record} from "ra-core";

export const styles = {
    input: { width: '24em' },
    number: { width: '8em' },
    date: { width: '32em', marginRight: '8px' },
    userSelect: { width: '32em' },
    tab: {
        width: '100%',
    },
    root: {
        marginTop: 0,
        '& .MuiFormLabel-root': {
            position: 'relative',
            fontSize: '1.35rem',
        },
        '& .ra-input .MuiFormLabel-root': {
            position: 'absolute',
            fontSize: '1rem',
        },
    },
    toolbar: {
        justifyContent: 'space-between',
    },
};


export const validateDuration = value => {
    let durations = Object.values(value).filter(v => v !== undefined);
    durations = Object.values(durations).filter(
        (v: any) =>
            'fromDuration' in v &&
            'toDuration' in v &&
            v.fromDuration !== null &&
            v.toDuration !== null
    );

    durations = Object.values(durations).sort(
        // @ts-ignore
        (v1, v2) => v1.fromDuration - v2.fromDuration
    );
    if (durations.length > 0) {
        for (let i = 0; i < durations.length; i++) {
            // @ts-ignore
            if (durations[i].fromDuration - durations[i].toDuration > 0) {
                return "Mục 'Thời lượng từ' phải nhỏ hơn mục 'Thời lượng đến'";
            }
            // @ts-ignore
            if (durations[i].fromDuration <= 0 || durations[i].toDuration <= 0 || !Number.isInteger(durations[i].fromDuration) || !Number.isInteger(durations[i].toDuration)) {
                return "Thời lượng cuộc gọi phải lớn hơn 0 và là số nguyên";
            }
            // @ts-ignore
            if (durations[i].callQuantity <= 0) {
                return "Số lượng cuộc gọi phải lớn hơn 0";
            }
            if (i !== 0) {
                // @ts-ignore
                if (durations[i].fromDuration <= durations[i - 1].toDuration) {
                    // @ts-ignore
                    return (
                        'Các khoảng thời lượng (Thời lượng từ-Thời lượng đến) không được trùng nhau: ' +
                        // @ts-ignore
                        '(' +
                        // @ts-ignore
                        durations[i - 1].fromDuration +
                        '-' +
                        // @ts-ignore
                        durations[i - 1].toDuration +
                        ')' +
                        ' trùng với ' +
                        // @ts-ignore
                        '(' +
                        // @ts-ignore
                        durations[i].fromDuration +
                        '-' +
                        // @ts-ignore
                        durations[i].toDuration +
                        ')'
                    );
                }
            }
        }
    }
    return undefined;
};

export const filterUsersByPartnerIdOrDeptId = (users: RecordMap<Record>, partnerId: string) => {
    const items: Array<{
        id: string | null | undefined | Identifier;
        name: string | null | undefined;
    }> = Object.values(users)
        ? Object.values(users)
            .filter(u => u.partnerId === partnerId || u.deptId === partnerId)
            .map(u => {
                return {
                    name:
                        u.fullName +
                        (u.telephonistCode === '' ||
                        u.telephonistCode === null
                            ? ''
                            : ' - ' + u.telephonistCode) +
                        (u.deptId === null
                            ? ' - ' + u.partnerName
                            : ' - ' + u.deptName),

                    id: u.id,
                };
            })
        : [];
    return items;
}
