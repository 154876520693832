import * as React from 'react';
import {Identifier, useGetList} from 'react-admin';
import { Card, CardContent } from '@material-ui/core';
import NhanSuMCC from "./NhanSuMCC";
import GiamSatTuDong from "./GiamSatTuDong";
import ChatLuongVaXepLoai from "./ChatLuongVaXepLoai";
import TieuChi from "./TieuChi";
import GSV from "./GSV";
import DTV from "./DTV";
import CuocGoiPhanMau from "./CuocGoiPhanMau";
import LichSuChinhSuaBienBan from "./LichSuChinhSuaBienBan";

interface ReportParams {
    type: string;
    fromDate: string;
    toDate: string;
}

// @ts-ignore
const ReportListV2 = () => {
    // userType
    const { data: userTypeData } = useGetList(
        'category',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
        {groupCategoryCode: 'CD'}
    );
    const userTypeChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined;
    }> = Object.values(userTypeData)
        ? Object.values(userTypeData).map(d => {
            return {
                title: d.name,
                value: d.value,
            };
        }).filter(({title, value}) => value !== null)
        : [];
    const userTypeNest = userTypeChildren.length > 0 ? [{
        title: 'Tất cả',
        value: 'all_children',
        children: [...new Map(userTypeChildren.map(item => [item["value"], item])).values()]
    }] : []

    // DTV
    const { data: DTVData } = useGetList(
        'user',
        { perPage: 1000, page: 1 },
        { field: 'fullName', order: 'ASC' },
        {userType: 3}
    );
    const DTVChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined;
    }> = Object.values(DTVData)
        ? Object.values(DTVData).map(d => {
            return {
                title: d.fullName + " - " + d.telephonistCode,
                value: d.telephonistCode,
            };
        }).filter(({title, value}) => value !== null)
        : [];
    const DTVNest = DTVChildren.length > 0 ? [...new Map(DTVChildren.map(item => [item["value"], item])).values()] : []

    //GSV
    const { data: GSVData } = useGetList(
        'user',
        { perPage: 1000, page: 1 },
        { field: 'fullName', order: 'ASC' },
        {userType: 2}
    );
    const GSVChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined | Identifier;
    }> = Object.values(GSVData)
        ? Object.values(GSVData).map(d => {
            return {
                title: d.fullName,
                value: d.id,
            };
        }).filter(({title, value}) => value !== null)
        : [];
    const GSVNest = GSVChildren.length > 0 ? [{
        title: 'Tất cả',
        value: 'all_children',
        children: [...new Map(GSVChildren.map(item => [item["value"], item])).values()]
    }] : []

    //group
    const { data: groupData } = useGetList(
        'group',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
    );

    const groupChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined | Identifier;
    }> = Object.values(groupData)
        ? Object.values(groupData).map(d => {
            return {
                title: d.groupName,
                value: d.id,
            };
        }).filter(({title, value}) => value !== null)
        : [];
    const groupNest = groupChildren.length > 0 ? [{
        title: 'Tất cả',
        value: 'all_children',
        children: [...new Map(groupChildren.map(item => [item["value"], item])).values()]
    }] : []

    // topicCode
    const { data: topicCodeData } = useGetList(
        'category',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
        {groupCategoryCode: 'CALL_TOPIC'}
    );
    // @ts-ignore
    const topicCodeChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined;
    }> = Object.values(topicCodeData)
        ? Object.values(topicCodeData).map(d => {
            return {
                title: d.name,
                value: d.code,
            };
        }).filter(({title, value}) => value !== null)
        : [];

    const topicCodeNest = topicCodeChildren.length > 0 ? [{
        title: 'Tất cả',
        value: 'all_children',
        children: [...new Map(topicCodeChildren.map(item => [item["value"], item])).values()]
    }] : []


    // telephonistGroup
    const { data: telephonistGroupData } = useGetList(
        'telephonistGroup',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' }
    );
    const telephonistGroupChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined | Identifier;
    }> = Object.values(telephonistGroupData)
        ? Object.values(telephonistGroupData).map(d => {
            return {
                title: d.name,
                value: d.id,
            };
        }).filter(({title, value}) => value !== null)
        : [];
    const telephonistGroupNest = telephonistGroupChildren.length > 0 ? [{
        title: 'Tất cả',
        value: 'all_children',
        children: [...new Map(telephonistGroupChildren.map(item => [item["value"], item])).values()]
    }] : []

    // parentPartner
    const { data: parentPartnerData } = useGetList(
        'partner',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
        {allParentParentsForReport: true}
    );
    const parentPartnerChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined;
    }> = Object.values(parentPartnerData)
        ? Object.values(parentPartnerData).map(d => {
            return {
                title: d.name,
                value: d.code,
            };
        }).filter(({title, value}) => value !== null)
        : [];
    const parentPartnerNest = parentPartnerChildren.length > 0 ? [{
        title: 'Tất cả',
        value: 'all_children',
        children: [...new Map(parentPartnerChildren.map(item => [item["value"], item])).values()]
    }] : []

    // department
    const { data: departmentData } = useGetList(
        'department',
        { perPage: 100, page: 1 },
        { field: 'deptName', order: 'ASC' }
    );
    const departmentChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined | Identifier;
    }> = Object.values(departmentData)
        ? Object.values(departmentData).map(d => {
            return {
                title: d.deptName,
                value: d.id,
            };
        }).filter(({title, value}) => value !== null)
        : [];
    const departmentNest = departmentChildren.length > 0 ? [{
        title: 'Tất cả',
        value: 'all_children',
        children: [...new Map(departmentChildren.map(item => [item["value"], item])).values()]
    }] : []

    //xepLoai
    const xepLoaiNest = [{
        title: 'Tất cả',
        value: 'all_children',
        children: [ {title: "Xếp loại A", value:"A"}, {title: "Xếp loại B", value:"B"},
                    {title: "Xếp loại C", value:"C"}, {title: "Xếp loại D", value:"D"} ]
    }]

    // typicalCall
    const { data: typicalCallData } = useGetList(
        'category',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
        {groupCategoryCode: 'CGDH'}
    );
    const typicalCallChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined | Identifier;
    }> = Object.values(typicalCallData)
        ? Object.values(typicalCallData).map(d => {
            return {
                title: d.name,
                value: d.id,
            };
        }).filter(({title, value}) => value !== null)
        : [];

    const typicalCallNest = typicalCallChildren.length > 0 ? [{
        title: 'Tất cả',
        value: 'all_children',
        children: [...new Map(typicalCallChildren.map(item => [item["value"], item])).values()]
    }] : []

    //callCriteria
    const { data: callCriteriaData } = useGetList(
        'criteriaCall',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
        {hasChild: true}
    );
    const callCriteriaChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined | Identifier;
    }> = Object.values(callCriteriaData)
        ? Object.values(callCriteriaData).map(d => {
            return {
                title: d.name,
                value: d.id,
            };
        }).filter(({title, value}) => value !== null)
        : [];

    const callCriteriaNest = callCriteriaChildren.length > 0 ? [{
        title: 'Tất cả',
        value: 'all_children',
        children: [...new Map(callCriteriaChildren.map(item => [item["value"], item])).values()]
    }] : [];

    //formEvaluate
    const { data: formEvaluateData } = useGetList(
        'form-evaluate',
        {page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
        { isActive: true }
    );
    const formEvaluateChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined | Identifier;
    }> = Object.values(formEvaluateData)
        ? Object.values(formEvaluateData)
            .map(d => {
            return {
                title: d.name,
                value: d.id,
            };
        }).filter(({title, value}) => value !== null)
        : [];

    const formEvaluateNest = formEvaluateChildren.length > 0 ? [{
        title: 'Tất cả',
        value: 'all_children',
        children: [...new Map(formEvaluateChildren.map(item => [item["value"], item])).values()]
    }] : [];

    //PARTNER
    const { data: partnerData } = useGetList(
        'partner',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' }
    );
    const partnerChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined | Identifier;
    }> = Object.values(partnerData)
        ? Object.values(partnerData)
            .map(d => {
                return {
                    title: d.name,
                    value: d.id,
                };
            }).filter(({title, value}) => value !== null)
        : [];

    // donVi
    const donViChildren = [...departmentChildren, ...partnerChildren];
    const donViNest =  donViChildren.length > 0 ? [{
        title: 'Tất cả',
        value: 'all_children',
        children: [...new Map(donViChildren.map(item => [item["value"], item])).values()]
    }] : [];


    // distribution
    const { data: callDistributionData } = useGetList(
        'distribution',
        {page: 1, perPage: 1000 },
        { field: 'createTime', order: 'DESC' }
    );
    const callDistributionChildren: Array<{
        title: string | null | undefined;
        value: string | null | undefined | Identifier;
    }> = Object.values(callDistributionData)
        ? Object.values(callDistributionData)
            .map(d => {
                return {
                    title: d.description,
                    value: d.id,
                };
            }).filter(({title, value}) => value !== null)
        : [];

    // ratingStatus;
    const ratingStatusChildren = [
        {
            title: "Đã đánh giá",
            value: 0
        },
        {
            title: "Chưa đánh giá",
            value: 1
        },
        {
            title: "Mẫu lỗi",
            value: 2
        }
    ]


    return (
        <div>
            <Card>
                <CardContent>
                    <GiamSatTuDong parentPartnerNest={parentPartnerNest}
                                   // telephonistGroupNest={telephonistGroupNest}
                                   topicCodeNest={topicCodeNest}
                                   DTVNest={DTVNest}
                    />
                </CardContent>
            </Card>
        </div>
    );
};
export default ReportListV2;
