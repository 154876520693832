import * as React from 'react';
import { useSelector } from 'react-redux';
import { Layout, LayoutProps, Notification, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { darkTheme, lightTheme } from './themes';
import { AppState } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import MyNotification from "./MyNotification";

const CustomSidebar = (props: any) => <Sidebar {...props} size={250} />;

const useStyles = makeStyles(theme => ({
    sidebarIsOpen: {
        marginLeft: '240px',
        transition: 'margin-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0',
        },
    },
    sidebarIsClosed: {
        marginLeft: '55px',
        transition: 'margin-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0',
        },
    },
}));

export default ({ children, ...props }: LayoutProps) => {
    const theme = useSelector((state: AppState) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    const classes = useStyles();
    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}
            menu={Menu}
            theme={theme}
            notification={MyNotification}
        >
            <div
                className={
                    open ? classes.sidebarIsOpen : classes.sidebarIsClosed
                }
            >
                {children}
            </div>
        </Layout>
    );
};
