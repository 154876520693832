import React from 'react';
import {ApexOptions} from "apexcharts";
import ReactApexChart from 'react-apexcharts'

type PieChartProps = {
    data: number[],
    categories: string[],
    colors: string[],
}

const PieChart = (props: PieChartProps) => {
    const options: ApexOptions = {
        chart: {
            type: 'donut',
            height: '100%'
        },
        legend: {
            position: 'bottom',
        },
        labels: props.categories,
        colors: props.colors,
        dataLabels: {
            style: {
                colors: ['#000']
            },
            // formatter: function (val, opts) {
            //     return opts.w.config.series[opts.seriesIndex]
            // },
        },
    };

    return (
        <div style={{height: 300}}>
            <ReactApexChart
                options={options}
                series={props.data}
                type="donut" height={300}/>
        </div>
    )
};

export default PieChart;