import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Record, useDataProvider, useLocale } from 'react-admin';
import {
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ComplainAction } from '../types';
import {
    AddCircleOutlined,
    Block,
    CheckCircle,
    Edit,
} from '@material-ui/icons';

const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

interface AsideProps {
    record?: Record;
    basePath?: string;
}

const Aside: FC<AsideProps> = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            {record && <EventList record={record} basePath={basePath} />}
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
};

interface EventListProps {
    record?: Record;
    basePath?: string;
}

const useEventStyles = makeStyles({
    stepper: {
        background: 'none',
        border: 'none',
        marginLeft: '0.3em',
    },
});

const ICON_MAP = {
    CREATE: AddCircleOutlined,
    dongY: CheckCircle,
    rejected: Block,
    rating: Edit,
};

const EventList: FC<EventListProps> = ({ record, basePath }) => {
    const classes = useEventStyles();
    const locale = useLocale();
    const [events, setEvents] = useState<ComplainAction[]>([]);
    const dataProvider = useDataProvider();
    const loadEvent = async () => {
        const { data } = await dataProvider.getList<ComplainAction>(
            `complain/${record?.id}/actions`,
            {
                filter: {},
                sort: { field: 'createTime', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        setEvents(data);
    };
    useEffect(() => {
        if (record && record.id) {
            loadEvent();
        }
    }, [record, dataProvider]);

    return (
        <>
            <Stepper orientation="vertical" classes={{ root: classes.stepper }}>
                {events &&
                    events.map(event => (
                        <Step
                            key={`${event.type}-${event.id}`}
                            expanded
                            active
                            completed
                        >
                            <StepLabel
                                StepIconComponent={() => {
                                    const Component = ICON_MAP[event.type];
                                    return (
                                        <Component
                                            fontSize="small"
                                            color="disabled"
                                            style={{ paddingLeft: 3 }}
                                        />
                                    );
                                }}
                            >
                                {new Date(event.createTime).toLocaleString(
                                    locale,
                                    {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                    }
                                )}
                            </StepLabel>
                            <StepContent>
                                <Typography
                                    variant="subtitle2"
                                    color="textSecondary"
                                >
                                    {event.createBy}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {event.content}
                                </Typography>
                            </StepContent>
                        </Step>
                    ))}
            </Stepper>
        </>
    );
};

export default Aside;
