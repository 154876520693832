import * as React from 'react';
import { FC, Fragment, useCallback } from 'react';
import {
    CreateButton,
    Datagrid,
    DatagridProps,
    DateField,
    FunctionField,
    List,
    ListProps,
    TextField,
    TopToolbar,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import DialogEdit from '../component/DialogEdit';
import CategoryListAside from './CategoryListAside';
import StatusField from '../component/StatusField';
import DialogCreate from '../component/DialogCreate';
import CategoryCreate from './CategoryCreate';

const Actions = (props: any) => (
    <TopToolbar>
        <CreateButton color="primary" />
    </TopToolbar>
);

interface DataGridProps extends DatagridProps {
    page?: number;
    perPage?: number;
}

const DataGrid: FC<DataGridProps> = props => {
    return (
        <Datagrid {...props} optimized rowClick="edit">
            <FunctionField
                label="#"
                render={record =>
                    ((props.page || 1) - 1) * (props.perPage || 0) +
                    Object.keys(props.data || {}).indexOf(record?.id as string) +
                    1
                }
            />
            <TextField source="name" />
            {/*<TextField source="code" />*/}
            <StatusField source="status" />
            <DateField source="createTime" />
        </Datagrid>
    );
};

const CategoryList: FC<ListProps> = props => {
    const history = useHistory();
    const handleClose = useCallback(() => {
        // @ts-ignore
        history.push(props.basePath);
    }, [history]);

    return (
        <Fragment>
            <List
                title="Danh sách Cấu hình"
                {...props}
                actions={<Actions />}
                aside={<CategoryListAside />}
                filterDefaultValues={{
                    groupId: '6d17d9ea-efcb-4fff-a522-8b9795fed822',
                }}
                perPage={25}
            >
                <DataGrid />
            </List>
            <Route
                path={`${props.basePath}/create`}
                render={() => (
                    <DialogCreate
                        {...props}
                        onCancel={handleClose}
                        title={'Thêm mới Cấu hình'}
                    >
                        <CategoryCreate />
                    </DialogCreate>
                )}
            />
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch =
                        match && match.params && match.params.id !== 'create';

                    return (
                        isMatch && (
                            <DialogEdit
                                {...props}
                                onCancel={handleClose}
                                title={'Sửa Từ khóa'}
                                id={match?.params.id}
                            >
                                <CategoryCreate />
                            </DialogEdit>
                        )
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default CategoryList;
