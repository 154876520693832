import { CardContent } from '@material-ui/core';
import { Card, Form } from 'antd';
import React, { useState } from 'react';
import { Identifier, fetchUtils, useGetList } from 'react-admin';
import NhanSuReport from './NhanSuReport';
import Criteria from './Criteria';
import BaoCaoGiamSatTuDong from './BaoCaoGiamSatTuDong';
import BaoCaoChatLuongVaXepLoai from './BaoCaoChatLuongVaXepLoai';
import BaoCaoGiamSatVien from './BaoCaoGiamSatVien';
import BaoCaoDienThoaiVien from './BaoCaoDienThoaiVien';
import CuocGoiPhanMau from './CuocGoiPhanMau';
import BaoCaoLichSuChinhSuaBienBan from './BaoCaoLichSuChinhSuaBienBan';

export const constants = {
    reportTypes: {
        AGGREGATION: {
            id: 'AGGREGATION',
            name: 'Tổng hợp',
        },
        DETAIL: {
            id: 'DETAIL',
            name: 'Chi tiết',
        },
    },

    templateType: {
        ReportType1: {
            id: 'CTKV-TCT',
            name: 'Phân loại theo khu vực',
        },
        ReportType2: {
            id: 'MCC-TCT',
            name: 'Phân loại theo đối tác tổng hợp',
        },
        ReportType3: {
            id: 'CTKV',
            name: 'Phân loại theo đối tác chi tiết',
        },
    },
    phanLoaiChiTietGroups: {
        ReportType1: {
            id: 'MCC-TCT',
            name: 'DANH SÁCH NHÂN SỰ LÀM VIỆC TẠI CÁC MCC - TCT',
        },
        ReportType2: {
            id: 'PARTNER-TCT',
            name: 'DANH SÁCH NHÂN SỰ CTY',
        },
        ReportType3: {
            id: 'PARTNER_ROOT-CTKV',
            name:
                'DANH SÁCH NHÂN SỰ CÁC MCC THUỘC CÔNG TY Công Ty Dịch vụ MobiFone',
        },
        ReportType4: {
            id: 'CTKV',
            name: 'DANH SÁCH NHÂN SỰ CTY theo Khu Vực',
        },
        ReportType5: {
            id: 'TITLE-PARTNER_ROOT-CTKV',
            name: 'DANH SÁCH THEO CHỨC DANH NHÂN SỰ MCC THUỘC CÔNG TY DỊCH VỤ ',
        },
    },

    reportTypeAuto: {
        tongHop: {
            id: 'AGGREGATION',
            name: 'Báo cáo tích mã',
        },
    },

    loaiBaoCaoGiamSatVien: {
        nangSuat: {
            id: 'PRODUCTIVITY_BY_DAY',
            name: 'Năng suất làm việc theo ngày',
        },
        tieuChiTongHop: {
            id: 'AGGREGATION_ERROR',
            name: 'Tiêu chí tổng hợp giám sát viên',
        },
        tieuChiChiTiet: {
            id: 'DETAIL',
            name: 'Tiêu chí chi tiết giám sát viên',
        },
    },

    loaiBaoCaoDienThoaiVien: {
        baoCao1: {
            id: 'QUALITY_RESULT_1',
            name: 'Báo cáo kết quả giám sát chất lượng điện thoại viên lần 1',
        },
        baoCao2: {
            id: 'QUALITY_RESULT_2',
            name: 'Báo cáo kết quả giám sát chất lượng điện thoại viên lần 2',
        },
        baoCaoDoiSoat: {
            id: 'FOR_CONTROL',
            name: 'Báo cáo đối soát',
        },

        tieuChiLoiTongHop: {
            id: 'AGGREGATION_ERROR',
            name: 'Báo cáo tiêu chí lỗi tổng hợp điện thoại viên',
        },
        tieuChiLoiChiTiet: {
            id: 'DETAIL',
            name: 'Báo cáo lỗi chi tiết điện thoại viên',
        },
        khieuNai: {
            id: 'COMPLAINT',
            name: 'Báo cáo khiếu nại biên bản của điện thoại viên',
        },
    },

    classify: {
        baoCaoTheoCtyKhuVuc: {
            id: 'CTKV-TCT',
            name: 'BÁO CÁO GIÁM SÁT TỰ ĐỘNG CÁC CTKV - TCT',
        },
        baoCaoTheoCtyKhuVucChiTiet: {
            id: 'CTKV-DT-TCT',
            name: 'BÁO CÁO GIÁM SÁT TỰ ĐỘNG THEO CTKV CHI TIẾT ĐỐI TÁC -TCT ',
        },
        baoCaoGiamSatTuDong: {
            id: 'DT-TCT',
            name: 'BÁO CÁO GIÁM SÁT TỰ ĐỘNG CÁC ĐỐI TÁC - TCT',
        },

        baoCaoTheoChuDe: {
            id: 'ChuDe-TCT',
            name: 'BÁO CÁO MỨC ĐỘ CẢNH BÁO THEO CHỦ ĐỀ - TCT',
        },

        baoCaoTheoChuDeCtyKhuVuc: {
            id: 'ChuDe_TCT-CTKV',
            name: 'BÁO CÁO MỨC ĐỘ CẢNH BÁO THEO CHỦ ĐỀ - TCT/CTKV',
        },
    },
    xepLoai: {
        xepLoaiA: { id: 'A', title: 'Xếp loại A', value: 'A' },
        xepLoaiB: { id: 'B', title: 'Xếp loại B', value: 'B' },
        xepLoaiC: { id: 'C', title: 'Xếp loại C', value: 'C' },
        xepLoaiD: { id: 'D', title: 'Xếp loại D', value: 'D' },
    },

    phanLoaiChatLuongVaXepLoai: {
        tongHop: {
            id: 'AGGREGATION',
            TLKH_CTKV: {
                id: 'TLKH_CTKV',
                name: 'Phân loại TLKH theo công ty khu vực',
            },
            TLKH_DoiTac: {
                id: 'TLKH_DoiTac',
                name: 'Phân loại TLKH theo đối tác',
            },
            MCC_TCT: {
                id: 'MCC_TCT',
                name: 'Phân loại theo đối tác tổng hợp',
            },
            MCC_DoiTac: {
                id: 'MCC_DoiTac',
                name: 'Phân loại theo đối tác chi tiết',
            },
        },
        chitiet: {
            id: 'DETAIL',
            TLKH_DTV_TCT: {
                id: 'TLKH_DTV_TCT',
                name: 'Phân loại TLKH DTV tổng công ty',
            },
            TLKH_DTV_CTKV: {
                id: 'TLKH_DTV_CTKV',
                name: 'Phân loại TLKH DTV theo CTKV',
            },
            TLKH_DTV_DoiTac: {
                id: 'TLKH_DTV_DoiTac',
                name: 'Phân loại TLKH DTV theo đối tác',
            },
            TLKH_DoiTac: {
                id: 'TLKH_DoiTac',
                name: 'Phân loại TLKH DTV theo đối tác chi tiết',
            },
            TLKHDTV_NhomCN_CTKV: {
                id: 'TLKHDTV_NhomCN_CTKV',
                name: 'Phân loại TLKH DTV theo nhóm chức năng công ty khu vực',
            },
            TLKHDTV_NhomCN_DoiTac: {
                id: 'TLKHDTV_NhomCN_DoiTac',
                name: 'Phân loại TLKH DTV theo nhóm chức năng đối tác',
            },
            XepLoai_DTV: {
                id: 'XepLoai_DTV',
                name: 'Phân loại theo xếp loại DTV',
            },
            GSV: {
                id: 'GSV',
                name: 'Phân loại theo giám sát viên',
            },
        },
    },
    phanLoaiCuocGoiPhanMau: {
        chiTiet: {
            id: 'DETAIL',
            name: 'Chi tiết',
        },

        tongHop: {
            id: 'AGGREGATION',
            name: 'Tổng hợp',
        },

        soLuong: {
            id: 'QUANTITY',
            name: 'Số lượng',
        },
    },

    ratingStatus: {
        daDanhGia: {
            title: 'Đã đánh giá',
            id: '1',
        },
        chuaDanhGia: {
            title: 'Chưa đánh giá',
            id: '2',
        },
        mauLoi: {
            title: 'Mẫu lỗi',
            id: '3',
        },
    },
};

const ReportList = () => {
    // userType
    const { data: userTypeData } = useGetList(
        'category',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
        { groupCategoryCode: 'CD' }
    );
    const userTypeChoices: Array<{
        name: any;
        id: any;
    }> = Object.values(userTypeData)
        ? Object.values(userTypeData)
              .map(d => {
                  return {
                      name: d.name,
                      id: d.value,
                  };
              })
              .filter(({ id, name }) => id !== null)
        : [];

    //telephonistGroup
    const { data: telephonistGroupData } = useGetList(
        'telephonistGroup',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' }
    );
    const telephonistGroupChoices: Array<{
        name: any;
        id: any;
    }> = Object.values(telephonistGroupData)
        ? Object.values(telephonistGroupData)
              .map(d => {
                  return {
                      name: d.name,
                      id: d.id,
                  };
              })
              .filter(({ id, name }) => id !== null)
        : [];

    // parentPartner
    const { data: parentPartnerData } = useGetList(
        'partner',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
        { allParentParentsForReport: true }
    );
    const parentPartnerChoices: Array<{
        name: any;
        id: any;
    }> = Object.values(parentPartnerData)
        ? Object.values(parentPartnerData)
              .map(d => {
                  return {
                      name: d.name,
                      id: d.code,
                  };
              })
              .filter(({ id, name }) => id !== null)
        : [];

    // topicCode
    const { data: topicCodeData } = useGetList(
        'category',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
        { groupCategoryCode: 'CALL_TOPIC' }
    );

    // @ts-ignore
    const topicCodeChoices: Array<{
        title: string | null | undefined;
        value: string | null | undefined;
    }> = Object.values(topicCodeData)
        ? Object.values(topicCodeData)
              .map((d, index) => {
                  return {
                      title: d.name,
                      id: d.code,
                  };
              })
              .filter(({ title, id }) => id !== null)
        : [];

    // DTV
    const { data: DTVData } = useGetList(
        'user',
        { perPage: 1000, page: 1 },
        { field: 'fullName', order: 'ASC' },
        { userType: 3 }
    );
    const DTVChoices: Array<{
        title: string | null | undefined;
        id: string | null | undefined;
    }> = Object.values(DTVData)
        ? Object.values(DTVData)
              .map(d => {
                  return {
                      title: d.fullName + ' - ' + d.telephonistCode,
                      id: d.telephonistCode,
                  };
              })
              .filter(({ title, id }) => id !== null)
        : [];
    // department
    const { data: departmentData } = useGetList(
        'department',
        { perPage: 100, page: 1 },
        { field: 'deptName', order: 'ASC' }
    );
    const departmentChoices: Array<{
        title: string | null | undefined;
        id: string | null | undefined | Identifier;
    }> = Object.values(departmentData)
        ? Object.values(departmentData)
              .map(d => {
                  return {
                      title: d.deptName,
                      id: d.id,
                  };
              })
              .filter(({ title, id }) => id !== null)
        : [];
    // GSV
    const { data: GSVData } = useGetList(
        'user',
        { perPage: 1000, page: 1 },
        { field: 'fullName', order: 'ASC' },
        { userType: 2 }
    );
    const GSVChoices: Array<{
        title: string | null | undefined;
        id: string | null | undefined | Identifier;
    }> = Object.values(GSVData)
        ? Object.values(GSVData)
              .map(d => {
                  return {
                      title: d.fullName,
                      id: d.id,
                  };
              })
              .filter(({ title, id }) => id !== null)
        : [];
    //group
    const { data: groupData } = useGetList(
        'group',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' }
    );

    const groupChoices: Array<{
        title: string | null | undefined;
        id: string | null | undefined | Identifier;
    }> = Object.values(groupData)
        ? Object.values(groupData)
              .map(d => {
                  return {
                      title: d.groupName,
                      id: d.id,
                  };
              })
              .filter(({ title, id }) => id !== null)
        : [];
    // typicalCall
    const { data: typicalCallData } = useGetList(
        'category',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
        { groupCategoryCode: 'CGDH' }
    );
    const typicalCallChoices: Array<{
        title: string | null | undefined;
        id: string | null | undefined | Identifier;
    }> = Object.values(typicalCallData)
        ? Object.values(typicalCallData)
              .map(d => {
                  return {
                      title: d.name,
                      id: d.id,
                  };
              })
              .filter(({ title, id }) => id !== null)
        : [];

    //formEvaluate
    const { data: formEvaluateData } = useGetList(
        'form-evaluate',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
        { isActive: true }
    );
    const formEvaluateChoise: Array<{
        title: string | null | undefined;
        id: string | null | undefined | Identifier;
    }> = Object.values(formEvaluateData)
        ? Object.values(formEvaluateData)
              .map(d => {
                  return {
                      title: d.name,
                      id: d.id,
                  };
              })
              .filter(({ title, id }) => id !== null)
        : [];
    //callCriteria
    const { data: callCriteriaData } = useGetList(
        'criteriaCall',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
        { hasChild: true }
    );
    const callCriteriaChoices: Array<{
        title: string | null | undefined;
        id: string | null | undefined | Identifier;
    }> = Object.values(callCriteriaData)
        ? Object.values(callCriteriaData)
              .map(d => {
                  return {
                      title: d.name,
                      id: d.id,
                  };
              })
              .filter(({ title, id }) => id !== null)
        : [];

    // callDistribution
    const { data: callDistributionData } = useGetList(
        'distribution',
        { page: 1, perPage: 1000 },
        { field: 'createTime', order: 'DESC' }
    );
    const callDistributionChoices: Array<{
        title: string | null | undefined;
        id: string | null | undefined | Identifier;
    }> = Object.values(callDistributionData)
        ? Object.values(callDistributionData)
              .map(d => {
                  return {
                      title: d.description,
                      id: d.id,
                  };
              })
              .filter(({ title, id }) => id !== null)
        : [];
    return (
        <div>
            <Form>
                <Card>
                    <CardContent>
                        <BaoCaoGiamSatTuDong
                            reportTypeAuto={[
                                constants.reportTypeAuto.tongHop,
                            ]}
                            parentPartners={parentPartnerChoices}
                            topicCode={topicCodeChoices}
                            DTV={DTVChoices}
                            classify={[
                                constants.classify.baoCaoTheoCtyKhuVuc,
                                constants.classify.baoCaoTheoCtyKhuVucChiTiet,
                                constants.classify.baoCaoTheoChuDe,
                                constants.classify.baoCaoGiamSatTuDong,
                                constants.classify.baoCaoTheoChuDeCtyKhuVuc,
                            ]}
                        />
                    </CardContent>
                </Card>
            </Form>
        </div>
    );
};
export default ReportList;
