import * as React from 'react';
import {FC, Fragment} from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/core/styles';

import {ToolbarProps, usePermissions,} from 'react-admin';
import AcceptButton from './AcceptButton';
import RejectButton from './RejectButton';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'space-between',
    },
}));


const ComplainEditToolbar: FC<ToolbarProps> = ({
                                                   basePath,
                                                   handleSubmitWithRedirect,
                                                   invalid,
                                                   record,
                                                   resource,
                                                   saving,
                                               }) => {
    const classes = useStyles();
    const {permissions} = usePermissions();
    if (!record) return null;
    return (
        <MuiToolbar className={classes.root}>
            {permissions &&
            permissions.includes('complain_edit') &&
            <Fragment>
                <AcceptButton record={record}/>
                <RejectButton record={record}/>
            </Fragment>
            }
        </MuiToolbar>
    );
};

export default ComplainEditToolbar;
