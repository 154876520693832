import { Box } from '@material-ui/core';
import * as React from 'react';
import { useGetIdentity } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    link: {
        cursor: 'pointer',
        backgroundColor: theme.palette.type === 'light' ? grey[100] : grey[900],
    },
    linkClosed: {
        cursor: 'pointer',
    },
    avatar: {
        marginRight: theme.spacing(1),
        marginTop: -theme.spacing(0.5),
        marginBottom: -theme.spacing(0.5),
    },
    subTitle: {
        fontSize: 12,
    },
    icon: {
        color: blue[500],
    },
}));

const UserCard = ({ sidebarIsOpen }) => {
    const classes = useStyles();
    const { identity } = useGetIdentity();
    const history = useHistory();
    const handleClick = () => {
        history.push('/profile');
    };
    return (
        <Box px={sidebarIsOpen ? 2 : 1} onClick={handleClick}>
            {!sidebarIsOpen && (
                <Box
                    className={classes.linkClosed}
                    borderRadius={16}
                    py={2}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <Avatar
                        src={
                            'https://res.cloudinary.com/minimal-ui/image/upload/v1614655910/upload_minimal/avatar/minimal_avatar.jpg'
                        }
                    />
                </Box>
            )}
            {sidebarIsOpen && (
                <Box
                    className={classes.link}
                    borderRadius={16}
                    p={2}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <Avatar
                        src={
                            'https://res.cloudinary.com/minimal-ui/image/upload/v1614655910/upload_minimal/avatar/minimal_avatar.jpg'
                        }
                    />
                    <Box ml={2}>
                        <Typography variant="subtitle2">
                            {identity?.fullName}
                        </Typography>
                        <Typography
                            className={classes.subTitle}
                            variant="body2"
                        >
                            {identity?.userType}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default UserCard;
