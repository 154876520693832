import * as React from 'react';
import { ChangeEvent, FC, memo, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useListFilterContext } from 'ra-core';
import { TextInput } from 'react-admin';

const TextInputSearch: FC<{ source?: string; label: string }> = props => {
    const { source = 'q', label, ...rest } = props;
    const { filterValues, setFilters } = useListFilterContext();

    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setFilters({ ...filterValues, [source]: event.target.value }, null);
        } else {
            const { [source]: _, ...filters } = filterValues;
            setFilters(filters, null);
        }
    };

    const initialValues = useMemo(
        () => ({
            [source]: filterValues[source],
        }),
        [filterValues, source]
    );

    const onSubmit = () => undefined;

    return (
        <Form initialValues={initialValues} onSubmit={onSubmit}>
            {() => (
                <TextInput
                    resettable
                    fullWidth
                    helperText={false}
                    margin={'dense'}
                    size={'small'}
                    source={source}
                    onChange={onSearchChange}
                    label={label}
                    {...rest}
                />
            )}
        </Form>
    );
};

export default memo(TextInputSearch);
