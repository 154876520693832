import { TreeSelect } from 'antd';
import * as React from 'react';
import { useInput, useQuery, useQueryWithStore } from 'react-admin';
import { forwardRef, useImperativeHandle, useState } from 'react';

interface TreeInputProps {
    source: string;
    placeholder: string;
    treeCheckable: boolean;
    defaultValue?: any;
    disabled?: boolean;
    onChange?: (value: any) => void;
    selectChild?: boolean;
}

const TichMaTreeInput = forwardRef((props: TreeInputProps, ref) => {
    const {
        onChange,
        placeholder,
        treeCheckable,
        defaultValue,
        disabled,
        selectChild,
        ...rest
    } = props;
    const { loaded, data: tichMas } = useQuery({
        type: 'getList',
        resource: 'callReflect',
        payload: {
            filter: {
                parentId: null,
            },
            sort: { field: 'name', order: 'asc' },
            pagination: { page: 1, perPage: 100000 },
        },
    });
    const entryList: Array<{
        parentId: string | null | undefined;
        title: string | null | undefined;
        value: string | null | undefined;
    }> = tichMas
        ? tichMas.map(d => {
              return {
                  parentId: d.parentId,
                  title: d.name,
                  value: d.id,
              };
          })
        : [];

    const nestTree = function (
        items,
        value = null,
        link = 'parentId',
        level = 0
    ) {
        if (level === 5) {
            return [];
        } else {
            return items
                .filter(item => item[link] === value)
                .map(item => {
                    const childs = nestTree(
                        items,
                        item.value,
                        'parentId',
                        level + 1
                    );
                    return {
                        ...item,
                        disabled: childs.length > 0,
                        children: childs,
                    };
                });
        }
    };

    const {
        input: { onChange: onChange_, value, onBlur },
        meta: { touched, error },
    } = useInput(rest);
    const [selectedItem, setSelectedItem] = useState(defaultValue ?? undefined);

    useImperativeHandle(ref, () => ({
        getSelectedItem: () => selectedItem,
    }));

    return (
        <TreeSelect
            treeLine={{
                showLeafIcon: false,
            }}
            value={value != '' ? value : undefined}
            showSearch
            treeNodeFilterProp="title"
            style={{
                width: '100%',
            }}
            dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
                zIndex: '100000',
            }}
            allowClear
            onBlur={onBlur}
            treeData={nestTree(entryList)}
            onChange={item => {
                setSelectedItem(item);
                onChange_(item);
                if (onChange) {
                    onChange(item);
                }
            }}
            treeCheckable={treeCheckable}
            placeholder={placeholder}
            defaultValue={defaultValue ?? undefined}
            disabled={disabled}
        />
    );
});

export default TichMaTreeInput;
