import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, MenuItemLink, UserMenu, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../types';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { IconButton } from '@material-ui/core';
import { changeTheme } from '../configuration/actions';

const useStyles = makeStyles(theme => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
}));

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    const theme = useSelector((state: AppState) => state.theme);
    const dispatch = useDispatch();

    return (
        <AppBar {...props} color="primary" userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />

            <span className={classes.spacer} />

            {theme === 'light' ? (
                <IconButton
                    color="inherit"
                    onClick={() => dispatch(changeTheme('dark'))}
                >
                    <Brightness7Icon />
                </IconButton>
            ) : (
                <IconButton
                    color="inherit"
                    onClick={() => dispatch(changeTheme('light'))}
                >
                    <Brightness4Icon />
                </IconButton>
            )}
        </AppBar>
    );
};

export default CustomAppBar;
