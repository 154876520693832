import { WarningRounded } from '@material-ui/icons';
import {
    Button,
    FormWithRedirect,
    Labeled,
    required,
    SaveButton,
    TextField,
    TextInput,
    useCreate,
    useNotify,
    useRefresh,
} from 'react-admin';
import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    btnComplain: {
        marginLeft: theme.spacing(2),
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[700],
        },
    },
}));

const ComplainButton = ({
    disabled,
    callId,
    callingRatingId,
    complainScores,
}) => {
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const [open, setOpen] = useState<boolean>(false);
    const [create, { loading }] = useCreate('complain');
    const [loadding, setLoadding] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const close = () => {
        setOpen(false);
    };
    const openDialog = () => {
        setOpen(true);
    };

    const submit = async values => {
        setLoadding(true);
        setButtonDisabled(true);
        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }) => {
                    refresh();
                    notify('Khiếu nại thành công');
                    close();
                    setLoadding(false);
                    setButtonDisabled(false);
                },
                onFailure: ({ body }) => {
                    notify(body.error, 'error');
                    setLoadding(false);
                    setButtonDisabled(false);
                },
            }
        );
    };

    return (
        <React.Fragment>
            <Button
                variant={'contained'}
                onClick={openDialog}
                className={classes.btnComplain}
                disableElevation
                startIcon={<WarningRounded />}
                label={'Khiếu nại'}
                disabled={disabled || loading || buttonDisabled}
            />
            <Dialog open={open} onClose={close} fullWidth>
                <DialogTitle>
                    <Typography variant={'h6'} component={'h2'}>
                        Khiếu nại cuộc gọi
                    </Typography>
                </DialogTitle>
                <FormWithRedirect
                    resource="posts"
                    defaultValue={{ callId, callingRatingId, complainScores }}
                    save={submit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving,
                    }) => (
                        <>
                            <DialogContent>
                                <Labeled label={'ID cuộc gọi'}>
                                    <TextField source="callId" />
                                </Labeled>
                                <TextInput
                                    resource="complain"
                                    source="opinion"
                                    multiline
                                    rows={4}
                                    validate={required()}
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                {loadding ? (
                                    <CircularProgress size={18} />
                                ) : (
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        saving={saving}
                                        disabled={disabled}
                                        label="Lưu"
                                    />
                                )}

                                <Button onClick={close} label={'Hủy'} />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </React.Fragment>
    );
};

export default ComplainButton;
