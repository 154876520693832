import { Button } from 'react-admin';
import * as React from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

const BackButton = () => {
    const history = useHistory();
    const back = useCallback(() => {
        history.goBack();
    }, [history]);
    return (
        <Button onClick={back} startIcon={<ArrowBack />} label={'Quay lại'} />
    );
};

export default BackButton;
