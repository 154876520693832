import { DatePicker, DatePickerProps } from 'antd';
import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    weekPicker: {
        '& input': {
            width: '165px',
        },
    },
    dateRangePicker: {
        width: '225px',
    },
    datePicker: {
        width: '120px',
    },
    yearPicker: {
        width: '80px',
    },
    monthPicker: {
        width: '100px',
    },
});

export type TimeFilterProps = {
    type: 'date' | 'dateRange' | 'month' | 'week' | 'twelveMonths' | 'year';
    onchange?: (values: { type: string; fromDate: Date; toDate: Date }) => void;
    dateDifference?: number;
};

const dateFormat = 'DD/MM/YYYY';

const monthFormat = 'MM/YYYY';

const customWeekStartEndFormat: DatePickerProps['format'] = value => {
    return (
        `${dayjs(value.toDate()).startOf('week').format(dateFormat)} - ` +
        `${dayjs(value.toDate()).endOf('week').format(dateFormat)}`
    );
};

const TimeFilter = (props: TimeFilterProps) => {
    const { type, onchange, dateDifference } = props;

    const classes = useStyles();

    const [selectedByDate, setSelectedByDate] = useState<Moment | undefined>(
        moment(new Date(), dateFormat)
    );

    const [selectedByMonth, setSelectedByMonth] = useState<Moment | undefined>(
        moment(new Date(), dateFormat)
    );

    const [selectedByWeek, setSelectedByWeek] = useState<Moment | undefined>(
        moment(
            new Date(new Date().setDate(new Date().getDate() - 7)),
            dateFormat
        )
    );

    const [selectedByDateRange, setSelectedByDateRange] = useState<
        [Moment | null, Moment | null] | null
    >([
        moment(
            new Date(
                new Date().setDate(
                    new Date().getDate() -
                        (dateDifference ? dateDifference : 31)
                )
            ),
            dateFormat
        ),
        moment(new Date(new Date().setDate(new Date().getDate())), dateFormat),
    ]);

    const [selectedByYear, setSelectedByYear] = useState<Moment | undefined>(
        moment(new Date(), dateFormat)
    );

    const [selectedByTwelveMonths, setSelectedByTwelveMonths] = useState<
        Moment | undefined
    >(moment(new Date(), dateFormat));

    useEffect(() => {
        switch (type) {
            case 'date':
                if (onchange && selectedByDate) {
                    onchange({
                        type: type,
                        fromDate: dayjs(selectedByDate.toDate())
                            .startOf('day')
                            .toDate(),
                        toDate: dayjs(selectedByDate.toDate())
                            .endOf('day')
                            .toDate(),
                    });
                }
                break;
            case 'dateRange':
                if (
                    onchange &&
                    selectedByDateRange &&
                    selectedByDateRange[0] &&
                    selectedByDateRange[1]
                ) {
                    onchange({
                        type: type,
                        fromDate: dayjs(selectedByDateRange[0].toDate())
                            .startOf('day')
                            .toDate(),
                        toDate: dayjs(selectedByDateRange[1].toDate())
                            .endOf('day')
                            .toDate(),
                    });
                }
                break;
            case 'month':
                if (onchange && selectedByMonth) {
                    onchange({
                        type: type,
                        fromDate: dayjs(selectedByMonth.toDate())
                            .startOf('month')
                            .toDate(),
                        toDate: dayjs(selectedByMonth.toDate())
                            .endOf('month')
                            .toDate(),
                    });
                }
                break;
            case 'week':
                if (onchange && selectedByWeek) {
                    onchange({
                        type: type,
                        fromDate: dayjs(selectedByWeek.toDate())
                            .startOf('week')
                            .toDate(),
                        toDate: dayjs(selectedByWeek.toDate())
                            .endOf('week')
                            .toDate(),
                    });
                }
                break;
            case 'twelveMonths':
                if (onchange && selectedByTwelveMonths) {
                    onchange({
                        type: type,
                        fromDate: dayjs(selectedByTwelveMonths.toDate())
                            .startOf('year')
                            .toDate(),
                        toDate: dayjs(selectedByTwelveMonths.toDate())
                            .endOf('year')
                            .toDate(),
                    });
                }
                break;
            case 'year':
                if (onchange && selectedByTwelveMonths) {
                    onchange({
                        type: type,
                        fromDate: dayjs(selectedByTwelveMonths.toDate())
                            .startOf('year')
                            .toDate(),
                        toDate: dayjs(selectedByTwelveMonths.toDate())
                            .endOf('year')
                            .toDate(),
                    });
                }
                break;
        }
    }, [type]);

    return (
        <>
            {type === 'date' && (
                <DatePicker
                    className={classes.datePicker}
                    value={selectedByDate}
                    defaultValue={selectedByDate}
                    format={dateFormat}
                    onChange={d => {
                        const localType = type;
                        let date;
                        if (d) {
                            date = d;
                        } else {
                            date = moment(new Date(new Date()), dateFormat);
                        }
                        setSelectedByDate(date);
                        if (onchange) {
                            onchange({
                                type: localType,
                                fromDate: dayjs(date.toDate())
                                    .startOf('day')
                                    .toDate(),
                                toDate: dayjs(date.toDate())
                                    .endOf('day')
                                    .toDate(),
                            });
                        }
                    }}
                />
            )}
            {type === 'week' && (
                <DatePicker
                    className={classes.weekPicker}
                    value={selectedByWeek}
                    defaultValue={selectedByWeek}
                    format={customWeekStartEndFormat}
                    picker="week"
                    onChange={d => {
                        const localType = type;
                        let date;
                        if (d) {
                            date = d;
                        } else {
                            date = moment(
                                new Date(
                                    new Date().setDate(new Date().getDate() - 7)
                                ),
                                dateFormat
                            );
                        }
                        setSelectedByWeek(date);
                        if (onchange) {
                            onchange({
                                type: localType,
                                fromDate: dayjs(date.toDate())
                                    .startOf('week')
                                    .toDate(),
                                toDate: dayjs(date.toDate())
                                    .endOf('week')
                                    .toDate(),
                            });
                        }
                    }}
                />
            )}
            {type === 'month' && (
                <DatePicker
                    className={classes.monthPicker}
                    value={selectedByMonth}
                    defaultValue={selectedByMonth}
                    format={monthFormat}
                    picker="month"
                    onChange={d => {
                        const localType = type;
                        let date;
                        if (d) {
                            date = d;
                        } else {
                            date = moment(new Date(), dateFormat);
                        }
                        setSelectedByMonth(date);
                        if (onchange) {
                            onchange({
                                type: localType,
                                fromDate: dayjs(date.toDate())
                                    .startOf('month')
                                    .toDate(),
                                toDate: dayjs(date.toDate())
                                    .endOf('month')
                                    .toDate(),
                            });
                        }
                    }}
                />
            )}
            {type === 'dateRange' && (
                <DatePicker.RangePicker
                    className={classes.dateRangePicker}
                    value={selectedByDateRange}
                    picker={'date'}
                    onChange={d => {
                        const localType = type;
                        let dates;
                        if (d) {
                            dates = d;
                        } else {
                            dates = [
                                moment(
                                    new Date(
                                        new Date().setDate(
                                            new Date().getDate() -
                                                (dateDifference
                                                    ? dateDifference
                                                    : 31)
                                        )
                                    ),
                                    'YYYY-MM-DD'
                                ),
                                moment(
                                    new Date(
                                        new Date().setDate(new Date().getDate())
                                    ),
                                    'YYYY-MM-DD'
                                ),
                            ];
                        }
                        setSelectedByDateRange(dates);
                        if (onchange) {
                            onchange({
                                type: localType,
                                fromDate: dayjs(dates[0].toDate())
                                    .startOf('day')
                                    .toDate(),
                                toDate: dayjs(dates[1].toDate())
                                    .endOf('day')
                                    .toDate(),
                            });
                        }
                    }}
                    defaultValue={selectedByDateRange}
                    format={dateFormat}
                />
            )}
            {type === 'year' && (
                <DatePicker
                    className={classes.yearPicker}
                    value={selectedByYear}
                    defaultValue={selectedByYear}
                    onChange={d => {
                        const localType = type;
                        let date;
                        if (d) {
                            date = d;
                        } else {
                            date = moment(new Date(new Date()), dateFormat);
                        }
                        setSelectedByYear(date);
                        if (onchange) {
                            onchange({
                                type: localType,
                                fromDate: dayjs(date.toDate())
                                    .startOf('year')
                                    .toDate(),
                                toDate: dayjs(date.toDate())
                                    .endOf('year')
                                    .toDate(),
                            });
                        }
                    }}
                    picker={'year'}
                />
            )}
            {type === 'twelveMonths' && (
                <DatePicker
                    className={classes.yearPicker}
                    value={selectedByTwelveMonths}
                    defaultValue={selectedByTwelveMonths}
                    onChange={d => {
                        const localType = type;
                        let date;
                        if (d) {
                            date = d;
                        } else {
                            date = moment(new Date(new Date()), dateFormat);
                        }
                        setSelectedByTwelveMonths(date);
                        if (onchange) {
                            onchange({
                                type: localType,
                                fromDate: dayjs(date.toDate())
                                    .startOf('year')
                                    .toDate(),
                                toDate: dayjs(date.toDate())
                                    .endOf('year')
                                    .toDate(),
                            });
                        }
                    }}
                    picker={'year'}
                />
            )}
        </>
    );
};
export default TimeFilter;
