import * as React from 'react';
import { FC, Fragment, useCallback } from 'react';
import {
    BulkActionProps,
    BulkDeleteButton,
    CreateButton,
    Datagrid,
    DatagridProps,
    DateField,
    Filter,
    FilterProps,
    FunctionField,
    List,
    ListProps,
    SearchInput,
    TextField,
    TopToolbar,
} from 'react-admin';
import { Route } from 'react-router';
import FiltersTemplateCreate from './FiltersTemplateCreate';
import { useHistory } from 'react-router-dom';
import DialogCreate from '../component/DialogCreate';
import DialogEdit from '../component/DialogEdit';
import IsActiveField from '../danhMuc/IsActiveField';

interface DataGridProps extends DatagridProps {
    page?: number;
    perPage?: number;
}

const ListFilters = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const Actions = (props: any) => (
    <TopToolbar>
        <CreateButton color="primary" />
    </TopToolbar>
);

const BulkActionButtons = (props: BulkActionProps) => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const DataGrid: FC<DataGridProps> = props => {
    return (
        <Datagrid {...props} optimized rowClick="edit">
            <FunctionField
                label="#"
                render={record =>
                    ((props.page || 1) - 1) * (props.perPage || 0) +
                    Object.keys(props.data || {}).indexOf(record?.id as string) +
                    1
                }
            />
            <TextField source="name" />
            <TextField source="description" />
            <IsActiveField />
            <DateField source="createTime" />
        </Datagrid>
    );
};

const FiltersTemplateList: FC<ListProps> = props => {
    const history = useHistory();
    const handleClose = useCallback(() => {
        history.push('/filtersTemplate');
    }, [history]);

    return (
        <Fragment>
            <List
                {...props}
                actions={<Actions />}
                filters={<ListFilters />}
                bulkActionButtons={<BulkActionButtons />}
                perPage={25}
                sort={{ field: 'updateTime', order: 'desc' }}
            >
                <DataGrid />
            </List>
            <Route
                path="/filtersTemplate/create"
                render={() => (
                    <DialogCreate
                        {...props}
                        onCancel={handleClose}
                        title={'Thêm cấu hình lọc'}
                    >
                        <FiltersTemplateCreate />
                    </DialogCreate>
                )}
            />
            <Route path="/filtersTemplate/:id">
                {({ match }) => {
                    const isMatch =
                        match && match.params && match.params.id !== 'create';

                    return (
                        isMatch && (
                            <DialogEdit
                                {...props}
                                onCancel={handleClose}
                                title={'Sửa cấu hình lọc'}
                                id={match?.params.id}
                            >
                                <FiltersTemplateCreate />
                            </DialogEdit>
                        )
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default FiltersTemplateList;
