import {
    Datagrid,
    DatagridProps,
    DateField,
    FunctionField,
    List,
} from 'react-admin';
import * as React from 'react';
import { FC } from 'react';

interface DataGridProps extends DatagridProps {
    page?: number;
    perPage?: number;
}

const PostPanel = ({ id, record, resource }) => (
    <div
        dangerouslySetInnerHTML={{
            __html: JSON.stringify({
                callId: record?.callId,
                normalTopics: record?.normalTopics,
                bestTopics: record?.bestTopics,
                contents: record?.contents,
                resultPatterns: record?.resultPatterns,
            }),
        }}
    />
);
const DataGrid: FC<DataGridProps> = props => {
    return (
        // @ts-ignore
        <Datagrid {...props} optimized expand={<PostPanel />}>
            <FunctionField
                label="#"
                render={record =>
                    ((props.page || 1) - 1) * (props.perPage || 0) +
                    Object.keys(props.data || {}).indexOf(
                        record?.id as string
                    ) +
                    1
                }
            />
            <DateField
                showTime
                source="starttime"
                label={'Thời gian đồng bộ từ tổng đài'}
            />
            <FunctionField
                label="Node IPCC"
                render={record =>
                    record?.inum ? record.inum.substring(0, 6) : ''
                }
            />
            <FunctionField
                label="Đường dẫn trên IPCC"
                render={record => {
                    if (record?.inum) {
                        let inputString = record?.pathFile;
                        return inputString.substring(30);
                    }
                    return '';
                }}
            />
            <DateField
                showTime
                source="uploadedTime"
                label={'Thời gian đẩy qua S2T'}
            />
            <FunctionField
                label="Trạng thái"
                render={record => {
                    return "{code: 200, 'message: Success!'}";
                }}
            />
            <DateField
                showTime
                source="syncTime"
                label={'Thời gian nhận kq S2T'}
            />
            <FunctionField
                label="Dữ liệu trả về"
                render={record => {
                    const json = {
                        callId: record?.callId,
                        normalTopics: record?.normalTopics,
                        bestTopics: record?.bestTopics,
                        contents: record?.contents,
                        resultPatterns: record?.resultPatterns,
                    };
                    return JSON.stringify(json).substring(0, 200) + '...';
                }}
            />
        </Datagrid>
    );
};
const CallLogsList = props => {
    return (
        <List {...props} bulkActionButtons={false}>
            <DataGrid></DataGrid>
        </List>
    );
};

export default CallLogsList;
