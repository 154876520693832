import * as React from 'react';
import {createRef, FC, useEffect, useRef, useState} from 'react';
import {
    Create,
    CreateProps, Edit, EditButton, EditProps,
    FormTab, Labeled,
    required, SaveButton, SimpleForm,
    TabbedForm, TextField,
    TextInput, Toolbar, useGetOne,
    useQueryWithStore, useRecordContext, useTranslate
} from 'react-admin';
import 'antd/dist/antd.css';
import CommonEditToolbar from '../../component/CommonEditToolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {useLocation} from "react-router-dom";
import TreeInput from "./TreeInput";

const useStyles = makeStyles({
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tab: {
        maxWidth: '40em',
        display: 'block',
    },
});


const PhanAnhEdit: FC<EditProps> = props => {
    const classes = useStyles();
    const location = useLocation();
    const translate = useTranslate();
    const callReflectId = location.pathname.split("/").pop()


    const { loaded, data } = useQueryWithStore({
        type: 'getList',
        resource: 'callReflect',
        payload: {
            filter: {
                parentId: null,
            },
            sort: { field: 'name', order: 'DESC' },
            pagination: { page: 1, perPage: 1000 },
        },
    });

    const entryList: Array<{
        parentId: string | null | undefined;
        title: string | null | undefined;
        value: string | null | undefined;
    }> = data
        ? data.map(d => {
            return {
                parentId: d.parentId,
                title: d.name,
                value: d.id,
            };
        })
        : [];

    const nestTree = function (items, value = null, link = 'parentId', disabled = false, level=0) {
        if (level === 2) {
            return;
        } else {
            return items
                .filter(item => item[link] === value)
                .map(item => {
                    if (item.value === callReflectId) {
                        return {
                            ...item,
                            disabled: true,
                            children: nestTree(items, item.value, 'parentId', true, level + 1)
                        }
                    }
                    if (disabled) {
                        return {
                            ...item,
                            disabled: true,
                            children: nestTree(items, item.value, 'parentId', true, level + 1)
                        }
                    }
                    return {
                        ...item,
                        disabled: false,
                        children: nestTree(items, item.value, 'parentId', false, level + 1)
                    }
                });
        }
    }
    return (
        <Edit
            {...props}
            title={'Chỉnh sửa chủ đề cuộc gọi'}
            actions={<CommonEditToolbar />}
        >
            <TabbedForm toolbar={<Toolbar alwaysEnableSaveButton />}>
                <FormTab label="THÔNG TIN CHUNG" contentClassName={classes.tab} >
                    <TextInput
                        validate={requiredValidate}
                        source="name"
                        fullWidth
                        label={translate('resources.callReflect.fields.name')}
                    />
                    <Box className="my_ant_tree_select single_choice">
                        <Labeled label={"Cấp cha"}>
                            <TreeInput source={'parentId'} data={nestTree(entryList)} treeCheckable={false} placeholder={"Tìm kiếm cấp cha"}/>
                        </Labeled>
                    </Box>
                </FormTab>
             </TabbedForm>
        </Edit>
    );
};

const requiredValidate = [required()];

export default PhanAnhEdit;
