import { Check } from '@material-ui/icons';
import {
    Button,
    FormWithRedirect,
    Labeled,
    required,
    SaveButton,
    SelectInput,
    TextField,
    TextInput,
    useCreate,
    useDataProvider,
    useNotify,
    useRefresh,
} from 'react-admin';
import * as React from 'react';
import { createRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { teal } from '@material-ui/core/colors';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Category } from '../types';
import CriteriaCardEvaluate from './CriteriaCardEvaluate';

const useStyles = makeStyles(theme => ({
    btnEvaluateOther: {
        marginLeft: theme.spacing(2),
        backgroundColor: teal[500],
        '&:hover': {
            backgroundColor: teal[700],
        },
    },
}));

const EvaluateOther = ({ callId, criterias, detail, callingRatingId }) => {
    const [criteriaSelected, setCriteriaSelected] = useState<Category[]>([]);
    const [totalScore, setTotalScore] = useState<number>(0);
    const [elRefs, setElRefs] = React.useState([]);
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const [open, setOpen] = useState<boolean>(false);
    const [create, { loading }] = useCreate('evaluateOther');
    const close = () => {
        setOpen(false);
    };
    const openDialog = () => {
        setOpen(true);
    };
    const listRank = [
        { id: 'Đạt', name: 'Đạt tiêu chuẩn' },
        { id: 'Không đạt', name: 'Chưa đạt tiêu chuẩn' },
    ];
    const submit = async values => {
        const criteriaDetailsMap = elRefs.map(({ current }) =>
            // @ts-ignore
            current.getData()
        );
        const criteriaDetails = criteriaDetailsMap.reduce(
            (hash, acc) => [...hash, ...acc],
            []
        );
        values.criteriaGSVForms = criteriaDetails;
        console.log(criteriaDetails);

        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }) => {
                    refresh();
                    notify('Đánh giá thành công','success');
                    close();
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                },
                onFailure: ({ body }) => {
                    notify(body.error, 'error');
                },
            }
        );
        console.log(values);
    };
    React.useEffect(() => {
        // add or remove refs
        if (criterias && criterias.length) {
            setElRefs(elRefs =>
                Array.from(
                    Array(
                        criterias.filter(val => val.children.length > 0).length
                    ),
                    (d, i) => i
                ).map((_, i) => elRefs[i] || createRef())
            );
        }
    }, [criterias]);

    return (
        <React.Fragment>
            <Button
                variant={'contained'}
                onClick={openDialog}
                className={classes.btnEvaluateOther}
                disableElevation
                startIcon={<Check />}
                label={'Đánh giá GSV'}
            />
            <Dialog open={open} onClose={close} fullWidth>
                <DialogTitle>
                    <Typography variant={'h6'} component={'h2'}>
                        Giám sát viên Mobifone Serivce đánh giá Giám sát viên
                    </Typography>
                </DialogTitle>
                <FormWithRedirect
                    resource="posts"
                    initialValues={{ callId, callingRatingId, detail }}
                    save={submit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving,
                    }) => (
                        <>
                            <DialogContent>
                                <Labeled label={'ID cuộc gọi'}>
                                    <TextField
                                        source="callId"
                                        record={detail}
                                    />
                                </Labeled>
                                <br />
                                <Labeled label={'GSV được đánh giá'}>
                                    <TextField
                                        source="supervisorName"
                                        record={detail}
                                    />
                                </Labeled>

                                <Labeled label={'Nhận xét của GSV'}>
                                    <TextField
                                        fullWidth
                                        source="note"
                                        record={detail}
                                    />
                                </Labeled>

                                <Typography variant="h6" gutterBottom>
                                    Tiêu chí đánh giá
                                </Typography>
                                {/*<Chip color={'primary'} label={`${totalScore} điểm`} />*/}
                                {/*</Box>*/}

                                {criterias
                                    .filter(val => val.children.length > 0)
                                    .map((criteria, i) => (
                                        <CriteriaCardEvaluate
                                            ref={elRefs[i]}
                                            criteria={criteria}
                                            criteriaSelected={criteriaSelected}
                                            key={criteria.id}
                                        />
                                    ))}

                                <TextInput
                                    resource="evaluateOther"
                                    source="opinion"
                                    multiline
                                    rows={4}
                                    validate={required()}
                                    fullWidth
                                />

                                <SelectInput
                                    resource="evaluateOther"
                                    source="rankSelected"
                                    choices={listRank}
                                />
                            </DialogContent>
                            <DialogActions>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    saving={saving}
                                    disabled={loading}
                                />
                                <Button onClick={close} label={'Hủy'} />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </React.Fragment>
    );
};

export default EvaluateOther;
