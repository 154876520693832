import * as React from 'react';
import {Button, useNotify} from "react-admin";
import {useState} from "react";
import {BASE_URL} from "../common/constants";
import {CircularProgress} from "@material-ui/core";
import {buildHeader} from "../utils/utils";
import {CloudDownload} from "@material-ui/icons";


const requireFieldNames = {
    userTypes: "Chức danh",
    fromDate: "Từ ngày",
    toDate: "Đến ngày",
    type: "Loại báo cáo",
    departmentIds: "Đơn vị",
    xepLoais: "Xếp loại",
    partnerCodes: "Đối tác",
    callCriteriaIds: "Tiêu chí",
    callDistributionIds: "Kỳ chia mẫu"
}

export const isTrue = (value) => {
    if(value === undefined) return false
    if(value === null) return false
    // @ts-ignore
    if(value instanceof Date && isNaN(value)) return false
    if(typeof(value) === "string" && value.trim() === "") return false
    if(typeof(value) === "object" && value.length === 0) return false
    return true;
}
// @ts-ignore
const ReportButton = (props: {
    url: string,
    fileName: string,
    requireFields?: object
}) : JSX.Element => {
    const notify = useNotify();
    const [loading, setLoading] = useState<boolean>(false);

    let reportFileName = props.fileName;
    let data

    const checkValidated = () => {
        if(props.requireFields !== undefined) {
            for (const [key, value] of Object.entries(props.requireFields)) {
                if(!isTrue(value)) {
                    notify('Chọn mục ' + requireFieldNames[key], { type: 'warning' })
                    return false;
                }
            }
            if(isTrue(props.requireFields['fromDate']) && isTrue(props.requireFields['toDate'])) {
                if(props.requireFields['fromDate'] > props.requireFields['toDate']) {
                    notify('Mục Đến ngày phải lớn hơn hoặc bằng mục Từ ngày', {type: 'warning'});
                    return false;
                }
            }
            return true;
        }
        return true;
    }

    const exportBaoCao = () => {
        if(checkValidated()) {
            setLoading(true);
            const request = new Request(`${BASE_URL}/${props.url}`, {
                method: 'GET',
                headers: buildHeader()
            })

            fetch(request)
                .then(response => {
                    // @ts-ignore
                    if (response.headers.get("content-disposition") !== null) {
                        // @ts-ignore
                        reportFileName = response.headers.get("content-disposition").split('filename=')[1].split(';')[0]
                        // console.log(reportFileName)
                    }
                    return response.blob();
                })
                .then(response => {
                    setLoading(false);
                    data = response;
                    let fileURL = window.URL.createObjectURL(
                        new Blob([response])
                    )
                    let fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                        'download',
                        `${reportFileName}`
                    )
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(({body, ...res}) => {
                    setLoading(false);
                    notify(body.error, 'error');
                });
        }
    }

    return (
            <>
            <Button
                // className={'btn-blue btn-get-request'}
                variant="contained"
                color="primary"
                size="small"
                disabled={loading}
                label="Xuất báo cáo"
                disableElevation
                onClick={exportBaoCao}
                startIcon={
                    loading ? (
                        <CircularProgress size={16} />
                    ) : (
                        <CloudDownload />
                    )
                }
            />
            </>
        );
}

export default ReportButton;