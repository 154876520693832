import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    CreateProps,
    FormTab,
    required,
    TabbedForm,
    TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import TransferList from './TransferUser';

export const styles = {
    input: { width: '24em' },
    userSelect: { width: '32em' },
};

const useStyles = makeStyles(styles);

const TelephonistGroupCreate: FC<CreateProps> = props => {
    const classes = useStyles();

    return (
        <Create {...props} title="Tạo mới nhóm Người dùng">
            <TabbedForm>
                <FormTab label="resources.group.tabs.information">
                    <TextInput
                        className={classes.input}
                        autoFocus
                        source="name"
                        validate={required()}
                    />
                    {/*<SelectInput*/}
                    {/*    className={classes.input}*/}
                    {/*    validate={required()}*/}
                    {/*    source="type"*/}
                    {/*    resource="telephonistGroup"*/}
                    {/*    optionText="name"*/}
                    {/*    choices={[*/}
                    {/*        { id: 'DTV', name: 'Điện thoại viên' },*/}
                    {/*        { id: 'GSV', name: 'Giám sát viên' },*/}
                    {/*    ]}*/}
                    {/*/>*/}

                    <TextInput
                        source="description"
                        multiline
                        className={classes.input}
                        rows={4}
                    />
                </FormTab>
                <FormTab
                    label="resources.telephonistGroup.tabs.users"
                    path="users"
                >
                    <TransferList />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default TelephonistGroupCreate;
