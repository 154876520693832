import * as React from 'react';
import { FC } from 'react';
import {
    required,
    SelectInput,
    TextInput,
    useQueryWithStore,
} from 'react-admin';

const DepartmentCreate: FC = () => {
    const { loaded, data } = useQueryWithStore({
        type: 'getList',
        resource: 'department',
        payload: {
            filter: {
                parentId: null,
            },
            sort: { field: 'deptName', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        },
    });

    return (
        <form>
            <div>
                <TextInput
                    validate={requiredValidate}
                    source="deptName"
                    resource="department"
                    fullWidth
                />
            </div>
            <div>
                <SelectInput
                    source="parentId"
                    resource="department"
                    fullWidth
                    optionText="deptName"
                    choices={data ? data.filter(item => !item.parentId) : []}
                />
            </div>
            <div>
                <TextInput
                    source="deptCode"
                    validate={requiredValidate}
                    resource="department"
                    fullWidth
                />
            </div>
        </form>
    );
};

const requiredValidate = [required()];

export default DepartmentCreate;
