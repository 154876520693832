import React from 'react';
import {ApexOptions} from "apexcharts";
import ReactApexChart from 'react-apexcharts'

type BarChartProps = {
    data: {name?: string, data: number[]}[],
    categories: string[],
    colors: string[],
    distributed: boolean,
    showLegend: boolean,
    showDataLabels: boolean,
    height?: string | number,
    nameChart?: string
    yLabel?: string,
}

const BarChart = (props: BarChartProps) => {
    const options: ApexOptions = {
        chart: {
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: props.distributed,
                horizontal: false,
                borderRadius: 4,
                dataLabels: {
                    orientation: 'vertical',
                    position: "center"
                },
            }
        },
        colors: props.colors,
        dataLabels: {
            enabled: props.showDataLabels,
            textAnchor: 'start',
            // position: "top",
            style: {
                colors: ['#000']
            },
            formatter: function (val, opt) {
                return val.toLocaleString();
            },
            offsetX: 0,
            dropShadow: {
                enabled: false
            }
        },
        stroke: {
            width: 6,
            colors: ['transparent']
        },
        xaxis: {
            categories: props.categories,
        },
        yaxis: {
            labels: {
                show: true
            },
            title: {
                text: props.yLabel,
                style: {
                    color: '#000',
                    fontWeight: 'bold',
                    fontFamily: '"Roboto","Helvetica","Arial", sans-serif',
                }
            }
        },
        tooltip: {
            x: {
                show: true,
            },
            y: {
                title: {
                    formatter: function () {
                        return ''
                    }
                }
            }
        },
        legend: {
          show: props.showLegend
        },
        grid: {
            position: 'back',
            borderColor: '#e7e7e7',
            strokeDashArray: 2,
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        }
    }

    return (
        <div style={{height: '300px'}}>
            <ReactApexChart
                options={options}
                series={props.data}
                type="bar"
                height={props.height ? props.height : '100%'}
            />
        </div>
    )
};

export default BarChart;