// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputHelperText } from 'ra-ui-materialui';
import { useInput } from 'react-admin';

const AutoComplete = props => {
    // console.log(props);
    const {
        choices,
        loading,
        label,
        getOptionLabel,
        optionValue = 'id',
    } = props;
    const {
        input: { name, onChange, value, helperText, ...rest },
        meta: { touched, error },
        isRequired,
    } = useInput(props);
    const [open, setOpen] = React.useState(false);
    const values = value
        ? choices?.find(item1 => item1[optionValue] === value)
        : null;

    return (
        <Autocomplete
            open={open}
            value={values}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            // @ts-ignore
            onChange={(event: ChangeEvent<{}>, newValue: any) => {
                onChange(newValue ? newValue[optionValue] : '');
            }}
            // @ts-ignore
            getOptionSelected={(option, value) =>
                option[optionValue] === value[optionValue]
            }
            // @ts-ignore
            getOptionLabel={getOptionLabel}
            options={choices}
            loading={loading}
            renderInput={params => (
                <TextField
                    style={{ width: 400 }}
                    {...params}
                    {...rest}
                    label={'Đơn vị'}
                    required={isRequired}
                    variant="filled"
                    size="small"
                    error={!!(touched && error)}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default AutoComplete;
