import * as React from 'react';
import { FC } from 'react';
import {
    DeleteButton,
    Edit,
    EditProps,
    FormTab,
    required,
    SaveButton,
    TabbedForm,
    TextInput,
    Toolbar,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CommonEditToolbar from '../component/CommonEditToolbar';
import TransferList from './TransferUser';

export const styles = {
    input: { width: '24em' },
};

const useStyles = makeStyles(styles);

const TelephonistGroupEditToolbar = props => {
    const { record } = props;
    console.log(props);
    return (
        <Toolbar {...props} style={{ justifyContent: 'space-between' }}>
            <SaveButton label="Lưu" redirect="list" submitOnEnter={false} />
            {(!record?.userIds?.length || record?.userIds?.length === 0) && (
                <DeleteButton />
            )}
        </Toolbar>
    );
};
const TelephonistGroupEdit: FC<EditProps> = props => {
    const classes = useStyles();
    return (
        <Edit
            {...props}
            title="Chi tiết nhóm"
            actions={<CommonEditToolbar />}
            mutationMode={'pessimistic'}
        >
            <TabbedForm toolbar={<TelephonistGroupEditToolbar />}>
                <FormTab label="resources.group.tabs.information">
                    <TextInput
                        className={classes.input}
                        autoFocus
                        source="name"
                        validate={required()}
                    />
                    {/*<SelectInput*/}
                    {/*    className={classes.input}*/}
                    {/*    validate={required()}*/}
                    {/*    source="type"*/}
                    {/*    optionText="name"*/}
                    {/*    choices={[*/}
                    {/*        { id: 3, name: 'Điện thoại viên' },*/}
                    {/*        { id: 2, name: 'Giám sát viên' },*/}
                    {/*    ]}*/}
                    {/*/>*/}
                    <TextInput
                        source="description"
                        multiline
                        className={classes.input}
                        rows={4}
                    />
                </FormTab>
                <FormTab
                    label="resources.telephonistGroup.tabs.users"
                    path="users"
                >
                    <TransferList />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default TelephonistGroupEdit;
