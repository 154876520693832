import * as React from 'react';
import { ReactElement } from 'react';
import {
    CreateButton,
    Datagrid,
    Filter,
    FilterProps,
    List,
    ListProps,
    SearchInput,
    TextField,
    TopToolbar,
} from 'react-admin';

const PartnerFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const Actions = (props: any) => (
    <TopToolbar>
        <CreateButton color="primary" />
    </TopToolbar>
);

const PartnerList = (props: ListProps): ReactElement => {
    return (
        <List
            {...props}
            filters={<PartnerFilter />}
            actions={<Actions />}
            sort={{ field: 'updateTime', order: 'DESC' }}
            perPage={25}
        >
            <Datagrid optimized rowClick="edit">
                <TextField source="name" />
                <TextField source="code" />
                <TextField source="deptName" />
                <TextField source="description" />
            </Datagrid>
        </List>
    );
};

export default PartnerList;
