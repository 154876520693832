import React, { useCallback, useEffect, useState } from 'react';
import { chartColors, MemberType, TimeFilterValueType } from './Dashboard';
import dayjs from 'dayjs';
import { memberType } from './MemberTree';
import { BASE_URL } from '../common/constants';
import { httpClient } from '../dataProvider';
import { serialize } from '../dataProvider/utils';
import { useNotify } from 'react-admin';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { Select } from 'antd';
import TimeFilter from './TimeFilter';
import BarChart from './BarChart';
import PieChart from './PieChart';
import LinearProgress from '@mui/material/LinearProgress';

type GeneralCallStatisticsProps = {
    member: MemberType | undefined;
};
const CallGeneralStatistics = ({ member }: GeneralCallStatisticsProps) => {
    const notify = useNotify();

    const [callGeneralFilter, setCallGeneralFilter] = useState<
        TimeFilterValueType
    >();

    const [callGeneralStatistics, setCallGeneralStatistics] = useState<{
        barData: { name?: string; data: number[] }[];
        barCategories: string[];
        barColors: string[];
        pieData: number[];
        pieCategories: string[];
        pieColors: string[];
    }>({
        barData: [],
        barCategories: [],
        barColors: [],
        pieData: [],
        pieCategories: [],
        pieColors: [],
    });

    const [
        fetchCallGeneralStatisticsLoading,
        setFetchCallGeneralStatisticsLoading,
    ] = useState<boolean>(false);

    const [callGeneralTimeType, setCallGeneralTimeType] = useState<
        'date' | 'dateRange' | 'month' | 'week' | 'twelveMonths' | 'year'
    >('date');

    const fetchCallGeneralStatistics = useCallback(async () => {
        if (callGeneralFilter && member) {
            if (
                callGeneralFilter.type === 'dateRange' &&
                (dayjs(callGeneralFilter.toDate.getTime()).diff(
                    dayjs(callGeneralFilter.fromDate),
                    'day'
                ) > 31 ||
                    dayjs(callGeneralFilter.toDate.getTime()).diff(
                        dayjs(callGeneralFilter.fromDate),
                        'day'
                    ) < 0)
            ) {
                notify(
                    'Báo cáo tổng quát cuộc gọi: Không được vượt qua 31 ngày và ngày bắt đầu phải nhỏ hơn hoặc bằng ngày kết thúc',
                    'warning'
                );
                return;
            }
            const filter = {
                'starttime.greaterThanOrEqual': callGeneralFilter.fromDate.getTime(),
                'starttime.lessThanOrEqual': callGeneralFilter.toDate.getTime(),
            };

            setFetchCallGeneralStatisticsLoading(true);

            const url = `${BASE_URL}/dashboard/callStatistics`;
            let totalCall,
                evaluatedCall,
                notEvaluatedCall,
                errorCall,
                hasComplainCall;
            const errors: any[] = [];
            const promises: Promise<any>[] = [
                httpClient(
                    `${url}?${serialize({
                        name: 'tongQuatTong',
                        ...filter,
                    })}`,
                    {}
                ).then(response => {
                    return response.json;
                }),
                httpClient(
                    `${url}?${serialize({
                        name: 'tongQuatChuaDanhGia',
                        ...filter,
                        'tichMaId.specified': false,
                    })}`,
                    {}
                ).then(response => {
                    return response.json;
                }),
                httpClient(
                    `${url}?${serialize({
                        name: 'tongQuatDaDanhGia',
                        ...filter,
                        'tichMaId.specified': true,
                    })}`,
                    {}
                ).then(response => {
                    return response.json;
                }),
                httpClient(
                    `${url}?${serialize({
                        name: 'tongQuatLoi',
                        ...filter,
                        'errorCall.equals': true,
                    })}`,
                    {}
                ).then(response => {
                    return response.json;
                }),
                httpClient(
                    `${url}?${serialize({
                        name: 'tongQuatKhieuNai',
                        ...filter,
                        'hasComplain.equals': true,
                    })}`,
                    {}
                ).then(response => {
                    return response.json;
                }),
            ];
            await Promise.all(
                promises.map(p => p.catch(err => errors.push(err)))
            ).then(values => {
                [
                    totalCall,
                    notEvaluatedCall,
                    evaluatedCall,
                    errorCall,
                    hasComplainCall,
                ] = values;
            });
            if (errors.length > 0) {
                notify(
                    'Báo cáo tổng quát cuộc gọi: Có lỗi khi load dữ liệu',
                    'warning'
                );
            } else {
                const callGeneralBarDataTmp: {
                    name?: string;
                    data: number[];
                }[] = [
                    {
                        data: [
                            Math.floor(
                                totalCall[0] && totalCall[0].y
                                    ? Number(totalCall[0].y)
                                    : 0
                            ),
                            Math.floor(
                                notEvaluatedCall[0] && notEvaluatedCall[0].y
                                    ? Number(notEvaluatedCall[0].y)
                                    : 0
                            ),
                            Math.floor(
                                evaluatedCall[0] && evaluatedCall[0].y
                                    ? Number(evaluatedCall[0].y)
                                    : 0
                            ),
                        ],
                    },
                ];
                const callGeneralBarCategoriesTmp: string[] = [
                    'Tổng số cuộc gọi',
                    'Chưa tích mã',
                    'Đã tích mã',
                ];
                const callGeneralBarColorsTmp: string[] = [
                    ...Object.values(chartColors),
                ];
                const callGeneralPieDataTmp: number[] = [
                    evaluatedCall[0] && evaluatedCall[0].y
                        ? Number(evaluatedCall[0].y)
                        : 0,
                    notEvaluatedCall[0] && notEvaluatedCall[0].y
                        ? Number(notEvaluatedCall[0].y)
                        : 0,
                ];
                const callGeneralPieCategoriesTmp: string[] = [
                    'Đã tích mã',
                    'Chưa tích mã',
                ];
                const callGeneralPieColorsTmp: string[] = [
                    chartColors.evaluatedCall,
                    chartColors.notEvaluatedCall,
                ];
                setCallGeneralStatistics({
                    barData: callGeneralBarDataTmp,
                    barCategories: callGeneralBarCategoriesTmp,
                    barColors: callGeneralBarColorsTmp,
                    pieData: callGeneralPieDataTmp,
                    pieCategories: callGeneralPieCategoriesTmp,
                    pieColors: callGeneralPieColorsTmp,
                });
            }
            setFetchCallGeneralStatisticsLoading(false);
        }
    }, [
        callGeneralFilter?.type,
        callGeneralFilter?.toDate,
        callGeneralFilter?.fromDate,
        member?.type,
        member?.memberId,
    ]);

    useEffect(() => {
        fetchCallGeneralStatistics();
    }, [fetchCallGeneralStatistics]);

    return (
        <>
            <Box>
                <Typography variant="body1">
                    <Box>
                        <p style={{ margin: '0 5px 0 0' }}>
                            Báo cáo tổng quát cuộc gọi theo
                        </p>
                        <Select
                            defaultValue={callGeneralTimeType}
                            onChange={value => {
                                setCallGeneralTimeType(value);
                            }}
                            style={{ width: 140 }}
                            options={[
                                { value: 'date', label: 'Ngày' },
                                {
                                    value: 'dateRange',
                                    label: 'Khoảng ngày',
                                },
                                { value: 'month', label: 'Tháng' },
                                { value: 'week', label: 'Tuần' },
                            ]}
                        />
                    </Box>
                </Typography>
                <Tooltip title="Thời gian bắt đầu cuộc gọi" placement="top">
                    <div>
                        <TimeFilter
                            type={callGeneralTimeType}
                            onchange={(values: {
                                type: string;
                                fromDate: Date;
                                toDate: Date;
                            }) => {
                                setCallGeneralFilter({ ...values });
                            }}
                        />
                    </div>
                </Tooltip>
            </Box>
            {!fetchCallGeneralStatisticsLoading ? (
                <Grid container spacing={2}>
                    <Grid item xs={8} md={8} sm={12}>
                        <BarChart
                            data={callGeneralStatistics.barData}
                            categories={callGeneralStatistics.barCategories}
                            colors={callGeneralStatistics.barColors}
                            showLegend={false}
                            showDataLabels={true}
                            distributed={true}
                            yLabel={'Cuộc'}
                        />
                    </Grid>
                    <Grid item xs={4} md={4} sm={12}>
                        <PieChart
                            data={callGeneralStatistics.pieData}
                            categories={callGeneralStatistics.pieCategories}
                            colors={callGeneralStatistics.pieColors}
                        />
                    </Grid>
                </Grid>
            ) : (
                <LinearProgress style={{ marginTop: '5px' }} />
            )}
        </>
    );
};

export default CallGeneralStatistics;
