import React from 'react';
import {ApexOptions} from "apexcharts";
import ReactApexChart from 'react-apexcharts'

type LineChartProps = {
    data: {name?: string, data: number[]}[],
    categories: string[],
    colors: string[],
    yLabel?: string,
}

const LineChart = (props: LineChartProps) => {
    const options: ApexOptions = {
        chart: {
            height: 400,
            type: 'line',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        colors: props.colors,
        dataLabels: {
            enabled: true,
            background: {
                borderRadius: 100,
            },
            formatter: (val: string | number | number[], opts?: any) => {
                return '';
            },
        },
        stroke: {
            curve: 'straight',
            width: 2,
        },
        grid: {
            position: 'back',
            borderColor: '#e7e7e7',
            strokeDashArray: 2,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        markers: {
            strokeWidth: 0,
            size: 6,
        },
        xaxis: {
            categories: props.categories,
            tickPlacement: 'on',
            labels: {
                rotate: -45,
                rotateAlways: true,
            },
        },
        yaxis: {
            floating: false,
            labels: {
                show: true,
                formatter: (val: number, opts?: any) => {
                    return val.toLocaleString();
                },
            },
            title: {
                text: props.yLabel,
                style: {
                    color: '#000',
                    fontWeight: 'bold',
                    fontFamily: '"Roboto","Helvetica","Arial", sans-serif',
                }
            }
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
        }
    };

    return (
        <div style={{height: '100%'}}>
            <ReactApexChart
                options={options}
                series={props.data}
                type="line"
                height={'400'}
            />
        </div>
    )
};

export default LineChart;