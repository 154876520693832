import Dialog from '@material-ui/core/Dialog';
import * as React from 'react';
import { FC } from 'react';
import {
    AutocompleteInput,
    Create,
    Labeled,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useCreate,
    useCreateSuggestionContext,
    useQueryWithStore,
} from 'react-admin';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    TextField,
} from '@material-ui/core';
import TichMaTreeInput from './TichMaTreeInput';
import CommonEditToolbar from '../../component/CommonEditToolbar';

const TuKhoaCreate: FC = props => {
    return (
        <Create
            {...props}
            title={'Thêm mới từ điển chuyên ngành'}
            actions={<CommonEditToolbar />}
        >
            <SimpleForm>
                <TextInput
                    validate={requiredValidate}
                    source="textValue"
                    resource="keywords"
                    fullWidth
                />
                <ReferenceInput
                    source="keywordGroupId"
                    reference="keywordGroup"
                    resource="keywords"
                    fullWidth
                    perPage={10000}
                    sort={{ field: 'name', order: 'ASC' }}
                >
                    <AutocompleteInput
                        fullWidth
                        validate={requiredValidate}
                        optionText="name"
                        create={<CreateCategory />}
                        createLabel="---Tạo mới---"
                    />
                </ReferenceInput>
                <Box className="my_ant_tree_select single_choice">
                    <Labeled label={'Chủ đề tích mã'}>
                        <TichMaTreeInput
                            source={'parentId'}
                            treeCheckable={false}
                            placeholder={'Tìm kiếm chủ đề tích mã'}
                        />
                    </Labeled>
                </Box>
                <TextInput
                    multiline
                    source="description"
                    row={4}
                    resource="keywords"
                    fullWidth
                />
            </SimpleForm>
        </Create>
    );
};

const CreateCategory = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = React.useState(filter || '');
    const [create] = useCreate('keywordGroup');

    const handleSubmit = event => {
        event.preventDefault();
        create(
            {
                payload: {
                    data: {
                        name: value,
                        code: value,
                    },
                },
            },
            {
                onSuccess: ({ data }) => {
                    setValue('');
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label="Tạo nhóm từ khoá"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Lưu</Button>
                    <Button onClick={onCancel}>Huỷ</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const requiredValidate = [required()];

export default TuKhoaCreate;
