import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    BulkActionProps,
    BulkDeleteButton,
    Datagrid,
    DatagridProps,
    DateField,
    FunctionField,
    List,
    ListProps,
    TextField,
} from 'react-admin';
import StatusField from './StatusField';
import ComplainListAside from './ComplainListAside';

interface DataGridProps extends DatagridProps {
    page?: number;
    perPage?: number;
}

const BulkActionButtons = (props: BulkActionProps) => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const DataGrid: FC<DataGridProps> = props => {
    return (
        <Datagrid {...props} optimized rowClick="show">
            <FunctionField
                label="#"
                render={record =>
                    ((props.page || 1) - 1) * (props.perPage || 0) +
                    Object.keys(props.data || {}).indexOf(record?.id as string) +
                    1
                }
            />
            <TextField source="callId" />
            <TextField source="opinion" />
            <TextField source="supervisorName" />
            <TextField source="createBy" />
            <DateField source="createTime" />
            <StatusField />
        </Datagrid>
    );
};

const ComplainList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            // empty={false}
            aside={<ComplainListAside />}
            bulkActionButtons={<BulkActionButtons />}
            perPage={25}
            sort={{ field: 'updateTime', order: 'desc' }}
        >
            <DataGrid />
        </List>
    );
};

export default ComplainList;
