import * as React from 'react';
import PropTypes from 'prop-types';
import { useGetIdentity, useNotify, useUpdate } from 'react-admin';
import { CheckCircle, RemoveCircle } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
    active: {
        color: green[500],
        cursor: 'pointer',
    },
    pointer: {
        cursor: 'pointer',
    },
    root: {
        textAlign: 'center',
    },
});

const IsActiveField = props => {
    const classes = useStyles();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const { source, basePath, record } = props;
    const letActive = e => {
        e.stopPropagation();
        active();
    };
    const [active, { loading }] = useUpdate(
        basePath.substring(1),
        record?.id,
        { ...record, [source]: true },
        record,
        {
            undoable: false,
            onSuccess: () => {
                notify('Kích hoạt thành công', 'info', {}, true);
            },
            onFailure: ({ body }) => {
                notify(body.error, 'warning');
            },
        }
    );

    const getActive = () => {
        if (record) {
            return record[source];
        }
        return false;
    };

    const letDeactivate = e => {
        e.stopPropagation();
        deactivate();
    };
    const [deactivate, { loading: loading1 }] = useUpdate(
        basePath.substring(1),
        record?.id,
        { ...record, [source]: false },
        record,
        {
            undoable: false,
            onSuccess: () => {
                notify('Hủy kích hoạt thành công', 'info', {}, true);
            },
            onFailure: ({ body }) => {
                notify(body.error, 'warning');
            },
        }
    );
    if (loading || loading1) {
        return <CircularProgress size={16} />;
    }
    if (getActive()) {
        return (
            <CheckCircle className={classes.active} onClick={letDeactivate} />
        );
    }
    return (
        <RemoveCircle
            color="disabled"
            className={classes.pointer}
            onClick={letActive}
        />
    );
};

IsActiveField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    identity: PropTypes.object,
    source: PropTypes.string,
    basePath: PropTypes.string,
};

IsActiveField.defaultProps = {
    source: 'isActive',
    textAlign: 'center',
};

export default IsActiveField;
