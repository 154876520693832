import GroupTwoToneIcon from '@material-ui/icons/GroupTwoTone';
import GroupCreate from './GroupCreate';
import GroupEdit from './GroupEdit';
import GroupList from './GroupList';

export default {
    icon: GroupTwoToneIcon,
    list: GroupList,
    create: GroupCreate,
    edit: GroupEdit,
};
