import CallIcon from '@material-ui/icons/Phone';
import CallList from './CallList';
import CallEdit from './CallEdit';
import CallCreate from "./CallCreate";

export default {
    icon: CallIcon,
    list: CallList,
    create: CallCreate,
    edit: CallEdit,
};

export const callToggleableFields = [
    'agentId',
    'agentDesc',
    'callId',
    'hotlineRoomName',
    'hotlineRoomDesc',
    'partnerId',
    'starttime',
    'duration',
    'evaluate',
    'generalComment',
    'phoneNumber',
    'incoming',
    'topic',
    'updateErrorPersonId',
    'updateErrorDate',
    'errorReason',
]

