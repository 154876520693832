import * as React from 'react';
import { FC } from 'react';
import {
    AutocompleteInput,
    Create,
    CreateProps,
    FormTab,
    ReferenceInput,
    required,
    TabbedForm,
    TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Department } from '../types';
import CommonEditToolbar from '../component/CommonEditToolbar';

interface PartnerTitleProps {
    record?: Department;
}

const PartnerTitle: FC<PartnerTitleProps> = ({ record }) =>
    record ? <span>Tạo mới: {record.reference}</span> : null;

const useStyles = makeStyles({
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tab: {
        maxWidth: '40em',
        display: 'block',
    },
});

const PartnerCreate: FC<CreateProps> = props => {
    const classes = useStyles();
    return (
        <Create
            {...props}
            title={<PartnerTitle />}
            actions={<CommonEditToolbar />}
        >
            <TabbedForm>
                <FormTab
                    label="resources.partner.tabs.information"
                    contentClassName={classes.tab}
                >
                    <TextInput
                        validate={requiredValidate}
                        source="name"
                        resource="partner"
                        fullWidth
                    />
                    <TextInput
                        validate={requiredValidate}
                        source="code"
                        resource="partner"
                        fullWidth
                    />
                    <ReferenceInput
                        validate={requiredValidate}
                        source="deptId"
                        resource="partner"
                        reference="department"
                        fullWidth
                    >
                        <AutocompleteInput
                            validate={[required()]}
                            optionText={(choice?: Department) =>
                                choice?.id // the empty choice is { id: '' }
                                    ? `${choice.deptName} - ${choice.deptCode}`
                                    : ''
                            }
                        />
                    </ReferenceInput>
                    <TextInput
                        multiline
                        source="description"
                        resource="partner"
                        fullWidth
                    />
                </FormTab>
                {/*<FormTab label="resources.partner.tabs.users" path="user">*/}
                {/*    <ReferenceManyField*/}
                {/*        reference="user"*/}
                {/*        target="partnerId"*/}
                {/*        addLabel={false}*/}
                {/*        pagination={<Pagination />}*/}
                {/*        fullWidth*/}
                {/*    >*/}
                {/*        <Datagrid>*/}
                {/*            <CustomerLinkField />*/}
                {/*            <TextField source="userName" />*/}
                {/*            <TextField source="deptName" />*/}
                {/*            <TextField source="status" />*/}
                {/*            <EmailField source="email" />*/}
                {/*            <ShowButton />*/}
                {/*        </Datagrid>*/}
                {/*    </ReferenceManyField>*/}
                {/*</FormTab>*/}
            </TabbedForm>
        </Create>
    );
};

const requiredValidate = [required()];

export default PartnerCreate;
