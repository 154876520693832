export const convertToTree = (list: any[]) => {
    let map = {},
        node,
        roots = [],
        i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== null) {
            const parent = list[map[node.parentId]];
            if (parent) {
                parent.children.push(node);
            } else {
                // @ts-ignore
                roots.push(node);
            }
            // if you have dangling branches check that map[node.parentId] exists
        } else {
            // @ts-ignore
            roots.push(node);
        }
    }
    return roots;
};

export const truncDate = (date: Date) => {
    const result = new Date(date);
    result.setHours(0);
    result.setMinutes(0);
    result.setSeconds(0);
    return result;
};
