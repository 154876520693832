import { AuthProvider } from 'react-admin';
import { BASE_URL } from './common/constants';

const redirectUri =
    'https://sso.mobifone.vn/sso/authorize-spa?response_type=id_token%20token&scope=openid&nonce=anything&state=anything&client_id=GSDTV&redirect_uri=https%3A%2F%2Fgsdtv.mobifone.vn%2Fgsdtv%2F%23%2Flogin';

const authProvider: AuthProvider = {
    // @ts-ignore
    login: ({ username, password, type = null, token }) => {
        // console.log('test' + token);
        if (type === 'sso') {
            if (token) {
                localStorage.setItem('token', token);
                localStorage.setItem('ldap_type', 'sso');
                return Promise.resolve();
            } else {
                window.location.replace(redirectUri);
                return;
            }
        } else {
            const request = new Request(`${BASE_URL}/auth/login`, {
                method: 'POST',
                body: JSON.stringify({ username, password }),
                headers: new Headers({
                    'Content-Type': 'application/json',
                    LdapType: 'normal',
                }),
            });
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then(({ token }) => {
                    localStorage.setItem('token', token);
                    localStorage.setItem('ldap_type', 'normal');
                })
                .catch(() => {
                    throw new Error('Mật khẩu không chính xác');
                });
        }
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            console.log('error debug: ' + error);
            localStorage.removeItem('token');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () =>
        localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
    getPermissions: () => {
        const request = new Request(`${BASE_URL}/user/fetch/`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                LdapType: `${localStorage.getItem('ldap_type')}`,
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ permissions }) => {
                return Promise.resolve(permissions);
            })
            .catch(reason => {
                localStorage.removeItem('token');
                return Promise.reject();
            });
    },
    getIdentity: () => {
        const userSaved = localStorage.getItem('user');
        if (userSaved) {
            return Promise.resolve({
                // @ts-ignore
                ...JSON.parse(userSaved),
                avatar:
                    'https://res.cloudinary.com/minimal-ui/image/upload/v1614655910/upload_minimal/avatar/minimal_avatar.jpg',
            });
        } else {
            const request = new Request(`${BASE_URL}/user/fetch/`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    LdapType: `${localStorage.getItem('ldap_type')}`,
                }),
            });
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then(({ user }) => {
                    localStorage.setItem('user', JSON.stringify(user));
                    return Promise.resolve({
                        ...user,
                        avatar:
                            'https://res.cloudinary.com/minimal-ui/image/upload/v1614655910/upload_minimal/avatar/minimal_avatar.jpg',
                    });
                });
        }
    },
};

export default authProvider;
