import {
    Edit,
    EditProps,
    FormTab,
    Labeled,
    Link,
    ReferenceManyField,
    SingleFieldList,
    TabbedForm,
    TextField,
    TextInput,
    useGetIdentity,
    useRecordContext,
    useDataProvider,
    Button,
    useCreate,
    useRefresh,
    useNotify,
    SaveButton,
    useUpdate,
    required,
    SelectInput,
} from 'react-admin';
import { TextField as MuiTextField } from '@material-ui/core';
import * as React from 'react';
import { createRef, FC, useCallback, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Box, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StatusField from './StatusField';
import Aside from './Aside';
import CommonEditToolbar from '../component/CommonEditToolbar';
import UpdateRatingButton from './DetailRatingChangeButton';
import EvaluateOtherEditToolbar from './EvaluateOtherEditToolbar';
import CriteriaCard from '../call/CriteriaCard';
import { Category } from '../types';
import { convertToTree } from '../utils';
import CriteriaCardEvaluate from '../call/CriteriaCardEvaluate';
import { SaveRounded } from '@material-ui/icons';
import data from '../segments/data';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
        marginTop: -theme.spacing(0.5),
        marginBottom: -theme.spacing(0.5),
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    fullWidth: {
        width: '100%',
    },
}));

const DetailForm = props => {
    const [opinion, setOpinion] = useState<string>('');
    const [rankSelected, setRankSelected] = useState<string>('');
    const [criterias, setCriteria] = useState<Category[]>([]);
    const [criteriasSelected, setCriteriaSelected] = useState<Category[]>([]);
    const [elRefs, setElRefs] = React.useState([]);
    const record = useRecordContext();
    const { identity } = useGetIdentity();
    const classes = useStyles();
    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const getActive = item => {
        if (identity) {
            if (item.deptId === 'TCT' || item.deptId === identity.deptId) {
                return item.isActive;
            } else {
                return item.deptIdsActive
                    ? item.deptIdsActive.includes(identity.deptId)
                    : false;
            }
        }
        return true;
    };

    React.useEffect(() => {
        console.log(record);
        if (record) {
            setCriteriaSelected(record.callManagerCriteriaList);
            setOpinion(record.opinion);
            setRankSelected(record.rankSelected);
        }
    }, [record]);

    React.useEffect(() => {
        // add or remove refs
        if (criterias && criterias.length) {
            setElRefs(elRefs =>
                Array.from(
                    Array(
                        criterias.filter(val => val.children.length > 0).length
                    ),
                    (d, i) => i
                ).map((_, i) => elRefs[i] || createRef())
            );
        }
    }, [criterias]);
    const submit = () => {
        const params = convertSubmitForm();
        console.log('test');
        console.log(params);
        try {
            dataProvider
                .update(`evaluateOther`, {
                    id: record.id,
                    data: params,
                    previousData: record,
                })
                .then(({ data }) => {
                    notify('Cập nhật đánh giá thành công!', 'success');
                })
                .catch(error => {
                    notify('Cập nhật đánh giá thất bại!', 'error');
                    return;
                });
        } catch (e) {
            console.log(e);
        }
    };
    const listRank = [
        { id: 'Đạt', name: 'Đạt tiêu chuẩn' },
        { id: 'Không đạt', name: 'Chưa đạt tiêu chuẩn' },
    ];
    const convertSubmitForm = () => {
        const criteriaDetailsMap = elRefs.map(({ current }) =>
            // @ts-ignore
            current.getData()
        );
        const criteriaDetails = criteriaDetailsMap.reduce(
            (hash, acc) => [...hash, ...acc],
            []
        );
        const criteriaGSVForms = criteriaDetails;
        return {
            rankSelected: rankSelected,
            opinion: opinion,
            criteriaGSVForms,
        };
    };

    const loadCategory = useCallback(async () => {
        const { data: evaluateOtherData } = await dataProvider.getList<
            Category
        >('criteriaGSV', {
            filter: {},
            sort: { field: 'name', order: 'ASC' },
            pagination: { page: 1, perPage: 50 },
        });
        setCriteria(convertToTree(evaluateOtherData.filter(getActive)));
    }, [dataProvider]);
    useEffect(() => {
        loadCategory();
    }, [loadCategory]);
    return (
        <div>
            {record && (
                <Box display={'flex'} flexWrap={'wrap'} my={3}>
                    <Box flex={1}>
                        <Labeled label="ID cuộc gọi">
                            <ReferenceManyField
                                reference="call"
                                source="callId"
                                target="callId"
                            >
                                <SingleFieldList>
                                    <TextField source="callId" />
                                </SingleFieldList>
                            </ReferenceManyField>
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <Labeled label="GSV được đánh giá">
                            <Link
                                className={classes.root}
                                to={`/user/${record.supervisorId}`}
                            >
                                <Avatar
                                    className={classes.avatar}
                                    alt={record.supervisorName}
                                />
                                {record.supervisorName}
                            </Link>
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <Labeled label="GSV Mobifone đánh giá">
                            <Link
                                className={classes.root}
                                to={`/user/${record.telephonistId}`}
                            >
                                <Avatar
                                    className={classes.avatar}
                                    alt={record.telephonistName}
                                />
                                {record.telephonistName}
                            </Link>
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <Labeled label="Điểm đánh giá">
                            <Chip
                                size={'small'}
                                variant={'outlined'}
                                color={'secondary'}
                                label={record.score}
                            />
                        </Labeled>
                    </Box>
                </Box>
            )}
            {record && (
                <Box display={'flex'} flexWrap={'wrap'} my={3}>
                    <Box flex={1}>
                        <Labeled label="Thời gian đánh giá">
                            <span>
                                {new Date(record.createTime).toLocaleString()}
                            </span>
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <SelectInput
                            resource="evaluateOther"
                            source="rankSelected"
                            choices={listRank}
                            onChange={({ target }) =>
                                setRankSelected(target.value)
                            }
                        />
                    </Box>
                    <Box flex={1}>
                        {/*<Labeled label="Trạng thái">
                            <StatusField record={record} {...props} />
                        </Labeled>*/}
                    </Box>
                    <Box flex={1}></Box>
                </Box>
            )}
            {criterias
                .filter(val => val.children.length > 0)
                .map((criteria, i) => (
                    <CriteriaCardEvaluate
                        disable={true}
                        ref={elRefs[i]}
                        criteria={criteria}
                        criteriaSelected={criteriasSelected}
                        key={criteria.id}
                    />
                ))}
            <Labeled label={'Nhận xét của GSV'}>
                <TextField fullWidth source="note" />
            </Labeled>

            <MuiTextField
                margin={'dense'}
                variant="filled"
                /*ChungPT: edit*/
                rows={6}
                fullWidth
                multiline
                value={opinion}
                label="Nhận xét chung"
                onChange={({ target }) => setOpinion(target.value)}
            />

            <Button
                startIcon={<SaveRounded />}
                variant={'contained'}
                onClick={submit}
                color="primary"
                disableElevation
                label={'Lưu đánh giá'}
            />
        </div>
    );
};

const EvaluateOtherEdit: FC<EditProps> = props => {
    return (
        <Edit
            {...props}
            title={'Chi tiết đánh giá'}
            aside={<Aside />}
            mutationMode={'pessimistic'}
            actions={<CommonEditToolbar />}
        >
            <TabbedForm toolbar={<EvaluateOtherEditToolbar />}>
                <FormTab label="Thông tin đánh giá">
                    <DetailForm />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default EvaluateOtherEdit;
