import React, {FC, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Grid, Typography} from '@mui/material';
import CallGeneralStatistics from './CallGeneralStatistics';
import CallDetailStatistics from './CallDetailStatistics';
import CallAverageTimeByWeekStatistics from './CallAverageTimeByWeekStatistics';
import CallAverageTimeByMonthStatistics from './CallAverageTimeByMonthStatistics';
import {useGetIdentity} from "react-admin";

const useStyles = makeStyles({
    chartTitle: {
        backgroundColor: '#f7f7f7',
        padding: 10,
        borderRadius: '4px',
        '& > div:first-child': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                '& > div:first-child': {
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    '& .ant-select-selection-item': {
                        fontWeight: 'bold',
                        fontSize: '0.95rem',
                    },
                    '& .ant-select-selector': {
                        backgroundColor: '#f7f7f7 !important',
                    },
                },
            },
        },
        '& .ant-select-selector': {
            height: '100% !important',
            paddingRight: '30px !important',
        },
        '& .ant-select-selection-search, .ant-select-selection-item': {
            display: 'flex !important',
            alignItems: 'center !important',
        },
    },
});

export type TimeFilterValueType = {
    type: string;
    fromDate: Date;
    toDate: Date;
};

export type MemberType = {
    memberId: string;
    type: string;
};

export const chartColors = {
    totalCall: '#1296FD',
    notEvaluatedCall: '#F39C12',
    evaluatedCall: '#3FBF00',
    errorCall: '#E74C3C',
    hasComplainCall: '#775dd0',
};

const Dashboard: FC = () => {
    const classes = useStyles();
    const { identity } = useGetIdentity();
    const selectedMember = {memberId:  identity?.deptId, type:  'department'}
    return (
        <>
            <Box>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#f7f7f7',
                        borderRadius: '4px',
                        padding: '10px',
                    }}
                >
                    <Typography variant="h5">
                        <Box
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            Báo cáo thống kê hệ thống
                        </Box>
                    </Typography>
                </Box>
                <Box>
                    <Box mt={2} className={classes.chartTitle}>
                        <CallGeneralStatistics member={selectedMember} />
                    </Box>

                    <Box mt={2} className={classes.chartTitle}>
                        <CallDetailStatistics member={selectedMember} />
                    </Box>
                    <Box mt={2} className={classes.chartTitle}>
                        <CallAverageTimeByMonthStatistics
                            member={selectedMember}
                        />
                    </Box>
                    <Box mt={2} style={{ minHeight: '400px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6} sm={12}>
                                <Box
                                    className={classes.chartTitle}
                                    style={{ height: '100%' }}
                                >
                                    <CallAverageTimeByWeekStatistics
                                        member={selectedMember}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Dashboard;
