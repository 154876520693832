import * as React from 'react';
import { FC, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps } from 'react-admin';
import { Call } from '../types';
import { AccessTimeTwoTone } from '@material-ui/icons';
import { blue } from '@material-ui/core/colors';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
        marginTop: -theme.spacing(0.5),
        marginBottom: -theme.spacing(0.5),
    },
    icon: {
        color: blue[500],
    },
}));

interface Props extends FieldProps<Call> {
    size?: string;
}

const DurationField: FC<Props> = ({ record, size }) => {
    const classes = useStyles();
    const format = (duration: number) => {
        let seconds = duration / 1000;
        const format = val => `0${Math.floor(val)}`.slice(-2);
        const minutes = (seconds % 3600) / 60;

        return [minutes, seconds % 60].map(format).join(':');
    };
    return record ? (
        <div className={classes.root}>
            <AccessTimeTwoTone className={classes.icon} fontSize="small" />
            <Box ml={1}>
                <span className={classes.icon}>{format(record.duration)}</span>
            </Box>
        </div>
    ) : null;
};

DurationField.defaultProps = {
    source: 'duration',
    label: 'resources.call.fields.duration',
    addLabel: true,
};

export default memo<Props>(DurationField);
