import * as React from 'react';
import { FC } from 'react';
import {
    required,
    SelectInput,
    TextInput,
    useQueryWithStore,
} from 'react-admin';

const PhanAnhCreate: FC = () => {
    const { loaded, data } = useQueryWithStore({
        type: 'getList',
        resource: 'groupCallService',
        payload: {
            filter: {
                parentId: null,
            },
            sort: { field: 'name', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        },
    });

    return (
        <form>
            <div>
                <TextInput
                    validate={requiredValidate}
                    source="name"
                    resource="groupCallService"
                    fullWidth
                />
            </div>
            <div>
                <SelectInput
                    source="parentId"
                    resource="groupCallService"
                    fullWidth
                    optionText="name"
                    choices={data ? data.filter(item => !item.parentId) : []}
                />
            </div>
            <div>
                <TextInput
                    multiline
                    source="description"
                    row={4}
                    resource="groupCallService"
                    fullWidth
                />
            </div>
        </form>
    );
};

const requiredValidate = [required()];

export default PhanAnhCreate;
