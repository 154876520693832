import * as React from 'react';
import { ReactElement } from 'react';
import {
    ChipField,
    CreateButton,
    Datagrid,
    EmailField,
    Filter,
    FilterProps,
    FunctionField,
    List,
    ListProps,
    ReferenceInput,
    ReferenceManyField,
    SearchInput,
    SingleFieldList,
    TextField,
    TopToolbar,
} from 'react-admin';
import { Theme, useMediaQuery } from '@material-ui/core';
import CustomerLinkField from './CustomerLinkField';
import MobileGrid from './MobileGrid';
import { Department } from '../types';
import ImportButton from './ImportButton';
import StatusField from '../component/StatusField';
import AutoComplete from '../component/AutoComplete';

const VisitorFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput source="donVi" reference="department">
            <AutoComplete
                getOptionLabel={(choice?: Department) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.deptName} - ${choice.deptCode}`
                        : ''
                }
            />
        </ReferenceInput>
    </Filter>
);

const ActionsRefresh = (props: any) => (
    <TopToolbar>
        <VisitorFilter context="button" />
        <ImportButton />
        <CreateButton color="primary" />
    </TopToolbar>
);

const UserList = (props: ListProps): ReactElement => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    return (
        <List
            {...props}
            filters={<VisitorFilter />}
            actions={<ActionsRefresh />}
            sort={{ field: 'updateTime', order: 'DESC' }}
            perPage={25}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid optimized rowClick="edit">
                    <CustomerLinkField />
                    <TextField source="userName" />
                    <FunctionField
                        source="deptName"
                        render={record => (
                            <span>
                                {record?.deptName || record?.partnerName}
                            </span>
                        )}
                    />
                    <ReferenceManyField
                        source="groupIds"
                        reference="group"
                        target="ids"
                    >
                        <SingleFieldList>
                            <ChipField size="small" source="groupName" />
                        </SingleFieldList>
                    </ReferenceManyField>
                    <EmailField source="email" />
                    <StatusField source="status" />
                </Datagrid>
            )}
        </List>
    );
};

export default UserList;
