import { stringify } from 'query-string';
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
    UPDATE_MANY,
} from 'react-admin';

/**
 * Maps react-admin queries to a REST API implemented using Java Spring Boot and Swagger
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?page=0&pageSize=10
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?id=1234&id=5678
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */

export const httpClient = (url: string, options: any) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }
    options.headers.set('LdapType', `${localStorage.getItem('ldap_type')}`);
    return fetchUtils.fetchJson(url, options);
};

const diff = (object, base) => {
    const result = {};
    Object.keys(object).forEach(key => {
        if (object[key] !== base[key]) {
            result[key] = object[key];
        }
    });
    return result;
};

export default (apiUrl: string) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (
        type: string,
        resource: string,
        params: any
    ) => {
        let url = '';
        const options = {};
        if (resource === 'callLogs') {
            resource = 'call';
        }
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { q } = params.filter;
                const { field, order } = params.sort;
                const query = {
                    ...params.filter,
                    page: page - 1,
                    size: perPage,
                    sort: `${field}${
                        resource === 'call' &&
                        field !== 'starttime' &&
                        field !== 'duration'
                            ? '.keyword'
                            : ''
                    },${order}`,
                    keyword: q,
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}`;
                break;
            case GET_MANY: {
                let query = { ids: params.ids };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const { q } = params.filter;
                const { field, order } = params.sort;
                const query = {
                    [params.target]: params.id,
                    page: page - 1,
                    size: perPage,
                    sort: `${field}${
                        resource === 'call' &&
                        field !== 'starttime' &&
                        field !== 'duration'
                            ? '.keyword'
                            : ''
                    },${order}`,
                    keyword: q,
                    ...params.filter,
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                // @ts-ignore
                options.method = 'PUT';
                // @ts-ignore
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                url = `${apiUrl}/${resource}`;
                // @ts-ignore
                options.method = 'POST';
                // @ts-ignore
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                // @ts-ignore
                options.method = 'DELETE';
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (
        response: any,
        type: any,
        resource: any,
        params: any
    ) => {
        const { headers, json, body } = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
            case GET_MANY:
                if (json === undefined) {
                    return { data: [{ id: 1, value: body }], total: 0 };
                }
                if (json.hasOwnProperty('totalHits')) {
                    return {
                        data: json.searchHits.map((item: { content: any }) => ({
                            ...item.content,
                        })),
                        total: parseInt(json.totalHits, 10),
                    };
                } else if (json.hasOwnProperty('totalElements')) {
                    return {
                        data: json.content,
                        total: parseInt(json.totalElements, 10),
                    };
                } else if (json.hasOwnProperty('length')) {
                    return {
                        data: json,
                        total: json.length,
                    };
                } else {
                    return {
                        data: [json],
                        total: 0,
                    };
                }
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type: any, resource: any, params: any) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map((id: string) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: stringify(params.data),
                    })
                )
            ).then(responses => ({
                data: responses.map((response: any) => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map((id: string) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({
                data: responses.map((response: any) => response.json),
            }));
        }

        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );
        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        );
    };
};
