import * as React from 'react';
import { FC } from 'react';
import { required, SelectInput, TextInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block', 'image'],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'], // remove formatting button
];

const NotifyTemplateCreate: FC = () => {
    return (
        <form>
            <div>
                <TextInput
                    validate={requiredValidate}
                    source="name"
                    resource="notifyTemplate"
                    fullWidth
                />
            </div>
            <div>
                <SelectInput
                    validate={[required()]}
                    source="type"
                    resource="notifyTemplate"
                    fullWidth
                    choices={[
                        {
                            id: 'email',
                            name: 'Email',
                        },
                        {
                            id: 'sms',
                            name: 'SMS',
                        },
                    ]}
                />
            </div>
            <div>
                <div>
                    <TextInput
                        multiline
                        source="description"
                        resource="notifyTemplate"
                        fullWidth
                    />
                </div>
            </div>
            <div>
                <RichTextInput
                    resource="notifyTemplate"
                    source="content"
                    fullWidth
                    options={{
                        modules: {
                            toolbar: toolbarOptions,
                            history: {
                                delay: 2000,
                                maxStack: 500,
                                userOnly: true,
                            },
                        },
                        theme: 'snow',
                    }}
                />
            </div>
        </form>
    );
};

const requiredValidate = [required()];

export default NotifyTemplateCreate;
