import * as React from 'react';
import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import {
    Badge,
    Box,
    Card,
    CardContent,
    Checkbox,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Popover,
    TextField,
    Typography,
} from '@material-ui/core';
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state';
import Chip from '@material-ui/core/Chip';
import {CommentTwoTone} from '@material-ui/icons';
import {log} from "util";

interface CommentType {
    [key: string]: string;
}

const CriteriaCard = forwardRef(
    (
        {criteria, criteriaSelected, record, disabled, scoreChange}: any,
        ref
    ) => {

        const defaultChecked = criteria.children.map(item => item.id);
        const [checked, setChecked] = useState<string[]>([...defaultChecked]);
        const [comments, setComments] = useState<CommentType>({});
        const handleToggle = (value: string) => () => {
            if (disabled) {
                return;
            }
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];
            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }



            setChecked(newChecked);
        };

        const comment = (id, content) => {
            const newComment = {...comments, [id]: content};
            setComments(newComment);
        };
        const getScore = () => {
            const checkedChild = criteria.children.filter(item =>
                checked.includes(item.id)
            );
            const score = checkedChild.reduce(
                (hash, cur) => hash + cur.score,
                0
            );
            // scoreChange(score);
            return score;
        };

        const showBadge = id => {
            return !comments[id];
        };

        useImperativeHandle(ref, () => ({
            getData: () =>
                checked.map(id => {
                    const selected = criteria.children.find(
                        child => child.id === id
                    );
                    return {
                        callCriteriaId: id,
                        score: selected ? selected.score : 0,
                        description: comments[id],
                    };
                }),
        }));

        const initDefault = useCallback(() => {
            if (criteriaSelected && criteriaSelected.length) {
                const defaultComment = {};
                const select = criteria.children.reduce((hash, cur) => {
                    const o = criteriaSelected.find(
                        item => item.callCriteriaId === cur.id
                    );
                    if (o) {
                        defaultComment[cur.id] = o.description;
                        return [...hash, cur.id];
                    }
                    return [...hash];

                }, []);

                setComments(defaultComment);
                setChecked(select);
            }
            if (record.callingRating1
                && criteriaSelected.length === 0){
                setChecked([]);
            }


        }, [criteriaSelected]);

        useEffect(() => {
            initDefault();
        }, [criteriaSelected]);

        return (
            <Box mb={2}>
                <Card variant={'outlined'}>
                    <CardContent>
                        <Box
                            flexDirection="row"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography gutterBottom variant="subtitle1">
                                {criteria.name}
                            </Typography>
                            <Chip
                                label={`${getScore()} điểm`}
                                color="primary"
                            />
                        </Box>
                        <List>
                            {criteria.children.map(value => {
                                const labelId = `[${value.score} điểm] ${value.name}`;
                                return (
                                    <ListItem
                                        key={value.id}
                                        role={undefined}
                                        dense
                                        button
                                        onClick={handleToggle(value.id)}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                disabled={disabled}
                                                edge="start"
                                                checked={
                                                    checked.indexOf(
                                                        value.id
                                                    ) !== -1
                                                }
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            id={labelId}
                                            primary={labelId}
                                        />
                                        <ListItemSecondaryAction>
                                            <PopupState
                                                variant="popover"
                                                popupId="demo-popup-popover"
                                            >
                                                {popupState => (
                                                    <div>
                                                        <Badge
                                                            color="secondary"
                                                            variant="dot"
                                                            invisible={showBadge(
                                                                value.id
                                                            )}
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                {...bindTrigger(
                                                                    popupState
                                                                )}
                                                                edge="end"
                                                                aria-label="comments"
                                                            >
                                                                <CommentTwoTone/>
                                                            </IconButton>
                                                        </Badge>
                                                        <Popover
                                                            {...bindPopover(
                                                                popupState
                                                            )}
                                                            anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal:
                                                                    'center',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal:
                                                                    'right',
                                                            }}
                                                        >
                                                            <Box p={2}>
                                                                <TextField
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                    value={
                                                                        comments[
                                                                            value
                                                                                .id
                                                                            ]
                                                                    }
                                                                    onChange={({
                                                                                   target,
                                                                               }) =>
                                                                        comment(
                                                                            value.id,
                                                                            target.value
                                                                        )
                                                                    }
                                                                    label={`Nhận xét tiêu chí`}
                                                                    variant={
                                                                        'filled'
                                                                    }
                                                                />
                                                            </Box>
                                                        </Popover>
                                                    </div>
                                                )}
                                            </PopupState>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </CardContent>
                </Card>
            </Box>
        );
    }
);

export default CriteriaCard;
