import { MemberType, TimeFilterValueType } from './Dashboard';
import { useNotify } from 'react-admin';
import React, { useCallback, useEffect, useState } from 'react';
import { memberType } from './MemberTree';
import { BASE_URL } from '../common/constants';
import { httpClient } from '../dataProvider';
import { serialize } from '../dataProvider/utils';
import { Box, Tooltip, Typography } from '@mui/material';
import TimeFilter from './TimeFilter';
import AreaChart from './AreaChart';
import LinearProgress from '@mui/material/LinearProgress';
import dayjs from 'dayjs';
import BarChart from './BarChart';

type CallAverageTimeByMonthStatisticsProps = {
    member: MemberType | undefined;
};

const CallAverageTimeByMonthStatistics = ({
    member,
}: CallAverageTimeByMonthStatisticsProps) => {
    const notify = useNotify();

    const [averageTimeByMonthFilter, setAverageTimeByMonthFilter] = useState<
        TimeFilterValueType
    >();

    const [
        averageTimeByMonthStatistics,
        setAverageTimeByMonthStatistics,
    ] = useState<{
        data: { name?: string; data: number[] }[];
        categories: string[];
        colors: string[];
    }>({
        data: [],
        categories: [],
        colors: [],
    });

    const [
        fetchAverageTimeByMonthStatisticsLoading,
        setFetchAverageTimeByMonthStatisticsLoading,
    ] = useState<boolean>(false);

    const fetchAverageTimeByMonthStatistics = useCallback(async () => {
        if (averageTimeByMonthFilter && member) {
            const filter = {
                'starttime.greaterThanOrEqual': averageTimeByMonthFilter.fromDate.getTime(),
                'starttime.lessThanOrEqual': averageTimeByMonthFilter.toDate.getTime(),
                groupBy: 'starttime',
                calendarInterval: 'month',
                appliedOperatorField: 'duration',
                operator: 'AVG',
            };

            setFetchAverageTimeByMonthStatisticsLoading(true);
            const url = `${BASE_URL}/dashboard/callStatistics`;
            let avgDuration;
            const errors: any[] = [];
            const promises: Promise<any>[] = [
                httpClient(
                    `${url}?${serialize({
                        ...filter,
                    })}`,
                    {}
                ).then(response => {
                    return response.json;
                }),
            ];
            await Promise.all(
                promises.map(p => p.catch(err => errors.push(err)))
            ).then(values => {
                [avgDuration] = values;
            });

            if (errors.length > 0) {
                notify(
                    'Thời gian TB cuộc gọi theo tháng: Có lỗi khi load dữ liệu',
                    'warning'
                );
            } else {
                const categoriesTmp: string[] = [];
                const colorsTmp: string[] = ['#0063B0'];
                const averageTimeTmp: number[] = [];
                for (let i = 0; i <= 12; i++) {
                    categoriesTmp.push(i.toLocaleString());
                    const avg =
                        Array.isArray(avgDuration) &&
                        avgDuration.find(
                            p => p.x && new Date(p.x).getMonth() === i
                        )?.y;
                    averageTimeTmp.push(
                        avg && avg !== Infinity && avg !== 'Infinity'
                            ? Number((avg * 0.001).toFixed(2))
                            : 0
                    );
                }
                setAverageTimeByMonthStatistics({
                    data: [
                        { name: 'Thời gian trung bình', data: averageTimeTmp },
                    ],
                    colors: colorsTmp,
                    categories: categoriesTmp,
                });
            }
            setFetchAverageTimeByMonthStatisticsLoading(false);
        }
    }, [
        averageTimeByMonthFilter?.type,
        averageTimeByMonthFilter?.toDate,
        averageTimeByMonthFilter?.fromDate,
        member?.type,
        member?.memberId,
    ]);

    useEffect(() => {
        fetchAverageTimeByMonthStatistics();
    }, [fetchAverageTimeByMonthStatistics]);

    return (
        <>
            <Box>
                <Typography>
                    <Box>Thời gian TB cuộc gọi theo tháng</Box>
                </Typography>
                <Tooltip title="Thời gian bắt đầu cuộc gọi" placement="top">
                    <div>
                        <TimeFilter
                            type={'twelveMonths'}
                            onchange={(values: {
                                type: string;
                                fromDate: Date;
                                toDate: Date;
                            }) => {
                                setAverageTimeByMonthFilter({ ...values });
                            }}
                        />
                    </div>
                </Tooltip>
            </Box>
            {!fetchAverageTimeByMonthStatisticsLoading ? (
                <BarChart
                    data={averageTimeByMonthStatistics.data}
                    categories={averageTimeByMonthStatistics.categories}
                    colors={averageTimeByMonthStatistics.colors}
                    distributed={false}
                    showLegend={false}
                    showDataLabels={true}
                    yLabel={'Giây'}
                />
            ) : (
                <LinearProgress style={{ marginTop: '5px' }} />
            )}
        </>
    );
};

export default CallAverageTimeByMonthStatistics;
