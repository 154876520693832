import * as React from 'react';
import { FC } from 'react';
import {
    FormDataConsumer,
    NumberInput,
    required,
    SelectInput,
    TextInput,
    useQueryWithStore,
} from 'react-admin';

const TieuChiDanhGiaCGCreate: FC = () => {
    const { loaded, data } = useQueryWithStore({
        type: 'getList',
        resource: 'criteriaCall',
        payload: {
            filter: {},
            sort: { field: 'name', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        },
    });

    return (
        <React.Fragment>
            <TextInput
                validate={requiredValidate}
                source="name"
                resource="criteriaCall"
                fullWidth
            />
            <SelectInput
                allowEmpty
                source="parentId"
                resource="criteriaCall"
                fullWidth
                optionText="name"
                choices={data ? data.filter(item => !item.parentId) : []}
            />
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.parentId ? (
                        <NumberInput
                            source="score"
                            min={1}
                            resource="criteriaCall"
                            validate={requiredValidate}
                        />
                    ) : (
                        <NumberInput
                            source="score"
                            min={1}
                            resource="criteriaCall"
                        />
                    )
                }
            </FormDataConsumer>
        </React.Fragment>
    );
};

const requiredValidate = [required()];

export default TieuChiDanhGiaCGCreate;
