import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    BulkActionProps,
    BulkDeleteButton,
    CloneButton,
    CreateButton,
    Datagrid,
    DatagridProps,
    DeleteButton,
    Filter,
    FilterProps,
    FunctionField,
    List,
    SearchInput,
    TextField,
    TopToolbar,
} from 'react-admin';
import { Box, Stack } from '@mui/material';

const ListFilters = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const Actions = (props: any) => (
    <TopToolbar>
        <CreateButton variant={'outlined'} color="primary" />
    </TopToolbar>
);

interface DataGridProps extends DatagridProps {
    page?: number;
    perPage?: number;
}

const GroupList: FC<DataGridProps> = props => {
    return (
        <List
            {...props}
            actions={<Actions />}
            filters={<ListFilters />}
            bulkActionButtons={false}
            perPage={25}
            sort={{ field: 'updateTime', order: 'DESC' }}
        >
            <Datagrid {...props} optimized rowClick="edit">
                <TextField source="groupName" />
                <TextField source="groupCode" />
                <TextField source="description" />
                <TextField source="status" />
                <FunctionField
                    label=""
                    render={record => (
                        <Stack direction={'row'} gap={2}>
                            <CloneButton record={record} />
                            {record?.status !== 'active' && (
                                <DeleteButton record={record} />
                            )}
                        </Stack>
                    )}
                />
            </Datagrid>
        </List>
    );
};

export default GroupList;
