import Box from "@material-ui/core/Box";
import AutoCompleteArray from "../component/AutoCompleteArray";
import {Identifier, required, SelectInput, useGetList, useRecordContext} from "react-admin";
import * as React from "react";
import {useEffect, useState} from "react";
import {useForm, useFormState} from "react-final-form";
import {filterUsersByPartnerIdOrDeptId} from "./DistributionUtils";

const DonViGiamSatTab = (props) => {
    const form = useForm();
    const [GSVItems, setGSVItems] = useState<
        Array<{
            id: string | null | undefined | Identifier;
            name: string | null | undefined;
        }>
        >([]);
    const { data: departments } = useGetList(
        'department',
        { perPage: 1000, page: 1 },
        { field: 'deptName', order: 'ASC' },
        { forReport: true }
    );
    const departmentItems: Array<{
        id: string | null | undefined | Identifier;
        name: string | null | undefined;
    }> = Object.values(departments)
        ? Object.values(departments).map(d => {
            return {
                name: `${d.deptName} - ${d.deptCode}`,
                id: d.id,
            };
        })
        : [];
    const { data: partners } = useGetList(
        'partner',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' }
    );
    const partnerItems: Array<{
        id: string | null | undefined | Identifier;
        name: string | null | undefined;
    }> = Object.values(partners)
        ? Object.values(partners).map(d => {
            return {
                name: `${d.name} - ${d.code}`,
                id: d.id,
            };
        })
        : [];
    const donViGiamSat = [...departmentItems, ...partnerItems];
    const { data: GSVs } = useGetList(
        'user',
        { perPage: 1000, page: 1 },
        { field: 'fullName', order: 'ASC' },
        { userType: 2 }
    );
    const onchange = value => {
        form.change('gsvIds', []);
        if (value === null || value === undefined) {
            setGSVItems([]);
        } else {
            setGSVItems(filterUsersByPartnerIdOrDeptId(GSVs, value));
        }
    };

    const formContext = useFormState();
    const donViGiamSatId =  formContext.values.donViGiamSatId;

    useEffect(() => {
        if(donViGiamSatId) {
            setGSVItems(filterUsersByPartnerIdOrDeptId(GSVs, donViGiamSatId));
        }
    }, [GSVs])

    const record = useRecordContext();
    useEffect(() => {
        if(record && record.id) {
            if (record.donViGiamSatId === null || record.donViGiamSatId === undefined) {
                setGSVItems([]);
            } else {
                setGSVItems(filterUsersByPartnerIdOrDeptId(GSVs, record.donViGiamSatId));
            }
        }
    }, [GSVs, record]);
    return (
        <Box flex={1} mr={{ md: 0, lg: '1em' }}>
            <Box mt={1}>
                <SelectInput
                    fullWidth
                    source="donViGiamSatId"
                    resource="distribution"
                    choices={donViGiamSat}
                    onChange={event => {
                        onchange(event.target.value);
                    }}
                    validate={[required()]}
                    disabled={props.permission !== "distribution_ppc1"}
                />
            </Box>
            <Box mt={1}>
                <AutoCompleteArray
                    fullWidth
                    source="gsvIds"
                    resource="distribution"
                    label={'Giám sát viên'}
                    choices={GSVItems}
                    getOptionLabel={option => `${option?.name}`}
                    disabled={props.permission !== "distribution_ppc2"}
                />
            </Box>
        </Box>
    );
}

export default DonViGiamSatTab;