import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import {
    createStyles,
    fade,
    makeStyles,
    Theme,
    withStyles,
} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { animated, useSpring } from 'react-spring'; // web.cjs is required for IE 11 support
import { TransitionProps } from '@material-ui/core/transitions';
import { Box, Popover, TextField } from '@material-ui/core';

function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const StyledTreeItem = withStyles((theme: Theme) =>
    createStyles({
        iconContainer: {
            '& .close': {
                opacity: 0.3,
            },
        },
        label: {
            padding: 4,
        },
        group: {
            marginLeft: 7,
            paddingLeft: 18,
            borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
        },
    })
)((props: TreeItemProps) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles(
    createStyles({
        root: {
            flexGrow: 1,
            maxHeight: 200,
            maxWidth: 400,
        },
        textField: {
            cursor: 'pointer',
        },
    })
);

interface TreeSelectProps {
    treeData: any[];
    flatData: any[];
    label: string;
    defaultValue: string | number;
    disabled?: boolean | undefined;
}

const TreeSelect = forwardRef(
    (
        { treeData, flatData, label, defaultValue, disabled }: TreeSelectProps,
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            getValue: () => selected.id,
        }));

        const classes = useStyles();
        const [
            anchorEl,
            setAnchorEl,
        ] = React.useState<HTMLButtonElement | null>(null);
        const [selected, setSelected] = useState({
            name: '',
            id: '',
        });

        const onLabelClick = values => {
            setSelected(values);
            setAnchorEl(null);
        };

        const onTextFieldClick = (
            event: React.MouseEvent<HTMLInputElement>
        ) => {
            if (disabled) {
                return;
            }
            event.stopPropagation();
            // @ts-ignore
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);

        useEffect(() => {
            const defaultSelect = flatData.find(
                item => item.id === defaultValue
            );
            if (defaultSelect) {
                setSelected({
                    id: defaultSelect.id,
                    name: defaultSelect.name,
                });
            }
        }, [defaultValue, flatData]);

        return (
            <div>
                <TextField
                    disabled={disabled}
                    className={classes.textField}
                    aria-describedby={'popover'}
                    onClick={onTextFieldClick}
                    margin={'dense'}
                    variant="filled"
                    fullWidth
                    aria-readonly
                    label={label}
                    value={selected.name}
                />
                <Popover
                    id={'popover'}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    open={open}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <Box p={2} minWidth={250}>
                        <TreeView
                            className={classes.root}
                            defaultCollapseIcon={<MinusSquare />}
                            defaultExpandIcon={<PlusSquare />}
                            defaultEndIcon={<CloseSquare />}
                        >
                            {treeData.map(item => (
                                <StyledTreeItem
                                    key={item.id}
                                    onLabelClick={e => {
                                        e.stopPropagation();
                                        onLabelClick(item);
                                    }}
                                    nodeId={item.id}
                                    label={item.name}
                                >
                                    {item.children.map(child => (
                                        <StyledTreeItem
                                            key={child.id}
                                            onLabelClick={e => {
                                                e.stopPropagation();
                                                onLabelClick(child);
                                            }}
                                            nodeId={child.id}
                                            label={child.name}
                                        />
                                    ))}
                                </StyledTreeItem>
                            ))}
                        </TreeView>
                    </Box>
                </Popover>
            </div>
        );
    }
);

export default TreeSelect;
