import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    BooleanField,
    BulkActionProps,
    BulkDeleteButton,
    CreateButton,
    Datagrid,
    DatagridProps,
    DateField,
    Filter,
    FilterProps,
    FunctionField,
    List,
    ListProps,
    SearchInput,
    TextField,
    TopToolbar,
} from 'react-admin';

interface DataGridProps extends DatagridProps {
    page?: number;
    perPage?: number;
}

const ListFilters = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const Actions = (props: any) => (
    <TopToolbar>
        <CreateButton color="primary" />
    </TopToolbar>
);

const BulkActionButtons = (props: BulkActionProps) => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const DataGrid: FC<DataGridProps> = props => {
    return (
        <Datagrid {...props} optimized rowClick="edit">
            <FunctionField
                label="#"
                render={record =>
                    ((props.page || 1) - 1) * (props.perPage || 0) +
                    Object.keys(props.data || {}).indexOf(record?.id as string) +
                    1
                }
            />
            <TextField source="name" />
            <DateField source="startTime" />
            <DateField source="finishTime" />
            <BooleanField source="isActive" />
            <DateField source="createTime" />
        </Datagrid>
    );
};

const MonitoringPeriodList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            empty={false}
            actions={<Actions />}
            filters={<ListFilters />}
            bulkActionButtons={<BulkActionButtons />}
            perPage={25}
            sort={{ field: 'updateTime', order: 'desc' }}
        >
            <DataGrid />
        </List>
    );
};

export default MonitoringPeriodList;
