import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Layout, Login } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';
import vietnameseMessages from './i18n/vi';

import visitors from './user';
import notifyFlow from './notifyFlow';
import notifyTemplate from './notifyTemplate';
import filtersTemplate from './filtersTemplate';
import monitoringPeriod from './monitoringPeriod';
import distribution from './distributionV2';
import complain from './complain';
import evaluateOther from './evaluateOther';
import telephonistGroup from './telephonistGroup';
import ipcc from './call';
import partner from './partner';
import department from './department';
import tieuChiDanhGiaCG from './danhMuc/tieuChiDanhGiaCG';
import tieuChiDanhGiaGSV from './danhMuc/tieuChiDanhGiaGSV';
import canhBao from './danhMuc/canhBao';
import phanLoaiDtv from './danhMuc/phanLoaiDtv';
import nhomDichVu from './danhMuc/nhomDichVu';
import phanLoaiGSV from './danhMuc/phanLoaiGsv';
import phanLoaiCGTheoChuDe from './danhMuc/phanLoaiCGTheoChuDe';
import phanAnh from './danhMuc/phanAnh';
import hinhThucDanhGia from './danhMuc/hinhThucDanhGia';
import tuKhoa from './danhMuc/tuKhoa';
import groups from './groups';
import categories from './categories';
import springDataProvider from './dataProvider';
import { BASE_URL } from './common/constants';
import { resourceSettingReducer } from './resource-setting-reducer';
import CallLogsList from './callLogs/CallLogsList';

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'vi') {
        return vietnameseMessages;
    }

    // Always fallback on english
    return englishMessages;
}, 'vi');

const dataProvider = springDataProvider(BASE_URL);

const App = () => {
    return (
        <Admin
            title="Giám sát điện thoại viên"
            dataProvider={dataProvider}
            customReducers={{
                theme: themeReducer,
                resourceSetting: resourceSettingReducer,
            }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            <Resource name="user" {...visitors} />
            <Resource name="group" {...groups} />
            <Resource name="call" {...ipcc} />
            <Resource name="callLogs" list={CallLogsList} />
            <Resource name="partner" {...partner} />
            <Resource name="department" {...department} />
            <Resource name="notifyFlow" {...notifyFlow} />
            <Resource name="notifyTemplate" {...notifyTemplate} />
            <Resource name="filtersTemplate" {...filtersTemplate} />
            <Resource name="period" {...monitoringPeriod} />
            <Resource name="distribution" {...distribution} />
            <Resource name="complain" {...complain} />
            <Resource name="evaluateOther" {...evaluateOther} />
            <Resource name="telephonistGroup" {...telephonistGroup} />
            <Resource name="criteriaCall" {...tieuChiDanhGiaCG} />
            <Resource name="criteriaGSV" {...tieuChiDanhGiaGSV} />
            <Resource name="callReflect" {...phanAnh} />
            <Resource
                name="callSubjectClassification"
                {...phanLoaiCGTheoChuDe}
            />
            <Resource name="telephonist-classification" {...phanLoaiGSV} />
            <Resource name="groupCallService" {...nhomDichVu} />
            <Resource name="warningCategory" {...canhBao} />
            <Resource name="telephonistCriteria" {...phanLoaiDtv} />
            <Resource name="form-evaluate" {...hinhThucDanhGia} />
            <Resource name="keywords" {...tuKhoa} />
            <Resource name="keywordGroup" />
            <Resource name="category" {...categories} />
            <Resource name="group-category" />
            <Resource name="callingRating" />
        </Admin>
    );
};

export default App;
