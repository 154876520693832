import {DateInput, required, TextInput} from "react-admin";
import Box from "@material-ui/core/Box";
import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./DistributionUtils";
const useStyles = makeStyles(styles);

const ThongTinChungTab = (props) => {
    const classes = useStyles();
    return (
        <Box
            className={classes.tab}
            display={{ md: 'block', lg: 'flex' }}
        >
            <Box flex={1} mr={{ md: 0, lg: '1em' }}>
                <TextInput
                    fullWidth
                    validate={[required()]}
                    source="description"
                    resource="distribution"
                    disabled={props.permission !== "distribution_ppc1"}
                />
                <Box display={'flex'}>
                    <Box
                        flex={1}
                        mr={{ xs: 0, sm: '0.5em' }}
                    >
                        <DateInput
                            validate={[required()]}
                            fullWidth
                            resource="distribution"
                            source="fromDate"
                            disabled={props.permission !== "distribution_ppc1"}
                        />
                    </Box>
                    <Box
                        flex={1}
                        ml={{ xs: 0, sm: '0.5em' }}
                    >
                        <DateInput
                            validate={[required()]}
                            source="toDate"
                            resource="distribution"
                            fullWidth
                            disabled={props.permission !== "distribution_ppc1"}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ThongTinChungTab;