import * as React from 'react';
import {Button, required, TextInput, useDataProvider, useNotify, useUpdate} from "react-admin";
import {Error} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import {Form} from "react-final-form";
import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    errorCallButton: {
        marginLeft: theme.spacing(2),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
}));

const ErrorCallButton = ({record}) => {
    const classes = useStyles();
    const [openError, setOpenError] = useState<boolean>(false);
    const [errorReason, setErrorReason] = useState("");
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const history = useHistory();
    const closeError = () => {
        setOpenError(false);
    };
    const openErrorDialog = () => {
        setOpenError(true);
    };
    const updateErrorCall = () => {
        if (record.errorCall == null || record.errorCall === false) {
            if (errorReason.trim() === '') {
                notify('Nhập lý do!', 'warning');
                return;
            }
        }
        dataProvider
            .update('call', {
                id: record.id,
                data: {
                    isError: !(record.errorCall && record.errorCall === true),
                    errorReason: errorReason,
                },
                previousData: record,
            })
            .then(({data}) => {
                notify('Cập nhật cuộc gọi lỗi thành công!', 'success');
                history.push('/call', {status: 6});
            })
            .catch(error => {
                notify('Cập nhật cuộc gọi lỗi thất bại!', 'error');
                return;
            });
    }
    return (
        <>
            <Button
                startIcon={<Error/>}
                variant={'contained'}
                onClick={
                    event => {
                        if (record.errorCall && record.errorCall === true) {
                            updateErrorCall()
                        } else {
                            openErrorDialog();
                        }
                    }
                }
                color="secondary"
                label={
                    record.errorCall && record.errorCall === true
                        ? 'Hủy cuộc gọi lỗi'
                        : 'Cuộc gọi lỗi'
                }
                className={classes.errorCallButton}
            />
            <Dialog open={openError} onClose={closeError} fullWidth>
                <DialogTitle>
                    <Typography variant={'h6'} component={'h2'}>
                        Lý do cuộc gọi lỗi
                    </Typography>
                </DialogTitle>
                <Form onSubmit={() => undefined}>
                    {() => (
                        <>
                            <DialogContent>
                                <TextInput
                                    resource="call"
                                    source="errorReason"
                                    multiline
                                    rows={4}
                                    validate={required()}
                                    label="Lý do"
                                    fullWidth
                                    onChange={event => {
                                        setErrorReason(event.target.value)
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={event => updateErrorCall()} label={'Lưu'}/>
                                <Button onClick={closeError} label={'Hủy'}/>
                            </DialogActions>
                        </>
                    )}
                </Form>
            </Dialog>
        </>
    )
}

export default ErrorCallButton;
