import React from 'react';
import {
    Avatar,
    Box,
    Card,
    Grid,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';
import {
    useGmailTabItemStyles,
    useGmailTabsStyles,
} from '@mui-treasury/styles/tabs';
import { useGradientAvatarStyles } from '@mui-treasury/styles/avatar/gradient';

import { Info, ListRounded, VpnKeyRounded } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    ChipField,
    EmailField,
    Labeled,
    ReferenceManyField,
    SingleFieldList,
    useGetIdentity,
} from 'react-admin';
import ChangePassword from './ChangePassword';
import TabPanel from '../component/TabPanel';

const indicatorColors = ['#d93025', '#188038', '#e37400'];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: 144,
            height: 144,
        },
    })
);

const Profile = () => {
    const { identity } = useGetIdentity();
    const avatarStyles = useGradientAvatarStyles({
        size: 144,
        gap: 3,
        thickness: 3,
        gapColor: '#f4f7fa',
        color: 'linear-gradient(to bottom right, #feac5e, #c779d0, #4bc0c8)',
    });
    const classes = useStyles();
    const [tabIndex, setTabIndex] = React.useState(0);
    const tabsStyles = useGmailTabsStyles({ indicatorColors });
    const tabItem1Styles = useGmailTabItemStyles({ color: indicatorColors[0] });
    const tabItem3Styles = useGmailTabItemStyles({ color: indicatorColors[1] });
    const tabItem4Styles = useGmailTabItemStyles({ color: indicatorColors[2] });
    return (
        <Box p={3}>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Card>
                        <Box p={3}>
                            <Box
                                width={'100%'}
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                <div className={avatarStyles.root}>
                                    <Avatar
                                        src={'https://i.pravatar.cc/300?img=12'}
                                    />
                                </div>
                            </Box>
                            <Box mt={3}>
                                <Labeled label={'Họ tên'}>
                                    <Typography variant={'body2'}>
                                        {identity?.fullName}
                                    </Typography>
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled label={'Email'}>
                                    <EmailField
                                        record={identity}
                                        source={'email'}
                                    />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled label={'Đơn vị'}>
                                    <Typography variant={'body2'}>
                                        {identity?.deptName}
                                    </Typography>
                                </Labeled>
                            </Box>
                            {identity && (
                                <Box>
                                    <Labeled label={'Nhóm quyền'}>
                                        <ReferenceManyField
                                            record={identity}
                                            resource={'user'}
                                            source="groupIds"
                                            reference="group"
                                            target="ids"
                                        >
                                            <SingleFieldList>
                                                <ChipField
                                                    size="small"
                                                    source="groupName"
                                                />
                                            </SingleFieldList>
                                        </ReferenceManyField>
                                    </Labeled>
                                </Box>
                            )}
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={9}>
                    <Card>
                        <Tabs
                            variant={'fullWidth'}
                            classes={tabsStyles}
                            value={tabIndex}
                            onChange={(e, index) => setTabIndex(index)}
                            TabIndicatorProps={{
                                children: (
                                    <div
                                        className={`MuiIndicator-${tabIndex}`}
                                    />
                                ),
                            }}
                        >
                            <Tab
                                classes={tabItem3Styles}
                                disableTouchRipple
                                label={'Bảo mật'}
                                icon={<VpnKeyRounded />}
                            />
                        </Tabs>
                        <TabPanel value={tabIndex} index={0}>
                            <Box padding={2}>
                                <ChangePassword />
                            </Box>

                        </TabPanel>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Profile;
