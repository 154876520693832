import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {
    Create,
    CreateProps,
    DateInput,
    FormTab,
    ReferenceArrayInput,
    required,
    SelectArrayInput,
    TabbedForm,
    TextInput,
    fetchUtils,
    useDataProvider,
    SelectInput,
    ReferenceInput,
    useRecordContext,
    useNotify,
    Button

} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {Department} from '../types';
import Box from '@material-ui/core/Box';
import TabTieuChi from './TabTieuChi';
import {BASE_URL} from "../common/constants";
import CommonEditToolbar from "../component/CommonEditToolbar";

export const styles = {
    input: {width: '24em'},
    number: {width: '8em'},
    date: {width: '32em', marginRight: '8px'},
    userSelect: {width: '32em'},
    tab: {
        width: '100%',
    },
};

const useStyles = makeStyles(styles);

const MonitoringPeriodCreate: FC<CreateProps> = props => {
    const notify = useNotify();
    const classes = useStyles();
    // const onFailure = () => {
    //     console.log('2222222222');
    //
    // };
    // const onSuccess = () => {
    //     console.log('111111')
    // };

    return (
        <Create {...props}
                title="Tạo mới kỳ giám sát"
                actions={
                        <CommonEditToolbar />
                }

                // onSuccess={onSuccess}
                // onFailure={onFailure}
        >
            <TabbedForm redirect={"list"}>
                <FormTab label="resources.monitoringPeriod.tabs.information">
                    <Box
                        className={classes.tab}
                        display={{md: 'block', lg: 'flex'}}
                    >
                        <Box flex={1} mr={{md: 0, lg: '1em'}}>
                            <TextInput
                                autoFocus
                                resource={'monitoringPeriod'}
                                fullWidth
                                source="name"
                            />

                            <Box display={'flex'}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <DateInput
                                        resource={'monitoringPeriod'}
                                        fullWidth
                                        source="startTime"
                                        validate={required('Bắt buộc nhập')}
                                        resettable
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <DateInput
                                        resource={'monitoringPeriod'}
                                        source="finishTime"
                                        fullWidth
                                        validate={required('Bắt buộc nhập')}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            flex={2}
                            ml={{xs: 0, lg: '1em'}}
                            mt={{xs: '1em', lg: 0}}
                        >
                            <ReferenceInput
                                source="departmentId"
                                reference="department"
                                resource="monitoringPeriod"
                            >
                                <SelectInput
                                    fullWidth
                                    // @ts-ignore
                                    optionText={(choice?: Department) =>
                                        choice?.id // the empty choice is { id: '' }
                                            ? `${choice.deptName} - ${choice.deptCode}`
                                            : ''
                                    }
                                />
                            </ReferenceInput>
                        </Box>
                    </Box>
                </FormTab>
                <FormTab
                    label="resources.monitoringPeriod.tabs.tieuChi"
                    path="kpi"
                >
                    <TabTieuChi/>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default MonitoringPeriodCreate;
