import * as React from 'react';
import { FC, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps, useGetOne } from 'react-admin';
import { Call } from '../types';
import { blue } from '@material-ui/core/colors';
import { Avatar, Box } from '@material-ui/core';
import { Breadcrumbs, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
        marginTop: -theme.spacing(0.5),
        marginBottom: -theme.spacing(0.5),
    },
    icon: {
        color: blue[500],
    },
}));

interface Props extends FieldProps<any> {
    source: string;
}

const TichMaField: FC<Props> = ({ record, source }) => {
    const classes = useStyles();
    const tichMaId = record ? record[source] : null;

    const { data: callReflect1 } = useGetOne('callReflect', tichMaId);
    const { data: callReflectParent } = useGetOne(
        'callReflect',
        callReflect1?.parentId
    );
    const { data: callReflectParentParent } = useGetOne(
        'callReflect',
        callReflectParent?.parentId
    );
    const { data: callReflectParentParentParent } = useGetOne(
        'callReflect',
        callReflectParentParent?.parentId
    );

    const breadcrumbs = [
        callReflectParentParentParent ? (
            <Link underline="hover" key="1" color="inherit" href="/">
                <Chip
                    size="small"
                    label={callReflectParentParentParent?.name}
                />
            </Link>
        ) : null,
        callReflectParentParent ? (
            <Link underline="hover" key="1" color="inherit" href="/">
                <Chip size="small" label={callReflectParentParent?.name} />
            </Link>
        ) : null,
        <Link underline="hover" key="1" color="inherit" href="/">
            <Chip size="small" label={callReflectParent?.name} />
        </Link>,
        <Typography key="3" color="text.primary">
            <Chip size="small" label={callReflect1?.name} />
        </Typography>,
    ];

    return callReflect1 ? (
        <div className={classes.root}>
            <Box>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {breadcrumbs.filter(breadcrumb => !!breadcrumb)}
                </Breadcrumbs>
            </Box>
        </div>
    ) : null;
};

TichMaField.defaultProps = {
    source: 'duration',
    label: 'resources.call.fields.tichMaId',
    addLabel: true,
};

export default memo<Props>(TichMaField);
