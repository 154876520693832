import { Visibility } from '@material-ui/icons';
import { Button, useDataProvider, useGetIdentity } from 'react-admin';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import TabPanel from '../component/TabPanel';
import RatingFormOne from '../call/RatingFormOne';
import { State } from '../call/CallEdit';
import { Category } from '../types';
import { convertToTree } from '../utils';

const UpdateRatingButton = ({ record }) => {
    const { identity } = useGetIdentity();
    const [open, setOpen] = useState<boolean>(false);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [criterias, setCriterias] = useState<any[]>([]);
    const dataProvider = useDataProvider();
    const close = () => {
        setOpen(false);
    };
    const openDialog = () => {
        setOpen(true);
    };

    const [state, setState] = useState<State>({
        chuDes: [],
        cuocGoiDienHinhs: [],
        mucDoHaiLongs: [],
        callReflects: [],
        formEvaluate: [],
        telephonistClass: [],
        telephonistCriteria: [],
    });

    const loadCategory = useCallback(async () => {
        const { data: cuocGoiDienHinhs } = await dataProvider.getList<Category>(
            'category/getByGroupCode',
            {
                filter: { groupCode: 'CGDH' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: callReflects } = await dataProvider.getList<Category>(
            'callReflect',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );

        const { data: formEvaluate } = await dataProvider.getList<Category>(
            'form-evaluate',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: mucDoHaiLongs } = await dataProvider.getList<Category>(
            'category/getByGroupCode',
            {
                filter: { groupCode: 'MDHL' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: chuDes } = await dataProvider.getList<Category>(
            'category/getByGroupCode',
            {
                filter: { groupCode: 'CALL_TOPIC' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: tieuChiData } = await dataProvider.getList<any>(
            'criteriaCall/monitoring',
            {
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
                filter: { callId: record && record.callId },
            }
        );
        setCriterias(convertToTree(tieuChiData.filter(getActive)));
        setState(state => ({
            ...state,
            cuocGoiDienHinhs,
            mucDoHaiLongs,
            chuDes,
            callReflects: callReflects.filter(getActive),
            formEvaluate: formEvaluate.filter(getActive),
        }));
    }, [dataProvider]);

    useEffect(() => {
        loadCategory();
    }, [loadCategory]);

    const getActive = item => {
        if (identity) {
            if (item.deptId === 'TCT' || item.deptId === identity.deptId) {
                return item.isActive;
            } else {
                return item.deptIdsActive
                    ? item.deptIdsActive.includes(identity.deptId)
                    : false;
            }
        }
        return true;
    };

    return (
        <React.Fragment>
            <Button
                variant={'text'}
                onClick={openDialog}
                color="primary"
                disableElevation
                startIcon={<Visibility />}
                label={'Chi tiết'}
            />
            <Dialog open={open} onClose={close} fullWidth maxWidth="lg">
                <DialogTitle>
                    <Typography variant={'h6'} component={'h2'}>
                        Chi tiết cập nhật đánh giá
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Tabs
                        value={tabIndex}
                        onChange={(e, index) => setTabIndex(index)}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Trước đánh giá" />
                        <Tab label="Sau đánh giá" />
                    </Tabs>
                    <TabPanel value={tabIndex} index={0}>
                        <RatingFormOne
                            disabled
                            record={record}
                            criterias={criterias}
                            callingRating={record.ratingBefore}
                            state={state}
                            type={undefined}
                        />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <RatingFormOne
                            disabled
                            record={record}
                            criterias={criterias}
                            callingRating={record.ratingAfter}
                            state={state}
                            type={undefined}
                        />
                    </TabPanel>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label={'Đóng'} />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default UpdateRatingButton;
