import * as React from 'react';
import { FC } from 'react';
import {
    DateInput,
    DeleteButton,
    Edit,
    EditProps,
    email,
    FieldProps,
    FormDataConsumer,
    FormWithRedirect,
    ListButton,
    PasswordInput,
    RadioButtonGroupInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SaveButton,
    SelectArrayInput,
    SelectInput,
    TextInput,
    Toolbar,
    TopToolbar,
    useTranslate,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import FullNameField from './FullNameField';
import { Customer, Department, Partner } from '../types';
import { AnyObject } from 'react-final-form';
import Aside from './Aside';
import { makeStyles } from '@material-ui/core/styles';
import CommonEditToolbar from '../component/CommonEditToolbar';

export const validatePasswords = ({
    password,
    confirm_password,
}: AnyObject) => {
    const errors = {} as any;

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = ['resources.user.errors.password_mismatch'];
    }

    return errors;
};

const TopBarActions = props => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

const useStyles = makeStyles({
    toolbar: {
        opacity: 0.87,
        whiteSpace: 'nowrap',
        display: 'flex',
    },
    large: {
        width: 20,
        height: 20,
    },
    small: {
        width: 15,
        height: 15,
    },
});

const UserEdit: FC<EditProps> = props => {
    return (
        <Edit
            mutationMode={'pessimistic'}
            title={<VisitorTitle />}
            component="div"
            {...props}
            actions={<TopBarActions />}
        >
            <VisitorForm />
        </Edit>
    );
};

const VisitorTitle: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const VisitorForm = (props: any) => {
    const translate = useTranslate();

    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.user.fieldGroups.identity'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="userName"
                                                resource="user"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="fullName"
                                                resource="user"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <TextInput
                                        type="email"
                                        source="email"
                                        resource="user"
                                        validate={[email(), required()]}
                                        fullWidth
                                    />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <DateInput
                                                source="birthday"
                                                resource="user"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>

                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                fullWidth
                                                resource="user"
                                                source="phoneNumber"
                                                validate={requiredValidate}
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <SelectInput
                                                resource="user"
                                                source="sex"
                                                choices={[
                                                    { id: 0, name: 'Nam' },
                                                    { id: 1, name: 'Nữ' },
                                                ]}
                                            />
                                        </Box>
                                    </Box>

                                    <TextInput
                                        resource="user"
                                        multiline
                                        source="note"
                                        fullWidth
                                    />
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <div>
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    {translate(
                                                        'resources.user.fieldGroups.change_password'
                                                    )}
                                                </Typography>
                                                <Box
                                                    display={{
                                                        xs: 'block',
                                                        sm: 'flex',
                                                    }}
                                                >
                                                    <Box
                                                        flex={1}
                                                        mr={{
                                                            xs: 0,
                                                            sm: '0.5em',
                                                        }}
                                                    >
                                                        <PasswordInput
                                                            resource="user"
                                                            fullWidth
                                                            source="password"
                                                        />
                                                    </Box>
                                                    <Box
                                                        flex={1}
                                                        mr={{
                                                            xs: 0,
                                                            sm: '0.5em',
                                                        }}
                                                    >
                                                        <PasswordInput
                                                            resource="user"
                                                            fullWidth
                                                            source="confirm_password"
                                                        />
                                                    </Box>
                                                </Box>
                                            </div>
                                        )}
                                    </FormDataConsumer>
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.user.fieldGroups.stats'
                                        )}
                                    </Typography>
                                    <div>
                                        <FormDataConsumer>
                                            {({ formData, ...rest }) =>
                                                formData.deptType ===
                                                'mobifone' ? (
                                                    <ReferenceInput
                                                        resource="user"
                                                        source="deptId"
                                                        reference="department"
                                                    >
                                                        <SelectInput
                                                            validate={[
                                                                required(),
                                                            ]}
                                                            fullWidth
                                                            optionText={(
                                                                choice?: Department
                                                            ) =>
                                                                choice?.id // the empty choice is { id: '' }
                                                                    ? `${choice.deptName} - ${choice.deptCode}`
                                                                    : ''
                                                            }
                                                        />
                                                    </ReferenceInput>
                                                ) : (
                                                    <ReferenceInput
                                                        resource="user"
                                                        source="partnerId"
                                                        reference="partner"
                                                    >
                                                        <SelectInput
                                                            validate={[
                                                                required(),
                                                            ]}
                                                            fullWidth
                                                            optionText={(
                                                                choice?: Partner
                                                            ) =>
                                                                choice?.id // the empty choice is { id: '' }
                                                                    ? `${choice.name} - ${choice.code}`
                                                                    : ''
                                                            }
                                                        />
                                                    </ReferenceInput>
                                                )
                                            }
                                        </FormDataConsumer>
                                    </div>

                                    <div>
                                        <ReferenceArrayInput
                                            resource="user"
                                            reference="group"
                                            source="groupIds"
                                        >
                                            <SelectArrayInput
                                                fullWidth
                                                optionText="groupName"
                                            />
                                        </ReferenceArrayInput>
                                    </div>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar {...formProps}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                width={'100%'}
                            >
                                <Box>
                                    <SaveButton {...formProps} />
                                </Box>
                                <Box>
                                    <DeleteButton
                                        {...formProps}
                                        mutationMode={'pessimistic'}
                                    />
                                </Box>
                            </Box>
                        </Toolbar>
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];

export default UserEdit;
