import * as React from 'react';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import {
    CreateButton,
    DeleteButton,
    EditButton,
    Filter,
    FilterProps,
    List,
    ListProps,
    SearchInput,
    TopToolbar,
    useListContext,
} from 'react-admin';
import {
    CustomTreeData,
    DataTypeProvider,
    TreeDataState,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import DialogCreate from '../../component/DialogCreate';
import DialogEdit from '../../component/DialogEdit';
import NhomDichVuCreate from './NhomDichVuCreate';
import IsActiveField from '../IsActiveField';

const GridFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const Actions = (props: any) => (
    <TopToolbar>
        <CreateButton color="primary" />
    </TopToolbar>
);

const GridData = props => {
    const { ids, data, basePath } = useListContext();
    const [treeData, setTreeData] = useState([]);
    const [defaultExpandedRowIds] = useState(
        Array.from(Array(100), (_, i) => i)
    );
    const [columns] = useState([
        { name: 'name', title: 'Tên tiêu chí' },
        { name: 'createBy', title: 'Người tạo' },
        { name: 'createTime', title: 'Thời gian tạo' },
        { name: 'isActive', title: 'Kích hoạt' },
        { name: 'actions', title: ' ' },
    ]);

    const getChildRows = (row, rootRows) => {
        const childRows = rootRows.filter(
            r => r.parentId === (row ? row.id : null)
        );
        return childRows.length ? childRows : null;
    };

    const [tableColumnExtensions] = useState([
        { columnName: 'name', width: 700 },
        { columnName: 'isActive', align: 'center' },
    ]);

    useEffect(() => {
        const flatData = ids.map(id => {
            const item = { ...data[id] };
            item.parentId = data[id].parentId ? data[id].parentId : null;
            item.chidlren = null;
            item.actions = item.id;
            return item;
        });
        // @ts-ignore
        setTreeData([...flatData]);
    }, [ids, data]);

    const ActionsFormatter = ({ value }) => (
        <div>
            <EditButton basePath={basePath} record={data[value]} />
            <DeleteButton
                mutationMode={'pessimistic'}
                basePath={basePath}
                record={data[value]}
            />
        </div>
    );

    const ActionsProvider = props => (
        <DataTypeProvider formatterComponent={ActionsFormatter} {...props} />
    );

    const [actionsColumns] = useState(['actions']);

    const CreateTimeFormatter = ({ value }) => (
        <span>{new Date(value).toLocaleString()}</span>
    );

    const CreateTimeProvider = props => (
        <DataTypeProvider formatterComponent={CreateTimeFormatter} {...props} />
    );

    const [createTimeColumns] = useState(['createTime']);

    const ActiveFormatter = ({ row }) => (
        <IsActiveField record={data[row.id]} basePath={basePath} />
    );

    const ActiveProvider = props => (
        <DataTypeProvider formatterComponent={ActiveFormatter} {...props} />
    );

    const [activeColumns] = useState(['isActive']);

    return (
        <Grid rows={treeData} columns={columns}>
            <ActionsProvider for={actionsColumns} />
            <CreateTimeProvider for={createTimeColumns} />
            <ActiveProvider for={activeColumns} />
            <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
            <CustomTreeData getChildRows={getChildRows} />
            {/*// @ts-ignore*/}
            <Table columnExtensions={tableColumnExtensions} />
            <TableHeaderRow />
            <TableTreeColumn for="name" />
        </Grid>
    );
};

const NhomDichVuList: FC<ListProps> = props => {
    const history = useHistory();
    const handleClose = useCallback(() => {
        // @ts-ignore
        history.push(props.basePath);
    }, [history]);

    return (
        <Fragment>
            <List
                empty={false}
                title="Danh mục nhóm dịch vụ"
                {...props}
                pagination={false}
                actions={<Actions />}
                filters={<GridFilter />}
                perPage={100}
            >
                <GridData />
            </List>
            <Route
                path={`${props.basePath}/create`}
                render={() => (
                    <DialogCreate
                        {...props}
                        onCancel={handleClose}
                        title={'Thêm mới nhóm dịch vụ'}
                    >
                        <NhomDichVuCreate />
                    </DialogCreate>
                )}
            />
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch =
                        match && match.params && match.params.id !== 'create';

                    return (
                        isMatch && (
                            <DialogEdit
                                {...props}
                                onCancel={handleClose}
                                title={'Sửa nhóm dịch vụ'}
                                id={match?.params.id}
                            >
                                <NhomDichVuCreate />
                            </DialogEdit>
                        )
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default NhomDichVuList;
