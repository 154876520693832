import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    CreateProps,
    DateInput,
    email,
    FormDataConsumer,
    FormWithRedirect,
    NumberInput,
    PasswordInput,
    RadioButtonGroupInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    TextInput,
    Toolbar,
    useGetList,
    useTranslate,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { Department, Partner } from '../types';
import { validatePasswords } from './UserEdit';
import CommonEditToolbar from '../component/CommonEditToolbar';

const UserCreate: FC<CreateProps> = props => {
    return (
        <Create {...props} component="div" actions={<CommonEditToolbar />}>
            <UserForm />
        </Create>
    );
};

const defaultValue = () => ({ deptType: 'mobifone' });

const UserForm = (props: any) => {
    const translate = useTranslate();

    const { data, ids } = useGetList(
        'category',
        { perPage: 100, page: 1 },
        { field: 'name', order: 'ASC' },
        { groupCategoryCode: 'CD' }
    );
    console.log(data, 'ddddddd');
    return (
        <FormWithRedirect
            {...props}
            initialValues={defaultValue}
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.user.fieldGroups.identity'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="userName"
                                                resource="user"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="fullName"
                                                resource="user"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <TextInput
                                        type="email"
                                        source="email"
                                        resource="user"
                                        validate={[email(), required()]}
                                        fullWidth
                                    />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <DateInput
                                                source="birthday"
                                                resource="user"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>

                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                fullWidth
                                                resource="user"
                                                source="phoneNumber"
                                                validate={requiredValidate}
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <SelectInput
                                                resource="user"
                                                source="sex"
                                                choices={[
                                                    { id: 0, name: 'Nam' },
                                                    { id: 1, name: 'Nữ' },
                                                ]}
                                            />
                                        </Box>
                                    </Box>

                                    <TextInput
                                        multiline
                                        source="note"
                                        resource="user"
                                        fullWidth
                                    />

                                    <Box mt="1em" />

                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <div>
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    {translate(
                                                        'resources.user.fieldGroups.change_password'
                                                    )}
                                                </Typography>
                                                <Box
                                                    display={{
                                                        xs: 'block',
                                                        sm: 'flex',
                                                    }}
                                                >
                                                    <Box
                                                        flex={1}
                                                        mr={{
                                                            xs: 0,
                                                            sm: '0.5em',
                                                        }}
                                                    >
                                                        <PasswordInput
                                                            resource="user"
                                                            fullWidth
                                                            source="password"
                                                        />
                                                    </Box>
                                                    <Box
                                                        flex={1}
                                                        mr={{
                                                            xs: 0,
                                                            sm: '0.5em',
                                                        }}
                                                    >
                                                        <PasswordInput
                                                            resource="user"
                                                            fullWidth
                                                            source="confirm_password"
                                                        />
                                                    </Box>
                                                </Box>
                                            </div>
                                        )}
                                    </FormDataConsumer>
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.user.fieldGroups.stats'
                                        )}
                                    </Typography>
                                    <div>
                                        <FormDataConsumer>
                                            {({ formData, ...rest }) =>
                                                formData.deptType ===
                                                'mobifone' ? (
                                                    <ReferenceInput
                                                        validate={[required()]}
                                                        source="deptId"
                                                        resource="user"
                                                        reference="department"
                                                    >
                                                        <SelectInput
                                                            validate={[
                                                                required(),
                                                            ]}
                                                            fullWidth
                                                            optionText={(
                                                                choice?: Department
                                                            ) =>
                                                                choice?.id // the empty choice is { id: '' }
                                                                    ? `${choice.deptName} - ${choice.deptCode}`
                                                                    : ''
                                                            }
                                                        />
                                                    </ReferenceInput>
                                                ) : (
                                                    <ReferenceInput
                                                        source="partnerId"
                                                        reference="partner"
                                                        resource="user"
                                                        validate={
                                                            requiredValidate
                                                        }
                                                    >
                                                        <SelectInput
                                                            validate={[
                                                                required(),
                                                            ]}
                                                            fullWidth
                                                            optionText={(
                                                                choice?: Partner
                                                            ) =>
                                                                choice?.id // the empty choice is { id: '' }
                                                                    ? `${choice.name} - ${choice.code}`
                                                                    : ''
                                                            }
                                                        />
                                                    </ReferenceInput>
                                                )
                                            }
                                        </FormDataConsumer>
                                    </div>

                                    <div>
                                        <ReferenceArrayInput
                                            reference="group"
                                            resource="user"
                                            source="groupIds"
                                        >
                                            <SelectArrayInput
                                                fullWidth
                                                optionText="groupName"
                                            />
                                        </ReferenceArrayInput>
                                    </div>
                                </Box>
                                <Box flex={1} />
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="user"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];

export default UserCreate;
