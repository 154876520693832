import * as React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import Report from './report';
import Profile from './profile/Profile';
import ReportV3 from './reportV3';

export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/profile" render={() => <Profile />} />,
    <Route exact path="/report" render={() => <ReportV3.list />} />,
    <Route exact path="/reportOld" render={() => <Report.list />} />,
];
