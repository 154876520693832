import {FC, useEffect, useState} from "react";
import {
    CreateProps,
    useNotify,
    TabbedForm,
    FormTab, Toolbar, usePermissions, Edit, SaveButton, useSaveContext, DeleteButton
} from "react-admin";
import CommonEditToolbar from "../component/CommonEditToolbar";
import * as React from "react";
import ThongTinChungTab from "./ThongTinChungTab";
import DonViGiamSatTab from "./DonViGiamSatTab";
import KPITab from "./KPITab";
import Box from "@material-ui/core/Box";
import {Route} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./DistributionUtils"
import {useForm, useFormState} from "react-final-form";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import CheckNumCallButton from "./CheckNumCallButton";
const useStyles = makeStyles(styles);

const MyToolbar = (props) => {
    const classes = useStyles();
    const formState = useFormState();
    const formSave = useSaveContext();
    const notify = useNotify();
    const form = useForm();
    const [openConfirm, setOpenConfirm] = useState(false);
    const onSubmit = () => {
        const fromDate = form.getFieldState("fromDate")?.value;
        const toDate = form.getFieldState("toDate")?.value;
        if(fromDate && toDate) {
            if(new Date(fromDate).getTime() > new Date(toDate).getTime()) {
                notify("Mục 'Từ ngày' không được lớn hơn mục 'Đến ngày'", 'warning');
                return;
            }
        }
        const fromStarttime = form.getFieldState("fromStarttime")?.value;
        const toStarttime = form.getFieldState("toStarttime")?.value;
        if(fromStarttime && toStarttime) {
            if(new Date(fromStarttime).getTime() > new Date(toStarttime).getTime()) {
                notify("Mục 'Ngày bắt đầu đầu cuộc gọi từ' không được lớn hơn mục 'Ngày bắt đầu đầu cuộc gọi đến'", 'warning');
                return;
            }
        }
        props.handleSubmitWithRedirect();
    }
    return (
        <Toolbar {...props} className={classes.toolbar}>
            <Box display={"flex"}>
                <SaveButton
                    submitOnEnter={false}
                    handleSubmitWithRedirect={() => setOpenConfirm(true)}
                    saving={formSave.saving}
                    disabled={!formState.dirty}
                />
                <Dialog
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Cập nhật phân phối"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Cập nhập sẽ xóa toàn bộ giữ liệu cũ (Trường hợp cập nhập giám sát viên để phân phối cấp hai, sẽ chỉ cập nhập giám sát viên). Bạn muốn tiếp tục cập nhập?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onSubmit}>Đồng ý</Button>
                        <Button onClick={() => setOpenConfirm(false)}>Hủy</Button>
                    </DialogActions>
                </Dialog>
                <Route
                    path="*/2"
                    render={props => (
                        <CheckNumCallButton />
                    )}
                />
            </Box>
            <DeleteButton undoable={false}/>
        </Toolbar>
    );
};


const DistributionEdit: FC<CreateProps> = props => {
    const notify = useNotify();
    const failure = (error) => {
        notify(error.body.message, { type: 'error', autoHideDuration: 30000});
    }
    const { permissions } = usePermissions();
    const [permission, setPermission] = useState("distribution_ppc2");
    useEffect(() => {
        if(permissions && permissions.includes("distribution_ppc1")) {
            setPermission("distribution_ppc1");
        } else if(permissions && permissions.includes("distribution_ppc2")) {
            setPermission("distribution_ppc2");
        }
    }, [permissions])
    return (
        <Edit
            {...props}
            mutationMode={'pessimistic'}
            actions={<CommonEditToolbar />}
            title="Chỉnh sửa phân phối cuộc gọi"
            onFailure={failure}
            undoable={false}
        >
            <TabbedForm redirect="list"
                        toolbar={<MyToolbar/>}
            >
                <FormTab label="resources.distribution.tabs.information">
                    <ThongTinChungTab
                        permission={permission}
                    />
                </FormTab>
                <FormTab label="resources.distribution.tabs.supervisorDepartment">
                    <DonViGiamSatTab
                        permission={permission}
                    />
                </FormTab>
                <FormTab label={"resources.distribution.tabs.kpi"}>
                    <KPITab
                        permission={permission}
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}
export default DistributionEdit;
