import * as React from 'react';
import { FC, memo } from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
    endOfYesterday,
    startOfMonth,
    startOfWeek,
    subMonths,
    subWeeks,
} from 'date-fns';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1,
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const STATUS = [
    {
        id: 'chuaXuLy',
        label: 'Chưa xử lý',
    },
    {
        id: 'dongY',
        label: 'Đồng ý',
    },
    {
        id: 'tuChoi',
        label: 'Từ chối',
    },
];

const Aside: FC = () => {
    return (
        <Card>
            <CardContent>
                <FilterLiveSearch />

                <FilterList
                    label="resources.evaluateOther.filters.createTime"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="resources.evaluateOther.filters.today"
                        value={{
                            fromDate: endOfYesterday().getTime(),
                            toDate: undefined,
                        }}
                    />
                    <FilterListItem
                        label="resources.evaluateOther.filters.this_week"
                        value={{
                            fromDate: startOfWeek(new Date()).getTime(),
                            toDate: undefined,
                        }}
                    />
                    <FilterListItem
                        label="resources.evaluateOther.filters.last_week"
                        value={{
                            fromDate: subWeeks(
                                startOfWeek(new Date()),
                                1
                            ).getTime(),
                            toDate: startOfWeek(new Date()).getTime(),
                        }}
                    />
                    <FilterListItem
                        label="resources.evaluateOther.filters.this_month"
                        value={{
                            fromDate: startOfMonth(new Date()).getTime(),
                            toDate: undefined,
                        }}
                    />
                    <FilterListItem
                        label="resources.evaluateOther.filters.last_month"
                        value={{
                            fromDate: subMonths(
                                startOfMonth(new Date()),
                                1
                            ).getTime(),
                            toDate: startOfMonth(new Date()).getTime(),
                        }}
                    />
                    <FilterListItem
                        label="resources.evaluateOther.filters.earlier"
                        value={{
                            fromDate: undefined,
                            toDate: subMonths(
                                startOfMonth(new Date()),
                                1
                            ).getTime(),
                        }}
                    />
                </FilterList>

                <FilterList
                    label="resources.evaluateOther.filters.personal"
                    icon={<LocalOfferIcon />}
                >
                    <FilterListItem
                        label={'Của tôi'}
                        key={1}
                        value={{ mine: true }}
                    />
                </FilterList>

                {/*<FilterList
                    label="resources.evaluateOther.filters.status"
                    icon={<LocalOfferIcon />}
                >
                    {STATUS.map(item => (
                        <FilterListItem
                            label={item.label}
                            key={item.id}
                            value={{ status: item.id }}
                        />
                    ))}
                </FilterList>*/}
            </CardContent>
        </Card>
    );
};

export default memo(Aside);
