import * as React from 'react';
import { ChangeEvent, FC, memo, useMemo } from 'react';
import { useListFilterContext } from 'ra-core';
import { Form } from 'react-final-form';
import { DateInput } from 'react-admin';
import { truncDate } from '../utils';

const DateLiveSearch: FC<{ source?: string; label: string }> = props => {
    const { source = 'q', label, ...rest } = props;
    const { filterValues, setFilters } = useListFilterContext();

    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = truncDate(new Date(event.target.value)).getTime();
        if (event.target) {
            setFilters({ ...filterValues, [source]: value }, null);
        } else {
            const { [source]: _, ...filters } = filterValues;
            setFilters(filters, null);
        }
    };

    const initialValues = useMemo(
        () => ({
            [source]: filterValues[source],
        }),
        [filterValues, source]
    );

    const onSubmit = () => undefined;

    return (
        <Form initialValues={initialValues} onSubmit={onSubmit}>
            {() => (
                <DateInput
                    resettable
                    helperText={false}
                    source={source}
                    fullWidth
                    label={label}
                    onChange={onSearchChange}
                    {...rest}
                />
            )}
        </Form>
    );
};

export default memo(DateLiveSearch);
