import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import Box from '@material-ui/core/Box';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import IconButton from '@material-ui/core/IconButton';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import { useRecordContext } from 'react-admin';
import './styles.css';
import { blue } from '@material-ui/core/colors';
import { Pause, PlayArrow } from '@material-ui/icons';

const formWaveSurferOptions = ref => ({
    container: ref,
    waveColor: '#eee',
    progressColor: blue[500],
    cursorColor: blue[500],
    barWidth: 2,
    barRadius: 4,
    responsive: true,
    height: 100,
    normalize: true,
    partialRender: true,
});

export default function WaveForm({ url, call}) {
    console.log(url)
    const [now, setNow] = useState(0);
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [playing, setPlay] = useState(false);
    const [volume, setVolume] = useState(1);
    const [volumeAfter, setVolumeAfter] = useState(volume);
    useEffect(() => {
        setPlay(false);

        const options = formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = WaveSurfer.create(options);

        wavesurfer.current.load(url);

        wavesurfer.current.on('ready', function () {
            if (wavesurfer.current) {
                wavesurfer.current.setVolume(volume);
                setVolume(volume);
            }
        });
        wavesurfer.current.on('audioprocess', function (e) {
            setNow(e);
        });
        return () => wavesurfer.current.destroy();
    }, [url]);

    const handlePlayPause = () => {
        setPlay(!playing);
        wavesurfer.current.playPause();
    };

    const onVolumeChange = e => {
        const { target } = e;
        const newVolume = +target.value;

        if (newVolume) {
            setVolume(newVolume);
            setVolumeAfter(newVolume);
            wavesurfer.current.setVolume(newVolume || 1);
        }
    };
    const onOffAudio = () => {
        if (volume > 0.01) {
            setVolume(0);
            wavesurfer.current.setVolume(0);
        } else {
            setVolume(volumeAfter);
            wavesurfer.current.setVolume(volumeAfter);
        }
    };
    const format = duration => {
        let seconds = duration;
        const format = val => `0${Math.floor(val)}`.slice(-2);
        const minutes = (seconds % 3600) / 60;
        return [minutes, seconds % 60].map(format).join(':');
    };

    return (
        <div>
            <div id="waveform" ref={waveformRef} />
            <div className="Top-controls">
                <Box className="controls">
                    <IconButton
                        color={'inherit'}
                        size={'small'}
                        style={{ marginRight: 10 }}
                        onClick={handlePlayPause}
                    >
                        {!playing? <PlayArrow /> : <Pause /> }
                    </IconButton>
                    <span>
                        {format(now)} / {format(call.duration / 1000)}
                    </span>
                    <IconButton
                        color={'inherit'}
                        onClick={onOffAudio}
                        size={'small'}
                        style={{
                            marginLeft: 15,
                            marginRight: 5,
                        }}
                    >
                        {volume > 0.01 ? <VolumeUpIcon /> : <VolumeOffIcon />}
                    </IconButton>
                    <div>
                        {volume > 0.01 ? (
                            <span className={`is-volume`}>
                                <input
                                    style={{
                                        height: 3,
                                        width: 100,
                                        marginBottom: 10,
                                    }}
                                    type="range"
                                    id="volume"
                                    name="volume"
                                    min="0.01"
                                    max="1"
                                    step=".025"
                                    onChange={onVolumeChange}
                                    defaultValue={volume}
                                />
                            </span>
                        ) : (
                            <div className={`No-volume`}>
                                <input
                                    style={{
                                        height: 3,
                                        width: 100,
                                        marginBottom: 10,
                                    }}
                                    type="range"
                                    id="volume"
                                    name="volume"
                                    min="0.01"
                                    max="1"
                                    step=".025"
                                    onChange={onVolumeChange}
                                    defaultValue={0}
                                />
                            </div>
                        )}
                    </div>
                </Box>
            </div>
        </div>
    );
}
