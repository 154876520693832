import React from 'react';
import {ApexOptions} from "apexcharts";
import ReactApexChart from 'react-apexcharts'

type AreaChartProps = {
    data: {name?: string, data: number[]}[],
    categories: string[],
    colors: string[],
}

const AreaChart = (props: AreaChartProps) => {
    const options: ApexOptions = {
        chart: {
            type: 'area',
            height: '100%',
            toolbar: {
                show: false
            },
        },
        dataLabels: {
            enabled: false,
            formatter: function (val) {
                return val !== null && val !== undefined ? val.toLocaleString() + 's' : '';
            },
        },
        stroke: {
            curve: 'straight'
        },
        xaxis: {
            categories: props.categories,
            labels: {
                rotate: -45,
                rotateAlways: true,
            },
        },
        yaxis: {
            title: {
                text: 'Giây',
                style: {
                    color: '#000',
                    fontWeight: 'bold',
                    fontFamily: '"Roboto","Helvetica","Arial", sans-serif',
                }
            }
        },
        colors: props.colors,
    };

    return (
        <div style={{height: '100%'}}>
            <ReactApexChart
                options={options}
                series={props.data}
                type="area" />
        </div>
    )
};

export default AreaChart;