import Box from "@material-ui/core/Box";
import {
    ArrayInput,
    DateTimeInput,
    Identifier,
    NumberInput,
    ReferenceArrayInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleFormIterator,
    TextInput,
    useGetList,
    useRecordContext
} from "react-admin";
import {filterUsersByPartnerIdOrDeptId, styles, validateDuration} from "./DistributionUtils";
import * as React from "react";
import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import AutoCompleteArray from "../component/AutoCompleteArray";
import {useForm, useFormState} from "react-final-form";
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles(styles);

const KPITab = (props) => {
    const classes = useStyles();
    const [DTVItems, setDTVItems] = useState<
        Array<{
            id: string | null | undefined | Identifier;
            name: string | null | undefined;
        }>
        >([]);
    const { data: partners } = useGetList(
        'partner',
        { perPage: 1000, page: 1 },
        { field: 'name', order: 'ASC' }
    );
    const partnerItems: Array<{
        id: string | null | undefined | Identifier;
        name: string | null | undefined;
    }> = Object.values(partners)
        ? Object.values(partners).map(d => {
            return {
                name: `${d.name} - ${d.code}`,
                id: d.id,
            };
        })
        : [];
    const { data: DTVs } = useGetList(
        'user',
        { perPage: 1000, page: 1 },
        { field: 'fullName', order: 'ASC' },
        { userType: 3 }
    );

    const onchange = value => {
        form.change('dtvIds', []);
        if (value === null || value === undefined) {
            setDTVItems([]);
        } else {
            setDTVItems(filterUsersByPartnerIdOrDeptId(DTVs, value));
        }
    };
    const [isDisabled, setIsDisabled] = useState(false);
    const form = useForm();
    const formContext = useFormState();
    const partnerId =  formContext.values.partnerId;

    useEffect(() => {
       if(partnerId) {
           setDTVItems(filterUsersByPartnerIdOrDeptId(DTVs, partnerId));
       }
    }, [DTVs])

    const record = useRecordContext();
    useEffect(() => {
        if(record && record.typeOfCallCondition === 0) {
            setIsDisabled(false);
        } else if(record && record.typeOfCallCondition === 1) {
            setIsDisabled(true);
        }
    }, [record]);
    useEffect(() => {
        if(record && record.id) {
            if (record.partnerId === null || record.partnerId === undefined) {
                setDTVItems([]);
            } else {
                setDTVItems(filterUsersByPartnerIdOrDeptId(DTVs, record.partnerId));
            }
        }
    }, [DTVs, record]);

    return (
        <>
            <SelectInput
                choices={[{id: 0, name: 'Lọc theo các điều kiện'}, {id: 1, name: 'Lọc theo inum cuộc gọi'}]}
                source={'typeOfCallCondition'}
                resource="distribution"
                fullWidth
                optionText="name"
                defaultValue={0}
                onChange={event => {
                    // console.log(event)
                    // @ts-ignore
                    if(event.target.value === 0) {
                        setIsDisabled(false)
                        form.change('fromStarttime', null);
                        form.change('toStarttime', null);
                        form.change('topicIds', null);
                        form.change('typeOfCallQuantity', null);
                        form.change('durations', null);
                        form.change('partnerId', null);
                        form.change('dtvIds', null);
                        form.change('incoming', null);
                        form.change('inums', ['0']);
                        setDTVItems([])
                    } else {
                        setIsDisabled(true);
                        // khi ẩn đi -> pass validate
                        form.change('fromStarttime', new Date());
                        form.change('toStarttime', new Date());
                        form.change('topicIds', ['0']);
                        form.change('typeOfCallQuantity', 0);
                        form.change('durations', [{fromDuration: 0, toDuration: 0, callQuantity: 0}]);
                        form.change('partnerId', '0');
                        form.change('dtvIds', ['0']);
                        form.change('incoming', true);
                        form.change('inums', null);
                        setDTVItems([])
                    }
                }}
                disabled={props.permission !== "distribution_ppc1"}
            />

            {isDisabled &&
                <Tooltip title=" Các Inum ngăn cách bởi dấu phẩy, vd: inum1,inum2,inum3,..." arrow>
                    <div>
                        <TextInput
                            fullWidth
                            source="inums"
                            resource="distribution"
                            validate={[required()]}
                            disabled={props.permission !== "distribution_ppc1"}
                            style={{marginRight: 0}}
                        />
                    </div>
                </Tooltip>
            }
            <Box
                className={classes.tab}
                display={{ md: 'block', lg: 'flex' }}
            >
                <Box flex={1} mr={{ md: 0, lg: '1em' }}>
                    {!isDisabled &&
                         <Box display={'flex'}>
                            <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                <DateTimeInput
                                    resource="distribution"
                                    fullWidth
                                    source="fromStarttime"
                                    validate={[required()]}
                                    disabled={props.permission !== "distribution_ppc1"}
                                />
                            </Box>
                            <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                <DateTimeInput
                                    resource="distribution"
                                    source="toStarttime"
                                    fullWidth
                                    validate={[required()]}
                                    disabled={props.permission !== "distribution_ppc1"}
                                />
                            </Box>
                         </Box>
                    }
                    {!isDisabled &&
                        <Box display={'flex'}>
                            <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                <SelectInput
                                    choices={[{id: 0, name: 'Số cuộc gọi (cuộc)'}, {id: 1, name: 'Tỉ lệ cuộc gọi (%)'}]}
                                    source={'typeOfCallQuantity'}
                                    resource="distribution"
                                    fullWidth
                                    optionText="name"
                                    validate={[required()]}
                                    disabled={props.permission !== "distribution_ppc1"}
                                />
                            </Box>
                            <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                <SelectInput
                                    choices={[{id: 'true', name: 'Inbound'}, {id: 'false', name: 'Outbound'}]}
                                    source={'incoming'}
                                    resource="distribution"
                                    fullWidth
                                    optionText="name"
                                    disabled={props.permission !== "distribution_ppc1"}
                                />
                            </Box>
                        </Box>
                    }
                    {!isDisabled &&
                        <ReferenceArrayInput
                            resource="distribution"
                            reference={'category'}
                            source={'topicIds'}
                            perPage={1000}
                            filter={{groupCategoryCode: 'CALL_TOPIC'}}
                            disabled={props.permission !== "distribution_ppc1"}
                        >
                            <SelectArrayInput
                                fullWidth optionText="name"
                                disabled={props.permission !== "distribution_ppc1"}
                            />
                        </ReferenceArrayInput>
                    }
                    {!isDisabled &&
                        <ArrayInput
                            source="durations"
                            resource="distribution"
                            className={classes.root}
                            validate={[required(), validateDuration]}
                            disabled={props.permission !== "distribution_ppc1"}
                        >
                            <SimpleFormIterator>
                                <NumberInput
                                    fullWidth
                                    source="fromDuration"
                                    validate={[required()]}
                                    disabled={props.permission !== "distribution_ppc1"}
                                    min={0}
                                />
                                <NumberInput
                                    source="toDuration"
                                    fullWidth
                                    validate={[required()]}
                                    disabled={props.permission !== "distribution_ppc1"}
                                    min={0}
                                />
                                <NumberInput
                                    fullWidth
                                    source="callQuantity"
                                    max={100}
                                    validate={[required()]}
                                    disabled={props.permission !== "distribution_ppc1"}
                                    min={0}
                                />
                                <NumberInput
                                    fullWidth
                                    source="numberFoundCall"
                                    max={100}
                                    disabled
                                    min={0}
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                </Box>
                {!isDisabled &&
                    <Box flex={1}>
                        <SelectInput
                            choices={partnerItems}
                            source="partnerId"
                            resource="distribution"
                            onChange={event => {
                                onchange(event.target.value);
                            }}
                            fullWidth
                            validate={[required()]}
                            disabled={props.permission !== "distribution_ppc1"}
                        />
                        <Box mt={1}>
                            <AutoCompleteArray
                                fullWidth
                                source="dtvIds"
                                resource="distribution"
                                label={'Điện thoại viên'}
                                choices={DTVItems}
                                getOptionLabel={option => `${option?.name}`}
                                disabled={props.permission !== "distribution_ppc1"}
                            />
                        </Box>
                    </Box>
                }
            </Box>
        </>
    )
};
export default KPITab;