import * as React from 'react';
import { FC, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps } from 'react-admin';
import { Call } from '../types';
import { blue } from '@material-ui/core/colors';
import { Avatar, Box, Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
        marginTop: -theme.spacing(0.5),
        marginBottom: -theme.spacing(0.5),
    },
    icon: {
        color: blue[500],
    },
}));

interface Props extends FieldProps<Call> {
    size?: string;
}

const DurationField: FC<Props> = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            {
                <Box>
                    <Chip
                        variant="outlined"
                        size="medium"
                        avatar={<Avatar>{record.score}</Avatar>}
                        label={`${record.createBy} | ${new Date(
                            record.createTime
                        ).toLocaleDateString()}`}
                        color="primary"
                    />
                </Box>
            }
        </div>
    ) : null;
};

DurationField.defaultProps = {
    source: 'duration',
    label: 'resources.call.fields.duration',
    addLabel: true,
};

export default memo<Props>(DurationField);
