import * as React from 'react';
import {
    Fragment,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    CreateButton,
    EditButton,
    Filter,
    FilterProps,
    List,
    ListProps,
    SearchInput,
    TopToolbar,
    useListContext,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Route, useHistory } from 'react-router-dom';
import DepartmentCreate from './DepartmentCreate';
import {
    CustomTreeData,
    DataTypeProvider,
    IntegratedFiltering,
    SearchState,
    TreeDataState,
} from '@devexpress/dx-react-grid';
import { CheckCircleTwoTone } from '@material-ui/icons';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';
import DialogEdit from '../component/DialogEdit';
import DialogCreate from '../component/DialogCreate';

const DepartmentFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const useStyles = makeStyles(theme => ({
    nb_commands: { color: 'purple' },
    hiddenOnSmallScreens: {
        display: 'table-cell',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

const Actions = props => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const GridData = props => {
    const { ids, data, basePath } = useListContext();
    const [treeData, setTreeData] = useState([]);
    const [defaultExpandedRowIds] = useState(
        Array.from(Array(100), (_, i) => i)
    );
    const [columns] = useState([
        { name: 'deptName', title: 'Tên đơn vị' },
        { name: 'deptCode', title: 'Mã đơn vị' },
        { name: 'actions', title: ' ' },
    ]);

    const getChildRows = (row, rootRows) => {
        const childRows = rootRows.filter(
            r => r.parentId === (row ? row.id : null)
        );
        return childRows.length ? childRows : null;
    };

    const [tableColumnExtensions] = useState([
        { columnName: 'deptName', width: 700 },
        { columnName: 'status', align: 'center' },
    ]);

    const { filterValues } = useListContext();

    useEffect(() => {
        if (!filterValues.q) {
            const flatData = ids.map(id => {
                const item = { ...data[id] };
                item.parentId = data[id].parentId ? data[id].parentId : null;
                item.chidlren = null;
                item.actions = item.id;
                return item;
            });
            // @ts-ignore
            setTreeData([...flatData]);
        } else {
            const flatData = ids.map(id => {
                const item = { ...data[id] };
                item.parentId = null;
                item.chidlren = null;
                item.actions = item.id;
                return item;
            });
            // @ts-ignore
            setTreeData([...flatData]);
        }
    }, [ids, data, filterValues]);

    const ActionsFormatter = ({ value }) => (
        <div>
            <EditButton basePath={basePath} record={data[value]} />
        </div>
    );

    const ActionsProvider = props => (
        <DataTypeProvider formatterComponent={ActionsFormatter} {...props} />
    );

    const [actionsColumns] = useState(['actions']);

    const CreateTimeFormatter = ({ value }) => (
        <span>{new Date(value).toLocaleString()}</span>
    );

    const CreateTimeProvider = props => (
        <DataTypeProvider formatterComponent={CreateTimeFormatter} {...props} />
    );

    const [createTimeColumns] = useState(['createTime']);

    const ActiveFormatter = ({ value }) => (
        <CheckCircleTwoTone color={value ? 'primary' : 'disabled'} />
    );

    const ActiveProvider = props => (
        <DataTypeProvider formatterComponent={ActiveFormatter} {...props} />
    );

    const [activeColumns] = useState(['status']);

    return (
        <Grid rows={treeData} columns={columns}>
            <ActionsProvider for={actionsColumns} />
            <CreateTimeProvider for={createTimeColumns} />
            <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
            <CustomTreeData getChildRows={getChildRows} />
            {/*// @ts-ignore*/}
            <Table columnExtensions={tableColumnExtensions} />
            <TableHeaderRow />
            <TableTreeColumn for="deptName" />
        </Grid>
    );
};

const DepartmentList = (props: ListProps): ReactElement => {
    const history = useHistory();

    const handleClose = useCallback(() => {
        history.push('/department');
    }, [history]);

    return (
        <Fragment>
            <List
                {...props}
                filters={<DepartmentFilter />}
                pagination={false}
                actions={<Actions />}
                sort={{ field: 'updateTime', order: 'DESC' }}
                perPage={100}
            >
                <GridData />
            </List>
            <Route
                path={`${props.basePath}/create`}
                render={() => (
                    <DialogCreate
                        {...props}
                        onCancel={handleClose}
                        title={'Thêm mới đơn vị'}
                    >
                        <DepartmentCreate />
                    </DialogCreate>
                )}
            />
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch =
                        match && match.params && match.params.id !== 'create';

                    return (
                        isMatch && (
                            <DialogEdit
                                {...props}
                                onCancel={handleClose}
                                title={'Chỉnh sửa đơn vị'}
                                id={match?.params.id}
                            >
                                <DepartmentCreate />
                            </DialogEdit>
                        )
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default DepartmentList;
