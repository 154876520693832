import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import {
    useGetIdentity,
    useQueryWithStore,
    useRecordContext,
} from 'react-admin';
import { useField } from 'react-final-form';
import {
    CustomTreeData,
    IntegratedSelection,
    SelectionState,
    TreeDataState,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableSelection,
    TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const TabTieuChi: FC = props => {
    const {
        input: { onChange },
    } = useField('criteriaCallIds');
    const [defaultExpandedRowIds] = useState(
        Array.from(Array(100), (_, i) => i)
    );
    const { identity } = useGetIdentity();
    const record = useRecordContext();

    const { data } = useQueryWithStore({
        type: 'getList',
        resource: 'criteriaCall',
        payload: {
            filter: { isActive: true },
            sort: { field: 'name', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        },
    });

    const [selection, setSelection] = useState(
        record ? record.criteriaCallIds : []
    );
    const [flatData, setFlatData] = useState([]);
    const [columns] = useState([
        { name: 'name', title: 'Tiêu chí' },
        { name: 'score', title: 'Điểm' },
    ]);

    useEffect(() => {
        if (selection && flatData.length) {
            // @ts-ignore
            const ids = selection.map(index => flatData[index]?.id);
            onChange(ids);
        }
    }, [selection]);

    useEffect(() => {
        // @ts-ignore
        if (record.criteriaCallIds && flatData) {
            const newSelection = record.criteriaCallIds.map(id =>
                // @ts-ignore
                flatData.findIndex(item => item.id === id)
            );
            setSelection(newSelection);
        }
    }, [record, flatData]);

    const getActive = item => {
        if (identity) {
            if (item.deptId === 'TCT' || item.deptId === identity.deptId) {
                return item.isActive;
            } else {
                return item.deptIdsActive
                    ? item.deptIdsActive.includes(identity.deptId)
                    : false;
            }
        }
        return true;
    };

    useEffect(() => {
        if (data) {
            const newData = data.filter(getActive).map(item => ({
                ...item,
                parentId: item.parentId ? item.parentId : null,
            }));
            console.log(newData);
            setFlatData(newData);
        }
    }, [data]);

    const getChildRows = (row, rootRows) => {
        const childRows = rootRows.filter(
            r => r.parentId === (row ? row.id : null)
        );
        return childRows.length ? childRows : null;
    };

    const [tableColumnExtensions] = useState([
        { columnName: 'name', width: 1000 },
    ]);
    return (
        <Box>
            <Typography variant="subtitle2" color="secondary">{`Đã chọn (${
                selection?.length || 0
            }) tiêu chí`}</Typography>
            <Grid rows={flatData} columns={columns}>
                <SelectionState
                    selection={selection}
                    // @ts-ignore
                    onSelectionChange={setSelection}
                />
                <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
                <CustomTreeData getChildRows={getChildRows} />
                <IntegratedSelection />
                <Table columnExtensions={tableColumnExtensions} />
                <TableSelection showSelectAll={true} />
                <TableHeaderRow />
                <TableTreeColumn for="name" />
            </Grid>
        </Box>
    );
};

export default TabTieuChi;
