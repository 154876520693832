import * as React from 'react';
import { FC, Fragment, useCallback, useState } from 'react';
import {
    Button,
    CreateButton,
    Datagrid,
    DatagridProps,
    DateField,
    Filter,
    FilterProps,
    FunctionField,
    List,
    ListContextProvider,
    ListProps,
    Pagination,
    SearchInput,
    TextField,
    TopToolbar,
    useDataProvider,
    useGetList,
    useListContext,
    useNotify,
    usePermissions,
    useRefresh,
} from 'react-admin';
import {
    Avatar,
    Divider,
    Link,
    Tab,
    Tabs,
    Theme,
    useMediaQuery,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import MobileGrid from './MobileGrid';
import CallListAside from './CallListAside';
import rowStyle from './rowStyle';
import DurationField from './DurationField';
import Chip from '@material-ui/core/Chip';
import { MenuOpenRounded } from '@material-ui/icons';
import ExportExcelButton from './ExportExcelButton';
import { ToggleFieldsMenu } from '../component/ToggleFieldsMenu';
import { useSelector } from 'react-redux';
import { AppState } from '../types';
import { callToggleableFields } from './index';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TichMaField from './TichMaField';

const useDataGridStyles = makeStyles({
    total: { fontWeight: 'bold' },
    center: { textAlign: 'center' },
    generalComment: {
        width: 120,
    },
    topic: {
        width: 120,
    },
    actions: {},
});

const FilterSimple = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const Actions = (props: any) => {
    const { permissions } = usePermissions();
    const { setFilters, displayedFilters } = useListContext();

    return (
        <TopToolbar>
            <Button
                variant="text"
                color="primary"
                size="small"
                label="Reset bộ lọc"
                onClick={() => {
                    setFilters({ status: 0 }, displayedFilters, true);
                }}
                startIcon={<RestartAltIcon />}
            />
            <ExportExcelButton
                url={`report/baoCaoDanhSachCuocGoi`}
                fileName={'call-list.xlsx'}
            />
            {permissions && permissions.includes('call_add') && (
                <CreateButton />
            )}
            <ToggleFieldsMenu
                resource={'call'}
                allFields={callToggleableFields}
            />
            <Button
                onClick={props.toggleAside}
                variant="text"
                color="secondary"
                label={props.showAside ? 'Ẩn tìm kiếm' : 'Hiện tìm kiếm'}
                startIcon={<MenuOpenRounded />}
            />
        </TopToolbar>
    );
};

const tabs = [{ id: 0, name: 'Tất cả' }];

interface TabbedDataGridProps extends DatagridProps {
    page?: number;
    perPage?: number;
}

const useGetTotals = (filterValues: any) => {
    const { total: totalCall } = useGetList(
        'call',
        { perPage: 1, page: 1 },
        { field: 'starttime', order: 'DESC' },
        { ...filterValues, status: 0 }
    );
    // const { total: totalNotYetRated } = useGetList(
    //     'call',
    //     { perPage: 1, page: 1 },
    //     { field: 'starttime', order: 'DESC' },
    //     { ...filterValues, status: 1 }
    // );
    // const { total: totalHaveEvaluated } = useGetList(
    //     'call',
    //     { perPage: 1, page: 1 },
    //     { field: 'starttime', order: 'DESC' },
    //     { ...filterValues, status: 2 }
    // );
    // const { total: totalHaveComplaint } = useGetList(
    //     'call',
    //     { perPage: 1, page: 1 },
    //     { field: 'starttime', order: 'DESC' },
    //     { ...filterValues, status: 3 }
    // );
    // const {total: totalHaveAuto} = useGetList(
    //     'call',
    //     {perPage: 1, page: 1},
    //     {field: 'starttime', order: 'DESC'},
    //     {...filterValues, status: 4}
    // );

    // const { total: totalCallDistribution } = useGetList(
    //     'call',
    //     { perPage: 1, page: 1 },
    //     { field: 'starttime', order: 'DESC' },
    //     { ...filterValues, status: 5 }
    // );
    // const { total: totalErrorCall } = useGetList(
    //     'call',
    //     { perPage: 1, page: 1 },
    //     { field: 'starttime', order: 'DESC' },
    //     { ...filterValues, status: 6 }
    // );

    return {
        0: totalCall,
        // 1: totalNotYetRated,
        // 2: totalHaveEvaluated,
        // 3: totalHaveComplaint,
        // 5: totalCallDistribution,
        // 6: totalErrorCall,
    };
};

const TabbedDataGrid: FC<TabbedDataGridProps> = props => {
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = listContext;
    const classes = useDataGridStyles();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const refresh = useRefresh();
    const totals = useGetTotals(filterValues) as any;
    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, status: value },
                    displayedFilters
                );
        },
        [displayedFilters, filterValues, setFilters]
    );
    const { hiddenFields } = useSelector((state: AppState) => {
        return state.resourceSetting['call'];
    });

    const isHiddenField = (field: string, hiddenFields: string[]) => {
        return hiddenFields && hiddenFields.indexOf(field) !== -1;
    };

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            totals[choice.id]
                                ? `${choice.name} (${totals[choice.id]})`
                                : choice.name
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            {isXSmall ? (
                <ListContextProvider value={{ ...listContext }}>
                    <MobileGrid {...props} />
                </ListContextProvider>
            ) : (
                <div>
                    <ListContextProvider value={{ ...listContext }}>
                        <Datagrid
                            {...props}
                            // @ts-ignore
                            rowStyle={rowStyle()}
                            optimized
                            rowClick="edit"
                        >
                            <FunctionField
                                label="#"
                                render={record =>
                                    ((props.page || 1) - 1) *
                                        (props.perPage || 0) +
                                    Object.keys(props.data || {}).indexOf(
                                        record?.id as string
                                    ) +
                                    1
                                }
                            />
                            {!isHiddenField('agentDesc', hiddenFields) && (
                                <TextField source="agentDesc" />
                            )}
                            {!isHiddenField('callId', hiddenFields) && (
                                <TextField source="callId" />
                            )}
                            {!isHiddenField('inum', hiddenFields) && (
                                <TextField source="inum" />
                            )}
                            {!isHiddenField(
                                'hotlineRoomName',
                                hiddenFields
                            ) && <TextField source="hotlineRoomName" />}
                            {!isHiddenField(
                                'hotlineRoomDesc',
                                hiddenFields
                            ) && <TextField source="hotlineRoomDesc" />}
                            {!isHiddenField('starttime', hiddenFields) && (
                                <DateField source="starttime" showTime />
                            )}
                            {!isHiddenField('duration', hiddenFields) &&
                                filterValues.status !== 6 && (
                                    <DurationField source="duration" />
                                )}
                            {!isHiddenField('phoneNumber', hiddenFields) && (
                                <FunctionField
                                    source="phoneNumber"
                                    render={record => (
                                        <Link
                                            href={`tel:+84${record?.phoneNumber}`}
                                        >{`+84${record?.phoneNumber}`}</Link>
                                    )}
                                />
                            )}
                            {!isHiddenField('incoming', hiddenFields) &&
                                filterValues.status !== 6 && (
                                    <FunctionField
                                        source="incoming"
                                        headerClassName={classes.center}
                                        cellClassName={classes.center}
                                        render={record => {
                                            if (record?.incoming) {
                                                return (
                                                    <label
                                                        style={{
                                                            color: '#1769aa',
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Inbound
                                                    </label>
                                                );
                                            } else {
                                                return (
                                                    <label
                                                        style={{
                                                            color: 'red',
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Outbound
                                                    </label>
                                                );
                                            }
                                        }}
                                    />
                                )}
                            {/*{!isHiddenField('topic', hiddenFields) &&*/}
                            {/*    filterValues.status !== 6 && (*/}
                            {/*        <FunctionField*/}
                            {/*            sortable={false}*/}
                            {/*            source="topic"*/}
                            {/*            render={record =>*/}
                            {/*                record?.bestTopics ? (*/}
                            {/*                    <Chip*/}
                            {/*                        className={classes.topic}*/}
                            {/*                        size="small"*/}
                            {/*                        avatar={*/}
                            {/*                            <Avatar>*/}
                            {/*                                {*/}
                            {/*                                    record*/}
                            {/*                                        ?.bestTopics*/}
                            {/*                                        .topicName[0]*/}
                            {/*                                }*/}
                            {/*                            </Avatar>*/}
                            {/*                        }*/}
                            {/*                        label={*/}
                            {/*                            record?.bestTopics*/}
                            {/*                                .topicName*/}
                            {/*                        }*/}
                            {/*                    />*/}
                            {/*                ) : null*/}
                            {/*            }*/}
                            {/*        />*/}
                            {/*    )}*/}
                            <TichMaField source="tichMaId" />
                        </Datagrid>
                    </ListContextProvider>
                </div>
            )}
        </Fragment>
    );
};

const CallList: FC<ListProps> = props => {
    const [showAside, setShowAside] = useState(false);
    const classes = useDataGridStyles();
    const toggleAside = () => {
        setShowAside(val => !val);
    };
    return (
        <React.Fragment>
            <List
                {...props}
                filterDefaultValues={{ status: 0 }}
                sort={{ field: 'starttime', order: 'DESC' }}
                perPage={25}
                actions={
                    <Actions toggleAside={toggleAside} showAside={showAside} />
                }
                filters={showAside ? undefined : <FilterSimple />}
                aside={
                    // @ts-ignore
                    <CallListAside onClose={toggleAside} open={showAside} />
                }
                bulkActionButtons={false}
                pagination={
                    <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />
                }
            >
                <TabbedDataGrid />
            </List>
        </React.Fragment>
    );
};
export default CallList;
