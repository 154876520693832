import * as React from 'react';
import { FC } from 'react';
import { CheckboxGroupInput, required, TextInput } from 'react-admin';

const FiltersTemplateCreate: FC = () => {
    return (
        <form>
            <div>
                <TextInput
                    validate={requiredValidate}
                    source="name"
                    resource="filtersTemplate"
                    fullWidth
                />
            </div>
            <div>
                <TextInput
                    multiline
                    source="description"
                    resource="filtersTemplate"
                    fullWidth
                />
            </div>
            <div>
                <CheckboxGroupInput
                    source="activeFilters"
                    resource="filtersTemplate"
                    choices={[
                        { name: 'Đơn vị', id: 'donVi' },
                        { name: 'Tổ nhóm', id: 'toNhom' },
                        {
                            name: 'Ngày cuộc gọi',
                            id: 'fromDate',
                        },
                        {
                            name: 'Ngày cuộc gọi',
                            id: 'toDate',
                        },
                        {
                            name: 'Thời lượng từ',
                            id: 'fromDuration',
                        },
                        {
                            name: 'Thời lượng đến',
                            id: 'toDuration',
                        },
                        {
                            name: 'Nội dung cuộc gọi',
                            id: 'content',
                        },
                        { name: 'Chủ đề', id: 'topic' },
                        {
                            name: 'Cuộc gọi điển hình',
                            id: 'cuocGoiDienHinh',
                        },
                        {
                            name: 'Nhóm từ khóa',
                            id: 'keywordGroupId',
                        },
                        { name: 'Danh số', id: 'danhso' },
                    ]}
                />
            </div>
        </form>
    );
};

const requiredValidate = [required()];

export default FiltersTemplateCreate;
