import * as React from 'react';
import { FC } from 'react';
import { ReferenceInput, required, SelectInput, TextInput } from 'react-admin';

const NotifyFlowCreate: FC = () => {
    return (
        <form>
            <div>
                <TextInput
                    validate={requiredValidate}
                    source="name"
                    resource="notifyFlow"
                    fullWidth
                />
            </div>
            <div>
                <ReferenceInput
                    filter={{ groupCode: 'MAJOR' }}
                    reference="category"
                    resource="notifyFlow"
                    source="majorId"
                >
                    <SelectInput fullWidth optionText="name" />
                </ReferenceInput>
            </div>
            <div>
                <ReferenceInput
                    reference="notifyTemplate"
                    resource="notifyFlow"
                    source="notifyTemplateId"
                >
                    <SelectInput fullWidth optionText="name" />
                </ReferenceInput>
            </div>
            <div>
                <TextInput
                    multiline
                    source="description"
                    resource="filtersTemplate"
                    fullWidth
                />
            </div>
        </form>
    );
};

const requiredValidate = [required()];

export default NotifyFlowCreate;
