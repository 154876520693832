import * as React from 'react';
import { FC, memo } from 'react';
import {Box, Card as MuiCard, CardContent} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
    endOfYesterday,
    startOfMonth,
    startOfWeek,
    subMonths,
    subWeeks,
} from 'date-fns';
import DateLiveSearch from "../component/DateLiveSearch";
const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(6),
    },
    calender:{
        width: 200
    }

}));

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1,
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },

    },
}))(MuiCard);

const STATUS = [
    {
        id: 'chuaXuLy',
        label: 'Chưa xử lý',
    },
    {
        id: 'dongY',
        label: 'Đồng ý',
    },
    {
        id: 'rejected',
        label: 'Từ chối',
    },
];

const Aside: FC = () => {
    const classes = useStyles();
    return (
        <Card>
            <CardContent>
                <FilterLiveSearch />

                <FilterList
                    label="resources.complain.filters.createTime"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="resources.complain.filters.today"
                        value={{
                            fromDate: endOfYesterday().getTime(),
                            toDate: undefined,
                        }}
                    />
                    <FilterListItem
                        label="resources.complain.filters.this_week"
                        value={{
                            fromDate: startOfWeek(new Date()).getTime(),
                            toDate: undefined,
                        }}
                    />
                    <FilterListItem
                        label="resources.complain.filters.last_week"
                        value={{
                            fromDate: subWeeks(
                                startOfWeek(new Date()),
                                1
                            ).getTime(),
                            toDate: startOfWeek(new Date()).getTime(),
                        }}
                    />
                    <FilterListItem
                        label="resources.complain.filters.this_month"
                        value={{
                            fromDate: startOfMonth(new Date()).getTime(),
                            toDate: undefined,
                        }}
                    />
                    <FilterListItem
                        label="resources.complain.filters.last_month"
                        value={{
                            fromDate: subMonths(
                                startOfMonth(new Date()),
                                1
                            ).getTime(),
                            toDate: startOfMonth(new Date()).getTime(),
                        }}
                    />
                    <FilterListItem
                        label="resources.complain.filters.earlier"
                        value={{
                            fromDate: undefined,
                            toDate: subMonths(
                                startOfMonth(new Date()),
                                1
                            ).getTime(),
                        }}
                    />
                    <Box px={'2em'} className={classes.calender}>
                        <Box flex={1} >
                            <DateLiveSearch
                                source="fromDate"
                                label={'Từ ngày'}
                            />
                        </Box>
                        <Box flex={1} ml={1} marginLeft={0}>
                            <DateLiveSearch
                                source="toDate"
                                label={'Tời ngày'}
                            />
                        </Box>
                    </Box>
                </FilterList>

                <FilterList
                    label="resources.complain.filters.personal"
                    icon={<LocalOfferIcon />}
                >
                    <FilterListItem
                        label={'Của tôi'}
                        key={1}
                        value={{ mine: true }}
                    />
                </FilterList>

                <FilterList
                    label="resources.complain.filters.status"
                    icon={<LocalOfferIcon />}
                >
                    {STATUS.map(item => (
                        <FilterListItem
                            label={item.label}
                            key={item.id}
                            value={{ status: item.id }}
                        />
                    ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default memo(Aside);
