import * as React from "react";
import {FC, useEffect, useState} from "react";
import {
    Create,
    CreateProps,
    FormTab,
    SaveButton,
    TabbedForm,
    Toolbar,
    useNotify,
    usePermissions,
    useSaveContext
} from "react-admin";
import CommonEditToolbar from "../component/CommonEditToolbar";
import ThongTinChungTab from "./ThongTinChungTab";
import DonViGiamSatTab from "./DonViGiamSatTab";
import KPITab from "./KPITab";
import Box from "@material-ui/core/Box";
import {Route} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./DistributionUtils";
import {useForm, useFormState} from "react-final-form";
import CheckNumCallButton from "./CheckNumCallButton";
import {PermissionTag} from "../component/PermissionTag";

const useStyles = makeStyles(styles);

const MyToolbar = (props) => {
    const classes = useStyles();
    const formState = useFormState();
    const formSave = useSaveContext();
    const notify = useNotify();
    const form = useForm();
    const onSubmit = () => {
        const fromDate = form.getFieldState("fromDate")?.value;
        const toDate = form.getFieldState("toDate")?.value;
        if(fromDate && toDate) {
            if(new Date(fromDate).getTime() > new Date(toDate).getTime()) {
                notify("Mục 'Từ ngày' không được lớn hơn mục 'Đến ngày'", 'warning');
                return;
            }
        }
        const fromStarttime = form.getFieldState("fromStarttime")?.value;
        const toStarttime = form.getFieldState("toStarttime")?.value;
        if(fromStarttime && toStarttime) {
            if(new Date(fromStarttime).getTime() > new Date(toStarttime).getTime()) {
                notify("Mục 'Ngày bắt đầu đầu cuộc gọi từ' không được lớn hơn mục 'Ngày bắt đầu đầu cuộc gọi đến'", 'warning');
                return;
            }
        }

        props.handleSubmitWithRedirect();
    }
    return (
        <Toolbar {...props} className={classes.toolbar}>
            <Box display={"flex"}>
                <PermissionTag permissionCode={'distribution_ppc1'}>
                    <>
                        <SaveButton
                            submitOnEnter={false}
                            handleSubmitWithRedirect={onSubmit}
                            saving={formSave.saving}
                            disabled={!formState.dirty}
                        />
                        <Route
                            path="*/2"
                            render={props => (
                                <CheckNumCallButton />
                            )}
                        />
                    </>
                </PermissionTag>
            </Box>
        </Toolbar>
    );
};


const DistributionCreate: FC<CreateProps> = props => {
    const notify = useNotify();
    const failure = (error) => {
        notify(error.body.message, { type: 'error', autoHideDuration: 30000});
    }
    const { permissions } = usePermissions();
    const [permission, setPermission] = useState("distribution_ppc2");
    useEffect(() => {
        if(permissions && permissions.includes("distribution_ppc1")) {
            setPermission("distribution_ppc1");
        } else if(permissions && permissions.includes("distribution_ppc2")) {
            setPermission("distribution_ppc2");
        }
    }, [permissions])
    return (
        <Create
            {...props}
            actions={<CommonEditToolbar />}
            title="Tạo mới phân phối cuộc gọi"
            onFailure={failure}
        >
        <TabbedForm redirect="list"
                    toolbar={<MyToolbar/>}
                    // save={handleSaveButton}
        >
            <FormTab label="resources.distribution.tabs.information">
                <ThongTinChungTab
                    permission={permission}
                />
            </FormTab>
            <FormTab label="resources.distribution.tabs.supervisorDepartment">
                <DonViGiamSatTab
                    permission={permission}
                />
            </FormTab>
            <FormTab label={"resources.distribution.tabs.kpi"}>
                 <KPITab
                     permission={permission}
                 />
            </FormTab>
        </TabbedForm>
        </Create>
    )
}
export default DistributionCreate;
