import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box/Box';
import {
    Accordion, AccordionDetails, AccordionSummary,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { grey, yellow, red, blue} from '@material-ui/core/colors';

import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) => ({
    inline: {
        display: 'inline',
    },
    root: {
        overflow: 'auto',
        flex: 1,
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    listItemAgent: {
        flexDirection: 'row-reverse',
    },
    textAgent: {
        textAlign: 'right',
        flex: '0 1 auto',
    },
    text: {
        flex: '0 1 auto',
    },
    avatarAgent: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    loiThoai: {
        '& .MuiPaper-root': {
            boxShadow: 'none',
            backgroundColor: '#f7f7f7',
        },
        '& .MuiAccordionSummary-root': {
            borderRadius: '10px',
        }
    }
}));

interface Content {
    channel: string;
    start: string;
    textNorm: string;
    uttSegment: string;
    end: string;
}

interface ChatProps {
    contents: Content[];
}

const Chat = ({ contents }: ChatProps) => {
    const classes = useStyles();

    const format = (duration: string) => {
        let seconds = parseFloat(duration);
        const format = val => `0${Math.floor(val)}`.slice(-2);
        const minutes = (seconds % 3600) / 60;

        return [minutes, seconds % 60].map(format).join(':');
    };

    return (
        <Box mt={1} mb={1} className={classes.loiThoai}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                >
                    <Typography>Lời thoại</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List className={classes.root}>
                {contents.map(content => {
                    if (content.channel === 'AGENT' || content.channel === 'NHAN_VIEN') {
                        return (
                            <ListItem
                                dense
                                alignItems="flex-start"
                                className={classes.listItemAgent}
                            >
                                <ListItemAvatar className={classes.avatarAgent}>
                                    <Avatar
                                        alt="Remy Sharp"
                                        src="/static/images/avatar/1.jpg"
                                    >
                                        DTV
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography
                                                variant={'caption'}
                                                color={'textSecondary'}
                                            >
                                                {format(content.start)}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    className={classes.textAgent}
                                    secondary={
                                        <React.Fragment>
                                            <Box
                                                p={1}
                                                bgcolor={yellow[100]}
                                                borderRadius={8}
                                            >
                                                {content.textNorm}
                                            </Box>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        );
                    } else {
                        return (
                            <ListItem dense alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar
                                        alt="Remy Sharp"
                                        src="/static/images/avatar/1.jpg"
                                    >
                                        KH
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography
                                                variant={'caption'}
                                                color={'textSecondary'}
                                            >
                                                {format(content.start)}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    className={classes.text}
                                    secondary={
                                        <React.Fragment>
                                            <Box
                                                p={1}
                                                bgcolor={blue[50]}
                                                borderRadius={8}
                                            >
                                                {content.textNorm}
                                            </Box>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        );
                    }
                })}
            </List>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default Chat;
