import * as React from 'react';
import {FC, Fragment} from 'react';
import {
    BulkActionProps,
    BulkDeleteButton,
    ChipField,
    CloneButton,
    CreateButton,
    Datagrid,
    DatagridProps,
    DateField,
    EditButton,
    FunctionField,
    List,
    ListProps,
    ReferenceManyField,
    SingleFieldList,
    TextField,
    TextInput,
    TopToolbar,
} from 'react-admin';
import {Box, Tooltip} from "@mui/material";
import {PermissionTag} from "../component/PermissionTag";

interface DataGridProps extends DatagridProps {
    page?: number;
    perPage?: number;
}

const Actions = (props: any) => (
    <TopToolbar>
        <PermissionTag permissionCode={'distribution_ppc1'}>
            <CreateButton color="primary" />
        </PermissionTag>
    </TopToolbar>
);

const BulkActionButtons = (props: BulkActionProps) => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const DataGrid: FC<DataGridProps> = props => {
    return (
        <Datagrid {...props} optimized>
            <FunctionField
                label="#"
                render={record =>
                    ((props.page || 1) - 1) * (props.perPage || 0) +
                    Object.keys(props.data || {}).indexOf(record?.id as string) +
                    1
                }
            />
            <TextField source="description" />
            <ReferenceManyField source="gsvIds" reference="user" target="ids">
                <FunctionField
                    render={record =>
                        record && record.gsvIds && record.gsvIds.length > 0
                            ?
                            <SingleFieldList>
                                <ChipField
                                    size={'small'}
                                    color="primary"
                                    source="fullName"
                                />
                            </SingleFieldList>
                            :
                            <></>
                    }
                />
            </ReferenceManyField>
            <DateField source="fromDate" />
            <DateField source="toDate" />
            <TextField source="createBy" />
            <DateField source="createTime" />
            <FunctionField
                render={record =>
                    <Box style={{display: 'flex'}}>
                        <Tooltip title={"Sửa"} arrow>
                            <div>
                                <EditButton record={record} label={""}/>
                            </div>
                        </Tooltip>
                        <PermissionTag permissionCode={'distribution_ppc1'}>
                            <Tooltip title={"Sao chép"} arrow>
                                <div>
                                    <CloneButton record={record} label={""}/>
                                </div>
                            </Tooltip>
                        </PermissionTag>
                    </Box>
                }
            />
        </Datagrid>
    );
};

const DistributionList: FC<ListProps> = props => {
    const filters = [
        <TextInput label="Chú thích phân phối" source="description" alwaysOn />,
    ];
    return (
        <List
            {...props}
            actions={<Actions />}
            filters={filters}
            bulkActionButtons={<BulkActionButtons />}
            perPage={25}
            sort={{ field: 'updateTime', order: 'desc' }}
        >
            <DataGrid />
        </List>
    );
};

export default DistributionList;
