import * as React from 'react';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import {
    CreateButton,
    DeleteButton,
    EditButton,
    Filter,
    FilterProps,
    List,
    ListProps,
    SearchInput,
    TopToolbar,
    useListContext,
} from 'react-admin';
import {
    DataTypeProvider,
    GroupingState,
    IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableGroupRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import DialogCreate from '../../component/DialogCreate';
import DialogEdit from '../../component/DialogEdit';
import TuKhoaCreate from './TuKhoaCreate';

const GridFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const Actions = (props: any) => (
    <TopToolbar>
        <CreateButton color="primary" />
    </TopToolbar>
);

const GridData = props => {
    const { ids, data, basePath } = useListContext();
    const [flatData, setFlatData] = useState([]);
    const [columns] = useState([
        { name: 'textValue', title: 'Từ điển chuyên ngành' },
        { name: 'keywordGroupName', title: 'Nhóm Từ điển chuyên ngành' },
        { name: 'createBy', title: 'Người tạo' },
        { name: 'createTime', title: 'Thời gian tạo' },
        { name: 'actions', title: ' ' },
    ]);

    useEffect(() => {
        const flatData = ids.map(id => {
            const item = { ...data[id] };
            item.actions = item.id;
            return item;
        });
        // @ts-ignore
        setFlatData([...flatData]);
    }, [ids, data]);

    const ActionsFormatter = ({ value }) => (
        <div>
            <EditButton basePath={basePath} record={data[value]} />
            <DeleteButton
                mutationMode={'pessimistic'}
                basePath={basePath}
                record={data[value]}
            />
        </div>
    );

    const ActionsProvider = props => (
        <DataTypeProvider formatterComponent={ActionsFormatter} {...props} />
    );

    const [actionsColumns] = useState(['actions']);

    const CreateTimeFormatter = ({ value }) => (
        <span>{new Date(value).toLocaleString()}</span>
    );

    const CreateTimeProvider = props => (
        <DataTypeProvider formatterComponent={CreateTimeFormatter} {...props} />
    );

    const [createTimeColumns] = useState(['createTime']);

    return (
        <Grid rows={flatData} columns={columns}>
            <ActionsProvider for={actionsColumns} />
            <CreateTimeProvider for={createTimeColumns} />
            <GroupingState grouping={[{ columnName: 'keywordGroupName' }]} />
            <IntegratedGrouping />
            <Table />
            <TableHeaderRow />
            <TableGroupRow />
        </Grid>
    );
};

const TuKhoaList: FC<ListProps> = props => {
    return (
        <Fragment>
            <List
                empty={false}
                title="Từ điển chuyên ngành"
                {...props}
                actions={<Actions />}
                filters={<GridFilter />}
                sort={{ field: 'keywordGroupName', order: 'asc' }}
                perPage={100}
            >
                <GridData />
            </List>
        </Fragment>
    );
};

export default TuKhoaList;
