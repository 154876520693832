import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Card,
    CircularProgress,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { ChevronRight, CloudDownload } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import { Form } from 'react-final-form';
import AutoCompleteArray from '../component/AutoCompleteArray';
import { Button, Labeled, required, useGetList, useNotify } from 'react-admin';
import AutoComplete from '../component/AutoComplete';
import { BASE_URL } from '../common/constants';
import { httpClient } from '../dataProvider';
import { serialize } from '../dataProvider/utils';
import { read, writeFileXLSX, utils } from 'xlsx';
import TreeInput from '../danhMuc/phanAnh/TreeInput';
import TichMaTreeInput from '../danhMuc/tuKhoa/TichMaTreeInput';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            cursor: 'pointer',
        },
        font: {
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.54)',
            lineHeight: 3,
        },
    })
);

const BaoCaoGiamSatTuDong = (props: {
    reportTypeAuto?: any;
    parentPartners?: any;
    topicCode?: any;
    DTV?: any;
    classify?: any;
}) => {
    const { data: callCriteriaData } = useGetList(
        'callReflect',
        { perPage: 100000, page: 1 },
        { field: 'name', order: 'ASC' }
    );
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const handleToggle = () => setOpen(val => !val);
    const notify = useNotify();
    const [state, setState] = useState({
        fromDate: '',
        toDate: '',
        tichMaId: '',
    });
    const handleChange = (key, value) => {
        const newData = { ...state };
        newData[key] = value;
        setState(newData);
    };

    const fromDateISOString =
        state.fromDate && new Date(state.fromDate).getTime();
    const toDateISOString = state.toDate && new Date(state.toDate).getTime();

    const exportExcel = rows => {
        rows.sort((a, b) => a['parentName'] - b['parentName']);

        /* generate worksheet and workbook */
        const worksheet = utils.json_to_sheet(rows);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Dates');

        /* fix headers */
        utils.sheet_add_aoa(worksheet, [['Name', 'Type Name', 'Số lượng']], {
            origin: 'A1',
        });

        /* calculate column width */
        const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
        worksheet['!cols'] = [{ wch: max_width }];

        /* create an XLSX file and try to save to Presidents.xlsx */
        writeFileXLSX(workbook, 'Báo cáo tích mã.xlsx', { compression: true });
    };
    const exportBaoCao = () => {
        if (!fromDateISOString && !toDateISOString) {
            notify('Chưa nhập Từ ngày và Tới ngày', {
                type: 'warning',
            });
            return false;
        }
        if (fromDateISOString > toDateISOString) {
            notify('Mục Đến ngày phải lớn hơn hoặc bằng mục Từ ngày', {
                type: 'warning',
            });
            return false;
        }
        setLoading(true);
        const filter = {
            'starttime.greaterThanOrEqual': fromDateISOString,
            'starttime.lessThanOrEqual': toDateISOString,
            'tichMaId.specified': true,
            'tichMaId.equals': state.tichMaId,
            groupBy: 'tichMaId',
        };
        const url = `${BASE_URL}/dashboard/callStatistics`;
        httpClient(
            `${url}?${serialize({
                name: 'trungBinhTheoTuan',
                ...filter,
            })}`,
            {}
        )
            .then(response => {
                setLoading(false);
                const rows = response.json.map(({ x, y }) => {
                    return {
                        name: callCriteriaData[x].name,
                        parentName:
                            callCriteriaData[callCriteriaData[x].parentId].name,
                        count: y,
                    };
                });
                rows.push({
                    name: '',
                    parentName: '',
                    count: rows.reduce((pre, cur) => pre + cur.count, 0),
                });
                console.log(rows);
                exportExcel(rows);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <Form
            initialValues={{ reportTypeAuto: 'AGGREGATION' }}
            onSubmit={() => {}}
        >
            {({ handleSubmit, values }) => (
                <>
                    <Card
                        variant={'outlined'}
                        elevation={0}
                        className={classes.root}
                    >
                        <Box p={2}>
                            <Box
                                onClick={handleToggle}
                                display={'flex'}
                                alignItems={'center'}
                                className={classes.heading}
                                justifyContent={'space-between'}
                            >
                                <Typography>Báo cáo tích mã</Typography>
                                {open ? <ExpandMore /> : <ChevronRight />}
                            </Box>
                            <Collapse in={open} timeout={100} unmountOnExit>
                                <Box width={'100%'}>
                                    <Box mt={1}>
                                        <AutoComplete
                                            fullWidth
                                            source="reportTypeAuto"
                                            label={'Loại báo cáo'}
                                            choices={props.reportTypeAuto}
                                            getOptionLabel={option =>
                                                `${option?.name}`
                                            }
                                            validate={[required()]}
                                        />
                                    </Box>
                                    <Box
                                        className="my_ant_tree_select single_choice"
                                        mt={1}
                                    >
                                        <Labeled label={'Chủ đề tích mã'}>
                                            <TichMaTreeInput
                                                onChange={value => {
                                                    setState(prevState => ({
                                                        ...prevState,
                                                        tichMaId: value,
                                                    }));
                                                }}
                                                selectChild
                                                source={'tichMaId'}
                                                treeCheckable={false}
                                                placeholder={
                                                    'Tìm kiếm chủ đề tích mã'
                                                }
                                            />
                                        </Labeled>
                                    </Box>

                                    <Box
                                        width={'100%'}
                                        display={'flex'}
                                        alignItems={'center'}
                                    >
                                        <Box flex={1} mr={1}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="filled"
                                                type="date"
                                                fullWidth
                                                value={state.fromDate}
                                                onChange={event =>
                                                    handleChange(
                                                        'fromDate',
                                                        event.target.value
                                                    )
                                                }
                                                margin={'dense'}
                                                label="Từ ngày *"
                                            />
                                        </Box>
                                        <Box flex={1} ml={1}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="filled"
                                                type="date"
                                                fullWidth
                                                value={state.toDate}
                                                onChange={event =>
                                                    handleChange(
                                                        'toDate',
                                                        event.target.value
                                                    )
                                                }
                                                margin={'dense'}
                                                label="Đến ngày *"
                                            />
                                        </Box>
                                    </Box>

                                    <Box
                                        width={'100%'}
                                        mt={1}
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disabled={loading}
                                            label="Xuất báo cáo"
                                            disableElevation
                                            onClick={exportBaoCao}
                                            startIcon={
                                                loading ? (
                                                    <CircularProgress
                                                        size={16}
                                                    />
                                                ) : (
                                                    <CloudDownload />
                                                )
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Collapse>
                        </Box>
                    </Card>
                </>
            )}
        </Form>
    );
};

export default BaoCaoGiamSatTuDong;
