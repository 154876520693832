import * as React from 'react';
import { FC } from 'react';
import { ReferenceInput, required, SelectInput, TextInput } from 'react-admin';

const CategoryCreate: FC = () => {
    return (
        <form>
            <div>
                <TextInput
                    validate={requiredValidate}
                    source="name"
                    resource="category"
                    fullWidth
                />
            </div>
            <div>
                <TextInput source="value" resource="category" fullWidth />
            </div>
            <div>
                <TextInput source="code" resource="category" fullWidth />
            </div>
            <div>
                <ReferenceInput
                    source="groupId"
                    resource="category"
                    reference="group-category"
                >
                    <SelectInput fullWidth optionText="name" />
                </ReferenceInput>
            </div>
            <div>
                <TextInput
                    multiline
                    source="description"
                    row={4}
                    resource="category"
                    fullWidth
                />
            </div>
        </form>
    );
};

const requiredValidate = [required()];

export default CategoryCreate;
