import * as React from 'react';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    Record,
    useDataProvider,
    useVersion,
} from 'react-admin';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1,
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const Aside: FC = () => {
    const version = useVersion();
    const dataProvider = useDataProvider();
    const [group, setGroup] = useState<Record[]>([]);

    const fetchGroup = useCallback(async () => {
        const res = await dataProvider.getList('group-category', {
            filter: {},
            sort: { field: 'name', order: 'ASC' },
            pagination: { page: 1, perPage: 50 },
        });
        if (res) setGroup(res.data);
    }, [dataProvider]);

    useEffect(() => {
        fetchGroup();
    }, [version]);

    return (
        <Card>
            <CardContent>
                <FilterLiveSearch />

                <FilterList
                    label="resources.category.filters.group"
                    icon={<LocalOfferIcon />}
                >
                    {group &&
                        group.map(item => (
                            <FilterListItem
                                label={item.name}
                                key={item.id}
                                value={{ groupId: item.id }}
                            />
                        ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default memo(Aside);
