import { MemberType, TimeFilterValueType } from './Dashboard';
import { useNotify } from 'react-admin';
import React, { useCallback, useEffect, useState } from 'react';
import { memberType } from './MemberTree';
import { BASE_URL } from '../common/constants';
import { httpClient } from '../dataProvider';
import { serialize } from '../dataProvider/utils';
import { Box, Tooltip, Typography } from '@mui/material';
import TimeFilter from './TimeFilter';
import AreaChart from './AreaChart';
import LinearProgress from '@mui/material/LinearProgress';

type CallAverageTimeByWeekStatisticsProps = {
    member: MemberType | undefined;
};

const CallAverageTimeByWeekStatistics = ({
    member,
}: CallAverageTimeByWeekStatisticsProps) => {
    const notify = useNotify();

    const [averageTimeByWeekFilter, setAverageTimeByWeekFilter] = useState<
        TimeFilterValueType
    >();

    const [
        averageTimeByWeekStatistics,
        setAverageTimeByWeekStatistics,
    ] = useState<{
        data: { name?: string; data: number[] }[];
        categories: string[];
        colors: string[];
    }>({
        data: [],
        categories: [],
        colors: [],
    });

    const [
        fetchAverageTimeByWeekStatisticsLoading,
        setFetchAverageTimeByWeekStatisticsLoading,
    ] = useState<boolean>(false);

    const fetchAverageTimeByWeekStatistics = useCallback(async () => {
        if (averageTimeByWeekFilter && member) {
            const filter = {
                'starttime.greaterThanOrEqual': averageTimeByWeekFilter.fromDate.getTime(),
                'starttime.lessThanOrEqual': averageTimeByWeekFilter.toDate.getTime(),
                groupBy: 'starttime',
                calendarInterval: 'day',
                appliedOperatorField: 'duration',
                operator: 'AVG',
            };
            setFetchAverageTimeByWeekStatisticsLoading(true);
            const url = `${BASE_URL}/dashboard/callStatistics`;
            let avgDuration;
            const errors: any[] = [];
            const promises: Promise<any>[] = [
                httpClient(
                    `${url}?${serialize({
                        name: 'trungBinhTheoTuan',
                        ...filter,
                    })}`,
                    {}
                ).then(response => {
                    return response.json;
                }),
            ];
            await Promise.all(
                promises.map(p => p.catch(err => errors.push(err)))
            ).then(values => {
                [avgDuration] = values;
            });

            if (errors.length > 0) {
                notify(
                    'Thời gian TB cuộc gọi theo tuần: Có lỗi khi load dữ liệu',
                    'warning'
                );
            } else {
                const byWeekCategoriesTmp: string[] = [];
                const byWeekColorsTmp: string[] = ['#3FBF00'];
                const byWeekAverageTimeTmp: number[] = [];
                const fromDateClone = new Date(
                    averageTimeByWeekFilter.fromDate
                );
                const toDateClone = new Date(averageTimeByWeekFilter.toDate);
                for (
                    let d = fromDateClone;
                    d <= toDateClone;
                    d.setDate(d.getDate() + 1)
                ) {
                    byWeekCategoriesTmp.push(
                        d.getDate() + '/' + (d.getMonth() + 1)
                    );
                    const avg =
                        Array.isArray(avgDuration) &&
                        avgDuration.find(
                            p =>
                                p.x &&
                                new Date(p.x).getDate() === d.getDate() &&
                                new Date(p.x).getMonth() === d.getMonth() &&
                                new Date(p.x).getFullYear() === d.getFullYear()
                        )?.y;
                    byWeekAverageTimeTmp.push(
                        avg && avg !== Infinity && avg !== 'Infinity'
                            ? Number((avg * 0.001).toFixed(2))
                            : 0
                    );
                }
                setAverageTimeByWeekStatistics({
                    data: [
                        {
                            name: 'Thời gian trung bình',
                            data: byWeekAverageTimeTmp,
                        },
                    ],
                    colors: byWeekColorsTmp,
                    categories: byWeekCategoriesTmp,
                });
            }
            setFetchAverageTimeByWeekStatisticsLoading(false);
        }
    }, [
        averageTimeByWeekFilter?.type,
        averageTimeByWeekFilter?.toDate,
        averageTimeByWeekFilter?.fromDate,
        member?.type,
        member?.memberId,
    ]);

    useEffect(() => {
        fetchAverageTimeByWeekStatistics();
    }, [fetchAverageTimeByWeekStatistics]);

    return (
        <>
            <Box>
                <Typography>
                    <Box>Thời gian TB cuộc gọi theo tuần</Box>
                </Typography>
                <Tooltip title="Thời gian bắt đầu cuộc gọi" placement="top">
                    <div>
                        <TimeFilter
                            type={'week'}
                            onchange={(values: {
                                type: string;
                                fromDate: Date;
                                toDate: Date;
                            }) => {
                                setAverageTimeByWeekFilter({ ...values });
                            }}
                        />
                    </div>
                </Tooltip>
            </Box>
            {!fetchAverageTimeByWeekStatisticsLoading ? (
                <AreaChart
                    data={averageTimeByWeekStatistics.data}
                    categories={averageTimeByWeekStatistics.categories}
                    colors={averageTimeByWeekStatistics.colors}
                />
            ) : (
                <LinearProgress style={{ marginTop: '5px' }} />
            )}
        </>
    );
};

export default CallAverageTimeByWeekStatistics;
