import * as React from 'react';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    CreateButton,
    DeleteButton,
    EditButton,
    Filter,
    FilterProps,
    List,
    ListProps,
    SearchInput,
    TopToolbar,
    useGetIdentity,
    useListContext,
} from 'react-admin';
import {
    CustomTreeData,
    DataTypeProvider,
    TreeDataState,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { useHistory } from 'react-router-dom';
import IsActiveField from '../IsActiveField';
import ImportButton from './ImportButton';

const GridFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="code" alwaysOn />
    </Filter>
);

const Actions = (props: any) => (
    <TopToolbar>
        <ImportButton />
        <CreateButton color="primary" />
    </TopToolbar>
);

const GridData = props => {
    const { ids, data, basePath } = useListContext();
    const [defaultExpandedRowIds] = useState(Array.from(Array(1), (_, i) => i));
    const [columns] = useState([
        { name: 'name', title: 'Tên Chủ đề tích mã' },
        { name: 'isActive', title: 'Kích hoạt' },
        { name: 'actions', title: ' ' },
    ]);

    const getChildRows = (row, rootRows) => {
        const childRows = rootRows.filter(
            r => r.parentId === (row ? row.id : null)
        );
        return childRows.length ? childRows : null;
    };

    const [tableColumnExtensions] = useState([
        { columnName: 'name', width: 700 },
        { columnName: 'isActive', align: 'center' },
    ]);

    const { filterValues } = useListContext();

    const treeData = useMemo(() => {
        if (!filterValues.code) {
            console.log('!filterValues.code');
            const flatData = ids.map(id => {
                const item = { ...data[id] };
                item.parentId = data[id].parentId ? data[id].parentId : null;
                item.chidlren = null;
                item.actions = item.id;
                return item;
            });
            return flatData;
        } else {
            console.log('else filterValues.code');
            const flatData = ids.map(id => {
                const item = { ...data[id] };
                item.parentId = null;
                item.chidlren = null;
                item.actions = item.id;
                return item;
            });
            return flatData;
        }
    }, [ids, filterValues.code]);

    const ActionsFormatter = ({ value }) => (
        <div>
            <EditButton basePath={basePath} record={data[value]} />
            <DeleteButton
                mutationMode={'pessimistic'}
                basePath={basePath}
                record={data[value]}
            />
        </div>
    );

    const ActionsProvider = props => (
        <DataTypeProvider formatterComponent={ActionsFormatter} {...props} />
    );

    const [actionsColumns] = useState(['actions']);

    const { identity } = useGetIdentity();
    const ActiveFormatter = ({ row }) => (
        <IsActiveField
            record={data[row.id]}
            basePath={basePath}
            identity={identity}
        />
    );

    const ActiveProvider = props => (
        <DataTypeProvider formatterComponent={ActiveFormatter} {...props} />
    );

    const [activeColumns] = useState(['isActive']);

    return (
        <Grid rows={treeData} columns={columns}>
            <ActionsProvider for={actionsColumns} />
            <ActiveProvider for={activeColumns} />
            <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
            <CustomTreeData getChildRows={getChildRows} />
            {/*// @ts-ignore*/}
            <Table columnExtensions={tableColumnExtensions} />
            <TableHeaderRow />
            <TableTreeColumn for="name" />
        </Grid>
    );
};

const PhanAnhList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            title="Danh mục chủ đề cuộc gọi"
            pagination={false}
            sort={{ field: 'name', order: 'asc' }}
            actions={<Actions />}
            filters={<GridFilter />}
            perPage={10000}
        >
            <GridData />
        </List>
    );
};

export default PhanAnhList;
