import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    EditProps,
    FormTab,
    FunctionField,
    Labeled,
    Pagination,
    ReferenceManyField,
    ShowButton,
    SimpleForm,
    TabbedForm,
    TextField,
    TopToolbar,
    useDataProvider,
    useGetIdentity,
    useGetList,
    useRecordContext,
} from 'react-admin';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Checkbox,
    Collapse,
    Drawer,
    Link,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RatingFormOne from './RatingFormOne';
import { convertToTree } from '../utils';
import Chat from './Chat';
import Chip from '@material-ui/core/Chip';
import { Alert } from '@material-ui/lab';
import StatusField from '../complain/StatusField';
import { Call, Category } from '../types';
import { BASE_URL } from '../common/constants';
import BackButton from '../component/BackButton';
import WaveForm from './WaveForm';
import TabPanel from '../component/TabPanel';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReviewHistory from './ReviewHistoty';
import { Stack } from '@mui/material';
import TichMaField from './TichMaField';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(6),
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 500,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    drawerPaper: {
        zIndex: 100,
        paddingTop: theme.spacing(6),
    },
    tab: {
        maxWidth: '40em',
        display: 'block',
    },
    main: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        padding: 4,
    },
    actionsBar: {
        justifyContent: 'flex-start',
    },
}));

export interface State {
    chuDes: Category[];
    cuocGoiDienHinhs: Category[];
    mucDoHaiLongs: Category[];
    callReflects: Category[];
    formEvaluate: Category[];
    telephonistCriteria: Category[];
    telephonistClass: Category[];
}

const TopBarActions = props => {
    const classes = useStyles();
    return (
        <TopToolbar className={classes.actionsBar}>
            <BackButton />
        </TopToolbar>
    );
};

const CallForm = (props: any) => {
    const classes = useStyles();
    const { identity } = useGetIdentity();
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [criterias, setCriterias] = useState<any[]>([]);
    const [state, setState] = useState<State>({
        telephonistClass: [],
        telephonistCriteria: [],
        chuDes: [],
        cuocGoiDienHinhs: [],
        mucDoHaiLongs: [],
        callReflects: [],
        formEvaluate: [],
    });
    const [holdCalls, setHoldCalls] = useState<any[]>([]);
    const loadCategory = useCallback(async () => {
        const { data: cuocGoiDienHinhs } = await dataProvider.getList<Category>(
            'category/getByGroupCode',
            {
                filter: { groupCode: 'CGDH' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        const { data: callReflects } = await dataProvider.getList<Category>(
            'callReflect',
            {
                filter: { isActive: true },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        const { data: telephonistClass } = await dataProvider.getList<Category>(
            'telephonist-classification',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        const { data: telephonistCriteria } = await dataProvider.getList<
            Category
        >('telephonistCriteria', {
            filter: { isActive: true },
            sort: { field: 'name', order: 'ASC' },
            pagination: { page: 1, perPage: 100 },
        });

        const { data: formEvaluate } = await dataProvider.getList<Category>(
            'form-evaluate',
            {
                filter: { isActive: true },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        const { data: mucDoHaiLongs } = await dataProvider.getList<Category>(
            'category/getByGroupCode',
            {
                filter: { groupCode: 'MDHL' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        const { data: chuDes } = await dataProvider.getList<Category>(
            'category/getByGroupCode',
            {
                filter: { groupCode: 'CALL_TOPIC' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        setState(state => ({
            ...state,
            cuocGoiDienHinhs,
            mucDoHaiLongs,
            chuDes,
            callReflects: callReflects.filter(getActive),
            formEvaluate: formEvaluate.filter(getActive),
            telephonistClass: telephonistClass.filter(getActive),
            telephonistCriteria: telephonistCriteria.filter(getActive),
        }));
    }, [dataProvider]);

    // useEffect(() => {
    //     loadCategory();
    // }, [loadCategory]);

    const getActive = item => {
        if (identity) {
            if (item.deptId === 'TCT' || item.deptId === identity.deptId) {
                return item.isActive;
            } else {
                return item.deptIdsActive
                    ? item.deptIdsActive.includes(identity.deptId)
                    : false;
            }
        }
        return true;
    };

    const loadTieuChi = useCallback(async () => {
        const { data: tieuChiData } = await dataProvider.getList<any>(
            'criteriaCall/monitoring',
            {
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
                filter: { callId: record && record.callId },
            }
        );
        setCriterias(convertToTree(tieuChiData.filter(getActive)));
    }, [dataProvider, record]);

    // useEffect(() => {
    //     loadTieuChi();
    // }, [loadTieuChi, record]);

    const disabledRating1 = () =>
        !!record.callingRating2 ||
        (record.callingRating1 &&
            identity?.fullName != record.callingRating1.supervisorName);

    const getSrc = inum => {
        return `${BASE_URL}/file/downloadCallAudio/${inum}`;
    };

    const loadHoldCall = useCallback(async () => {
        const { data: holdCallData } = await dataProvider.getList<any>(
            'call/findAllByCallIdAndHoldCall',
            {
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
                filter: { callId: record && record.callId },
            }
        );
        setHoldCalls(
            holdCallData.sort((a, b) => a['starttime'] - b['starttime'])
        );
    }, [props.record.callId]);

    useEffect(() => {
        loadHoldCall();
    }, [loadHoldCall, props.record.callId]);

    // console.log(holdCalls)
    return (
        <Paper elevation={1} className={classes.main}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {`Cuộc gọi số ${record.callId}`}
                </Typography>
            </div>
            <Stack direction={'row'}>
                <Box flex={1}>
                    <SimpleForm
                        className={classes.form}
                        {...props}
                        toolbar={null}
                    >
                        <TextField
                            source="inum"
                            formClassName={classes.inlineField}
                        />
                        <TextField
                            source="agentId"
                            formClassName={classes.inlineField}
                        />
                        <TextField
                            source="agentDesc"
                            formClassName={classes.inlineField}
                        />
                        <FunctionField
                            source="phoneNumber"
                            formClassName={classes.inlineField}
                            render={record => (
                                <Link
                                    href={`tel:+84${record?.phoneNumber}`}
                                >{`+84${record?.phoneNumber}`}</Link>
                            )}
                        />
                        <TextField
                            source="hotlineRoomDesc"
                            formClassName={classes.inlineField}
                        />
                        <FunctionField
                            source="incoming"
                            render={record => {
                                if (record?.incoming) {
                                    return (
                                        <label
                                            style={{
                                                color: '#1769aa',
                                                fontWeight: 700,
                                            }}
                                        >
                                            Inbound
                                        </label>
                                    );
                                } else {
                                    return (
                                        <label
                                            style={{
                                                color: 'red',
                                                fontWeight: 700,
                                            }}
                                        >
                                            Outbound
                                        </label>
                                    );
                                }
                            }}
                        />
                        <TextField
                            source="partnerId"
                            formClassName={classes.inlineField}
                        />

                        <DateField
                            source="starttime"
                            showTime
                            formClassName={classes.inlineField}
                        />
                        <Labeled label={'Chủ đề tích mã'}>
                            <TichMaField source="tichMaId" />
                        </Labeled>
                    </SimpleForm>
                </Box>
                <Box flex={1}>
                    {holdCalls.map(call => {
                        // console.log(3333, call.inum)
                        return (
                            <>
                                <WaveForm url={getSrc(call.inum)} call={call} />
                                {call.contents && (
                                    <Chat contents={call.contents} />
                                )}
                            </>
                        );
                    })}
                </Box>
            </Stack>
        </Paper>
    );
};

const CallEdit: FC<EditProps> = props => {
    return (
        <Edit
            title={'Chi tiết cuộc gọi'}
            component="div"
            {...props}
            actions={<TopBarActions />}
        >
            <CallForm />
        </Edit>
    );
};
export default CallEdit;
