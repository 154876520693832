import * as React from 'react';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import {
    Box,
    Card as MuiCard,
    Drawer,
    IconButton,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Form } from 'react-final-form';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    Identifier,
    Labeled,
    useDataProvider,
    useListContext,
    useVersion,
} from 'react-admin';
import {
    endOfYesterday,
    startOfMonth,
    startOfWeek,
    subMonths,
    subWeeks,
} from 'date-fns';
import {
    Call,
    HowToRegTwoTone,
    Menu,
    SupervisorAccount,
    Timelapse,
    TimerRounded,
} from '@material-ui/icons';
import {
    Category,
    Department,
    Distribution,
    Filter,
    GSV,
    TelephonistGroup,
} from '../types';
import SavedQueryList from './SavedQueryList';
import DateLiveSearch from '../component/DateLiveSearch';
import TextInputSearch from '../component/TextInputSearch';
import NumberInputSearch from '../component/NumberInputSearch';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CloseIcon from '@material-ui/icons/Close';
import DateTimeLiveSearch from '../component/DateTimeLiveSearch';
import TichMaTreeInput from '../danhMuc/tuKhoa/TichMaTreeInput';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    drawerPaper: {
        zIndex: 100,
        width: 500,
    },
    title: {
        marginTop: '1em',
        marginBottom: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

interface State {
    chuDes: Category[];
    cuocGoiDienHinhs: Category[];
    mucDoHaiLongs: Category[];
    keywordGroups: Category[];
    partners: Department[];
    telephonistGroups: TelephonistGroup[];
    filters: Filter[];
    department: Department[];
    gsv: GSV[];
    distributions: Distribution[];
}

// @ts-ignore
const Aside: FC = ({ onClose, open, ...props }) => {
    const classes = useStyles();
    const {
        page,
        setPage,
        perPage,
        filterValues,
        setFilters,
        displayedFilters,
    } = useListContext();
    const [state, setState] = useState<State>({
        chuDes: [],
        cuocGoiDienHinhs: [],
        keywordGroups: [],
        mucDoHaiLongs: [],
        partners: [],
        telephonistGroups: [],
        filters: [],
        department: [],
        gsv: [],
        distributions: [],
    });
    const version = useVersion();
    const dataProvider = useDataProvider();

    const fetchOrders = useCallback(async () => {
        const { data: mucDoHaiLongs } = await dataProvider.getList<Category>(
            'category',
            {
                filter: { groupCategoryCode: 'MDHL' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: chuDes } = await dataProvider.getList<Category>(
            'category',
            {
                filter: { groupCategoryCode: 'CALL_TOPIC' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: keywordGroups } = await dataProvider.getList<Category>(
            'keywordGroup',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: filters } = await dataProvider.getList<Filter>(
            'filters',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: telephonistGroups } = await dataProvider.getList<
            TelephonistGroup
        >('telephonistGroup', {
            filter: {},
            sort: { field: 'name', order: 'ASC' },
            pagination: { page: 1, perPage: 50 },
        });
        const { data: department } = await dataProvider.getList<Department>(
            'department',
            {
                filter: {},
                sort: { field: 'updateTime', order: 'DESC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: gsv } = await dataProvider.getList<GSV>('user', {
            filter: { userType: 2 },
            sort: { field: 'fullName', order: 'ASC' },
            pagination: { page: 1, perPage: 1000 },
        });

        setState(state => ({
            ...state,
            mucDoHaiLongs,
            chuDes,
            keywordGroups,
            filters,
            telephonistGroups,
            department,
            gsv,
        }));
    }, [dataProvider]);

    console.log(filterValues);

    useEffect(() => {
        fetchOrders();
    }, [version]);

    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            let newFilters = { ...filterValues, ...values };
            setFilters(newFilters, displayedFilters);
        }
    };

    useEffect(() => {
        if (
            filterValues?.doiTac !== null &&
            filterValues?.doiTac !== undefined &&
            filterValues?.doiTac !== '' &&
            state.gsv
        ) {
            const items: Array<{
                id: string | null | undefined | Identifier;
                fullName: string | null | undefined;
                partnerName: string | null | undefined;
            }> = Object.values(state.gsv)
                ? Object.values(state.gsv)
                      .filter(u => u.partnerId === filterValues?.doiTac)
                      .map(u => {
                          return {
                              fullName: u.fullName,
                              id: u.id,
                              partnerName: u.partnerName,
                          };
                      })
                : [];
        }
    }, [filterValues?.doiTac, state.gsv]);

    // @ts-ignore
    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor="right"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <PerfectScrollbar className={classes.root}>
                <div className={classes.title}>
                    <Typography variant="h6">Tìm kiếm nâng cao</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <FilterLiveSearch />

                <Form onSubmit={onSubmit} initialValues={filterValues}>
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <SavedQueryList />
                            <FilterList
                                label="resources.call.filters.start_time"
                                icon={<AccessTimeIcon />}
                            >
                                <FilterListItem
                                    label="resources.call.filters.today"
                                    value={{
                                        fromDate: endOfYesterday().getTime(),
                                        toDate: undefined,
                                    }}
                                />
                                <FilterListItem
                                    label="resources.call.filters.this_week"
                                    value={{
                                        toDate: undefined,
                                        fromDate: startOfWeek(
                                            new Date()
                                        ).getTime(),
                                    }}
                                />
                                <FilterListItem
                                    label="resources.call.filters.last_week"
                                    value={{
                                        fromDate: subWeeks(
                                            startOfWeek(new Date()),
                                            1
                                        ).getTime(),
                                        toDate: startOfWeek(
                                            new Date()
                                        ).getTime(),
                                    }}
                                />
                                <FilterListItem
                                    label="resources.call.filters.this_month"
                                    value={{
                                        fromDate: startOfMonth(
                                            new Date()
                                        ).getTime(),
                                        toDate: undefined,
                                    }}
                                />
                                <FilterListItem
                                    label="resources.call.filters.last_month"
                                    value={{
                                        fromDate: subMonths(
                                            startOfMonth(new Date()),
                                            1
                                        ).getTime(),
                                        toDate: startOfMonth(
                                            new Date()
                                        ).getTime(),
                                    }}
                                />
                                <Box
                                    px={'2em'}
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Box flex={1}>
                                        <DateTimeLiveSearch
                                            source="fromDate"
                                            label={'Từ ngày'}
                                        />
                                    </Box>
                                    <Box flex={1}>
                                        <DateTimeLiveSearch
                                            source="toDate"
                                            label={'Tời ngày'}
                                        />
                                    </Box>
                                </Box>
                            </FilterList>

                            <FilterList
                                label={'resources.call.fields.callDirection'}
                                icon={<Call />}
                            >
                                <FilterListItem
                                    label={'Inbound'}
                                    value={{
                                        inComing: true,
                                    }}
                                />
                                <FilterListItem
                                    label={'Outbound'}
                                    value={{
                                        inComing: false,
                                    }}
                                />
                            </FilterList>

                            <FilterList
                                label="resources.call.filters.duration"
                                icon={<Timelapse />}
                            >
                                <Box display="flex">
                                    <Box pl={'2em'}>
                                        <NumberInputSearch
                                            source={'fromDuration'}
                                            label="Từ"
                                        />
                                    </Box>
                                    <Box ml={1}>
                                        <NumberInputSearch
                                            source={'toDuration'}
                                            label="Đến"
                                        />
                                    </Box>
                                </Box>
                            </FilterList>
                            <FilterList label="Chủ đề tích mã" icon={<Menu />}>
                                <Box
                                    className="my_ant_tree_select single_choice"
                                    style={{
                                        paddingLeft: '26px',
                                        paddingRight: '20px',
                                    }}
                                >
                                    <Labeled label={'Chủ đề tích mã'}>
                                        <TichMaTreeInput
                                            onChange={val => {
                                                onSubmit({
                                                    tichMaId: val,
                                                });
                                            }}
                                            selectChild
                                            source={'tichMaId'}
                                            treeCheckable={false}
                                            placeholder={
                                                'Tìm kiếm chủ đề tích mã'
                                            }
                                        />
                                    </Labeled>
                                </Box>
                            </FilterList>
                            <FilterList label="Tiêu chí khác" icon={<Menu />}>
                                <Box pl={'2em'} pr={2}>
                                    <TextInputSearch
                                        source="danhso"
                                        label={'Danh số'}
                                    />
                                    <TextInputSearch
                                        source="content"
                                        label={'Nội dung'}
                                    />
                                    <TextInputSearch
                                        source="note"
                                        label={'Nhận xét chung'}
                                    />
                                    <TextField
                                        select
                                        margin={'dense'}
                                        variant="filled"
                                        fullWidth
                                        label="Đơn vị"
                                        onChange={({ target }) =>
                                            onSubmit({
                                                donVi: target.value,
                                            })
                                        }
                                        value={filterValues?.donVi}
                                    >
                                        <MenuItem value="">
                                            <em>Tất cả</em>
                                        </MenuItem>
                                        {state.department.map(item => (
                                            <MenuItem value={item.id}>
                                                {item.deptName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        select
                                        margin={'dense'}
                                        variant="filled"
                                        fullWidth
                                        label="Nhóm người dùng"
                                        onChange={({ target }) =>
                                            onSubmit({
                                                toNhom: target.value,
                                            })
                                        }
                                        value={filterValues?.toNhom}
                                    >
                                        <MenuItem value="">
                                            <em>Tất cả</em>
                                        </MenuItem>
                                        {state.telephonistGroups.map(item => (
                                            <MenuItem value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        select
                                        margin={'dense'}
                                        variant="filled"
                                        fullWidth
                                        label="Chủ đề"
                                        onChange={({ target }) =>
                                            onSubmit({
                                                topic: target.value,
                                            })
                                        }
                                        value={filterValues?.topic}
                                    >
                                        <MenuItem value="">
                                            <em>Tất cả</em>
                                        </MenuItem>
                                        {state.chuDes.map(item => (
                                            <MenuItem value={item.code}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        variant="filled"
                                        fullWidth
                                        select
                                        margin={'dense'}
                                        label="Nhóm từ khóa"
                                        onChange={({ target }) =>
                                            onSubmit({
                                                keywordGroupId: target.value,
                                            })
                                        }
                                        value={filterValues?.keywordGroupId}
                                    >
                                        <MenuItem value="">
                                            <em>Tất cả</em>
                                        </MenuItem>
                                        {state.keywordGroups.map(item => (
                                            <MenuItem value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            </FilterList>
                        </form>
                    )}
                </Form>
            </PerfectScrollbar>
        </Drawer>
    );
};

export default memo(Aside);
