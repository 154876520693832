import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import {
    Create,
    CreateProps,
    DataProviderProxy,
    fetchUtils,
    FileField,
    FileInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useDataProvider,
    useInput,
    useListContext,
    useNotify,
    useRecordContext,
    useRedirect,
    useTranslate,
    useVersion,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Typography } from '@material-ui/core';
import { DatePicker, Form } from 'antd';
import {
    Category,
    Customer,
    Department,
    Filter,
    TelephonistGroup,
} from '../types';
import CommonEditToolbar from '../component/CommonEditToolbar';
import './styles.css';
import { BASE_URL } from '../common/constants';
import { buildHeader } from '../utils/ultils';
import { useForm } from 'react-final-form';

export const styles = {
    field: {
        width: '100%',
    },
    callId: {
        width: '50%',
    },
    textInput: {
        width: '100%',
    },
    fileField: {
        borderColor: '#000000',
    },
};

const useStyles = makeStyles(styles);

interface State {
    chuDes: Category[];
    cuocGoiDienHinhs: Category[];
    mucDoHaiLongs: Category[];
    keywordGroups: Category[];
    partners: Department[];
    telephonistGroups: TelephonistGroup[];
    filters: Filter[];
    department: Department[];
}
// export const getUser = (
//     id: string | null | undefined,
//     dataProvider: DataProviderProxy
// ): Promise<string | null | undefined> => {
//     return id
//         ? dataProvider
//             .getOne('user', { id: 'a5b726a4-2e0d-466e-a473-15bc4f4e5a58' })
//             .then(value => value.data?.fullName)
//         : Promise.resolve(null);
// };

const CallCreate: FC<CreateProps> = (props: any) => {
    const dataProvider = useDataProvider();
    const version = useVersion();
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    // const form = useForm<Customer>();

    // const agentChanged = event => {
    //     // form.change('agentDesc', event);
    //     console.log(event.target.value , 'event')
    //     getUser(event, dataProvider).then(fullName => {
    //         console.log(fullName, 'fullName');
    //     });
    // }

    const fetchOrders = useCallback(async () => {
        const { data: cuocGoiDienHinhs } = await dataProvider.getList<Category>(
            'category',
            {
                filter: { groupCategoryCode: 'CGDH' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: mucDoHaiLongs } = await dataProvider.getList<Category>(
            'category',
            {
                filter: { groupCategoryCode: 'MDHL' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: chuDes } = await dataProvider.getList<Category>(
            'category',
            {
                filter: { groupCategoryCode: 'CALL_TOPIC' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: keywordGroups } = await dataProvider.getList<Category>(
            'keywordGroup',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: partners } = await dataProvider.getList<Department>(
            'partner',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: filters } = await dataProvider.getList<Filter>(
            'filters',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const { data: telephonistGroups } = await dataProvider.getList<
            TelephonistGroup
        >('telephonistGroup', {
            filter: {},
            sort: { field: 'name', order: 'ASC' },
            pagination: { page: 1, perPage: 50 },
        });
        const { data: department } = await dataProvider.getList<Department>(
            'department',
            {
                filter: {},
                sort: { field: 'updateTime', order: 'DESC' },
                pagination: { page: 1, perPage: 50 },
            }
        );

        setState(state => ({
            ...state,
            cuocGoiDienHinhs,
            mucDoHaiLongs,
            chuDes,
            keywordGroups,
            partners,
            filters,
            telephonistGroups,
            department,
        }));
    }, [dataProvider]);

    useEffect(() => {
        fetchOrders();
    }, [version]);
    const [state, setState] = useState<State>({
        chuDes: [],
        cuocGoiDienHinhs: [],
        keywordGroups: [],
        mucDoHaiLongs: [],
        partners: [],
        telephonistGroups: [],
        filters: [],
        department: [],
    });

    const BoundedTextField = props => {
        const {
            input: { name, onChange, ...rest },
        } = useInput(props);
        return (
            <DatePicker
                placeholder={'Thời gian bắt đầu'}
                format="HH:mm:ss DD/MM/YYYY"
                style={{
                    backgroundColor: '#F6F6F6',
                    borderTopColor: '#F6F6F6',
                    borderLeftColor: '#F6F6F6',
                    borderRightColor: '#F6F6F6',
                    width: '100%',
                    height: 48,
                    marginTop: 8,
                    borderBottomColor: '#000000',
                    fontSize: 20,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                }}
            />
        );
    };
    const handleSubmit = values => {
        console.log(values.file);
        const data = new FormData();
        data.append('file', values.file.rawFile);
        delete values['file'];
        data.append(
            'form',
            JSON.stringify({
                ...values,
                startTime: values.startTime.toDate().getTime(),
            })
        );
        fetchUtils
            .fetchJson(`${BASE_URL}/call/`, {
                method: 'POST',
                headers: buildHeader(),
                body: data,
            })
            .then(r => {
                notify('Thêm cuộc gọi thành công', 'success');
                redirect('/call');
            })
            .catch(({ body }) => {
                console.log(body);
            });
    };

    // @ts-ignore
    return (
        <Create
            {...props}
            title="Thêm mới cuộc gọi"
            actions={<CommonEditToolbar />}
        >
            <SimpleForm save={handleSubmit}>
                <Typography variant="h6" gutterBottom>
                    {translate('resources.call.fields.callCreate')}
                </Typography>
                <Box className={classes.field} style={{ display: 'flex' }}>
                    <Box className={classes.callId} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                            fullWidth
                            source="callId"
                            resource="call"
                            validate={required('Bắt buộc nhập')}
                        />
                    </Box>
                    <SelectInput
                        fullWidth
                        resource="call"
                        validate={required('Bắt buộc nhập')}
                        source={'partnerId'}
                        choices={state.partners}
                        optionText={'name'}
                        optionValue={'id'}
                    />
                </Box>

                <Box style={{ display: 'flex' }} className={classes.field}>
                    <Box
                        className={classes.textInput}
                        mr={{ xs: 0, sm: '0.5em' }}
                    >
                        <TextInput
                            fullWidth={true}
                            source="agentId"
                            resource="call"
                            // onChange={event =>
                            //     agentChanged(event)
                            // }
                            validate={required('Bắt buộc nhập')}
                        />
                    </Box>
                    <Box
                        className={classes.textInput}
                        mr={{ xs: 0, sm: '0.5em' }}
                    >
                        <TextInput
                            fullWidth={true}
                            source="agentDesc"
                            resource="call"
                            validate={required('Bắt buộc nhập')}
                        />
                    </Box>
                    <Box className={classes.textInput}>
                        <TextInput
                            fullWidth={true}
                            source="phoneNumber"
                            resource="call"
                            validate={required('Bắt buộc nhập')}
                        />
                    </Box>
                </Box>
                <Box style={{ display: 'flex' }} className={classes.field}>
                    <Box
                        className={classes.textInput}
                        mr={{ xs: 0, sm: '0.5em' }}
                    >
                        <TextInput
                            fullWidth={true}
                            source="hotlineRoomDesc"
                            resource="call"
                            validate={required('Bắt buộc nhập')}
                        />
                    </Box>
                    <Box
                        className={classes.textInput}
                        mr={{ xs: 0, sm: '0.5em' }}
                    >
                        <SelectInput
                            fullWidth={true}
                            source="incoming"
                            label="Hướng cuộc gọi"
                            choices={[
                                { id: true, name: 'Inbound' },
                                { id: false, name: 'Outbound' },
                            ]}
                        />
                    </Box>
                    <Box className={classes.textInput}>
                        <Form.Item>
                            <BoundedTextField source="startTime" />
                        </Form.Item>
                    </Box>
                </Box>
                <div className={`fileInput-style`}>
                    <FileInput
                        validate={required('Bắt buộc nhập')}
                        source="file"
                        label="File âm thanh"
                        accept=".mp3, .wav"
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </div>
            </SimpleForm>
        </Create>
    );
};

export default CallCreate;
