import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Notification} from 'react-admin';

const cssMsg = {
    snackbarContent: {
        whiteSpace: 'pre-wrap',
    }
};

// @ts-ignore
const MyNotification = withStyles(cssMsg)(({classes, ...props}) => (
    // @ts-ignore
    <Notification {...props} className={classes.snackbarContent}/>
));

export default MyNotification;