import * as React from 'react';
import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { useNotify, useRedirect, useTranslate, useUpdate } from 'react-admin';
import { Complain } from '../types';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton: FC<{ record }> = ({ record }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();
    const [open, setOpen] = useState(false);
    const [opinionResponse, setOpinionResponse] = useState('');
    const [approve, { loading }] = useUpdate(
        'complain',
        record.id,
        { status: 'dongY', opinionResponse },
        record,
        {
            undoable: true,
            onSuccess: () => {
                notify('Đồng ý khiếu nại thành công', 'info', {}, true);
                redirectTo('/complain');
            },
            onFailure: () => {
                notify('Đồng ý khiếu nại thất bại', 'warning');
            },
        }
    );
    const onCancel = () => {
        setOpen(false);
    };
    const openPopup = () => {
        setOpen(true);
    };
    return record && record.status === 'chuaXuLy' ? (
        <React.Fragment>
            <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={openPopup}
                disabled={loading}
            >
                <ThumbUp
                    color="primary"
                    style={{ paddingRight: '0.5em', color: 'green' }}
                />
                {translate('resources.complain.action.accept')}
            </Button>
            <Dialog open={open} onClose={onCancel} fullWidth>
                <form onSubmit={approve} noValidate={false}>
                    <DialogTitle>
                        <Typography variant={'h6'} component={'h2'}>
                            Đồng ý khiếu nại
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            required
                            value={opinionResponse}
                            variant={'filled'}
                            label={'Ý kiến phản hồi'}
                            multiline
                            rows={4}
                            fullWidth
                            onChange={event =>
                                setOpinionResponse(event.target.value)
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={loading}
                        >
                            Xác nhận
                        </Button>
                        <Button onClick={onCancel}>Hủy</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    ) : (
        <span />
    );
};

AcceptButton.propTypes = {
    record: PropTypes.any,
};

export default AcceptButton;
