import * as React from 'react';
import { FC, Fragment, ReactElement } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    icon: {
        height: '22px',
        marginRight: theme.spacing(2),
        minWidth: '22px',
        justifyContent: 'center',
    },
    iconExpand: {
        height: '22px',
        minWidth: '22px',
        marginRight: theme.spacing(2),
        justifyContent: 'center',
    },
    typo: { fontWeight: 500 },
    menuItem: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    spacer: {
        flex: 1,
    },
    sidebarIsOpen: {
        '& a': {
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
    },
    sidebarIsClosed: {
        '& a': {
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
    },
}));

interface Props {
    dense: boolean;
    handleToggle: () => void;
    icon: ReactElement;
    isOpen: boolean;
    name: string;
    sidebarIsOpen: boolean;
}

const SubMenu: FC<Props> = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    children,
    dense,
}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const header = (
        <MenuItem
            dense={dense}
            button
            onClick={handleToggle}
            className={classes.menuItem}
        >
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            {sidebarIsOpen && (
                <Typography variant="inherit" color="textSecondary">
                    {translate(name)}
                </Typography>
            )}
            <span className={classes.spacer} />
            <ListItemIcon className={classes.iconExpand}>
                {isOpen ? <ExpandMore /> : <ChevronRight />}
            </ListItemIcon>
        </MenuItem>
    );

    return (
        <Fragment>
            {isOpen ? (
                header
            ) : (
                <Tooltip title={translate(name)} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    className={
                        sidebarIsOpen
                            ? classes.sidebarIsOpen
                            : classes.sidebarIsClosed
                    }
                >
                    {children}
                </List>
            </Collapse>
        </Fragment>
    );
};

export default SubMenu;
