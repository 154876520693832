import { TopToolbar } from 'react-admin';
import * as React from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import BackButton from './BackButton';

const CommonEditToolbar = props => {
    const history = useHistory();

    const back = useCallback(() => {
        history.goBack();
    }, [history]);

    return (
        <TopToolbar>
            <BackButton />
            {/*<ListButton />*/}
        </TopToolbar>
    );
};
export default CommonEditToolbar;
