import * as React from 'react';
import { FC, Fragment, useState } from 'react';
import {
    BulkActionProps,
    BulkDeleteButton,
    Datagrid,
    DatagridProps,
    FunctionField,
    List,
    ListProps,
    TextField,
    useDataProvider,
    useGetIdentity,
} from 'react-admin';
import StatusField from './StatusField';
import EvaluateOtherListAside from './EvaluateOtherListAside';
import EvaluateField from './EvaluateField';

interface DataGridProps extends DatagridProps {
    page?: number;
    perPage?: number;
}

const BulkActionButtons = (props: BulkActionProps) => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const DataGrid: FC<DataGridProps> = props => {
    const [criterias, setCriterias] = useState<any[]>([]);
    const dataProvider = useDataProvider();
    const { identity } = useGetIdentity();

    const getActive = item => {
        if (identity) {
            if (item.deptId === 'TCT' || item.deptId === identity.deptId) {
                return item.isActive;
            } else {
                return item.deptIdsActive
                    ? item.deptIdsActive.includes(identity.deptId)
                    : false;
            }
        }
        return true;
    };
    return (
        <Datagrid {...props} optimized rowClick="show">
            <FunctionField
                label="#"
                render={record =>
                    ((props.page || 1) - 1) * (props.perPage || 0) +
                    Object.keys(props.data || {}).indexOf(record?.id as string) +
                    1
                }
            />
            <TextField source="callId" />
            <TextField source="opinion" />
            <TextField source="supervisorName" />
            <EvaluateField source="score" />
            <TextField source="rankSelected" />
            {/*<TextField source="createBy" />
            <DateField source="createTime" />*/}
            {/*<StatusField />*/}
        </Datagrid>
    );
};

const EvaluateOtherList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            // empty={false}
            aside={<EvaluateOtherListAside />}
            bulkActionButtons={<BulkActionButtons />}
            perPage={25}
            sort={{ field: 'updateTime', order: 'desc' }}
        >
            <DataGrid />
        </List>
    );
};

export default EvaluateOtherList;
