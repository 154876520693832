import * as React from 'react';
import {FC, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbDown from '@material-ui/icons/ThumbDown';
import { useNotify, useRedirect, useTranslate, useUpdate } from 'react-admin';
import { Complain } from '../types';
import {DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";

/**
 * This custom button demonstrate using a custom action to update data
 */
const RejectButton: FC<{ record }> = ({ record }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();
    const [open, setOpen] = useState(false);
    const [opinionResponse, setOpinionResponse] = useState('');
    const onCancel = () => {
        setOpen(false);
    };
    const openPopup = () => {
        setOpen(true);
    };

    const [reject, { loading }] = useUpdate(
        'complain',
        record.id,
        { status: 'rejected', opinionResponse},
        record,
        {
            undoable: true,
            onSuccess: () => {
                notify('Từ chối khiếu nại thành công', 'info', {}, true);
                redirectTo('/complain');
            },
            onFailure: () => {
                notify('Từ chối khiếu nại thất bại', 'warning');
            },
        }
    );

    return record && record.status === 'chuaXuLy' ? (
        <>
            <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={openPopup}
                disabled={loading}
            >
                <ThumbDown
                    color="primary"
                    style={{ paddingRight: '0.5em', color: 'red' }}
                />
                {translate('resources.complain.action.reject')}
            </Button>
            <Dialog open={open} onClose={onCancel} fullWidth>
                <form onSubmit={reject} noValidate={false}>
                    <DialogTitle>
                        <Typography variant={'h6'} component={'h2'}>
                            Từ chối khiếu nại
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            required
                            value={opinionResponse}
                            variant={'filled'}
                            label={'Ý kiến phản hồi'}
                            multiline
                            rows={4}
                            fullWidth
                            onChange={event =>
                                setOpinionResponse(event.target.value)
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={loading}
                        >
                            Xác nhận
                        </Button>
                        <Button onClick={onCancel}>Hủy</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    ) : (
        <span />
    );
};

RejectButton.propTypes = {
    record: PropTypes.any,
};

export default RejectButton;
