import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useDataProvider, useGetList, useVersion } from 'react-admin';
import { Customer } from '../types';
import { useField } from 'react-final-form';
import { Avatar, ListItemSecondaryAction } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto',
            width: '100%',
        },
        cardHeader: {
            padding: theme.spacing(1, 2),
        },
        list: {
            height: '50vh',
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
        grid: {
            flex: 1,
        },
    })
);

function not(a: Customer[], b: Customer[]) {
    return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a: Customer[], b: Customer[]) {
    return a.filter(value => b.indexOf(value) !== -1);
}

function union(a: Customer[], b: Customer[]) {
    return [...a, ...not(b, a)];
}

export default function TransferList(props) {
    const {
        input: { onChange, value },
    } = useField('userIds');
    const classes = useStyles();
    const [checked, setChecked] = React.useState<Customer[]>([]);
    const [left, setLeft] = React.useState<Customer[]>([]);
    const [right, setRight] = React.useState<Customer[]>([]);

    const { data: users, ids } = useGetList<Customer>(
        'user',
        { page: 1, perPage: 500 },
        { field: 'fullName', order: 'ASC' }
    );

    useEffect(() => {
        console.log('users', users);
        console.log('ids', ids);
        console.log('value', value);
        if (ids.length > 0) {
            const listUser = ids.map(id => users[id]);
            if (value) {
                setRight(listUser.filter(item => !value.includes(item.id)));
                setLeft(listUser.filter(item => value.includes(item.id)));
            } else {
                setRight(listUser);
            }
        }
    }, [ids, value]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: Customer) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const CustomerOfChecked = (items: Customer[]) =>
        intersection(checked, items).length;

    const handleToggleAll = (items: Customer[]) => () => {
        if (CustomerOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        const _left = not(left, leftChecked);
        setRight(right.concat(leftChecked));
        setLeft(_left);
        setChecked(not(checked, leftChecked));
        onChange(_left.map(item => item.id));
    };

    const handleCheckedLeft = () => {
        const _left = left.concat(rightChecked);
        setLeft(_left);
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        onChange(_left.map(item => item.id));
    };

    const customList = (title: React.ReactNode, items: Customer[]) => (
        <Card variant={'outlined'}>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={
                            CustomerOfChecked(items) === items.length &&
                            items.length !== 0
                        }
                        indeterminate={
                            CustomerOfChecked(items) !== items.length &&
                            CustomerOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${CustomerOfChecked(items)}/${
                    items.length
                } đã chọn`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value: Customer) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Avatar />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={`${value.fullName} - ${value.deptName}`}
                            />
                            <ListItemSecondaryAction>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={handleToggle(value)}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        // @ts-ignore
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid item className={classes.grid}>
                {customList('Danh sách người dùng đã chọn', left)}
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item className={classes.grid}>
                {customList('Danh sách người dùng', right)}
            </Grid>
        </Grid>
    );
}
