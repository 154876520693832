import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    TextField,
    Typography,
} from '@material-ui/core';
import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import {
    AddCircleOutlineOutlined,
    BookmarksOutlined,
    DeleteOutlined,
} from '@material-ui/icons';
import { Filter } from '../types';
import {
    Confirm,
    FilterListItem,
    useCreate,
    useDataProvider,
    useDelete,
    useListContext,
    useNotify,
    useVersion,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';

const _ = require('lodash');

const SavedQueryList = () => {
    const { filterValues, setFilters } = useListContext();
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteOne, { loading, error }] = useDelete();
    const version = useVersion();
    const dataProvider = useDataProvider();
    const [filtersSaved, setFiltersSaved] = useState<Filter[]>([]);
    const fetchFilters = useCallback(async () => {
        const { data } = await dataProvider.getList<Filter>('filters', {
            filter: {},
            sort: { field: 'name', order: 'ASC' },
            pagination: { page: 1, perPage: 50 },
        });
        setFiltersSaved(data);
    }, [dataProvider]);
    const isSelected = filtersSaved.find(value => {
        const data = JSON.parse(value.dataJson);
        return _.matches(
            _.pickBy(data, val => {
                return typeof val !== 'undefined';
            })
        )(filterValues);
    });

    useEffect(() => {
        fetchFilters();
    }, []);

    const onSuccess = entity => {
        setOpen(false);
        setFiltersSaved(val => [...val, entity]);
    };
    const onCancel = () => {
        setOpen(false);
    };
    const openCreate = () => {
        setOpen(true);
    };
    const openDeletePopup = () => {
        setOpenDelete(true);
    };
    const closeDeletePopup = () => {
        setOpenDelete(false);
    };
    const deleteFilter = () => {
        // @ts-ignore
        deleteOne('filters', isSelected.id, isSelected);
        setFiltersSaved(val => val.filter(item => item.id !== isSelected?.id));
        closeDeletePopup();
    };

    return (
        <React.Fragment>
            <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent={'space-between'}
            >
                <Box display="flex">
                    <Box mr={1}>
                        <BookmarksOutlined />
                    </Box>
                    <Typography variant={'overline'}>Bộ lọc đã lưu</Typography>
                </Box>
                <div>
                    {!isSelected && Object.keys(filterValues).length > 1 && (
                        <IconButton
                            onClick={openCreate}
                            size={'small'}
                            color={'default'}
                        >
                            <AddCircleOutlineOutlined />
                        </IconButton>
                    )}
                    {/*{isSelected && (*/}
                    {/*    <IconButton size={'small'} color={'default'}>*/}
                    {/*        <EditOutlined />*/}
                    {/*    </IconButton>*/}
                    {/*)}*/}
                    {isSelected && (
                        <IconButton
                            onClick={openDeletePopup}
                            size={'small'}
                            color={'default'}
                        >
                            <DeleteOutlined />
                        </IconButton>
                    )}
                </div>
            </Box>
            <List dense disablePadding>
                {filtersSaved.map(filter => (
                    <FilterListItem
                        label={filter.name}
                        value={{
                            ...JSON.parse(filter.dataJson),
                        }}
                    />
                ))}
            </List>
            <CreateFilter
                filterValues={filterValues}
                onSuccess={onSuccess}
                onCancel={onCancel}
                open={open}
            />
            <Confirm
                isOpen={openDelete}
                content={`Bạn có chắc chắn xóa bộ lọc ${isSelected?.name}?`}
                title={'Xóa bộ lọc'}
                onConfirm={deleteFilter}
                onClose={closeDeletePopup}
            />
        </React.Fragment>
    );
};

const CreateFilter = ({ onSuccess, onCancel, open, filterValues }) => {
    const [value, setValue] = React.useState('');
    const [create] = useCreate('filters');

    const notify = useNotify();
    const handleSubmit = event => {
        event.preventDefault();
        const _filterValues = { ...filterValues };
        delete _filterValues.status;
        create(
            {
                payload: {
                    data: {
                        dataJson: JSON.stringify(_filterValues),
                        name: value,
                        model: 'IPCCCall',
                    },
                },
            },
            {
                onSuccess: ({ data }) => {
                    notify('Tạo bộ lọc thành công', 'success');
                    onSuccess(data);
                },
            }
        );
    };

    return (
        <Dialog open={open} onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    <Typography variant={'h6'} component={'h2'}>
                        Lưu bộ lọc
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Tên bộ lọc"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" type="submit">
                        Lưu
                    </Button>
                    <Button onClick={onCancel}>Hủy</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default memo(SavedQueryList);
