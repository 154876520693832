import * as React from 'react';
import { FC } from 'react';
import {
    AutocompleteInput,
    Datagrid,
    Edit,
    EditProps,
    EmailField,
    FormTab,
    Pagination,
    ReferenceInput,
    ReferenceManyField,
    required,
    ShowButton,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Department } from '../types';
import CustomerLinkField from '../user/CustomerLinkField';
import CommonEditToolbar from '../component/CommonEditToolbar';

interface PartnerTitleProps {
    record?: Department;
}

const PartnerTitle: FC<PartnerTitleProps> = ({ record }) =>
    record ? <span>Chi tiết: {record.reference}</span> : null;

const useStyles = makeStyles({
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tab: {
        maxWidth: '40em',
        display: 'block',
    },
});

const PartnerEdit: FC<EditProps> = props => {
    const classes = useStyles();
    return (
        <Edit
            {...props}
            title={<PartnerTitle />}
            actions={<CommonEditToolbar />}
            mutationMode={'pessimistic'}
        >
            <TabbedForm>
                <FormTab
                    label="resources.partner.tabs.information"
                    contentClassName={classes.tab}
                >
                    <TextInput
                        validate={requiredValidate}
                        source="name"
                        resource="partner"
                        fullWidth
                    />
                    <TextInput
                        validate={requiredValidate}
                        source="code"
                        resource="partner"
                        fullWidth
                        disabled
                    />
                    <ReferenceInput
                        source="deptId"
                        resource="partner"
                        reference="department"
                        fullWidth
                    >
                        <AutocompleteInput
                            validate={[required()]}
                            optionText={(choice?: Department) =>
                                choice?.id // the empty choice is { id: '' }
                                    ? `${choice.deptName} - ${choice.deptCode}`
                                    : ''
                            }
                        />
                    </ReferenceInput>
                    <TextInput
                        multiline
                        source="description"
                        resource="partner"
                        fullWidth
                    />
                </FormTab>
                <FormTab label="resources.partner.tabs.users" path="user">
                    <ReferenceManyField
                        reference="user"
                        target="partnerId"
                        addLabel={false}
                        pagination={<Pagination />}
                        fullWidth
                    >
                        <Datagrid>
                            <CustomerLinkField />
                            <TextField source="userName" />
                            <TextField source="deptName" />
                            <TextField source="status" />
                            <EmailField source="email" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

const requiredValidate = [required()];

export default PartnerEdit;
