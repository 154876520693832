import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Card,
    MenuItem,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, {useEffect, useState} from 'react';
import {Labeled} from 'react-admin';
import { ChevronRight } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import {TreeSelect} from "antd";
import ReportButton from "./ReportButton";
import {stringify} from "querystring";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            cursor: 'pointer',
        },
    })
);

const GiamSatTuDong = (props: {
    parentPartnerNest?: any
    // telephonistGroupNest?: any
    topicCodeNest?: any
    DTVNest?: any
}) => {
    const listType = [
        { id: 'tongHop', name: 'Tổng hợp' },
        { id: 'chiTietDTV', name: 'Chi tiết điện thoại viên' },
        { id: 'chiTietCuocGoi', name: 'Chi tiết theo cuộc gọi' },
    ];
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        fromDate: '',
        toDate: '',
        type: 'tongHop',
        partnerCodes: new Array(),
        // telephonistGroupIds: [],
        topicCodes: new Array(),
        telephonistCodes: [],
    });
    const handleChange = (key, value) => {
        const newData = { ...state };
        newData[key] = value;
        setState(newData);
    };

    useEffect(() => {
        setState({
            fromDate: '',
            toDate: '',
            type: 'tongHop',
            partnerCodes: props.parentPartnerNest.length > 0 ? ["all_children"] : [],
            // telephonistGroupIds: [],
            topicCodes: props.topicCodeNest.length > 0 ? ["all_children"] : [],
            telephonistCodes: [],
        })
    }, [props.parentPartnerNest, props.topicCodeNest])

    const classes = useStyles();
    const handleToggle = () => setOpen(val => !val);
    return (
        <Card variant={'outlined'} elevation={0} className={classes.root}>
            <Box p={2}>
                <Box
                    onClick={handleToggle}
                    display={'flex'}
                    alignItems={'center'}
                    className={classes.heading}
                    justifyContent={'space-between'}
                >
                    <Typography>Báo cáo giám sát tự động</Typography>
                    {open ? <ExpandMore /> : <ChevronRight />}
                </Box>
                <Collapse in={open} timeout={100} unmountOnExit>
                    <Box width={'100%'}>
                        <Box mt={1}>
                            <TextField
                                variant="filled"
                                select
                                fullWidth
                                value={state.type}
                                onChange={event =>
                                    handleChange('type', event.target.value)
                                }
                                margin={'dense'}
                                label="Loại báo cáo *"
                            >
                                {listType.map(item => (
                                    <MenuItem value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Box mt={1} >
                            <Box className={"my_ant_tree_select multiple_choice tree_select_report"} >
                                <Labeled label={"Đối tác *"}>
                                    <TreeSelect
                                        treeLine={{
                                            showLeafIcon: false,
                                        }}
                                        showSearch
                                        treeNodeFilterProp="title"
                                        style={{
                                            width: '100%',
                                        }}
                                        dropdownStyle={{
                                            maxHeight: 400,
                                        }}
                                        allowClear
                                        treeDefaultExpandAll
                                        treeData={props.parentPartnerNest}
                                        treeCheckable
                                        placeholder={"Tìm kiếm đối tác"}
                                        showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                        onChange={v => handleChange("partnerCodes", v)}
                                        defaultValue={state.partnerCodes}
                                    />
                                </Labeled>
                            </Box>
                        </Box>
                        {/*<Box mt={1}>*/}
                        {/*    <Box className={"my_ant_tree_select multiple_choice tree_select_report"} >*/}
                        {/*        <Labeled label={"Nhóm chức năng"}>*/}
                        {/*            <TreeSelect*/}
                        {/*                treeLine={{*/}
                        {/*                    showLeafIcon: false,*/}
                        {/*                }}*/}
                        {/*                showSearch*/}
                        {/*                treeNodeFilterProp="title"*/}
                        {/*                style={{*/}
                        {/*                    width: '100%',*/}
                        {/*                }}*/}
                        {/*                dropdownStyle={{*/}
                        {/*                    maxHeight: 400,*/}
                        {/*                }}*/}
                        {/*                allowClear*/}
                        {/*                treeDefaultExpandAll*/}
                        {/*                treeData={props.telephonistGroupNest}*/}
                        {/*                treeCheckable*/}
                        {/*                placeholder={"Tìm kiếm nhóm chức năng"}*/}
                        {/*                showCheckedStrategy={TreeSelect.SHOW_PARENT}*/}
                        {/*                onChange={v => handleChange("telephonistGroupIds", v)}*/}
                        {/*            />*/}
                        {/*        </Labeled>*/}
                        {/*    </Box>*/}
                        {/*</Box>*/}
                        <Box mt={1}>
                            <Box className={"my_ant_tree_select multiple_choice tree_select_report"} >
                                <Labeled label={"Chủ đề"}>
                                    <TreeSelect
                                        treeLine={{
                                            showLeafIcon: false,
                                        }}
                                        showSearch
                                        treeNodeFilterProp="title"
                                        style={{
                                            width: '100%',
                                        }}
                                        dropdownStyle={{
                                            maxHeight: 400,
                                        }}
                                        allowClear
                                        treeDefaultExpandAll
                                        treeData={props.topicCodeNest}
                                        treeCheckable
                                        placeholder={"Tìm kiếm chủ đề"}
                                        showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                        onChange={v => handleChange("topicCodes", v)}
                                        defaultValue={state.topicCodes}
                                    />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box mt={1}>
                            <Box className={"my_ant_tree_select multiple_choice tree_select_report"} >
                                <Labeled label={"Điện thoại viên"}>
                                    <TreeSelect
                                        treeLine={{
                                            showLeafIcon: false,
                                        }}
                                        showSearch
                                        treeNodeFilterProp="title"
                                        style={{
                                            width: '100%',
                                        }}
                                        dropdownStyle={{
                                            maxHeight: 400,
                                        }}
                                        allowClear
                                        treeDefaultExpandAll
                                        treeData={props.DTVNest}
                                        treeCheckable
                                        placeholder={"Tìm kiếm điện thoại viên"}
                                        showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                        onChange={v => handleChange("telephonistCodes", v)}
                                    />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box width={'100%'} display={'flex'} alignItems={'center'}>
                            <Box flex={1} mr={1}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                    type="date"
                                    fullWidth
                                    value={state.fromDate}
                                    onChange={event =>
                                        handleChange('fromDate', event.target.value)
                                    }
                                    margin={'dense'}
                                    label="Từ ngày *"
                                />
                            </Box>
                            <Box flex={1} ml={1}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                    type="date"
                                    fullWidth
                                    value={state.toDate}
                                    onChange={event =>
                                        handleChange('toDate', event.target.value)
                                    }
                                    margin={'dense'}
                                    label="Đến ngày *"
                                />
                            </Box>
                        </Box>
                        <Box width={'100%'} mt={1} display={'flex'} justifyContent={'flex-end'}>
                            <ReportButton
                                url={`report/baoCaoGiamSatTuDong?${stringify({
                                    ...state,
                                    fromDate: new Date(state.fromDate).getTime(),
                                    toDate: new Date(state.toDate).getTime()
                                })}`}
                                fileName={"baoCaoGiamSatTuDong"}
                                requireFields={{
                                    partnerCodes: state.partnerCodes,
                                    fromDate: state.fromDate,
                                    toDate: state.toDate,
                                    type: state.type,
                                }}
                            />
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        </Card>
    );
};

export default GiamSatTuDong;
