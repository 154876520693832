import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    BulkActionProps,
    BulkDeleteButton,
    CreateButton,
    Datagrid,
    DatagridProps,
    DateField,
    DeleteWithConfirmButton,
    Filter,
    FilterProps,
    FunctionField,
    List,
    ListProps,
    SearchInput,
    TextField,
    TopToolbar,
} from 'react-admin';
import StatusField from '../component/StatusField';

interface DataGridProps extends DatagridProps {
    page?: number;
    perPage?: number;
}

const ListFilters = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const Actions = (props: any) => (
    <TopToolbar>
        <CreateButton color="primary" />
    </TopToolbar>
);

const DataGrid: FC<DataGridProps> = props => {
    return (
        <Datagrid {...props} optimized rowClick="edit">
            <FunctionField
                label="#"
                render={record =>
                    ((props.page || 1) - 1) * (props.perPage || 0) +
                    Object.keys(props.data || {}).indexOf(
                        record?.id as string
                    ) +
                    1
                }
            />
            <TextField source="name" />
            <FunctionField
                textAlign={'right'}
                resource={'telephonistGroup'}
                source="quantityTelephonist"
                render={record => <span>{record?.userIds.length}</span>}
            />
            <TextField source="description" />
            <StatusField source={'status'} />
            <DateField source="createTime" />
            <FunctionField
                textAlign={'right'}
                resource={'telephonistGroup'}
                render={record =>
                    record?.userIds.length > 0 ? (
                        <></>
                    ) : (
                        <DeleteWithConfirmButton record={record} />
                    )
                }
            />
        </Datagrid>
    );
};

const TelephonistGroupList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            actions={<Actions />}
            filters={<ListFilters />}
            bulkActionButtons={false}
            perPage={25}
            sort={{ field: 'updateTime', order: 'desc' }}
        >
            <DataGrid />
        </List>
    );
};

export default TelephonistGroupList;
