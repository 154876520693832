import * as React from 'react';
import { ReactElement } from 'react';
import { usePermissions } from 'react-admin';
import isEqual from 'lodash/isEqual'

type PermissionTagProps = {
    permissionCode: string | string[];
};

export const PermissionTag = (
    props: PermissionTagProps & {
        children: ReactElement;
    }
): ReactElement => {
    // console.log(1)
    const { permissionCode, children, ...rest } = props;
    const { permissions } = usePermissions();
    let codes_: string[];
    if (typeof permissionCode === 'string') {
        codes_ = [permissionCode];
    } else {
        codes_ = permissionCode;
    }
    return (
        <SubPermissionTag permissions={permissions} permissionCode={codes_} {...rest}>
            <>{children}</>
        </SubPermissionTag>
    )
};

type SubPermissionTagProps = {
    permissions: string[];
    permissionCode: string[];
};

export const SubPermissionTag = React.memo((props: SubPermissionTagProps & {
    children: ReactElement;
}): ReactElement => {
    // console.log(2)
    const { permissions, permissionCode , children, ...rest } = props;
    const include = currentValue => permissions?.includes(currentValue);

    return permissionCode && permissionCode.every(include) ? React.cloneElement(children, {...rest,}) : <></>;
}, (pre, next) => {
    return isEqual(pre,next)
})