import * as React from 'react';
import { FC } from 'react';
import {
    ChipField,
    DateField,
    EditProps,
    FieldProps,
    NumberField,
    ReferenceManyField,
    required,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    useTranslate,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import FullNameField from './FullNameField';
import { Customer } from '../types';

const UserEdit: FC<EditProps> = props => {
    return (
        <Show title={<VisitorTitle />} component="div" {...props}>
            <VisitorForm />
        </Show>
    );
};

const VisitorTitle: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const VisitorForm = (props: any) => {
    const translate = useTranslate();

    return (
        <SimpleShowLayout {...props}>
            <TextField source="userName" fullWidth />
            <TextField source="fullName" resource="user" fullWidth />
            <Card>
                <CardContent>
                    <Box display={{ md: 'block', lg: 'flex' }}>
                        <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.user.fieldGroups.identity'
                                )}
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextField
                                        {...props}
                                        source="userName"
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <TextField
                                        source="fullName"
                                        resource="user"
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <TextField
                                source="email"
                                resource="user"
                                fullWidth
                            />
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <DateField
                                        source="birthday"
                                        resource="user"
                                        fullWidth
                                    />
                                </Box>

                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberField
                                        fullWidth
                                        resource="user"
                                        source="phoneNumber"
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextField resource="user" source="sex" />
                                </Box>
                            </Box>

                            <TextField
                                resource="user"
                                source="note"
                                fullWidth
                            />
                        </Box>
                        <Box
                            flex={1}
                            ml={{ xs: 0, lg: '1em' }}
                            mt={{ xs: '1em', lg: 0 }}
                        >
                            <Typography variant="h6" gutterBottom>
                                {translate('resources.user.fieldGroups.stats')}
                            </Typography>
                            <div>
                                <TextField
                                    resource="user"
                                    source="deptName"
                                    fullWidth
                                />
                            </div>

                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1}>
                                    <TextField
                                        resource="user"
                                        source="telephonistCode"
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <TextField source="userType" />
                                </Box>
                            </Box>
                            <div>
                                <ReferenceManyField
                                    label="Group"
                                    source="groupIds"
                                    reference="group"
                                    target="ids"
                                >
                                    <SingleFieldList>
                                        <ChipField source="groupName" />
                                    </SingleFieldList>
                                </ReferenceManyField>
                            </div>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </SimpleShowLayout>
    );
};

const requiredValidate = [required()];

export default UserEdit;
