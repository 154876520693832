import {
    Edit,
    EditProps,
    FormTab,
    Labeled,
    Link,
    ReferenceManyField,
    SingleFieldList,
    TabbedForm,
    TextField,
    TextInput,
    useGetIdentity,
    useRecordContext,
} from 'react-admin';
import * as React from 'react';
import {FC} from 'react';
import ComplainEditToolbar from './ComplainEditToolbar';
import Avatar from '@material-ui/core/Avatar';
import {Box, Chip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import StatusField from './StatusField';
import Aside from './Aside';
import CommonEditToolbar from '../component/CommonEditToolbar';
import UpdateRatingButton from './DetailRatingChangeButton';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
        marginTop: -theme.spacing(0.5),
        marginBottom: -theme.spacing(0.5),
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    fullWidth: {
        width: '100%',
    },
}));

const DetailForm = props => {
    const record = useRecordContext();
    const {identity} = useGetIdentity();
    const classes = useStyles();
    return (
        <div>
            {record && (
                <Box display={'flex'} flexWrap={'wrap'} my={2}>
                    <Box flex={1}>
                        <Labeled label="ID cuộc gọi">
                            <ReferenceManyField
                                reference="call"
                                source="callId"
                                target="callId"
                            >
                                <SingleFieldList>
                                    <TextField source="callId"/>
                                </SingleFieldList>
                            </ReferenceManyField>
                        </Labeled>
                    </Box>
                    <Box style={{
                        flex: 1
                    }}>
                        <Labeled label="Người bị khiếu nại" >
                            <Box style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <Avatar
                                    className={classes.avatar}
                                    alt={record.supervisorName}
                                />
                                <div>
                                    {record.supervisorName}
                                </div>
                            </Box>
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <Labeled label="Người khiếu nại" >
                            <Box style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <Avatar
                                    className={classes.avatar}
                                    alt={record.telephonistName}
                                />
                                <div>
                                    {record.telephonistName}
                                </div>
                            </Box>
                        </Labeled>
                    </Box>
                </Box>
            )}
            {record && (
                <Box display={'flex'} flexWrap={'wrap'} my={2}>
                    <Box flex={1}>
                        <Labeled label="Thời gian khiếu nại">
                            <span>
                                {new Date(record.createTime).toLocaleString()}
                            </span>
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <Labeled label="Trạng thái">
                            <StatusField record={record} {...props} />
                        </Labeled>
                    </Box>
                    <Box flex={1}></Box>
                </Box>
            )}
            {record.ratingBefore && (
                <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    my={2}
                    alignItems="center"
                >
                    <Box flex={1}>
                        <Labeled label="Điểm trước khiếu nại">
                            <Chip
                                size={'small'}
                                variant={'outlined'}
                                color={'primary'}
                                label={record.ratingBefore.scores}
                            />
                        </Labeled>
                    </Box>

                    <Box flex={1}>
                        <Labeled label="Điểm sau khiếu nại">
                            <Chip
                                size={'small'}
                                variant={'outlined'}
                                color={'primary'}
                                label={record.ratingAfter.scores}
                            />
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <UpdateRatingButton record={record}/>
                    </Box>
                </Box>
            )}
            <TextInput
                source={'opinion'}
                {...props}
                className={classes.fullWidth}
                disabled={
                    identity?.id !== record.telephonistId ||
                    record.status !== 'chuaXuLy'
                }
            />
            <TextInput
                source={'opinionResponse'}
                {...props}
                className={classes.fullWidth}
                disabled={
                    identity?.id !== record.telephonistId ||
                    record.status !== 'chuaXuLy'
                }
            />
        </div>
    );
};

const ComplainEdit: FC<EditProps> = props => {
    return (
        <Edit
            {...props}
            title={'Chi tiết khiếu nại'}
            aside={<Aside/>}
            mutationMode={'pessimistic'}
            actions={<CommonEditToolbar/>}
        >
            <TabbedForm
                toolbar={<ComplainEditToolbar/>}
            >
                <FormTab label="Thông tin khiếu nại">
                    <DetailForm/>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default ComplainEdit;
