import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import gradient_bg from '../assets/gradient-bg.svg';
import viteLogo from '../assets/vite.svg';
import { InputAdornment, TextField } from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
    required,
    TextInput,
    useLogin,
    useNotify,
    useTranslate,
} from 'react-admin';

import { lightTheme } from './themes';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import {
    Box,
    Button,
    CardActions,
    CircularProgress,
    Stack,
    Typography,
} from '@mui/material';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'flex-start',
        background: '#fff',
        position: 'relative',
    },
    bannerWrapper: {
        height: '40vh',
        background: '#F3F3F3',
        width: '100%',
    },
    banner: {
        textAlign: 'center',
    },
    card: {
        border: 'none',
        width: '400px',
        boxShadow: '0px 0px 20px 0px rgb(50 50 50 / 14%)',
        borderRadius: '25px',
        overflow: 'hidden',
        position: 'absolute',
        background: '#ffffff',
        top: '25%',
        left: '50%',
        zIndex: 99,
        backdropFilter: 'blur(13px)',
    },
    avatar: {
        textAlign: 'center',
        background:
            'transparent linear-gradient(100deg, #0075DF 0%, #005DB2 100%) 0% 0% no-repeat padding-box',
        border: 'none',
    },
    textHeader: {
        lineHeight: '60px',
        color: '#ffffff',
        marginBottom: '0px',
        fontSize: '1.5rem !important',
    },
    hint: {
        marginTop: '15px',
        textAlign: 'justify',
        marginBottom: '0px',
        fontSize: '15px',
        color: '#3F4254',
    },
    form: {
        padding: '3em',
    },
    input: {
        marginTop: '1em',
        marginBottom: '2em',
    },
    actions: {
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
    },
    footer: {
        margin: '0 auto',
        height: '60px',
        width: 'calc( 100% - 20%)',
        borderTop: '1px solid #DBDBDB',
    },
    footerText: {
        textAlign: 'center',
        lineHeight: '60px',
        fontSize: '20px',
    },
    middle: {
        height: 'calc( 100vh - 40vh - 60px)',
        background: 'white',
    },
}));

const renderInputUsername = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <PersonOutlineOutlinedIcon color="primary" />
                </InputAdornment>
            ),
        }}
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const renderInputPassword = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <LockOutlinedIcon color="primary" />
                </InputAdornment>
            ),
        }}
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

interface FormValues {
    username?: string;
    password?: string;
}

const { Form } = withTypes<FormValues>();

const Login = props => {
    const [isLoading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation<{ nextPathname: string } | null>();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/').catch(
            (error: Error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning',
                    {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined,
                    }
                );
            }
        );
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Stack
                        direction={'row'}
                        sx={{
                            display: 'flex',
                            flex: '1 1 auto',
                            height: '100vh',
                            width: '100%',
                        }}
                    >
                        <Box
                            alignItems={'center'}
                            justifyContent={'center'}
                            display={'flex'}
                            bgcolor={'rgb(28, 37, 54)'}
                            padding={8}
                            sx={{
                                flex: '1 1 auto',
                                backgroundImage: `url(${gradient_bg})`,
                                backgroundPosition: 'center top',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <Box maxWidth={900}>
                                <Typography
                                    variant={'h4'}
                                    color={'white'}
                                    sx={{ fontWeight: 600 }}
                                >
                                    Phần mềm tích mã - MobiFone 8
                                </Typography>
                                <Typography
                                    component={'p'}
                                    variant={'body1'}
                                    color={'gray'}
                                    sx={{ marginTop: 1 }}
                                ></Typography>
                            </Box>
                        </Box>
                        <Stack
                            bgcolor={'#fff'}
                            sx={{
                                width: 600,
                            }}
                            justifyContent={'center'}
                            direction={'column'}
                            maxWidth={'100%'}
                            padding={8}
                        >
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                spacing={1}
                                marginBottom={8}
                            >
                                <Box width={24} height={24}>
                                    <img
                                        src={viteLogo}
                                        width={'100%'}
                                        height={'100%'}
                                    />
                                </Box>
                                <Typography
                                    sx={{ fontWeight: 'bold' }}
                                    variant={'subtitle2'}
                                    display={'inline'}
                                >
                                    MobiFone 8
                                </Typography>
                            </Stack>
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Typography
                                        variant={'h5'}
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        Đăng nhập
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box sx={{ marginTop: '2em' }}>
                                        <TextInput
                                            source="username"
                                            label={translate(
                                                'ra.auth.username'
                                            )}
                                            disabled={isLoading}
                                            validate={required()}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box sx={{ marginTop: '0' }}>
                                        <TextInput
                                            source="password"
                                            label={translate(
                                                'ra.auth.password'
                                            )}
                                            type="password"
                                            disabled={isLoading}
                                            validate={required()}
                                            fullWidth
                                        />
                                    </Box>
                                </Box>
                                <CardActions sx={{ padding: '1em 0 0 0' }}>
                                    <Button
                                        sx={{
                                            padding: '10px 10px',
                                            textTransform: 'unset',
                                            fontWeight: 600,
                                        }}
                                        disableElevation
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={isLoading}
                                        fullWidth
                                    >
                                        {isLoading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                                sx={{ marginRight: 1 }}
                                            />
                                        )}
                                        {translate('ra.auth.sign_in')}
                                    </Button>
                                </CardActions>
                            </Box>
                        </Stack>
                    </Stack>
                </form>
            )}
        />
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
