// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputHelperText } from 'ra-ui-materialui';
import { useInput } from 'react-admin';

const AutoCompleteArray = props => {
    const {
        choices,
        loading,
        label,
        getOptionLabel,
        optionValue = 'id',
        onchange,
        disabled = false
    } = props;

    const {
        input: { name, onChange, value, helperText, ...rest },
        meta: { touched, error },
        isRequired,
    } = useInput(props);
    const [open, setOpen] = React.useState(false);
    let values = value
        ? value.map(item => choices?.find(item1 => item1[optionValue] === item)).filter(item => item !== undefined)
        : [];
    return (
        <Autocomplete
            open={open}
            value={values}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            // @ts-ignore
            onChange={(event: ChangeEvent<{}>, newValue: any[]) => {
                onChange(newValue.map(val => val[optionValue]));
                if(onchange !== undefined) onchange(newValue.map(val => val[optionValue]));
            }}
            multiple
            // getOptionSelected={(option, value) =>
            //     option[optionValue] === value[optionValue]
            // }
            // @ts-ignore
            getOptionLabel={getOptionLabel}
            options={choices}
            loading={loading}
            disableCloseOnSelect
            disabled={disabled}
            renderInput={params => (
                <TextField
                    {...params}
                    {...rest}
                    label={label}
                    required={isRequired}
                    variant="filled"
                    size="small"
                    error={!!(touched && error)}
                    helperText={
                        <InputHelperText
                            // @ts-ignore
                            touched={touched}
                            error={error}
                            helperText={helperText}
                        />
                    }
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default AutoCompleteArray;
