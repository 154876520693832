import React, { useCallback, useEffect, useState } from 'react';
import { chartColors, MemberType, TimeFilterValueType } from './Dashboard';
import dayjs from 'dayjs';
import { memberType } from './MemberTree';
import { BASE_URL } from '../common/constants';
import { httpClient } from '../dataProvider';
import { serialize } from '../dataProvider/utils';
import { useNotify } from 'react-admin';
import { Box, Tooltip, Typography } from '@mui/material';
import { Select } from 'antd';
import TimeFilter from './TimeFilter';
import LineChart from './LineChart';
import LinearProgress from '@mui/material/LinearProgress';

type DetailCallStatisticsProps = {
    member: MemberType | undefined;
};

const CallDetailStatistics = ({ member }: DetailCallStatisticsProps) => {
    const notify = useNotify();

    const [callDetailTimeType, setCallDetailTimeType] = useState<
        'date' | 'dateRange' | 'month' | 'week' | 'twelveMonths' | 'year'
    >('dateRange');

    const [callDetailFilter, setCallDetailFilter] = useState<
        TimeFilterValueType
    >();

    const [callDetailStatistics, setCallDetailStatistics] = useState<{
        data: { name?: string; data: number[] }[];
        categories: string[];
        colors: string[];
    }>({
        data: [],
        categories: [],
        colors: [],
    });

    const [
        fetchCallDetailStatisticsLoading,
        setFetchCallDetailStatisticsLoading,
    ] = useState<boolean>(false);

    const fetchCallDetailStatistics = useCallback(async () => {
        if (callDetailFilter && member) {
            if (
                callDetailFilter.type !== 'twelveMonths' &&
                (dayjs(callDetailFilter.toDate).diff(
                    dayjs(callDetailFilter.fromDate),
                    'day'
                ) > 31 ||
                    dayjs(callDetailFilter.toDate).diff(
                        dayjs(callDetailFilter.fromDate),
                        'day'
                    ) < 0)
            ) {
                notify(
                    'Báo cáo chi tiết cuộc gọi: Không được vượt qua 31 ngày và ngày bắt đầu phải nhở hơn hoặc bằng ngày kết thúc',
                    'warning'
                );
                return;
            }
            const filter = {
                'starttime.greaterThanOrEqual': callDetailFilter.fromDate.getTime(),
                'starttime.lessThanOrEqual': callDetailFilter.toDate.getTime(),
                groupBy: 'starttime',
                calendarInterval:
                    callDetailFilter.type === 'twelveMonths' ? 'month' : 'day',
            };

            setFetchCallDetailStatisticsLoading(true);

            const url = `${BASE_URL}/dashboard/callStatistics`;
            let totalCall, notEvaluatedCall, evaluatedCall;
            const errors: any[] = [];
            const promises: Promise<any>[] = [
                httpClient(
                    `${url}?${serialize({
                        name: 'chiTietTong',
                        ...filter,
                    })}`,
                    {}
                ).then(response => {
                    return response.json;
                }),
                httpClient(
                    `${url}?${serialize({
                        name: 'chiTietChuaDanhGia',
                        ...filter,
                        'tichMaId.specified': false,
                    })}`,
                    {}
                ).then(response => {
                    return response.json;
                }),
                httpClient(
                    `${url}?${serialize({
                        name: 'chiTietDaDanhGia',
                        ...filter,
                        'tichMaId.specified': true,
                    })}`,
                    {}
                ).then(response => {
                    return response.json;
                }),
            ];

            await Promise.all(
                promises.map(p => p.catch(err => errors.push(err)))
            ).then(values => {
                [totalCall, notEvaluatedCall, evaluatedCall] = values;
            });

            if (errors.length > 0) {
                notify(
                    'Báo cáo chi tiết cuộc gọi: Có lỗi khi load dữ liệu',
                    'warning'
                );
            } else {
                const callDetailCategoriesTmp: string[] = [];
                const callDetailColorsTmp: string[] = [
                    chartColors.totalCall,
                    chartColors.notEvaluatedCall,
                    chartColors.evaluatedCall,
                ];
                const callDetailEvaluatedCallTmp: number[] = [];
                const callDetailNotEvaluatedCallTmp: number[] = [];
                const callDetailTotalCallTmp: number[] = [];
                const getY = (type, array, date) => {
                    return type === 'twelveMonths'
                        ? Array.isArray(array) &&
                              array.find(
                                  p =>
                                      p.x &&
                                      new Date(p.x).getMonth() ===
                                          date.getMonth() &&
                                      new Date(p.x).getFullYear() ===
                                          date.getFullYear()
                              )?.y
                        : Array.isArray(array) &&
                              array.find(
                                  p =>
                                      p.x &&
                                      new Date(p.x).getDate() ===
                                          date.getDate() &&
                                      new Date(p.x).getMonth() ===
                                          date.getMonth() &&
                                      new Date(p.x).getFullYear() ===
                                          date.getFullYear()
                              )?.y;
                };
                const fromDateClone = new Date(callDetailFilter.fromDate);
                const toDateClone = new Date(callDetailFilter.toDate);
                for (
                    let d = fromDateClone;
                    d <= toDateClone;
                    callDetailFilter.type === 'twelveMonths'
                        ? d.setMonth(d.getMonth() + 1)
                        : d.setDate(d.getDate() + 1)
                ) {
                    if (callDetailFilter.type === 'twelveMonths') {
                        callDetailCategoriesTmp.push(d.getMonth() + 1 + '');
                    } else {
                        callDetailCategoriesTmp.push(
                            d.getDate() + '/' + (d.getMonth() + 1)
                        );
                    }

                    const totalCallData = getY(
                        callDetailFilter.type,
                        totalCall,
                        d
                    );
                    callDetailTotalCallTmp.push(totalCallData || 0);

                    const evaluatedCallData = getY(
                        callDetailFilter.type,
                        evaluatedCall,
                        d
                    );
                    callDetailEvaluatedCallTmp.push(evaluatedCallData || 0);

                    const notEvaluatedData = getY(
                        callDetailFilter.type,
                        notEvaluatedCall,
                        d
                    );
                    callDetailNotEvaluatedCallTmp.push(notEvaluatedData || 0);
                }
                setCallDetailStatistics({
                    data: [
                        {
                            name: 'Đã tích mã',
                            data: callDetailEvaluatedCallTmp,
                        },
                        {
                            name: 'Chưa tích mã',
                            data: callDetailNotEvaluatedCallTmp,
                        },
                        {
                            name: 'Tổng cuộc gọi',
                            data: callDetailTotalCallTmp,
                        },
                    ],
                    colors: callDetailColorsTmp,
                    categories: callDetailCategoriesTmp,
                });
            }
            setFetchCallDetailStatisticsLoading(false);
        }
    }, [
        callDetailFilter?.type,
        callDetailFilter?.toDate,
        callDetailFilter?.fromDate,
        member?.type,
        member?.memberId,
    ]);

    useEffect(() => {
        fetchCallDetailStatistics();
    }, [fetchCallDetailStatistics]);

    return (
        <>
            <Box>
                <Typography variant="body1">
                    <Box>
                        <p style={{ margin: '0 5px 0 0' }}>
                            Báo cáo chi tiết cuộc gọi theo
                        </p>
                        <Select
                            defaultValue={callDetailTimeType}
                            onChange={value => {
                                setCallDetailTimeType(value);
                            }}
                            style={{ width: 140 }}
                            options={[
                                {
                                    value: 'dateRange',
                                    label: 'Khoảng ngày',
                                },
                                {
                                    value: 'twelveMonths',
                                    label: 'Tháng',
                                },
                                { value: 'week', label: 'Tuần' },
                            ]}
                        />
                    </Box>
                </Typography>
                <Tooltip title="Thời gian bắt đầu cuộc gọi" placement="top">
                    <div>
                        <TimeFilter
                            type={callDetailTimeType}
                            onchange={(values: {
                                type: string;
                                fromDate: Date;
                                toDate: Date;
                            }) => {
                                setCallDetailFilter({ ...values });
                            }}
                        />
                    </div>
                </Tooltip>
            </Box>
            {!fetchCallDetailStatisticsLoading ? (
                <LineChart
                    categories={callDetailStatistics.categories}
                    colors={callDetailStatistics.colors}
                    data={callDetailStatistics.data}
                    yLabel={'Cuộc'}
                />
            ) : (
                <LinearProgress style={{ marginTop: '5px' }} />
            )}
        </>
    );
};

export default CallDetailStatistics;
