import orange from '@material-ui/core/colors/orange';

import { Call } from '../types';

const rowStyle = () => (record: Call) => {
    let style = {};
    if (!record) {
        return style;
    }
    if (record.hasComplain)
        return {
            ...style,
            borderLeftColor: orange[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    return style;
};

export default rowStyle;
