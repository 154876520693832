import React, { useEffect, useState } from 'react';
import { EditProps, useRecordContext } from 'react-admin';
import {
    CallSplitTwoTone,
    CallTwoTone,
    CategoryTwoTone,
    DomainTwoTone,
    DraftsTwoTone,
    EmojiEventsTwoTone,
    ErrorTwoTone,
    HowToRegTwoTone,
    LensTwoTone,
    LibraryBooksTwoTone,
    NotificationsTwoTone,
    PageviewTwoTone,
    SettingsTwoTone,
    SupervisorAccountTwoTone,
} from '@material-ui/icons';
import visitors from '../user';
import DashboardTwoTone from '@material-ui/icons/DashboardTwoTone';
import GroupTwoToneIcon from '@material-ui/icons/GroupTwoTone';
import { resources } from '../common/constants';
import PermissionRow from './PermissionRow';
import { useField } from 'react-final-form';
import { AddIcCall } from '@mui/icons-material';

const ICON_MAP = {
    category: <CategoryTwoTone color={'primary'} />,
    call: <CallTwoTone color={'primary'} />,
    callLogs: <AddIcCall color={'primary'} />,
    callReflect: <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />,
    groupCallService: <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />,
    'classify-by-theme': (
        <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />
    ),
    'classify-gsv': <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />,
    distribution: <CallSplitTwoTone color={'primary'} />,
    'evaluation-form': (
        <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />
    ),
    department: <DomainTwoTone color={'primary'} />,
    department_list: <DomainTwoTone color={'primary'} />,
    user: <visitors.icon color={'primary'} />,
    user_list: <visitors.icon color={'primary'} />,
    group: <GroupTwoToneIcon color={'primary'} />,
    supervised: <CallTwoTone color={'primary'} />,
    telephonistGroup: <SupervisorAccountTwoTone color={'primary'} />,
    keywords: <LensTwoTone style={{ fontSize: 8 }} color={'primary'} />,
    report: <EmojiEventsTwoTone color={'primary'} />,
    dashboard: <DashboardTwoTone color={'primary'} />,
};

const GroupPermission = (props: EditProps) => {
    const {
        input: { onChange },
    } = useField('permissions');

    const record = useRecordContext();

    const [permissionState, setPermissionState] = useState({});

    const checkPermissionState = (key, checked) => {
        setPermissionState(val => ({ ...val, [key]: checked }));
    };

    useEffect(() => {
        const permissions = Object.keys(permissionState).reduce(
            // @ts-ignore
            (has, crr) => {
                if (!!permissionState[crr]) {
                    return [...has, crr];
                } else return has;
            },
            []
        );
        onChange(permissions);
    }, [permissionState]);

    useEffect(() => {
        if (record && record.permissions) {
            const newState = { ...permissionState };
            record.permissions.forEach(per => {
                newState[per] = true;
            });
            setPermissionState(newState);
        }
    }, [record]);

    return (
        <div>
            {resources.map((item, i) => (
                <PermissionRow
                    checkPermissionState={checkPermissionState}
                    permissionState={permissionState}
                    key={item.id}
                    row={item}
                    icon={ICON_MAP[item.id]}
                />
            ))}
        </div>
    );
};

export default GroupPermission;
