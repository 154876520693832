import { Box } from '@material-ui/core';
import React from 'react';
import {
    FormWithRedirect,
    minLength,
    PasswordInput,
    required,
    SaveButton,
    useCreate,
    useNotify,
} from 'react-admin';
import { AnyObject } from 'react-final-form';

const validatePasswords = ({ newPassword, confirmPassword }: AnyObject) => {
    const errors = {} as any;

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
        errors.confirmPassword = ['resources.user.errors.password_mismatch'];
    }

    return errors;
};

const ChangePassword = () => {
    const [create, { loading }] = useCreate('user/changePassword');
    const notify = useNotify();
    const handleSubmit = async values => {
        create(
            {
                payload: {
                    data: {
                        ...values,
                    },
                },
            },
            {
                onSuccess: ({ data }) => {
                    notify('Đổi mật khẩu thành công', 'success');
                },
                onFailure: ({ body }) => {
                    notify(body.error, 'error');
                },
            }
        );
    };
    return (
        <FormWithRedirect
            validate={validatePasswords}
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
                <>
                    <PasswordInput
                        fullWidth
                        source="oldPassword"
                        resource="profile"
                        validate={[required()]}
                    />
                    <PasswordInput
                        fullWidth
                        source="newPassword"
                        resource="profile"
                        helpText={'Ít nhấp 8 ký tự'}
                        validate={[required(), minLength(8)]}
                    />
                    <PasswordInput
                        fullWidth
                        resource="profile"
                        source="confirmPassword"
                        validate={[required()]}
                    />
                    <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                        <SaveButton
                            icon={undefined}
                            variant={'text'}
                            label={'Xác nhận'}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            saving={saving}
                            disabled={loading}
                        />
                    </Box>
                </>
            )}
        />
    );
};

export default ChangePassword;
