import { SaveRounded } from '@material-ui/icons';
import {
    BooleanInput,
    Button,
    FormWithRedirect,
    required,
    SaveButton,
    TextInput,
} from 'react-admin';
import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles(theme => ({
    btnComplain: {
        marginLeft: theme.spacing(2),
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[700],
        },
    },
}));

const UpdateRatingButton = ({ rating, disabled }) => {
    const [open, setOpen] = useState<boolean>(false);
    const close = () => {
        setOpen(false);
    };
    const openDialog = () => {
        setOpen(true);
    };
    const submit = values => {
        rating(values);
    };

    return (
        <React.Fragment>
            <Button
                variant={'contained'}
                onClick={openDialog}
                color="primary"
                disableElevation
                startIcon={<SaveRounded />}
                label={'lưu đánh giá'}
                disabled={disabled}
            />
            <Dialog open={open} onClose={close} fullWidth>
                <DialogTitle>
                    <Typography variant={'h6'} component={'h2'}>
                        Cập nhật đánh giá
                    </Typography>
                </DialogTitle>
                <FormWithRedirect
                    resource="posts"
                    save={submit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving,
                    }) => (
                        <>
                            <DialogContent>
                                <TextInput
                                    resource="call"
                                    source="reason"
                                    multiline
                                    rows={4}
                                    validate={required()}
                                    fullWidth
                                />
                                <BooleanInput
                                    label="Cập nhật cho khiếu nại vừa xử lý"
                                    source="processComplain"
                                />
                            </DialogContent>
                            <DialogActions>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    saving={saving}
                                    disabled={disabled}
                                />
                                <Button onClick={close} label={'Hủy'} />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </React.Fragment>
    );
};

export default UpdateRatingButton;
