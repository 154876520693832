import * as React from 'react';
import { useState } from 'react';
import { Button, useListContext, useNotify } from 'react-admin';
import { BASE_URL } from '../common/constants';
import { CircularProgress } from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { buildHeader } from '../utils/ultils';
import { stringify } from 'querystring';

// @ts-ignore
const ExportExcelButton = ({ url, fileName }): JSX.Element => {
    const notify = useNotify();
    const [loading, setLoading] = useState<boolean>(false);
    const { page, perPage, currentSort, filterValues } = useListContext();
    let reportFileName = fileName;
    const exportBaoCao = () => {
        setLoading(true);
        const request = new Request(
            `${BASE_URL}/${url}?${stringify({
                ...filterValues,
                page: page - 1,
                size: 10000,
                sort: currentSort.field,
                order: currentSort.order,
            })}`,
            {
                method: 'GET',
                headers: buildHeader(),
            }
        );

        fetch(request)
            .then(response => {
                return response.blob();
            })
            .then(response => {
                setLoading(false);
                let fileURL = window.URL.createObjectURL(
                    // @ts-ignore
                    new Blob([response])
                );
                let fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', `${reportFileName}`);
                document.body.appendChild(fileLink);
                fileLink.click();
            })
            .catch(({ body, ...res }) => {
                notify(body.error, 'error');
            });
    };

    return (
        <Button
            variant="text"
            color="primary"
            size="small"
            disabled={loading}
            label="Xuất cuộc gọi"
            onClick={exportBaoCao}
            startIcon={
                loading ? (
                    <CircularProgress size={16} />
                ) : (
                    <SystemUpdateAltIcon />
                )
            }
        />
    );
};

export default ExportExcelButton;
