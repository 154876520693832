import * as React from 'react';
import { FC } from 'react';
import {
    Button,
    Edit,
    EditProps,
    FormWithRedirect,
    SaveButton,
    useNotify,
    useRefresh,
} from 'react-admin';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';

interface DialogEditProps extends EditProps {
    onCancel: () => void;
    title: string;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const DialogEdit: FC<DialogEditProps> = ({
    title,
    onCancel,
    maxWidth = 'sm',
    ...props
}) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const onSuccess = res => {
        notify('Sửa bản ghi thành công !', 'success');
        onCancel();
        refresh();
    };
    return (
        <Edit
            {...props}
            mutationMode="pessimistic"
            onSuccess={onSuccess}
            title={' '}
        >
            <FormWithRedirect
                render={(formProps: any) => (
                    <Dialog
                        maxWidth={maxWidth}
                        open={true}
                        disableBackdropClick
                        onClose={onCancel}
                        fullWidth
                    >
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>{props.children}</DialogContent>
                        <DialogActions>
                            <SaveButton {...formProps} />
                            <Box mr={2}>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={onCancel}
                                />
                            </Box>
                        </DialogActions>
                    </Dialog>
                )}
            />
        </Edit>
    );
};

export default DialogEdit;
