import * as React from 'react';
import {
    createRef,
    FC,
    memo,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';
import CriteriaCard from './CriteriaCard';
import { Category } from '../types';
import {
    Button,
    Labeled,
    Link,
    useDataProvider,
    useGetIdentity,
    useNotify,
    usePermissions,
    useQueryWithStore,
    fetchUtils,
    useRedirect,
    TextInput,
    required,
    SaveButton,
} from 'react-admin';
import CustomizedTreeView from '../component/TreeSelect';
import { convertToTree } from '../utils';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { State } from './CallEdit';
import { SaveRounded, Error, Cancel } from '@material-ui/icons';
import { orange, red } from '@material-ui/core/colors';
import ComplainButton from './ComplainButton';
import UpdateRatingButton from './UpdateRatingButton';
import EvaluateOther from './EvaluateOther';
import TreeInput from '../danhMuc/phanAnh/TreeInput';
import { BASE_URL } from '../common/constants';
import { buildHeader } from '../utils/ultils';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'react-final-form';
import ErrorCallButton from './ErrorCallButton';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
        marginTop: -theme.spacing(0.5),
        marginBottom: -theme.spacing(0.5),
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    btnComplain: {
        marginLeft: theme.spacing(2),
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[700],
        },
    },
    errorCallButton: {
        marginLeft: theme.spacing(2),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
}));

interface RatingFormProps {
    record: any;
    criterias: any[];
    callingRating: any;
    type: RatingType;
    disabled: boolean;
    state: State;
}

interface CallingRatingDetail {
    callCriteriaId: string;
    description: string;
    rankSelected: string;
    topicId: string;
    isDelete: string;
    point: string;
}

interface FormState {
    satisfactionLevel: string;
    topicId: string;
    typicalCallId: string;
    parentReflectId: string;
    note: string;
    callId: string;
    rankSelected: string;
    callingRatingDetails: CallingRatingDetail[];
    formEvaluateId: string;
    reflectIds: string;
    isMonitoring1: boolean;
    isMonitoring2: boolean;
}

type RatingType = 'rating1' | 'rating2' | undefined;

const RatingFormOne: FC<RatingFormProps> = ({
    record,
    criterias,
    callingRating,
    type,
    disabled,
    state,
}) => {
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const history = useHistory();
    const classes = useStyles();
    const [elRefs, setElRefs] = React.useState([]);
    const treeSelectRef = useRef(createRef());
    const formEvaluateSelectRef = useRef(createRef());
    const telephonistClassRef = useRef(createRef());
    const [totalScore, setTotalScore] = useState<number>(0);
    const redirect = useRedirect();
    const [form, setForm] = useState<FormState>({
        callId: record.callId,
        callingRatingDetails: [],
        note: '',
        parentReflectId: '',
        satisfactionLevel: '',
        topicId: '',
        typicalCallId: '',
        rankSelected: '',
        formEvaluateId: '',
        reflectIds:
            type === 'rating1'
                ? record.callingRating1?.reflectIds || []
                : record.callingRating2?.reflectIds ||
                  record.callingRating1?.reflectIds ||
                  [],
        isMonitoring1: type === 'rating1',
        isMonitoring2: type === 'rating2',
    });
    const [criteriaSelected, setCriteriaSelected] = useState<Category[]>([]);
    // ChungPT add src for create new evaluate by MBF admin S
    const [criteriasEvaluate, setCriteriasEvaluate] = useState<any[]>([]);
    const [valueComplain, setValueComplain] = useState<number>(0);
    const [valueEvaluate, setValueEvaluate] = useState<number>(0);
    const { identity } = useGetIdentity();
    const scoreChange = score => {
        const criticals = [];
        elRefs.forEach(item => {
            // @ts-ignore
            if (item.current) {
                // @ts-ignore
                return criticals.push(...item.current.getData());
            }
        });
        const newTotalScore = criticals.reduce((pre, cur) => {
            // @ts-ignore
            if (cur) pre = pre + cur.score;
            return pre;
        }, 0);

        setTotalScore(newTotalScore);
        // callingRatingDetailsMap.forEach(item => )
    };
    // ChungPT add src for create new evaluate by MBF admin E
    const loadSelectCriteria = useCallback(async () => {
        const { data } = await dataProvider.getList<Category>(
            `callingRating/${callingRating.id}/loadCallRatingDetail`,
            {
                filter: { groupCode: 'CGDH' },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        setCriteriaSelected(data);
    }, [dataProvider]);

    useEffect(() => {
        if (callingRating) {
            loadSelectCriteria();
        }
    }, [callingRating]);

    useEffect(() => {
        if (callingRating) {
            const newForm = { ...form };
            newForm.callingRatingDetails = callingRating.callingRatingDetails;
            newForm.note = callingRating.note;
            newForm.parentReflectId = callingRating.parentReflectId;
            newForm.satisfactionLevel = callingRating.satisfactionLevel;
            newForm.topicId = callingRating.topicId;
            newForm.typicalCallId = callingRating.typicalCallId;
            newForm.formEvaluateId = callingRating.formEvaluateId;
            newForm.reflectIds = callingRating.reflectIds;
            newForm.rankSelected = callingRating.rankSelected;
            setForm(newForm);
            loadSelectCriteria();
        } else {
            if (record.callingRating1) {
                const newForm = { ...form };
                callingRating = record.callingRating1;
                newForm.callingRatingDetails =
                    callingRating.callingRatingDetails;
                newForm.note = callingRating.note;
                newForm.parentReflectId = callingRating.parentReflectId;
                newForm.satisfactionLevel = callingRating.satisfactionLevel;
                newForm.topicId = callingRating.topicId;
                newForm.typicalCallId = callingRating.typicalCallId;
                newForm.formEvaluateId = callingRating.formEvaluateId;
                newForm.reflectIds = callingRating.reflectIds;
                newForm.rankSelected = callingRating.rankSelected;
                setForm(newForm);
                loadSelectCriteria();
            }
        }
    }, [callingRating]);

    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            setForm(val => ({ ...val, ...values }));
        }
    };

    const convertSubmitForm = () => {
        const callingRatingDetailsMap = elRefs.map(({ current }) =>
            // @ts-ignore
            current.getData()
        );
        const callingRatingDetails = callingRatingDetailsMap.reduce(
            (hash, acc) => [...hash, ...acc],
            []
        );
        const newFormValues = {
            ...form,
            callingRatingDetails,
            // @ts-ignore
            formEvaluateId: formEvaluateSelectRef.current.getValue(),
            // @ts-ignore

            // @ts-ignore
            // telephonistCriteriaId: telephonistCriteriaRef.current?.getValue(),
            telephonistClassId:
                type === 'rating2'
                    ? // @ts-ignore
                      telephonistClassRef.current.getValue()
                    : '',
            // @ts-ignore
            reflectIds: treeSelectRef.current.getSelectedItem(),
        };
        if (!newFormValues.satisfactionLevel) {
            notify(
                'Nhập đầy đủ các trường trong phần Chi tiết. Thiếu trường độ hài lòng',
                'warning'
            );
            return;
        }
        if (!newFormValues.formEvaluateId) {
            notify(
                'Nhập đầy đủ các trường trong phần Chi tiết. Thiếu trường hình thức đánh giá',
                'warning'
            );
            return;
        }
        if (!newFormValues.typicalCallId) {
            notify(
                'Nhập đầy đủ các trường trong phần Chi tiết. Thiếu trường cuộc gọi điển hình',
                'warning'
            );
            return;
        }
        if (!newFormValues.note.trim()) {
            notify(
                'Nhập đầy đủ các trường trong phần Chi tiết. Thiếu trường nhận xét chung',
                'warning'
            );
            return;
        }
        if (
            !(newFormValues.reflectIds && newFormValues.reflectIds.length > 0)
        ) {
            notify(
                'Nhập đầy đủ các trường trong phần Chi tiết. Thiếu trường chủ đề cuộc gọi',
                'warning'
            );
            return;
        }
        return newFormValues;
    };

    React.useEffect(() => {
        // add or remove refs
        if (criterias && criterias.length) {
            setElRefs(elRefs =>
                Array.from(
                    Array(
                        criterias.filter(val => val.children.length > 0).length
                    ),
                    (d, i) => i
                ).map((_, i) => elRefs[i] || createRef())
            );
        }
    }, [criterias]);

    const notify = useNotify();
    const rating = async values => {
        const params = convertSubmitForm();
        if (!params) return;
        if (typeof values === 'string' && values === 'ERROR') {
            dataProvider
                .update('call', {
                    id: record.id,
                    data: {
                        isError:
                            record.errorCall && record.errorCall === true
                                ? false
                                : true,
                    },
                    previousData: record,
                })
                .then(({ data }) => {
                    notify('Cập nhật cuộc gọi lỗi thành công!', 'success');
                    history.push('/call', { status: 2 });
                })
                .catch(error => {
                    notify('Cập nhật cuộc gọi lỗi thất bại!', 'error');
                    return;
                });
        } else if (callingRating) {
            dataProvider
                .create(`callingRating/${callingRating.id}/updateRating`, {
                    data: { ...params, ...values },
                })
                .then(({ data }) => {
                    notify('Cập nhật đánh giá cuộc gọi thành công!', 'success');
                    history.push('/call', { status: 2 });
                })
                .catch(error => {
                    notify('Cập nhật đánh giá cuộc gọi thất bại!', 'error');
                    return;
                });
        } else {
            dataProvider
                .create(`callingRating/rating`, { data: params })
                .then(({ data }) => {
                    notify('Đánh giá cuộc gọi thành công!', 'success');
                    history.push('/call', { status: 2 });
                })
                .catch(error => {
                    notify('Đánh giá cuộc gọi thất bại!', 'error');
                    return;
                });
        }
    };

    const listRank = [
        { id: 'good', name: 'Tốt' },
        { id: 'bad', name: 'Xấu' },
    ];
    // ChungPT add src for create new evaluate by MBF admin S
    const loadCategory = useCallback(async () => {
        const { data: evaluateOtherData } = await dataProvider.getList<
            Category
        >('criteriaGSV', {
            filter: {},
            sort: { field: 'name', order: 'ASC' },
            pagination: { page: 1, perPage: 50 },
        });
        setCriteriasEvaluate(
            convertToTree(evaluateOtherData.filter(getActive))
        );
    }, [dataProvider]);

    const getActive = item => {
        if (identity) {
            if (item.deptId === 'TCT' || item.deptId === identity.deptId) {
                return item.isActive;
            } else {
                return item.deptIdsActive
                    ? item.deptIdsActive.includes(identity.deptId)
                    : false;
            }
        }
        return true;
    };
    useEffect(() => {
        loadCategory();
    }, [loadCategory]);

    const { loaded, data } = useQueryWithStore({
        type: 'getList',
        resource: 'callReflect',
        payload: {
            filter: {
                parentId: null,
            },
            sort: { field: 'id', order: 'DESC' },
            pagination: { page: 1, perPage: 1000 },
        },
    });
    const entryList: Array<{
        parentId: string | null | undefined;
        title: string | null | undefined;
        value: string | null | undefined;
    }> = data
        ? data.map(d => {
              return {
                  parentId: d.parentId,
                  title: d.name,
                  value: d.id,
              };
          })
        : [];

    const nestTree = function (
        items,
        value = null,
        link = 'parentId',
        level = 0
    ) {
        return items
            .filter(item => item[link] === value)
            .map(item => ({
                ...item,
                children: nestTree(items, item.value, 'parentId', level + 1),
            }));
    };
    const handleCancelRating = () => {
        if (record.callingRating2 && type === 'rating1') {
            notify(
                'không thể hủy đánh giá lần 1 do chưa hủy đánh giá lần 2!',
                'error'
            );
            return false;
        } else {
            fetchUtils
                .fetchJson(
                    `${BASE_URL}/callingRating/${
                        type === 'rating1'
                            ? record.callingRating1.id
                            : record.callingRating2.id
                    }`,
                    {
                        method: 'DELETE',
                        headers: buildHeader(),
                    }
                )
                .then(r => {
                    notify('Hủy đánh giá thành công!', 'success');
                    redirect('/call');
                })
                .catch(({ body }) => {
                    notify('Hủy đánh giá thất bại!', 'error');
                    console.log(body);
                });
        }
    };
    useEffect(() => {
        fetchUtils
            .fetchJson(`${BASE_URL}/sys/config/findByCode/TIME_OUT_COMPLAIN`, {
                method: 'GET',
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    LdapType: `${localStorage.getItem('ldap_type')}`,
                }),
            })
            .then(response => {
                const { json } = response;
                setValueComplain(json.value);
            });
    }, [valueComplain]);

    useEffect(() => {
        fetchUtils
            .fetchJson(
                `${BASE_URL}/sys/config/findByCode/TIME_OUT_SAVE_RATING`,
                {
                    method: 'GET',
                    headers: new Headers({
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                        LdapType: `${localStorage.getItem('ldap_type')}`,
                    }),
                }
            )
            .then(response => {
                const { json } = response;
                setValueEvaluate(json.value);
                // return {json};
            });
    }, [valueEvaluate]);
    const showComplain = () => {
        console.log(callingRating);

        const myDate = new Date().getTime();
        const dateTodayRating1 = new Date(callingRating.ratingDate).getTime();
        const hourRating1 = (myDate - dateTodayRating1) / 3600000;
        if (hourRating1 <= valueComplain && !record._haveEvaluated) {
            return true;
        } else {
            return false;
        }
    };
    const showEvaluate = () => {
        const myDate = new Date().getTime();
        const dateTodayRating2 = new Date(callingRating.ratingDate).getTime();
        const hourRating2 = (myDate - dateTodayRating2) / 3600000;
        if (hourRating2 <= valueEvaluate) {
            return true;
        } else {
            return false;
        }
    };
    const showCancelRating = () =>
        record.callingRating1 &&
        identity?.fullName === record.callingRating1.supervisorName;
    const hasPermissionRating = () =>
        type === undefined ||
        (type === 'rating1' && permissions.includes('call_rate1')) ||
        (type === 'rating2' && permissions.includes('call_rate2'));

    // ChungPT add src for create new evaluate by MBF admin E

    const [isComplain, setisComplain] = useState(false);
    useEffect(() => {
        if (!record.callId || !callingRating) {
            setisComplain(false);
            return;
        }
        fetchUtils
            .fetchJson(
                `${BASE_URL}/complain?callId=${record.callId}&callingRatingId=${callingRating.id}`,
                {
                    method: 'GET',
                    headers: new Headers({
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                        LdapType: `${localStorage.getItem('ldap_type')}`,
                    }),
                }
            )
            .then(response => {
                const { json } = response;
                setisComplain(!!json.numberOfElements);
            });
    }, [record?.callId, callingRating]);

    return (
        <Box m={1}>
            {record.errorCall && record.errorCall === true && (
                <Box my={2}>
                    <Alert severity="error">
                        Cuộc gọi này đã được đánh dấu là cuộc gọi lỗi
                    </Alert>
                </Box>
            )}
            {callingRating && type === 'rating1' && (
                <Box my={2}>
                    <Alert severity="info">
                        Cuộc gọi này đã được đánh giá lần 1, bạn có thể cập nhật
                        hoặc đánh giá lần 2
                    </Alert>
                </Box>
            )}
            {callingRating && (
                <Box display={'flex'} my={2}>
                    <Box flex={1}>
                        <Labeled label="Người đánh giá ">
                            <div
                                className={classes.root}
                                // to={`/user/${callingRating.supervisionId}`}
                            >
                                <Avatar
                                    className={classes.avatar}
                                    alt={callingRating.supervisorName}
                                />
                                {callingRating.supervisorName}
                            </div>
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <Labeled label="Thời gian đánh giá">
                            <span>
                                {new Date(
                                    callingRating.ratingDate
                                ).toLocaleString()}
                            </span>
                        </Labeled>
                    </Box>
                    <Box flex={1}>
                        <Labeled label="Điểm đã đánh giá">
                            <Chip
                                size={'small'}
                                variant={'outlined'}
                                color={'secondary'}
                                label={callingRating.scores}
                            />
                        </Labeled>
                    </Box>
                </Box>
            )}

            {/*<Box display={'flex'} justifyContent={'space-between'}>*/}
            <Typography variant="h6" gutterBottom>
                Tiêu chí đánh giá
            </Typography>

            {/*các tiêu chí đánh giá*/}
            {criterias
                .filter(val => val.children.length > 0)
                .map((criteria, i) => (
                    <CriteriaCard
                        // scoreChange={scoreChange}
                        disabled={disabled}
                        ref={elRefs[i]}
                        record={record}
                        criteria={criteria}
                        criteriaSelected={criteriaSelected}
                        key={criteria.id}
                    />
                ))}
            <Typography variant="h6" gutterBottom>
                Chi tiết
            </Typography>
            <Box mx={1} mb={-2}>
                <Box className="my_ant_tree_select multiple_choice">
                    <Labeled label={'Chủ đề cuộc gọi'}>
                        <TreeInput
                            source={'reflectIds'}
                            data={nestTree(entryList)}
                            treeCheckable
                            placeholder={'Tìm kiếm chủ đề cuộc gọi'}
                            ref={treeSelectRef}
                            defaultValue={form.reflectIds}
                        />
                    </Labeled>
                </Box>
            </Box>
            <Box display={{ md: 'flex', lg: 'flex' }} flexDirection="row">
                <Box flex={1} mx={1}>
                    <CustomizedTreeView
                        disabled={disabled}
                        defaultValue={form.formEvaluateId}
                        ref={formEvaluateSelectRef}
                        label="Hình thức đánh giá"
                        treeData={convertToTree(state.formEvaluate)}
                        flatData={state.formEvaluate}
                    />
                </Box>

                <Box flex={1} mx={1}>
                    <TextField
                        disabled={disabled}
                        select
                        margin={'dense'}
                        variant="filled"
                        fullWidth
                        value={form.satisfactionLevel}
                        label="Độ hài lòng"
                        onChange={({ target }) =>
                            onSubmit({
                                satisfactionLevel: target.value,
                            })
                        }
                    >
                        {state.mucDoHaiLongs.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Box>
            <Box display={{ md: 'flex', lg: 'flex' }} flexDirection="row">
                <Box flex={1} mx={1}>
                    <TextField
                        disabled={disabled}
                        select
                        margin={'dense'}
                        variant="filled"
                        fullWidth
                        value={form.typicalCallId}
                        label="Cuộc gọi điển hình"
                        onChange={({ target }) =>
                            onSubmit({
                                typicalCallId: target.value,
                            })
                        }
                    >
                        {state.cuocGoiDienHinhs.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Box>
            <Box display={{ md: 'flex', lg: 'flex' }} flexDirection="row">
                {type === 'rating2' && (
                    <Box flex={1} mx={1}>
                        <CustomizedTreeView
                            disabled={disabled}
                            defaultValue={callingRating?.telephonistClassId}
                            ref={telephonistClassRef}
                            label="Phân loại giám sát viên"
                            treeData={convertToTree(state.telephonistClass)}
                            flatData={state.telephonistClass}
                        />
                    </Box>
                )}
            </Box>
            <Box mx={1} sx={{ marginBottom: '10px' }}>
                <TextField
                    disabled={disabled}
                    margin={'dense'}
                    variant="filled"
                    /*ChungPT: edit*/
                    rows={6}
                    fullWidth
                    multiline
                    value={form.note}
                    label="Nhận xét chung"
                    onChange={({ target }) =>
                        onSubmit({
                            note: target.value,
                        })
                    }
                />
            </Box>

            {!!type && (
                <Box p={2} display="flex" justifyContent="flex-end">
                    {permissions && hasPermissionRating() && callingRating && (
                        <UpdateRatingButton
                            rating={rating}
                            disabled={disabled}
                        />
                    )}
                    {permissions && hasPermissionRating() && !callingRating && (
                        <Button
                            startIcon={<SaveRounded />}
                            variant={'contained'}
                            onClick={rating}
                            color="primary"
                            disableElevation
                            label={'Lưu Đánh giá'}
                            disabled={disabled}
                        />
                    )}
                    {permissions &&
                        permissions.includes('call_complain') &&
                        callingRating &&
                        showComplain() && (
                            <ComplainButton
                                disabled={isComplain}
                                callId={record.callId}
                                callingRatingId={callingRating.id}
                                complainScores={callingRating.scores}
                            />
                        )}
                    {/*// ChungPT add src for create new evaluate by MBF admin S*/}
                    {permissions &&
                        permissions.includes('call_rategsv') &&
                        callingRating &&
                        showEvaluate() && (
                            <EvaluateOther
                                callId={record.callId}
                                criterias={criteriasEvaluate}
                                detail={callingRating}
                                callingRatingId={callingRating.id}
                            />
                        )}
                    <ErrorCallButton record={record} />
                    {permissions && showCancelRating() && (
                        <Button
                            startIcon={<Cancel />}
                            variant={'contained'}
                            className={classes.errorCallButton}
                            label={'Hủy đánh giá'}
                            onClick={handleCancelRating}
                            // disabled={record.callingRating2? true : false}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default memo<RatingFormProps>(RatingFormOne);
