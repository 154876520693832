import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import {
    MenuItemLink,
    MenuProps,
    useLogout,
    usePermissions,
    useTranslate,
} from 'react-admin';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import visitors from '../user';
import SubMenu from './SubMenu';
import { AppState } from '../types';
import DashboardTwoTone from '@material-ui/icons/DashboardTwoTone';
import {
    AddIcCall,
    CallSplitTwoTone,
    CallTwoTone,
    CategoryTwoTone,
    DomainTwoTone,
    DraftsTwoTone,
    EmojiEventsTwoTone,
    ErrorTwoTone,
    Lens,
    LibraryBooksTwoTone,
    NotificationsTwoTone,
    PageviewTwoTone,
    SettingsTwoTone,
} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import UserCard from './UserCard';
import { makeStyles } from '@material-ui/core/styles';

type MenuName =
    | 'menuCatalog'
    | 'menuCategory'
    | 'menuDepartments'
    | 'menuUsers';

const useStyles = makeStyles(theme => ({
    fixed: {
        position: 'fixed',
        width: '240px',
        height: '100vh',
        overflow: 'auto',
        marginBottom: 16,
    },
    icon: {
        minWidth: '22px',
        justifyContent: 'center',
    },
}));

const Menu: FC<MenuProps> = ({ onMenuClick, logout, dense = false }) => {
    const classes = useStyles();
    const logout1 = useLogout();
    const [state, setState] = useState({
        menuCatalog: false,
        menuSales: false,
        menuDepartments: false,
        menuUsers: false,
        menuCategory: false,
    });
    const [pageCanAccess, setPageCanAccess] = useState([]);
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        const newState = { ...state };
        Object.keys(newState).forEach(item => {
            if (item === menu) {
                newState[item] = !newState[menu];
            } else {
                newState[item] = false;
            }
        });
        setState(newState);
    };

    const getText = (text: string) => {
        if (open) {
            return text;
        }
        return '';
    };

    useEffect(() => {
        if (permissions === null) {
            logout1();
            return;
        }
        if (permissions && permissions.length) {
            const pages = permissions.reduce((hash, crr) => {
                const page = crr.split('_')[0];
                if (!hash.includes(page)) {
                    hash.push(page);
                }
                return hash;
            }, []);
            setPageCanAccess(pages);
        }
    }, [permissions]);

    const canAccessPage = page => {
        // @ts-ignore
        return pageCanAccess && pageCanAccess.includes(page);
    };

    return (
        <PerfectScrollbar>
            <Box mt={9}>
                <Box mb={3} mt={3}>
                    <UserCard sidebarIsOpen={open} />
                </Box>
                <Box m={1.5}>
                    <Typography variant="subtitle2" color="secondary">
                        {translate('pos.menu.general')}
                    </Typography>
                </Box>
                <MenuItemLink
                    to={`/`}
                    primaryText={getText(translate('ra.page.dashboard'))}
                    exact
                    leftIcon={<DashboardTwoTone fontSize={'small'} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                {/* {canAccessPage('report') && (
                    <MenuItemLink
                        to={`/reportOld`}
                        primaryText={getText(
                            translate(`Báo cáo cũ`, {
                                smart_count: 2,
                            })
                        )}
                        leftIcon={<EmojiEventsTwoTone fontSize={'small'} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                )} */}

                {canAccessPage('report') && (
                    <MenuItemLink
                        to={`/report`}
                        primaryText={getText(
                            translate(`Báo cáo`, {
                                smart_count: 2,
                            })
                        )}
                        leftIcon={<EmojiEventsTwoTone fontSize={'small'} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                )}

                <Box m={1.5}>
                    <Typography variant="subtitle2" color="secondary">
                        {translate('pos.menu.callSupervision')}
                    </Typography>
                </Box>
                {canAccessPage('call') && (
                    <MenuItemLink
                        to={`/call`}
                        primaryText={getText(
                            translate(`resources.call.name`, {
                                smart_count: 2,
                            })
                        )}
                        leftIcon={<CallTwoTone fontSize={'small'} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                )}
                {canAccessPage('callLogs') && (
                    <MenuItemLink
                        to={`/callLogs`}
                        primaryText={'Quản lý Logs cuộc gọi'}
                        leftIcon={<AddIcCall fontSize={'small'} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                )}

                <Box m={1.5}>
                    <Typography variant="subtitle2" color="secondary">
                        {translate('pos.menu.management')}
                    </Typography>
                </Box>
                <SubMenu
                    handleToggle={() => handleToggle('menuUsers')}
                    isOpen={state.menuUsers}
                    sidebarIsOpen={open}
                    name="pos.menu.user"
                    icon={<visitors.icon fontSize={'small'} />}
                    dense={dense}
                >
                    {canAccessPage('user') && (
                        <MenuItemLink
                            to={`/user`}
                            primaryText={getText(
                                translate(`resources.user.name`, {
                                    smart_count: 2,
                                })
                            )}
                            leftIcon={
                                <Lens
                                    style={{ fontSize: 4 }}
                                    className={classes.icon}
                                />
                            }
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    )}
                    {canAccessPage('group') && (
                        <MenuItemLink
                            to={`/group`}
                            primaryText={getText(
                                translate(`resources.group.name`, {
                                    smart_count: 2,
                                })
                            )}
                            leftIcon={
                                <Lens
                                    style={{ fontSize: 4 }}
                                    className={classes.icon}
                                />
                            }
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    )}
                    {canAccessPage('telephonistGroup') && (
                        <MenuItemLink
                            to={`/telephonistGroup`}
                            primaryText={getText(
                                translate(`pos.menu.telephonistGroup`, {
                                    smart_count: 2,
                                })
                            )}
                            leftIcon={
                                <Lens
                                    style={{ fontSize: 4 }}
                                    className={classes.icon}
                                />
                            }
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    )}
                </SubMenu>
                <SubMenu
                    handleToggle={() => handleToggle('menuDepartments')}
                    isOpen={state.menuDepartments}
                    sidebarIsOpen={open}
                    name="pos.menu.department"
                    icon={<DomainTwoTone fontSize={'small'} />}
                    dense={dense}
                >
                    {canAccessPage('department') && (
                        <MenuItemLink
                            to={`/department`}
                            primaryText={getText(
                                translate(`resources.department.name`, {
                                    smart_count: 2,
                                })
                            )}
                            leftIcon={
                                <Lens
                                    style={{ fontSize: 4 }}
                                    className={classes.icon}
                                />
                            }
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    )}
                    {/*{canAccessPage('partner') && (*/}
                    {/*    <MenuItemLink*/}
                    {/*        to={`/partner`}*/}
                    {/*        primaryText={getText(*/}
                    {/*            translate(`resources.partner.name`, {*/}
                    {/*                smart_count: 2,*/}
                    {/*            })*/}
                    {/*        )}*/}
                    {/*        leftIcon={*/}
                    {/*            <Lens*/}
                    {/*                style={{ fontSize: 4 }}*/}
                    {/*                className={classes.icon}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*        onClick={onMenuClick}*/}
                    {/*        sidebarIsOpen={open}*/}
                    {/*        dense={dense}*/}
                    {/*    />*/}
                    {/*)}*/}
                </SubMenu>
                {canAccessPage('category') && (
                    <SubMenu
                        handleToggle={() => handleToggle('menuCategory')}
                        isOpen={state.menuCategory}
                        sidebarIsOpen={open}
                        name="pos.menu.category"
                        icon={<CategoryTwoTone fontSize={'small'} />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/callReflect`}
                            primaryText={getText(
                                translate(`pos.menu.callReflect`, {
                                    smart_count: 2,
                                })
                            )}
                            leftIcon={
                                <Lens
                                    style={{ fontSize: 4 }}
                                    className={classes.icon}
                                />
                            }
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/keywords`}
                            primaryText={getText(
                                translate(`pos.menu.keywords`, {
                                    smart_count: 2,
                                })
                            )}
                            leftIcon={
                                <Lens
                                    style={{ fontSize: 4 }}
                                    className={classes.icon}
                                />
                            }
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    </SubMenu>
                )}

                {/*<Box m={1.5}>*/}
                {/*    <Typography variant="subtitle2" color="secondary">*/}
                {/*        {translate('pos.menu.setting')}*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                {/*<MenuItemLink*/}
                {/*    to="/category"*/}
                {/*    primaryText={getText(translate('pos.configuration'))}*/}
                {/*    leftIcon={<SettingsTwoTone fontSize={'small'} />}*/}
                {/*    onClick={onMenuClick}*/}
                {/*    sidebarIsOpen={open}*/}
                {/*    dense={dense}*/}
                {/*/>*/}
                {/*{canAccessPage('filtersTemplate') && (*/}
                {/*    <MenuItemLink*/}
                {/*        to="/filtersTemplate"*/}
                {/*        primaryText={getText(*/}
                {/*            translate('pos.menu.filtersTemplate')*/}
                {/*        )}*/}
                {/*        leftIcon={<PageviewTwoTone fontSize={'small'} />}*/}
                {/*        onClick={onMenuClick}*/}
                {/*        sidebarIsOpen={open}*/}
                {/*        dense={dense}*/}
                {/*    />*/}
                {/*)}*/}
                {/*{canAccessPage('notifyFlow') && (*/}
                {/*    <MenuItemLink*/}
                {/*        to="/notifyFlow"*/}
                {/*        primaryText={getText(translate('pos.menu.notifyFlow'))}*/}
                {/*        leftIcon={<NotificationsTwoTone fontSize={'small'} />}*/}
                {/*        onClick={onMenuClick}*/}
                {/*        sidebarIsOpen={open}*/}
                {/*        dense={dense}*/}
                {/*    />*/}
                {/*)}*/}
                {/*{canAccessPage('notifyTemplate') && (*/}
                {/*    <MenuItemLink*/}
                {/*        to="/notifyTemplate"*/}
                {/*        primaryText={getText(*/}
                {/*            translate('pos.menu.notifyTemplate')*/}
                {/*        )}*/}
                {/*        leftIcon={<DraftsTwoTone fontSize={'small'} />}*/}
                {/*        onClick={onMenuClick}*/}
                {/*        sidebarIsOpen={open}*/}
                {/*        dense={dense}*/}
                {/*    />*/}
                {/*)}*/}
                {isXSmall && logout}
            </Box>
        </PerfectScrollbar>
    );
};

export default Menu;
