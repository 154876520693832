import {Reducer} from 'redux';
export type ResourceSetting = {
    [resource: string]: {
        hiddenFields: string[];
    };
};

const defaultResourceSetting: ResourceSetting = {
    ['call']: {
        hiddenFields: [],
    },
};

export const ACTIONS = {
    UPDATE_HIDDEN_FIELDS: 'resource.updateHiddenFields'
};

type UpdateHiddenFieldsData = { resource: string; hiddenFields: string[] };

const updateHiddenFields_ = (
    previousState: ResourceSetting,
    payload: UpdateHiddenFieldsData
) => {
    const {resource, hiddenFields} = payload;
    const newState = {
        ...previousState,
    };
    newState[resource] = {
        ...newState[resource],
        hiddenFields: hiddenFields,
    };
    return newState;
};

export const resourceSettingReducer: Reducer<ResourceSetting> = (
    previousState = defaultResourceSetting,
    action
) => {
    switch (action.type) {
        case ACTIONS.UPDATE_HIDDEN_FIELDS:
            return updateHiddenFields_(previousState, action.payload);
        default:
            return previousState;
    }
};

export const updateHiddenFields = (data: UpdateHiddenFieldsData) => ({
    type: ACTIONS.UPDATE_HIDDEN_FIELDS,
    payload: data,
});
