export const darkTheme = {
    palette: {
        primary: {
            main: '#0060af',
            light: '#337fbf',
            dark: '#00437a',
        },
        secondary: {
            main: '#ed1c24',
            light: '#f0494f',
            dark: '#a51319',
        },
        type: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
    },
    shape: {
        borderRadius: 10,
    },
    overrides: {
        RaList: {
            main: {
                marginTop: 16,
            },
            content: {
                overflow: 'auto',
            },
        },
        RaListToolbar: {
            actions: {
                paddingTop: '16px',
            },
        },
        RaTopToolbar: {
            root: {
                paddingTop: '16px',
            },
        },
        TableHeaderCell: {
            cell: {
                padding: '6px 24px 6px 16px',
            },
        },
        TableCell: {
            cell: {
                padding: '6px 24px 6px 16px',
            },
        },
        MuiAlert: {
            root: {
                border: 'none',
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: '0.875rem',
            },
        },
        RaLayout: {
            content: {
                paddingLeft: '24px !important',
            },
        },
        RaSidebar: {
            fixed: {
                position: 'relative',
            },
            root: {
                borderRight: '1px solid rgba(145, 158, 171, 0.24)',
                position: 'fixed',
                top: 0,
                display: 'flex',
                height: '100vh',
                zIndex: 1000,
            },
        },
        // RaSearchInput: {
        //     input: {
        //         marginTop: '8px',
        //     },
        // },
        RaMenuItemLink: {
            icon: {
                minWidth: '32px',
            },
            root: {
                paddingTop: '12px',
                paddingBottom: '12px',
                // borderRight: '4px solid #fff',
            },
            active: {
                '& div': {
                    color: '#0060af',
                },
                backgroundColor: 'rgb(0 96 216 / 8%)',
                color: '#0060af',
                fontWeight: 500,
                borderRight: '3px solid',
                borderRightColor: '#0060af',
            },
        },
        MuiPopover: {
            paper: {
                border: 'none',
            },
        },
        // MuiPaper: {
        //     elevation1: {
        //         boxShadow: 'none',
        //     },
        //     rounded: {
        //         borderRadius: '8px',
        //     },
        //
        //     root: {
        //         border: '1px solid rgba(145, 158, 171, 0.24)',
        //         backgroundClip: 'padding-box',
        //     },
        // },
        MuiButton: {
            root: {
                borderRadius: '4px',
            },
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',
                },
            },
        },
        MuiAppBar: {
            root: {
                border: 'none',
            },
            colorSecondary: {
                color: '#808080',
                backgroundColor: '#fff',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiSnackbarContent: {
            root: {
                border: 'none',
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: false,
        },
    },
};

export const lightTheme = {
    palette: {
        primary: {
            main: '#0060af',
            light: '#337fbf',
            dark: '#00437a',
        },
        secondary: {
            main: '#ed1c24',
            light: '#f0494f',
            dark: '#a51319',
        },
        background: {
            default: '#ffffff',
        },
        type: 'light' as 'light',
    },
    shape: {
        borderRadius: 10,
    },
    overrides: {
        RaList: {
            main: {
                marginTop: 16,
            },
            content: {
                overflow: 'auto',
            },
        },
        RaDatagrid: {
            rowEven: {
                backgroundColor: '#f5f5f5',
            },
        },
        RaListToolbar: {
            actions: {
                paddingTop: '16px',
            },
        },
        RaTopToolbar: {
            root: {
                paddingTop: '16px',
            },
        },
        TableHeaderCell: {
            cell: {
                padding: '6px 24px 6px 16px',
            },
        },
        TableCell: {
            cell: {
                padding: '6px 24px 6px 16px',
            },
        },
        MuiAlert: {
            root: {
                border: 'none',
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: '0.875rem',
            },
        },
        RaLayout: {
            content: {
                paddingLeft: '24px !important',
                background: 'rgb(244, 245, 247)',
            },
        },
        RaSidebar: {
            root: {
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                position: 'fixed',
                top: 0,
                display: 'flex',
                height: '100vh',
                zIndex: 1000,
                backgroundColor: 'white',
            },
            fixed: {
                position: 'relative',
            },
        },
        // RaSearchInput: {
        //     input: {
        //         marginTop: '8px',
        //     },
        // },
        RaMenuItemLink: {
            icon: {
                minWidth: '22px',
                marginRight: '16px',
            },
            root: {
                paddingTop: '12px',
                paddingBottom: '12px',
                // borderRight: '4px solid #fff',
            },
            active: {
                '& div': {
                    color: '#0060af',
                },
                backgroundColor: 'rgb(0 96 216 / 8%)',
                color: '#0060af',
                fontWeight: 500,
                borderRight: '3px solid',
                borderRightColor: '#0060af',
            },
        },
        MuiPopover: {
            paper: {
                border: 'none',
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow:
                    'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
                // boxShadow: 'none',
            },
            // elevation8: {
            //     boxShadow:
            //         '0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 4%)',
            // },
            rounded: {
                borderRadius: '8px',
            },

            root: {
                backgroundClip: 'padding-box',
                // border: '1px solid rgba(0, 0, 0, 0.06)',
            },
        },
        MuiButton: {
            root: {
                borderRadius: '4px',
            },
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',
                },
            },
        },
        MuiAppBar: {
            root: {
                border: 'none',
            },
            colorSecondary: {
                color: '#808080',
                backgroundColor: '#fff',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiSnackbarContent: {
            root: {
                border: 'none',
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: false,
        },
    },
};
