import {useFormState} from "react-final-form";
import {Button, fetchUtils, useNotify, useTranslate} from "react-admin";
import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {isTrue} from "../report/ReportButton";
import {useState} from "react";
import {BASE_URL} from "../common/constants";
import {buildHeader} from "../utils/utils";
import {stringify} from "querystring";
const styles = {
    root: {
        marginLeft: '10px'
    },
}

const requireFieldNames = [
    'fromStarttime',
    'toStarttime',
    'partnerId',
]

const useStyles = makeStyles(styles);
const CheckNumCallButton = () => {
    const formState = useFormState()
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState('');
    const notify = useNotify()
    const translate = useTranslate();
    const require = requireFieldNames.map(v => translate("resources.distribution.fields." + v)).join("/")
    const checkValidated = () => {
        for(let i = 0; i < requireFieldNames.length; i++) {
            if(requireFieldNames[i] in formState.values) {
                if(!isTrue(formState.values[requireFieldNames[i]])) {
                    notify('Các trường ' + require + ' cần được nhập', { type: 'warning' })
                    return false
                }
            } else {
                notify('Các trường ' + require + ' cần được nhập', { type: 'warning' })
                return false
            }
        }
        return true
    }
    const checkNumCall = () => {
        if(checkValidated()) {
            let data = {
                typeOfCallCondition: formState.values.typeOfCallCondition,
                fromStarttime: new Date(formState.values.fromStarttime).getTime(),
                toStarttime: new Date(formState.values.toStarttime).getTime(),
                partnerId: formState.values.partnerId,
                dtvIds: formState.values.dtvIds,
                incoming: formState.values.incoming,
                inums: formState.values.inums,
                topicIds: formState.values.topicIds,
            }
            const fromDurations: any[] = []
            const toDurations: any[] = []
            if('durations' in formState.values) {
                const durations = formState.values.durations
                // console.log('durations', durations)
                if(isTrue(durations)) {
                    for(let i = 0; i < durations.length; i++) {
                        if(isTrue(durations[i])) {
                            if(!isTrue(durations[i]["fromDuration"])) {
                                notify("Nhập thời lượng từ", "warning")
                                return;
                            }
                            if(!isTrue(durations[i]["toDuration"])) {
                                notify("Nhập thời lượng đến", "warning")
                                return;
                            }
                            if(!Number.isInteger(durations[i]["fromDuration"]) || !Number.isInteger(durations[i]["toDuration"])) {
                                notify("Thời lượng phải là số nguyên", "warning")
                                return;
                            }

                            toDurations.push(durations[i]["toDuration"])
                            fromDurations.push(durations[i]["fromDuration"])
                        }
                    }
                }
            }
            if(isTrue(fromDurations) && isTrue(toDurations) && fromDurations.length === toDurations.length) {
                data["fromDurations"] = fromDurations
                data["toDurations"] = toDurations
            }

            setLoading(true);

            fetchUtils.fetchJson(
                `${BASE_URL}/distribution/checkNumCall?${stringify({...data})}`,
                {method: 'GET', headers: buildHeader()})
                .then(response => {
                    setLoading(false);
                    setResult(response.body)
                    setOpen(true)
                }).catch(({body, ...res}) => {
                    setLoading(false);
                    notify(body.message, {type: 'warning'});
                });
        }
    }
    const classes = useStyles();
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box>
            <Button
                disabled={loading || !formState.dirty}
                className={classes.root}
                variant="contained"
                color="primary"
                size="medium"
                onClick={checkNumCall}
                label={"Số lượng cuộc gọi tìm được"}
                startIcon={
                    loading && <CircularProgress size={16} />
                }
            />
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Kết quả tìm kiếm</DialogTitle>
                <DialogContent>
                    <Box sx={{whiteSpace: 'pre-wrap',}}>{result}</Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} label={"Thoát"}/>
                </DialogActions>
            </Dialog>
        </Box>

    )
}

export default CheckNumCallButton;