import { TranslationMessages } from 'react-admin';

const vietnameseMessages: TranslationMessages = {
    ra: {
        action: {
            add_filter: 'Thêm filters',
            add: 'Thêm',
            back: 'Trở về',
            bulk_actions: '%{smart_count} đã chọn',
            cancel: 'Hủy bỏ',
            clear_input_value: 'Xóa giá trị',
            clone: 'Sao chép',
            confirm: 'Xác nhận',
            create: 'Tạo mới',
            create_item: 'Create %{item}',
            delete: 'Xóa',
            edit: 'Sửa',
            export: 'Xuất',
            list: 'Danh sách',
            refresh: 'Làm mới',
            remove_filter: 'Bỏ filters',
            remove: 'Xóa bỏ',
            save: 'Lưu',
            search: 'Tìm kiếm',
            show: 'Hiển thị',
            sort: 'Sắp xếp',
            undo: 'Hoàn tác',
            unselect: 'Huỷ chọn',
            expand: 'Mở rộng',
            close: 'Đóng',
            open_menu: 'Mở menu',
            close_menu: 'Đóng menu',
            update: 'Cập nhật',
            move_up: 'Lên',
            move_down: 'Xuống',
            select_all: 'string',
            select_row: 'string',
        },
        boolean: {
            true: 'Có',
            false: 'Không',
            null: '',
        },
        page: {
            create: 'Tạo %{name}',
            dashboard: 'Bảng điều khiển',
            edit: '%{name} #%{id}',
            error: 'Đã xảy ra lỗi',
            list: 'Danh sách %{name}',
            loading: 'Đang tải',
            not_found: 'Không tìm thấy',
            show: '%{name} #%{id}',
            empty: 'Chưa có %{name} nào.',
            invite: 'Bạn có muốn tạo mới không?',
        },
        input: {
            file: {
                upload_several:
                    'Nhấp để chọn một tệp tin hoặc kéo thả nhiều tệp tin để tải lên.',
                upload_single:
                    'Nhấp để chọn một tệp tin hoặc kéo thả để tải lên.',
            },
            image: {
                upload_several:
                    'Nhấp để chọn một bức ảnh hoặc kéo thả nhiều bức ảnh để tải lên.',
                upload_single:
                    'Nhấp để chọn một bức ảnh hoặc kéo thả để tải lên.',
            },
            references: {
                all_missing: 'Không thể tìm thấy dữ liệu tham khảo.',
                many_missing:
                    'Ít nhất một tài liệu tham khảo liên quan không còn tồn tại.',
                single_missing:
                    'Tài liệu tham khảo liên quan không còn tồn tại.',
            },
            password: {
                toggle_visible: 'Ẩn mật khẩu',
                toggle_hidden: 'Hiện mật khẩu',
            },
        },
        message: {
            about: 'Về',
            are_you_sure: 'Bạn có chắc chắn không?',
            bulk_delete_content:
                'Bạn có chắc chắn muốn xóa %{name} này? |||| Bạn có chắc chắn muốn xóa %{smart_count} bản ghi này?',
            bulk_delete_title:
                'Xóa %{name} |||| Xóa %{smart_count} bản ghi %{name}',
            delete_content: 'Bạn có chắc chắn muốn xóa bản ghi này?',
            delete_title: 'Xóa %{name}',
            details: 'Chi tiết',
            error:
                'Đã xảy ra lỗi ở phía người dùng và yêu cầu của bạn không thể hoàn thành.',
            invalid_form: 'Dữ liệu nhập không đúng. Vui lòng kiểm tra lại',
            loading: 'Trang web đang tải, vui lòng đợi trong giây lát',
            no: 'Không',
            not_found:
                'Bạn đã nhập một URL sai hoặc bạn đã làm theo một liên kết không đúng.',
            yes: 'Có',
            unsaved_changes:
                'Một vài thay đổi của bạn chưa được lưu. Bạn có chắc là muốn bỏ qua chúng?',
            bulk_update_content: 'Nội dung cập nhật',
            bulk_update_title: 'Tiêu đề cập nhật',
        },
        navigation: {
            no_results: 'Không tìm thấy kết quả nào',
            no_more_results:
                'Trang số %{page} đã vượt giới hạn. Vui lòng trở lại trang trước.',
            page_out_of_boundaries: 'Trang số %{page} đã vượt giới hạn',
            page_out_from_end: 'Không thể đi tiếp sau trang cuối',
            page_out_from_begin: 'Không thể trở lại trước trang 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} của %{total}',
            page_rows_per_page: 'Số hàng trên mỗi trang:',
            next: 'Tiếp',
            prev: 'Trước',
            skip_nav: 'Bỏ qua',
        },
        sort: {
            sort_by: 'Sắp xếp theo %{field} %{order}',
            ASC: 'Thuận',
            DESC: 'Đảo',
        },
        auth: {
            auth_check_error: 'Vui lòng đăng nhập để tiếp tục',
            user_menu: 'Hồ sơ',
            username: 'Tên đăng nhập',
            password: 'Mật khẩu',
            sign_in: 'Đăng nhập',
            sign_in_sso: 'Đăng nhập SSO',
            sign_in_error: 'Đăng nhập không thành công, vui lòng thử lại',
            logout: 'Đăng xuất',
        },
        notification: {
            updated:
                'Cập nhật thành công |||| Cập nhật thành công %{smart_count} thành phần',
            created: 'Đã tạo thành công',
            deleted:
                'Đã xóa thành công |||| Xóa thành công %{smart_count} thành phần',
            bad_item: 'Thành phần không đúng',
            item_doesnt_exist: 'Thành phần không tồn tại',
            http_error: 'Lỗi giao tiếp với máy chủ',
            data_provider_error:
                'Lỗi dataProvider. Kiểm tra console để biết chi tiết.',
            i18n_error: 'Không thể tải bản dịch cho ngôn ngữ này',
            canceled: 'Hủy bỏ hành động',
            logged_out:
                'Phiên làm việc của bạn đã kết thúc, vui lòng kết nối lại.',
            not_authorized: 'Không có quyền',
        },
        validation: {
            required: 'Bắt buộc',
            minLength: 'Phải ít nhất %{min} ký tự',
            maxLength: 'Phải nhỏ hơn hoặc bằng %{max} ký tự',
            minValue: 'Phải lớn hơn hoặc bằng %{min}',
            maxValue: 'Phải nhỏ hơn hoặc bằng %{max}',
            number: 'Phải là số',
            email: 'Phải là địa chỉ email hợp lệ',
            oneOf: 'Phải chọn một trong số lựa chọn sau: %{options}',
            regex: 'Phải phù hợp với định dạng (regexp): %{pattern}',
        },
    },
    pos: {
        search: 'Tìm kiếm',
        configuration: 'Cấu hình',
        language: 'Ngôn ngữ',
        theme: {
            name: 'Giao diện',
            light: 'Sáng',
            dark: 'Tối',
        },
        permission: {
            view: 'Xem',
            add: 'Thêm',
            edit: 'Sửa',
            delete: 'Xóa',
            rate1: 'Đánh giá lần 1 2',
            rate2: 'Đánh giá lần 2',
            rategsv: 'Đánh GSV',
            complain: 'Khiếu nại',
            export: 'Xuất Excel',
            ppc1: 'Phân phối cấp 1',
            ppc2: 'Phân phối cấp 2',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            month_history: '30 Day Revenue History',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'New Customers',
            all_customers: 'See all customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to the react-admin e-commerce demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                ra_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            dashboard: 'Dashboard',
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
            user: 'Người dùng',
            group: 'Nhóm người dùng',
            partner: 'Đối tác',
            department: 'Đơn vị',
            general: 'CHUNG',
            callSupervision: 'TÍCH MÃ CUỘC GỌI',
            management: 'QUẢN TRỊ',
            setting: 'CÀI ĐẶT',
            filtersTemplate: 'Tiêu chí lọc',
            notifyFlow: 'Luồng thông báo',
            notifyTemplate: 'Mẫu thông báo',
            report: 'Báo cáo',
            reportV3: 'Báo cáo V3',
            distribution: 'Phân phối cuộc gọi',
            category: 'Danh mục',
            period: 'Kỳ giám sát',
            criteriaCall: 'Tiêu chí đánh giá CG',
            criteriaGSV: 'Tiêu chí đánh giá GSV',
            callReflect: 'Chủ đề tích mã',
            groupCallService: 'Nhóm dịch vụ',
            'telephonist-classification': 'Phân loại GSV',
            callSubjectClassification: 'Phân loại CG theo chủ đề',
            'form-evaluate': 'Hình thức đánh giá',
            warningCategory: 'Cảnh báo',
            telephonistCriteria: 'Phân loại ĐTV',
            keywords: 'Từ điển chuyên ngành',
            telephonistGroup: 'Nhóm người dùng',
        },
    },
    resources: {
        user: {
            name: 'Người dùng |||| Người dùng ',
            fields: {
                groupIds: 'Nhóm quyền',
                first_seen: 'Lần đầu tiên nhìn thấy',
                createTime: 'Ngày tạo',
                createBy: 'Người tạo',
                fullName: 'Họ tên',
                status: 'Trạng thái',
                note: 'Ghi chú',
                department: 'Đơn vị',
                deptId: 'Đơn vị',
                deptType: 'Trực thuộc',
                deptName: 'Đơn vị',
                userName: 'Tên đăng nhập',
                birthday: 'Ngày sinh',
                phoneNumber: 'Số́ điện thoại',
                sex: 'Giới tính',
                userType: 'Chức danh',
                telephonistCode: 'Danh số',
                description: 'Ghi chú',
                partnerId: 'Đối tác',
                updateTime: 'Cập nhật thời gian',
            },
            fieldGroups: {
                identity: 'Thông tin tài khoản nguoi dùng',
                stats: 'Thông tin đơn vị',
                history: 'Lịch sử',
                change_password: 'Mật khẩu',
            },
            errors: {
                password_mismatch: 'Mật khẩu không trùng khớp',
            },
        },
        department: {
            name: 'Đơn vị |||| Đơn vị',
            fields: {
                deptName: 'Tên đơn vị',
                deptCode: 'Mã đơn vị',
                parentId: 'Đơn vị cha',
            },
        },
        partner: {
            name: 'Đối tác',
            fields: {
                name: 'Tên',
                code: 'Mã',
                deptName: 'Đơn vị phối hợp',
                status: 'Trạng thái',
                description: 'Mô tả',
                deptId: 'Đơn vị phối hợp',
            },
            tabs: {
                information: 'Thông tin chung',
                users: 'Danh sách nhân viên',
            },
        },
        group: {
            detail: 'Chi tiết',
            name: 'Nhóm quyền',
            fields: {
                groupCode: 'Mã',
                groupName: 'Tên',
                description: 'Mô tả',
                status: 'Trạng thái',
            },
            tabs: {
                information: 'Thông tin chung',
                permissions: 'Quyền',
            },
        },
        telephonistGroup: {
            detail: 'Chi tiết',
            name: 'Nhóm người dùng',
            fields: {
                partnerId: 'Đối tác',
                name: 'Tên',
                description: 'Mô tả',
                status: 'Trạng thái',
                createTime: 'Thời gian tạo',
                quantityTelephonist: 'Số lượng người dùng',
                type: 'Kiểu nhóm',
            },
            tabs: {
                information: 'Thông tin chung',
                users: 'Người dùng',
            },
        },
        monitoringPeriod: {
            detail: 'Chi tiết',
            name: 'Kỳ giám sát |||| Danh sách Kỳ giám sát',
            fields: {
                startTime: 'Từ ngày',
                finishTime: 'Tới ngày',
                name: 'Mô tả kỳ giám sát',
                departmentIds: 'Đơn vị áp dụng',
                departmentId: 'Đơn vị áp dụng',
            },
            tabs: {
                information: 'Thông tin chung',
                tieuChi: 'Tiêu chí',
            },
        },
        call: {
            name: 'Gọi |||| Cuộc gọi ',
            fields: {
                tichMaId: 'Chủ đề tích mã',
                reason: 'Lý do',
                agentId: 'Danh số',
                agentDesc: 'Tên ĐTV',
                callId: 'Id cuộc gọi',
                starttime: 'Thời gian bắt đầu',
                hotlineRoomName: 'Số bàn',
                hotlineRoomDesc: 'Nhánh dịch vụ',
                partnerId: 'Đối tác',
                phoneNumber: 'Số điện thoại',
                duration: 'Thời lượng',
                evaluate: 'Đánh giá',
                generalComment: 'NX chung',
                incoming: 'Hướng',
                callDirection: 'Hướng cuộc gọi',
                topic: 'Chủ đề',
                action: 'Thao tác',
                callRating1: {
                    ratingDate: 'Ngày đánh giá',
                    scores: 'Điểm',
                    supervisorName: 'Tên người giám sát',
                },
                callCreate: 'Thông tin cuộc gọi',
                updateErrorPersonId: 'Người đánh lỗi',
                updateErrorDate: 'Thời gian đánh lỗi',
                errorReason: 'Lý do đánh lỗi',
            },
            filters: {
                start_time: 'Thời gian bắt đầu',
                duration: 'Độ dài',
                today: 'Hôm nay',
                this_week: 'Tuần này',
                last_week: 'Tuần trước',
                this_month: 'Tháng này',
                last_month: 'Tháng trước',
                earlier: 'Trước đó',
            },
        },
        category: {
            name: 'Cấu hình |||| Dan sách cấu hình ',
            fields: {
                name: 'Tên',
                code: 'Mã',
                isActive: 'Kích hoạt',
                createTime: 'Thời gian tạo',
                value: 'Giá trị',
                groupId: 'Nhóm cấu hình',
                description: 'Mô tả',
                status: 'Trạng thái',
            },
            filters: {
                group: 'Nhóm cấu hình',
            },
        },
        complain: {
            name: 'Khiếu nại |||| Khiếu nại ',
            fields: {
                agentId: 'Danh số',
                agentDesc: 'Tên nhà điện thoại',
                callId: 'Id cuộc gọi',
                start_time: 'Thời gian bắt đầu',
                content: 'Nội dung',
                opinion: 'Ý kiến',
                opinionResponse: 'Phản hồi khiếu nại',
                supervisorName: 'Giám sát viên',
                createBy: 'Người tạo',
                createTime: 'Thời gian tạo',
                callRating1: {},
            },
            action: {
                accept: 'Đồng ý',
                reject: ' Từ chối',
            },
            filters: {
                group: 'Nhóm cấu hình',
                createTime: 'Thời gian bắt đầu',
                duration: 'Duration',
                today: 'Hôm nay',
                this_week: 'Tuần này',
                last_week: 'Tuần trước',
                this_month: 'Tháng này',
                last_month: 'Tháng trước',
                earlier: 'Trước đó',
                status: 'Trạng thái',
                personal: 'Cá nhân',
            },
        },
        evaluateOther: {
            name: 'Thông tin đánh giá |||| Đánh giá GSV ',
            fields: {
                agentId: 'Danh số',
                agentDesc: 'Tên nhà điện thoại',
                callId: 'Id cuộc gọi',
                start_time: 'Thời gian bắt đầu',
                content: 'Nội dung',
                opinion: 'Ý kiến điều chỉnh',
                opinionResponse: 'Phản hồi đánh giá',
                supervisorName: 'Giám sát viên được đánh giá',
                createBy: 'Người tạo',
                createTime: 'Thời gian tạo',
                rankSelected: 'Kết quả đánh giá',
                score: 'Thông tin đánh giá',
            },
            action: {
                accept: 'Đồng ý',
                reject: ' Từ chối',
            },
            filters: {
                group: 'Nhóm cấu hình',
                createTime: 'Thời gian bắt đầu',
                duration: 'Duration',
                today: 'Hôm nay',
                this_week: 'Tuần này',
                last_week: 'Tuần trước',
                this_month: 'Tháng này',
                last_month: 'Tháng trước',
                earlier: 'Trước đó',
                status: 'Trạng thái',
                personal: 'Cá nhân',
            },
        },
        period: {
            name: 'Kỳ giám sát |||| Kỳ giám sát ',
            fields: {
                name: 'Tên',
                startTime: 'Ngày áp dụng',
                finishTime: 'Ngày kết thúc',
                isActive: 'Trạng thái',
                createTime: 'Ngày tạo',
                opinion: 'Ý kiến',
                opinionResponse: 'Phản hồi khiếu nại',
                supervisorName: 'Giám sát viên',
                createBy: 'Người tạo',
                callRating1: {},
            },
        },
        filtersTemplate: {
            name: 'Kỳ giám sát |||| Kỳ giám sát ',
            fields: {
                name: 'Tên',
                startTime: 'Ngày áp dụng',
                finishTime: 'Ngày kết thúc',
                isActive: 'Trạng thái',
                createTime: 'Ngày tạo',
                description: 'Mô tả',
                opinionResponse: 'Phản hồi khiếu nại',
                supervisorName: 'Giám sát viên',
                createBy: 'Người tạo',
                activeFilters: 'Tiêu chí kích hoạt',
            },
        },
        notifyFlow: {
            name: 'Luồng thông báo',
            fields: {
                name: 'Tên',
                majorId: 'Nghiệp vụ',
                notifyTemplateId: 'Mẫu thông báo',
                startTime: 'Ngày áp dụng',
                finishTime: 'Ngày kết thúc',
                isActive: 'Trạng thái',
                createTime: 'Ngày tạo',
                description: 'Mô tả',
                opinionResponse: 'Phản hồi khiếu nại',
                supervisorName: 'Giám sát viên',
                createBy: 'Người tạo',
                activeFilters: 'Tiêu chí kích hoạt',
            },
        },
        notifyTemplate: {
            name: 'Mẫu thông báo',
            fields: {
                name: 'Tên',
                notifyTemplateId: 'Mẫu thông báo',
                startTime: 'Ngày áp dụng',
                finishTime: 'Ngày kết thúc',
                isActive: 'Trạng thái',
                createTime: 'Ngày tạo',
                description: 'Mô tả',
                opinionResponse: 'Phản hồi khiếu nại',
                content: 'Nội dung thông báo',
                createBy: 'Người tạo',
                type: 'Loại thông báo',
            },
        },
        keywords: {
            name: 'Từ điển chuyên ngành',
            fields: {
                textValue: 'Từ điển chuyên ngành',
                keywordGroupId: 'Nhóm Từ điển chuyên ngành',
                startTime: 'Ngày áp dụng',
                finishTime: 'Ngày kết thúc',
                isActive: 'Trạng thái',
                createTime: 'Ngày tạo',
                description: 'Mô tả',
                opinionResponse: 'Phản hồi khiếu nại',
                content: 'Nội dung thông báo',
                createBy: 'Người tạo',
                type: 'Loại thông báo',
            },
        },
        telephonistCriteria: {
            name: 'Phân loại ĐTV',
            fields: {
                name: 'Tên phân loại',
                parentId: 'Phân loại cha',
                description: 'Mô tả',
            },
        },
        warningCategory: {
            name: 'Cảnh báo',
            fields: {
                name: 'Tên cảnh báo',
                parentId: 'Phân loại cha',
                description: 'Mô tả',
            },
        },
        'form-evaluate': {
            name: 'Cảnh báo',
            fields: {
                name: 'Tên tiêu chí',
                parentId: 'Phân loại cha',
                description: 'Mô tả',
            },
        },
        groupCallService: {
            name: 'Nhóm dịch vụ',
            fields: {
                name: 'Tên nhóm',
                parentId: 'Phân loại cha',
                description: 'Mô tả',
            },
        },
        callReflect: {
            name: 'Chủ đề tích mã',
            fields: {
                name: 'Tên chủ đề tích mã',
                parentId: 'Cấp 1',
                description: 'Mô tả',
            },
        },
        callSubjectClassification: {
            name: 'Phân loại cuộc gọi theo chủ đề',
            fields: {
                name: 'Tên phân loại',
                parentId: 'Phân loại cha',
                description: 'Mô tả',
            },
        },
        'telephonist-classification': {
            name: 'Phân loại giám sát viên',
            fields: {
                name: 'Tên phân loại',
                parentId: 'Phân loại cha',
                description: 'Mô tả',
            },
        },
        criteriaCall: {
            name: 'Tiêu chí đánh giá cuộc gọi',
            fields: {
                name: 'Tên tiêu chí',
                parentId: 'Phân loại cha',
                score: 'Điểm',
            },
        },
        criteriaGSV: {
            name: 'Tiêu chí đánh giá giám sát viên',
            fields: {
                name: 'Tên tiêu chí',
                parentId: 'Phân loại cha',
                content: 'Mô tả',
                score: 'Điểm',
            },
        },
        profile: {
            name: 'Tiêu chí đánh giá giám sát viên',
            fields: {
                oldPassword: 'Mật khẩu cũ',
                newPassword: 'Mật khẩu mới',
                confirmPassword: 'Xác nhận mật khẩu',
            },
        },
        distribution: {
            name: 'Phân phối cuộc gọi',
            fields: {
                fromDate: 'Từ ngày',
                toDate: 'Đến ngày',
                isActive: 'Kích hoạt',
                createTime: 'Thời gian tạo',
                createBy: 'Tạo bởi',
                description: 'Chú thích phân phối',
                monitoringPeriodId: 'Kỳ giám sát',
                numberOfCall: 'Số cuộc gọi cần giám sát',
                gsvIds: 'Giám sát viên',
                partnerId: 'Đối tác cần giám sát',
                fromDuration: 'Thời lượng từ (giây)',
                toDuration: 'Thời lượng đến (giây)',
                telephonistGroupId: 'Nhóm điện thoại viên',
                topicIds: 'Chủ đề',
                dtvIds: 'Điện thoại viên',
                tiLeCuocGoiGiamSat: 'Tỉ lệ số cuộc gọi cần giám sát (%)',
                donViGiamSatIds: 'Đơn vị giám sát',
                donViGiamSatId: 'Đơn vị giám sát',
                fromStarttime: 'Ngày bắt đầu cuộc gọi từ',
                toStarttime: 'Ngày bắt đầu cuộc gọi đến',
                durations: 'Thời lượng cuộc gọi',
                typeOfCallQuantity: 'Đơn vị lấy mẫu cuộc gọi',
                callQuantity: 'Số lượng/ Tỉ lệ cuộc gọi',
                inums: 'Inum cuộc gọi',
                incoming: 'Hướng cuộc gọi',
                typeOfCallCondition: 'Điều kiện lọc',
                numberFoundCall: 'Số lượng cuộc gọi lấy được',
            },
            action: {
                accept: 'Đồng ý',
                reject: ' Từ chối',
            },
            filters: {
                group: 'Nhóm cấu hình',
                createTime: 'Thời gian bắt đầu',
                duration: 'Duration',
                today: 'Hôm nay',
                this_week: 'Tuần này',
                last_week: 'Tuần trước',
                this_month: 'Tháng này',
                last_month: 'Tháng trước',
                earlier: 'Trước đó',
                status: 'Trạng thái',
                personal: 'Cá nhân',
            },
            tabs: {
                information: 'Thông tin chung',
                supervisorDepartment: 'Đơn vị giám sát',
                kpi: 'KPI',
            },
        },
    },
    error: {
        'user.changePassword.invalid.oldPassword':
            'Mật khẩu hiện tại không chính xác',
        'user.invalidPassword': 'Mật khẩu  không chính xác',
        'user.create.duplicate.username': 'Trùng tên đăng nhập',
        'user.create.duplicate.phone': 'Trùng số điện thoại',
        'complain.existed': 'Đang có khiếu nại chưa xử lý',
        'authenticate.jwtExpired':
            'Hết phiên đăng nhập, vui lòng đăng nhập lại',
        'authenticate.userNotExists': 'Người dùng không tồn tại',
        'authenticate.userDeactivated': 'Tài khoản đang bị khoá',
        'create.invalidForm': 'Sai dữ liệu thêm mới',
        'update.invalidForm': 'Sai dữ liệu cập nhật',
        not_found: 'Không tìm thấy bản ghi',
        'parentId.empty': 'Chưa chọn bản ghi cha',
        'group.create.exist.groupName': 'Nhóm đã tồn tại',
        'not.permission': 'Không có quyền thực hiện hành động này',
        resourceNotFound: 'Không tìm thấy bản ghi',
        'keyword.existed': 'Từ khoá đã tồn tại',
        'duplicate.code': 'Mã đã tồn tại',
        'callCriterias.invalid': 'Dữ liệu tiêu chí chưa đúng',
        'group.create.exist.groupCode': 'Mã nhóm đã tồn tại',
        'duplicate.monitoring.period':
            'Đã có kỳ giám sát trong khoảng thời gian này',
    },
};
export default vietnameseMessages;
